import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import {
  TableCollapseContainer,
  TablePanel,
  TableLine,
  TableColumn,
  ArrowImg,
  SectionTitle,
  SectionTitleContainer,
  BudgetGuestsNote
} from "./BudgetTableExpensesStyles";
import { numberFormat } from "../../infra/services/utils/FormatNumber";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import DetailBlock from "./components/DetailBlock";
import BaseServiceBlock from "./components/BaseServiceBlock";
import BaseButton from "../buttons/BaseButton";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";
import { CostTypes } from "../../infra/services/utils/Options";
import { WeddingStatus } from "../../pages/reserve/ReservePage";

const BudgetTableExpenses = ({
  expenses,
  expensesExtras,
  expensesList,
  offersList,
  discountsList,
  numTables,
  numGuests,
  minGuests,
  numPlaces,
  numChildren,
  numStaff,
  numPax,
  numPaxShow,
  music,
  priceGuest,
  upgrade,
  translate,
  activeLanguage,
  weddingInfo
}) => {
  const [openCollapse, setOpenCollapse] = useState([]);
  const [collapseButton, setCollapseButton] = useState("OPEN_ALL");

  const budgetExpenses = {
    name: 'BUDGET_EXPENSES',
    value: expensesList?.length > 0 ? expensesList.reduce((a, { value }) => a + value, 0) : 0,
    index: 0
  };

  const budgetOffers = {
    name: 'BUDGET_OFFERS',
    value: offersList?.length > 0 ? offersList.reduce((a, { value }) => a + value, 0) : 0,
    index: 2
  };

  const budgetDiscounts = {
    name: 'BUDGET_DISCOUNTS',
    value: discountsList?.length > 0 ? discountsList.reduce((a, { value }) => a + value, 0) : 0,
    index: 1
  };

  const toggleOpen = () => {
    if (openCollapse.length > 0) {
      setOpenCollapse([]);
      setCollapseButton("OPEN_ALL");
    } else {
      setOpenCollapse(["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", '10', '11', '12']);
      setCollapseButton("CLOSE_ALL");
    }
  };

  const collapseCallback = (keys) => {
    setOpenCollapse(keys);

    if (keys.length > 0) {
      setCollapseButton("CLOSE_ALL");
    } else {
      setCollapseButton("OPEN_ALL");
    }
  };

  const renderPriceDetail = (type, price, quantity, cost) => {
    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(cost !== undefined ? cost : price, 2, ",", ".")}€/un x ${quantity} ${translate("UNITS")}`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(cost !== undefined ? cost : price, 2, ",", ".")}€/pax x ${numPax} pax`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat(cost !== undefined ? cost : price, 2, ",", ".")}€/${translate('QUANTITY_PEOPLE')} x ${numGuests + numChildren} ${translate('PEOPLE')}`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(cost !== undefined ? cost : price, 2, ",", ".")}€/${translate("QUANTITY_TABLE")} x ${numTables} ${translate("TABLES")}`;
    }

    //Global
    return translate("VALUE");
  };

  const renderPrice = (type, price, quantity, cost) => {
    if (cost !== undefined) cost = parseFloat(cost);

    if (type === CostTypes.QUANTITY) {
      return `${numberFormat(quantity * (cost !== undefined ? cost : price), 2, ",", ".")}€`;
    } else if (type === CostTypes.GUEST) {
      return `${numberFormat(numPax * (cost !== undefined ? cost : price), 2, ",", ".")}€`;
    } else if (type === CostTypes.PEOPLE) {
      return `${numberFormat((numGuests + numChildren) * (cost !== undefined ? cost : price), 2, ",", ".")}€`;
    } else if (type === CostTypes.TABLE) {
      return `${numberFormat(numTables * (cost !== undefined ? cost : price), 2, ",", ".")}€`;
    }

    //Global
    return `${numberFormat(cost !== undefined ? cost : price, 2, ",", ".")}€`;
  };

  const renderDetailedExpenses = (tag) => {
    switch (tag) {
      case "BUDGET_ADJUDICATED":
        return (
          <React.Fragment>
            <BudgetGuestsNote>
              {translate("BUDGET_GUESTS_NOTE")}
            </BudgetGuestsNote>
            <BaseServiceBlock
              minGuests={minGuests}
              price={`${numberFormat(priceGuest, 2, ",", ".")}€`}
              pax={numPaxShow}
              seats={numPlaces}
            />
            {numGuests > 0 && (
              <DetailBlock
                title={translate("GUESTS_ADULTS")}
                subtitle={`${numGuests} x ${numberFormat(
                  priceGuest,
                  2,
                  ",",
                  "."
                )}€`}
                subtitleValue={`${numberFormat(
                  numGuests * priceGuest,
                  2,
                  ",",
                  "."
                )}€`}
              />
            )}
            {numChildren > 0 && (
              <DetailBlock
                title={translate("GUESTS_CHILDREN")}
                subtitle={`${numChildren} x ${numberFormat(
                  priceGuest / 2,
                  2,
                  ",",
                  "."
                )}€`}
                subtitleValue={`${numberFormat(
                  numChildren * (priceGuest / 2),
                  2,
                  ",",
                  "."
                )}€`}
              />
            )}
            {numStaff > 0 && (
              <DetailBlock
                title={translate("GUESTS_STAFF")}
                subtitle={`${numStaff} x ${numberFormat(
                  priceGuest / 2,
                  2,
                  ",",
                  "."
                )}€`}
                subtitleValue={`${numberFormat(
                  numStaff * (priceGuest / 2),
                  2,
                  ",",
                  "."
                )}€`}
              />
            )}
            {music > 0 && (
              <DetailBlock
                title={translate("MUSIC_LICENCE")}
                subtitle={`${numberFormat(music, 2, ",", ".")}€`}
              />
            )}
          </React.Fragment>
        );
      case 'BUDGET_ROOM_PLAN':
        return inGuestMapTest() && expensesExtras?.tables?.qtd > 0 && expensesExtras?.tables?.cost > 0
          ?
          <DetailBlock
            title={translate('EXTRA_TABLES')}
            subtitle={`${expensesExtras?.tables?.qtd} x ${numberFormat((expensesExtras?.tables?.cost || 0), 2, ",", ".")}€`}
            subtitleValue={`${numberFormat(expensesExtras?.tables?.qtd * (expensesExtras?.tables?.cost || 0), 2, ",", ".")}€`}
          />
          : <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>;
      case "BUDGET_CEREMONY":
        if (expensesExtras && expensesExtras.ceremony?.length > 0) {
          return expensesExtras.ceremony.map((expense, index) => (
            <DetailBlock
              key={`ceremony_${index}`}
              title={expense.title[activeLanguage?.code]}
              subtitle={
                renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_STAFF":
        if (expensesExtras && expensesExtras.staff?.length > 0) {
          return expensesExtras.staff.map((expense, index) => (
            <DetailBlock
              key={`staff_${index}`}
              title={expense.title[activeLanguage?.code]}
              subtitle={
                renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_FOOD":
        if (expensesExtras && expensesExtras.food?.length > 0) {
          return expensesExtras.food.map((expense, index) => {
            const isSidedishe = expense.category && expense.category === 'sidedishes' ? true : false;
            let title = expense.title ? expense.title[activeLanguage?.code] : expense.name ? expense.name[activeLanguage?.code] : null;
            if (isSidedishe) title = `${title} - ${translate('SIDEDISHE')}`;

            return <DetailBlock
              key={`food_${index}`}
              title={title}
              subtitle={
                renderPriceDetail(
                  expense.name ? CostTypes.GUEST : expense.cost_type,
                  expense.name ? expense.extra_cost : expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.name ? CostTypes.GUEST : expense.cost_type,
                  expense.name ? expense.extra_cost : expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          });
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_DRINK":
        if (expensesExtras && expensesExtras.drinks?.length > 0) {
          return expensesExtras.drinks.map((expense, index) => (
            <DetailBlock
              key={`drink_${index}`}
              title={expense.title[activeLanguage?.code]}
              subtitle={
                renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_DECORATION":
        if (expensesExtras && expensesExtras.decoration?.length > 0) {
          return expensesExtras.decoration.map((expense, index) => (
            <DetailBlock
              key={`decoration_${index}`}
              title={expense.title[activeLanguage?.code]}
              subtitle={
                renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_PARTY":
        if (expensesExtras && expensesExtras.party?.length > 0) {
          return expensesExtras.party.map((expense, index) => (
            <DetailBlock
              key={`party_${index}`}
              title={expense.title[activeLanguage?.code]}
              subtitle={
                renderPriceDetail(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
              subtitleValue={
                renderPrice(
                  expense.cost_type,
                  expense.cost_price,
                  expense.selected_quantity,
                  expense.selected_cost,
                )
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_UPGRADE":
        if (upgrade) {
          return (
            <DetailBlock
              title={upgrade.name[activeLanguage?.code]}
              subtitle={
                upgrade.price > 0
                  ? renderPriceDetail(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
              subtitleValue={
                upgrade.price > 0
                  ? renderPrice(CostTypes.GUEST, upgrade.price, 0, undefined)
                  : null
              }
            />
          );
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_EXPENSES":
        if (expensesList && expensesList.length > 0) {
          return expensesList.map((expense, index) => (
            <DetailBlock
              key={`others_${index}`}
              title={expense.title}
              subtitle={
                expense.value > 0
                  ? renderPriceDetail(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
              subtitleValue={
                expense.value > 0
                  ? renderPrice(CostTypes.GLOBAL, expense.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_OFFERS":
        if (offersList && offersList.length > 0) {
          return offersList.map((offer, index) => (
            <DetailBlock
              key={`offers_${index}`}
              title={offer.title}
              subtitle={
                offer.value > 0
                  ? renderPriceDetail(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
              subtitleValue={
                offer.value > 0
                  ? renderPrice(CostTypes.GLOBAL, offer.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      case "BUDGET_DISCOUNTS":
        if (discountsList && discountsList.length > 0) {
          return discountsList.map((discount, index) => (
            <DetailBlock
              key={`discount_${index}`}
              title={discount.title}
              subtitle={
                discount.value > 0
                  ? renderPriceDetail(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
              subtitleValue={
                discount.value > 0
                  ? renderPrice(CostTypes.GLOBAL, discount.value, 0, undefined)
                  : null
              }
            />
          ));
        }
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
      default:
        return (
          <div style={{ fontSize: "16px", color: "#000000" }}>
            {translate("BUDGET_NO_EXPENSES")}
          </div>
        );
    }
  };

  const renderLine = (detail) => {
    return (
      <TableLine>
        <TableColumn>{translate(detail.name)}</TableColumn>
        <TableColumn>
          {detail.value > 0
            ? `${numberFormat(detail.value, 2, ",", ".")}€`
            : ""}
        </TableColumn>
      </TableLine>
    );
  };

  const checkIfItsOpen = (tag) => {
    switch (tag) {
      case 'BUDGET_ADJUDICATED':
        return false;
      case 'BUDGET_ROOM_PLAN':
        return inGuestMapTest() && expensesExtras?.tables?.qtd > 0 && expensesExtras?.tables?.cost > 0 ? true : false;
      case 'BUDGET_CEREMONY':
        return expensesExtras?.ceremony?.length > 0 ? true : false;
      case 'BUDGET_STAFF':
        return expensesExtras?.staff?.length > 0 ? true : false;
      case 'BUDGET_FOOD':
        return expensesExtras?.food?.length > 0 ? true : false;
      case 'BUDGET_DRINK':
        return expensesExtras?.drinks?.length > 0 ? true : false;
      case 'BUDGET_DECORATION':
        return expensesExtras.decoration?.length > 0 ? true : false;
      case 'BUDGET_PARTY':
        return expensesExtras?.party?.length > 0 ? true : false;
      case 'BUDGET_UPGRADE':
        return upgrade ? true : false;
      case 'BUDGET_EXPENSES':  // 'BUDGET_OTHERS':
        return expensesList?.length > 0 ? true : false;
      case 'BUDGET_OFFERS':
        return offersList?.length > 0 ? true : false;
      case 'BUDGET_DISCOUNTS':
        return discountsList?.length > 0 ? true : false;
      default:
        return false;
    }
  }

  const hideConfirmedBudgetsInfo = () => {
    return weddingInfo?.status === WeddingStatus.RESERVE || weddingInfo?.status === WeddingStatus.RESERVE_SINALIZATION;
  };

  const getOpenPanels = () => {
    const openPanels = [];

    let stats = [...expenses];
    if (expenses?.length > 0) {
      stats = stats.filter(f => f?.tag !== 'BUDGET_EXPENSES');
      for (let index = 0; index < stats.length; index++) {
        const section = stats[index];
        const toOpen = checkIfItsOpen(section?.name);
        if (toOpen) openPanels.push(index?.toString())
      }
    }

    if (budgetExpenses?.value > 0) {
      const toOpen = checkIfItsOpen(budgetExpenses?.name);
      if (toOpen) {
        let index = stats?.length + budgetExpenses.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetDiscounts?.value > 0) {
      const toOpen = checkIfItsOpen(budgetDiscounts?.name);
      if (toOpen) {
        let index = stats?.length + budgetDiscounts.index;
        openPanels.push(index?.toString());
      }
    }

    if (budgetOffers?.value > 0) {
      const toOpen = checkIfItsOpen(budgetOffers?.name);
      if (toOpen) {
        let index = stats?.length + budgetOffers.index;
        openPanels.push(index?.toString());
      }
    }

    return openPanels;
  }

  return (
    <React.Fragment>
      <SectionTitleContainer>
        <SectionTitle>{translate("DETAILED_BUDGET")}</SectionTitle>
        <BaseButton
          align="initial"
          variant="raised"
          htmlType="button"
          type="primaryMedium"
          text={translate(collapseButton)}
          onClick={() => toggleOpen()}
        />
      </SectionTitleContainer>
      <TableCollapseContainer
        onChange={collapseCallback}
        activeKey={openCollapse || []}
        // defaultActiveKey={getOpenPanels()}
        expandIconPosition="right"
        expandIcon={({ isActive }) => (
          <ArrowImg src={ArrowDown} isActive={isActive} />
        )}
      >
        {expenses.length > 0 &&
          expenses
            .filter(f => hideConfirmedBudgetsInfo() ? f?.name === 'BUDGET_ADJUDICATED' : f?.name !== 'BUDGET_EXPENSES')
            .map((detail, index) => (
              <TablePanel header={renderLine(detail)} key={index}>
                {renderDetailedExpenses(detail.name)}
              </TablePanel>
            ))}
        {!hideConfirmedBudgetsInfo() && <React.Fragment>
          {budgetExpenses && budgetExpenses?.value > 0
            && <TablePanel header={renderLine(budgetExpenses)}
              key={expenses?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetExpenses.index}>
              {renderDetailedExpenses(budgetExpenses.name)}
            </TablePanel>
          }
          {budgetDiscounts && budgetDiscounts?.value > 0
            && <TablePanel header={renderLine(budgetDiscounts)}
              key={expenses?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetDiscounts.index}>
              {renderDetailedExpenses(budgetDiscounts.name)}
            </TablePanel>
          }
          {budgetOffers && budgetOffers?.value > 0
            && <TablePanel header={renderLine(budgetOffers)}
              key={expenses?.filter(f => f?.tag !== 'BUDGET_EXPENSES')?.length + budgetOffers.index}>
              {renderDetailedExpenses(budgetOffers.name)}
            </TablePanel>
          }
        </React.Fragment>
        }
      </TableCollapseContainer>
    </React.Fragment >
  );
};

export default withLocalize(BudgetTableExpenses);
