import React, {Component} from 'react';
import MainPage from './MainPage';
import { GetGuests } from '../../infra/requests/GuestsRequests';

class Guests extends Component {
  state = {
    guestsInfo: undefined,
    ready: false
  };

  componentDidMount() {
    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if(this.props.id !== prevProps?.id) {
      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    let guestsInfo = undefined;
    const {data, success} = await GetGuests();

    if(success) {
      guestsInfo = data;
    }

    this.setState({
      guestsInfo,
      ready: true
    });
  };

  render() {
    const { guestsInfo, ready } = this.state;

    if(!ready) return false;

    return (
      <MainPage tag='GUESTS' title='GUESTS' url='/guests' guestsInfo={guestsInfo}/>
    );
  }
}

export default Guests;