import React, { useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import {
  MobileMenuIcon,
  MenuImg,
  MobileMenu,
  MobileMenuGroup,
  MobileImg,
  MobileText,
  MobileArrow,
  MobileMenuRow,
  MobileSettingsMenu,
  MobileSettingsMenuGroup,
  MobileSettingsText,
  MobileLanguageImg,
  MobileLanguageSpace,
  MobileMenuCollapse,
  MobileMenuPanel
} from "./HeaderStyles";
import Menu from "../../assets/icons/menu_mobile.svg";
import Close from "../../assets/icons/close.svg";
import Check from "../../assets/icons/check.svg";
import Logout from "../../assets/icons/logout.svg";
import AboutUs from "../../assets/icons/about_us.svg";
import { menuItems, menuVisitItems, reserveMenuItems, settingsMenu } from "./HeaderOptions";
import ArrowLeft from "../svg/ArrowLeft";
import { DeleteUser } from "../../redux/User/user.actions";
import { DeleteWedding } from "../../redux/Wedding/wedding.actions";
import Alert from "../../components/alert/Alert";
import { WeddingStatus } from "../../pages/reserve/ReservePage";
import { accessReserve } from "../../infra/services/utils/Helpers";
import { GetWeddingInfo } from "../../infra/requests/MyWeddingRequests";
import { VisitsBlockedType } from "../../pages/agenda/VisitAgendaPage";

const MobileMenuComponent = ({
  mobileMenuOpen,
  toggleMobileMenu,
  languages,
  history,
  location,
  translate,
  activeLanguage,
  setActiveLanguage,
  user,
  wedding
}) => {
  const [weddingDB, setWeddingDB] = useState(wedding);

  useEffect(() => {
    getWedding();
  }, []);

  const getWedding = async () => {
    const resultWedding = await GetWeddingInfo();
    const wedding = resultWedding?.success && resultWedding?.data ? resultWedding.data : wedding;
    setWeddingDB(wedding);
  }

  const handleClickMenu = url => {
    if (url === "DISABLED") {
      Alert.new({
        type: "success",
        title: translate("AVAILABLE_SOON_ALERT")
      });
    } else {
      history.push(url);
    }
  };

  const changeLanguage = code => {
    setActiveLanguage(code);
    moment.locale(code);
    localStorage.setItem("LANG_COOKIE", code);
  };

  const logout = () => {
    localStorage.clear();
    DeleteUser();
    DeleteWedding();
    history.push("/login");
  };

  const goToProfile = () => {
    history.push("/profile");
  };

  const renderAccordionHeader = item => {
    return (
      <MobileMenuRow>
        <MobileImg src={item.img} />
        <MobileText>{translate(item.name)}</MobileText>
        <MobileArrow>
          <ArrowLeft />
        </MobileArrow>
      </MobileMenuRow>
    );
  };

  const menuOptions = accessReserve(user, weddingDB)
    ? reserveMenuItems
    : weddingDB?.visitsBlocked !== VisitsBlockedType.COMPLETED
      ? menuVisitItems
      : menuItems;

  return (
    <React.Fragment>
      <MobileMenuIcon>
        {mobileMenuOpen ? (
          <MenuImg src={Close} onClick={() => toggleMobileMenu()} />
        ) : (
          <MenuImg src={Menu} onClick={() => toggleMobileMenu()} />
        )}
      </MobileMenuIcon>
      <MobileMenu open={mobileMenuOpen}>
        {menuOptions.map(item =>
          item.url === "SETTINGS_MENU" ? (
            <MobileMenuCollapse key={item.name} accordion>
              <MobileMenuPanel
                header={renderAccordionHeader(item)}
                key="1"
                showArrow={false}
              >
                <MobileSettingsMenu>
                  {settingsMenu.map(item => (
                    <MobileSettingsMenuGroup
                      key={item.name}
                      onClick={() => handleClickMenu(item.url)}
                    >
                      <MobileLanguageSpace />
                      <MobileSettingsText>
                        {translate(item.name)}
                      </MobileSettingsText>
                    </MobileSettingsMenuGroup>
                  ))}
                  {languages.map((item, index) => (
                    <MobileSettingsMenuGroup
                      key={index}
                      border={index === 0 ? true : false}
                      onClick={() => changeLanguage(item.code)}
                    >
                      {activeLanguage && activeLanguage.code === item.code ? (
                        <MobileLanguageImg src={Check} />
                      ) : (
                        <MobileLanguageSpace />
                      )}
                      <MobileSettingsText>
                        {translate(`LANG_${item.code.toUpperCase()}`)}
                      </MobileSettingsText>
                    </MobileSettingsMenuGroup>
                  ))}
                </MobileSettingsMenu>
              </MobileMenuPanel>
            </MobileMenuCollapse>
          ) : (
            <MobileMenuGroup
              key={item.name}
              onClick={() => handleClickMenu(item.url)}
            >
              <MobileMenuRow>
                <MobileImg src={item.img} />
                <MobileText>{translate(item.name)}</MobileText>
              </MobileMenuRow>
            </MobileMenuGroup>
          )
        )}
        {accessReserve(user, wedding) &&
          <MobileMenuGroup border={true} onClick={() => goToProfile()}>
            <MobileMenuRow>
              <MobileImg src={AboutUs} />
              <MobileText>{translate("ABOUT_US")}</MobileText>
            </MobileMenuRow>
          </MobileMenuGroup>}
        <MobileMenuGroup border={true} onClick={() => logout()}>
          <MobileMenuRow>
            <MobileImg src={Logout} />
            <MobileText>{translate("LOGOUT")}</MobileText>
          </MobileMenuRow>
        </MobileMenuGroup>
      </MobileMenu>
    </React.Fragment>
  );
};

export default withLocalize(withRouter(MobileMenuComponent));
