import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form, Popconfirm, Icon, Tag } from 'antd';
import styled from 'styled-components';
import Table from '../../components/table/Table';

export const TableContent = styled.div`
    width: 100%;
    margin-top: 0px;
    overflow: auto;
    max-height: calc(100vh - 250px);
    padding: 24px;

    table tr th {
        color: black !important;
    }

    .ant-table {
        overflow: auto;
    }

    .ant-table table {
        border-radius: 0 !important;
    }

    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 0px !important;
    }

    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 0px !important;
    }

    .ant-table-tbody > tr > td.align-center {
        text-align: center;
    }

    .ant-empty-image {
        height: 0 !important;
        margin-bottom: 0 !important;
    }

    .ant-table-placeholder {
        padding: 10px 16px !important;
    }
`;

class FoodRestrictionsModal extends React.Component {
    state = {
        columns: [
            {
                title: this.props.translate('ACRONYM'),
                titleKey: 'ACRONYM',
                className: 'align-center',
                width: '60px',
                render: data => {
                    const { activeLanguage } = this.state;
                    return <Tag>{data?.acronym[activeLanguage.code]}</Tag>;
                }
            },
            {
                title: this.props.translate('SUBTITLE'),
                titleKey: 'SUBTITLE',
                width: '85px',
                render: data => {
                    const { activeLanguage } = this.state;
                    return data?.subtitle ? data?.subtitle[activeLanguage.code] : '';
                }
            },
            {
                title: this.props.translate('DESCRIPTION'),
                titleKey: 'DESCRIPTION',
                render: data => {
                    const { activeLanguage } = this.state;
                    return data?.description ? data?.description[activeLanguage.code] : '';
                }
            },
        ],

        activeLanguage: { code: 'pt' },
        rows: [],
    }

    componentDidMount() {
        const { initialValues, activeLanguage } = this.props;

        this.setState({
            rows: initialValues ? initialValues : [],
            activeLanguage: activeLanguage || {code: 'pt'}
        });
    }

    componentDidUpdate(prevProps, props) {
        const { translate } = this.props;
        let { columns } = this.state;

        if (prevProps.activeLanguage !== this.props.activeLanguage) {
            this.setState({ activeLanguage: this.props.activeLanguage });

            // If activeLanguage changes, then update column titles in table
            if (prevProps.activeLanguage.code !== this.props.activeLanguage.code) {
                for (let index = 0; index < columns.length; index++) {
                    const column = columns[index];
                    if (column.titleKey) column.title = translate(column.titleKey);
                }
                this.setState({ columns });
            }
        }

    }

    render() {
        const { translate, isOpen, onClose } = this.props;
        const { loading } = this.props;
        const { columns, rows } = this.state;

        return (
            <Modal
                visible={isOpen}
                title={translate('DIETARY_RESTRICTIONS')}
                maskClosable
                onCancel={onClose}
                className="policy-modal"
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={onClose}>
                        {translate('CLOSE')}
                    </Button>
                ]}
            >

                <TableContent first={true}>
                    <Table
                        columns={columns}
                        rows={rows}
                        showHeader={true}
                        emptyText={translate('NO_DIETARY_RESTRICTIONS')}
                        rowKey={'_id'}
                        hasPagination={false}
                        loading={loading}
                    />
                </TableContent>

            </Modal>
        );
    }
};

export default (FoodRestrictionsModal)