import styled from "styled-components";
import { device } from '../../styles/Responsive';

const menuTransition = "0.5s";

export const Layout = styled.div`

`

export const MainContainer = styled.div`
  padding-top: 108px;
  padding-left: ${({ reserve, open }) => (reserve ? '0' : open ? "300px" : "105px")};
  transition: padding-left ${menuTransition} linear;

  @media ${device.desktopL} {
    padding-top: 89px;
  }

  @media ${device.desktop} {
    padding-top: 80px;
  }

  @media ${device.laptopL} {
    padding-top: 66px;
    padding-left: ${({ reserve, open }) => (reserve ? '0' : open ? "250px" : "105px")};
  }

  @media ${device.laptop} {
    padding-left: ${({ reserve }) => (reserve ? '0' : '105px')};
  }

  @media ${device.tablet} {
    padding-left: ${({ reserve }) => (reserve ? '0' : '50px')};
  }

  @media ${device.mobileL} {
    padding-top: ${({ reserve }) => (reserve ? '0' : '60px')};
  }
`;

export const Dashboard = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  display: inline-block;
  background-color: ${p => p.theme.primaryBackgroundColor};
  text-align: center;
`;