import styled from "styled-components";
import { Button } from "antd";
import { device } from "../../styles/Responsive";

export const StyledButton = styled(Button)`
  overflow: hidden;
  float: ${(p) => (p.align ? p.align : "left")};

  svg {
    width: ${p => p?.theme.iconSvgSize};
    height:  ${p => p?.theme.iconSvgSize};
  }

  &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;

      svg, svg path {
        color: white;
        fill: white;
        fill-opacity: 1;
      }
    }
  }

  &.ant-btn-dangerMedium {
    background-color: ${(p) => p.theme.inputErrorColor};
    color: ${(p) => p.theme.primaryBackgroundColor};
    border-radius: 6px;
    border-color: ${(p) => p.theme.inputErrorBorderColor};
    width: auto;
    height: auto;
    padding: 12px 100px;

    &:hover,
    &:focus {
      background-color: ${(p) => p.theme.inputErrorFocusColor};
      color: white;
      border: 1px solid transparent;
      
      svg, svg path {
        color: white;
        fill: white;
        fill-opacity: 1;
      }
    }

    & span {
      color: #ffffff;
      font-family: "Titillium Web", sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      text-align: center;
    }

    @media ${device.mobileL} {
      padding: 12px 70px;

      & span {
        font-size: 20px;
      }
    }

    @media ${device.mobileS} {
      padding: 10px 20px;

      & span {
        font-size: 18px;
      }
    }
  }

  &.ant-btn-primaryLarge,
  &.ant-btn-primaryMedium,
  &.ant-btn-cancelLarge,
  &.ant-btn-cancelMedium {
    background-color: ${(p) =>
    p.bgcolor === "primaryColor" ? p.theme.primaryColor : p.theme.primaryCTA};
    border-radius: 6px;
    width: auto;
    height: auto;
    padding: 12px 100px;

    &:hover,
    &:focus {
      background-color: ${(p) => p.bgcolor === "primaryColor" ? p.theme.primaryColor : p.theme.secondaryCTA};
      color: #ffffff;
      border: 1px solid transparent;
      
      svg, svg path {
        color: #ffffff;
        fill: #ffffff;
        fill-opacity: 1;
      }
    }

    & span {
      color: #ffffff;
      font-family: "Titillium Web", sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
      text-align: center;
    }

    @media ${device.mobileL} {
      padding: 12px 70px;

      & span {
        font-size: 20px;
      }
    }

    @media ${device.mobileS} {
      padding: 10px 20px;

      & span {
        font-size: 18px;
      }
    }
  }

  &.ant-btn-primaryMedium,
  &.ant-btn-cancelMedium {
    padding: 12px 30px;

    @media ${device.tablet} {
      padding: 10px 15px;

      & span {
        font-size: 18px;
      }
    }

    @media ${device.mobileL} {
      padding: 8px 10px;

      & span {
        font-size: 16px;
      }
    }

    @media ${device.mobileM} {
      padding: 6px 5px;

      & span {
        font-size: 14px;
      }
    }
  }

  &.ant-btn-primarySmall,
  &.ant-btn-cancelSmall {
    background-color: ${({ theme }) => theme.primaryCTA};
    border-radius: 6px;
    width: auto;
    height: auto;
    padding: 8px 12px;

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.secondaryCTA};
      border: 1px solid transparent;

      & span {
        color: #ffffff;
      }

      svg, svg path {
        color: #ffffff;
        fill: #ffffff;
        fill-opacity: 1;
      }
    }

    & span {
      color: #ffffff;
      font-family: "Titillium Web", sans-serif;
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      text-align: center;
      transition: color 0.5s linear;
    }

    @media ${device.mobileL} {
      padding: 6px 8px;
    }
  }

  &.ant-btn-cancelLarge,
  &.ant-btn-cancelMedium,
  &.ant-btn-cancelSmall {
    background-color: #ffffff;
    border: 1px solid ${({ theme }) => theme.primaryCTA};

    &:hover,
    &:focus {
      background-color: ${({ theme }) => theme.secondaryCTA};

      & span {
        color: #ffffff;
      }

      svg, svg path {
        color: #ffffff;
        fill: #ffffff;
        fill-opacity: 1;
      }
    }

    & span {
      color: ${({ theme }) => theme.secondaryCTA};
    }
  }

  &.ant-btn-dark,
  &.ant-btn-darkMedium,
  &.ant-btn-darkSmall {
    background-color: #000000;
    border: 1px solid #000000;

    .anticon {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;

      & span {
        color: #ffffff;
      }

      svg, svg path {
        color: #ffffff;
        fill: #ffffff;
        fill-opacity: 1;
      }
    }

    & span {
      color: #ffffff;
    }
  }
`;

export const FloatButton = styled(Button)`
  position: absolute;
  top: 15px;
  right: ${({ position }) =>
    position
      ? position === 1
        ? "70px"
        : `${(position - 1) * 55 + 70}px`
      : "15px"};

  &.ant-btn-danger {
    background-color: ${(p) => p.theme.primaryBackgroundColor};
    color: ${(p) => p.theme.inputErrorColor};

    &:hover {
      background-color: ${(p) => p.theme.inputErrorColor};
      color: white;
    }
  }
`;

export const TextContainer = styled.span`
  font-family: "Titillium Web", sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 38px;
  width: 168px;
  text-align: right;
  white-space: pre-line;
  margin-right: 35px;
  transition: opacity 0.5s linear;
  z-index: 1;

  @media ${device.laptop} {
    display: none;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background-color: ${(p) => (p.error ? p.theme.inputErrorColor : "#000000")};
  transition: background-color 0.5s linear;
  z-index: 1;
`;

export const IconText = styled.span`
  display: none;
  font-family: "Titillium Web", sans-serif;
  font-size: 15px;
  line-height: 20px;
  font-weight: 700;
  color: #ffffff;

  @media ${device.laptop} {
    display: block;
  }
`;

export const Icon = styled.span`
  width: 24px;
  height: 24px;

  & svg {
    width: 24px;
    height: 24px;

    & #arrowright {
      fill: #ffffff;
    }

    & #save {
      fill: #ffffff;
    }

    & #plus {
      fill: #ffffff;
    }
  }

  @media ${device.laptop} {
    width: 14px;
    height: 14px;
    line-height: 14px;
    margin-top: 6px;

    & svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const LoadingImage = styled.img`
  width: 24px;
  height: 24px;

  @media ${device.laptop} {
    width: 14px;
    height: 14px;
  }
`;

// top: 75%;
export const StyledSaveButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0px solid transparent;
  background-color: transparent;
  position: fixed;
  bottom: 20px;
  right: 15px;
  height: auto;
  padding: 0;
  margin: 0;
  cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  color: ${(p) => (p.error ? p.theme.inputErrorColor : "#000000")};
  z-index: 999;
  overflow: hidden;

  ${({ loading }) =>
    loading
      ? `
        pointer-events: none;
        opacity: 0.5;
      `
      : null};

  @media ${device.desktopL} {
    padding: ${(p) => (p.withBackground ? "5px" : "0px")};
  }

  @media ${device.desktop} {
    bottom: 15px;
    top: unset;
  }

  @media ${device.laptop} {
    padding: 0;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
    border-radius: 35px;

    @media ${device.desktopL} {
      background-color: ${(p) =>
    p.withBackground ? "rgba(255, 255, 255, 0.9)" : "transparent"};
    }

    @media ${device.laptop} {
      background-color: transparent;
    }
  }

  &:hover {
    ${TextContainer} {
      opacity: 0.4;
    }

    ${IconContainer} {
      background-color: ${(p) => p.theme.secondaryCTA};
    }
  }
`;

export const StyledRoundButton = styled.button`
  background-color: ${p => p?.theme?.primaryColor};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 0;
  color: #ffffff;
  padding: 5px 35px;
  font-family: "Titillium Web", sans-serif;
  font-size: 18px;
  line-height: 27px;
  cursor: pointer;
  transition: background-color 0.5s linear;

  &:hover {
    background-color: ${(p) => p.theme.primaryCTA};
  }
`;

export const StyledRoundButtonIcon = styled.span`
  padding-left: 10px;

  & svg {
    transform: rotateZ(90deg);
    width: 10px;
    height: 10px;

    & #left {
      fill: #ffffff;
    }
  }
`;
