import React from 'react'

const Edit = ({ fill }) => {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22">
      <g id="Nosso-dia" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="3-O-nosso-dia" transform="translate(-581.000000, -654.000000)">
          <g id="Group-129" transform="translate(580.000000, 653.000000)">
            <rect id="Rectangle-44" x="0" y="0" width="24" height="24"></rect>
            <g id="edit" transform="translate(1.500000, 1.500000)" fill={ fill } fillRule="nonzero">
              <path d="M20.15625,19.3125 L0.84375,19.3125 C0.37734375,19.3125 0,19.6898437 0,20.15625 C0,20.6226562 0.37734375,21 0.84375,21 L20.15625,21 C20.6226563,21 21,20.6226562 21,20.15625 C21,19.6898437 20.6226563,19.3125 20.15625,19.3125 Z M5.4375,16.546875 L10.9570312,14.2101563 C11.0460938,14.1726563 11.1257813,14.11875 11.1960938,14.0507813 L20.5054688,4.74140625 C21.1640625,4.0828125 21.16875,3.009375 20.5101563,2.35078125 L18.6515625,0.4921875 C17.9929688,-0.16640625 16.9195313,-0.16171875 16.2609375,0.496875 L6.9515625,9.80625 C6.88359375,9.87421875 6.8296875,9.95625 6.7921875,10.0453125 L4.453125,15.5625 C4.321875,15.871875 4.41328125,16.1835938 4.6125,16.3851563 C4.81171875,16.5867188 5.12578125,16.678125 5.4375,16.546875 Z M17.4539062,1.68984375 L17.4585937,1.68515625 L19.3148437,3.54140625 L19.3101562,3.54609375 L17.8570312,4.99921875 L16.0007812,3.14296875 L17.4539062,1.68984375 Z M8.2734375,10.8703125 L14.8078125,4.3359375 L16.6640625,6.1921875 L10.1296875,12.7265625 L6.909375,14.090625 L8.2734375,10.8703125 Z" id="Shape"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Edit