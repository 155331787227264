import moment from 'moment';
import { findPageByTag } from './PageInfo';

export const getWeddingDate = (date) => {
  let day = undefined, month = undefined, year = undefined;

  if(date) {
    const momentDate = moment.utc(date);

    day = momentDate.format('DD');
    month = momentDate.format('MMMM');
    year = momentDate.format('YYYY');
  }

  return { day, month, year };
}

export const getArrivalDate = (dates, translate) => {
  let arrival_time = translate('NOT_DEFINED'), visit_time = translate('NOT_DEFINED');
  
  if(dates.length > 0) {
    for(let i = 0; i < dates.length; i++) {
      if(dates[i].name === 'Chegada à Quinta' || dates[i].name === 'Arrival at Wedding Place' || dates[i].name === 'Arrivée sur le lieu du mariage' || dates[i].name === 'Llegada a Quinta') {
        arrival_time = dates[i].time;
        break;
      }
    }

    if(arrival_time !== translate('NOT_DEFINED')) {      
      const start_hour = moment.utc(arrival_time, 'HH:mm').subtract(2.0, 'hours').format('HH:mm');
      const final_hour = moment.utc(arrival_time, 'HH:mm').subtract(0.5, 'hours').format('HH:mm');

      arrival_time = `${arrival_time}h`;
      visit_time = `${start_hour}h - ${final_hour}h`;
    }
  }

  return { arrival_time, visit_time };
}

export const findQuestionByID = (answers, id) => {
  let question = undefined;

  if(answers.length > 0) {
    for(let i = 0; i < answers.length; i++) {
      if(answers[i].question._id === id) {
        question = answers[i];
        break;
      }
    }
  }

  return question;
}

export const findColors = (colorsInfo, response) => {
  let colors = [];

  for(let i = 0; i < response.length; i++) {
    for(let j = 0; j < colorsInfo.length; j++) {
      if(response[i] === colorsInfo[j]._id) {
        colors.push(colorsInfo[j]);
      }
    }
  }

  return colors;
}

export const findAnswer = (sectionsInfo, questionID, responseID, weddingPlace, tag) => {
  const section = findPageByTag(sectionsInfo, tag);
  let answer = undefined;

  if(section && section.chapters && section.chapters.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      if(section.chapters[i].questions && section.chapters[i].questions.length > 0) {
        for(let j = 0; j < section.chapters[i].questions.length; j++) {
          if(section.chapters[i].questions[j]._id === questionID) {
            if(section.chapters[i].questions[j].options && section.chapters[i].questions[j].options.length > 0) {
              for(let h = 0; h < section.chapters[i].questions[j].options.length; h++) {
                if(
                  section.chapters[i].questions[j].options[h]._id === responseID &&
                  (
                    !section.chapters[i].questions[j].options[h].local ||
                    section.chapters[i].questions[j].options[h].local === weddingPlace
                  ) 
                ) {
                  answer = section.chapters[i].questions[j].options[h];
                  break;
                }
              }
            }

            break;
          }
        }
      }
    }
  }

  return answer;
}