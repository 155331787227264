import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OptionCard from "../optionCard/OptionCard";
import { OptionsContainer } from "./StaffOptionQuestionStyles";
import { CardsContainer, CardsRow } from "../manageQuestionType/ManageQuestionTypeStyles";

const StaffOptionQuestion = ({ input, meta, question, wedding, isProcessClosed }) => {
  return (
    <OptionsContainer>
      <CardsContainer>
        <CardsRow>
          {
            question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) =>
              <OptionCard 
                key={index}
                record={elem}
                input={input}
                canEditSection={true}
                isProcessClosed={isProcessClosed}
                question={question}
              />
            )
          }
        </CardsRow>
      </CardsContainer>
    </OptionsContainer>
  );
};

StaffOptionQuestion.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

export default connect(mapStateToProps)(StaffOptionQuestion);