import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2, titleH3 } from '../../styles/TextSizes';
import { Form } from "antd";

export const AboutUsContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  margin: auto;
  margin-bottom: 40px;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
`;

export const Title = styled(titleH2)`
  
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  padding-top: 30px;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.desktop} {
    padding-top: 15px;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const SectionTitle = styled(titleH3)`
  text-align: left;
  padding-top: 105px;

  @media ${device.desktopL} {
    padding-top: 70px;
  }

  @media ${device.desktop} {
    padding-top: 50px;
  }
`;

export const SectionSubtitle = styled.div`
  text-align: left;
  padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

export const BillingFormContainer = styled.div`
  padding: 30px 0px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & button:nth-child(2) {
    margin-left: 20px;
  }
`;

export const FieldContainer = styled.div`
  width: 100%;
`;

export const PaymentInfoContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
`;