import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

export const BudgetSectionContainer = styled.div`
  width: 100%;
  background-color: ${p => p.theme.thirdColor};
  margin-top: 25px;
  padding: 15px;
`;

export const Title = styled(titleH5)`
  text-align: left;
  width: 100%;
  line-height: 29px;
`;

export const Section = styled.div`
  text-align: left;
  width: 100%;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.3;
  padding-top: 5px;

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Description = styled.div`
  width: 100%;
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-top: ${({isReceipt}) => isReceipt ? '0px' : '10px'};

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const Amount = styled(titleH5)`
  text-align: left;
  width: 100%;
  line-height: 29px;
  padding-top: 10px;
`;

export const Date = styled.div`
  text-align: left;
  width: 100%;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 10px;

  @media ${device.tablet} {
    font-size: 13px;
    line-height: 20px;
  }
`;

export const TitleWithOptions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 20px;
`;