import React from 'react'

const Enlarge = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none">
      <rect id="icon_rect" width="27" height="27" rx="4" fill="black" fillOpacity="0.4"/>
      <path id="icon_path" fillRule="evenodd" clipRule="evenodd" d="M6 10.3333V7.4142L10.2928 11.7071C10.4804 11.8946 10.7347 12 11 12C11.2652 12 11.5196 11.8946 11.7071 11.7071C11.8946 11.5196 12 11.2652 12 11C12 10.7347 11.8946 10.4804 11.7071 10.2928L7.4142 6H10.3333C10.8856 6 11.3333 5.55228 11.3333 5C11.3333 4.44772 10.8856 4 10.3333 4H5C4.44772 4 4 4.44772 4 5V10.3333C4 10.8856 4.44772 11.3333 5 11.3333C5.55228 11.3333 6 10.8856 6 10.3333ZM17.7071 16.2929L22 20.5858V17.6667C22 17.1144 22.4477 16.6667 23 16.6667C23.5523 16.6667 24 17.1144 24 17.6667V23C24 23.5523 23.5523 24 23 24H17.6667C17.1144 24 16.6667 23.5523 16.6667 23C16.6667 22.4477 17.1144 22 17.6667 22H20.5858L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929Z" fill="white" fillOpacity="0.7"/>
    </svg>
  )
}

export default Enlarge