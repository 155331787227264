import React from "react";
import {
  BarContainer,
  Bar,
  DotsContainer,
  Dot
} from "./ProgressBarStyles";

const ProgressBar = ({ type, num_answers, num_questions }) => {
  const percentage = Math.round((num_answers / num_questions) * 100);

  const renderDots = () => {
    let res = [];

    for(let i = 0; i < num_answers; i++) {
      res.push(<Dot key={i}/>);
    }

    return res;
  }

  return (
    <BarContainer type={type}>
      <Bar type={type} percentage={percentage}/>
      {
        type === 'section' ?
        <DotsContainer num_answers={num_answers} percentage={percentage}>
          {
            renderDots()
          }
        </DotsContainer>
        : null
      }
    </BarContainer>
  );
}

export default ProgressBar;