import React from "react";
import {
  CardContainer,
  CardContent,
  Title,
  Number,
  Line,
  Text,
  Icon
} from "./SectionCardStyles";
import LongArrowRight from '../svg/LongArrowRight';
import ProgressBar from '../progressBar/ProgressBar';
import { findNumberQuestionsChapter } from "../../infra/services/utils/PageInfo";

const SectionCard = ({ chapter, number, title, text, onClick, tag, guestsInfo, styleInfo }) => {
  const { num_questions, num_answers } = findNumberQuestionsChapter(chapter, tag, guestsInfo, styleInfo);

  return (
    <CardContainer onClick={onClick}>
      <CardContent>
        <Number>{number}</Number>
        <Title>{title}</Title>
        <Line/>
        <Text dangerouslySetInnerHTML={{ __html: text}}/>
        <Icon><LongArrowRight/></Icon>
      </CardContent>
      <ProgressBar type="card" num_questions={num_questions} num_answers={num_answers}/>
    </CardContainer>
  );
}

export default SectionCard;