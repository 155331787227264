import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { reduxForm, Field, initialize } from 'redux-form';
import {
  Description,
  Information,
  FormContainer,
  ButtonsContainer,
  CloseButton,
  SelectContainer,
  NewOptionContainer,
  ImportantMomentsContainer,
  ImportantMomentsRow,
  ImportantMoment,
  ImportantMomentText,
  ImportantMomentsTitle
} from './OurDayStyles';
import DecorationNavigation from '../../components/decorationNavigation/DecorationNavigation';
import {
  findPageByTag
} from '../../infra/services/utils/PageInfo';
import {
  findFirstQuestion,
  findPrevChapter,
  findNextSection
} from '../../infra/services/utils/PageNavigation';
import Timeline from '../../components/timeline/Timeline';
import { GetOurDay, AddMoment, DeleteMoment, EditMoment } from '../../infra/requests/OurDayRequests';
import SaveButton from '../../components/buttons/SaveButton';
import Alert from "../../components/alert/Alert";
import BaseButton from "../../components/buttons/BaseButton";
import TimelineCategory from "../../components/timelineCategory/TimelineCategory";
import TimeInput from "../../components/inputs/TimeInput";
import { categories, checkMomentExists, addNewMoment, findMoment } from "./Categories";
import { findMomentByID } from "../../infra/services/utils/PageOurDay";
import Close from "../../assets/icons/close.svg";
import FormValidator from '../../infra/services/validations/FormValidator';
import { SpinLoading } from "../../styles/BasicStyles";
import TextInput from "../../components/inputs/TextInput";
import {
  processClosed
} from '../../infra/services/utils/BlockAnswers';
import moment from 'moment';

const validations = FormValidator.make({
  name: 'required',
  time: 'required'
});

class OurDay extends Component {
  state = {
    list: [],
    categoriesList: [],
    importantCategories: [],
    description: undefined,
    information: undefined,
    nextQuestion: undefined,
    prevChapter: undefined,
    openAddMoment: false,
    openEditModal: false,
    editMoment: undefined,
    importantMoment: undefined,
    showNew: false,
    requiredError: false,
    isProcessClosed: undefined,
    ready: false,
    loading: false
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    this.getOutDay();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflowY = 'auto';

      this.getOutDay();
    }
  }

  getOutDay = async () => {
    const { weddingInfo, decorationInfo } = this.props;

    const { description, information } = findPageByTag(decorationInfo, 'DAY');
    const nextSection = findNextSection(decorationInfo, '5e06224c47f1554b3dd6d418');
    const nextQuestion = findFirstQuestion(nextSection);
    const prevChapter = findPrevChapter(decorationInfo, '5e06224c47f1554b3dd6d418', [], 0);
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    await this.getOutDayStaff();

    this.setState({
      description,
      information,
      nextQuestion,
      prevChapter,
      isProcessClosed,
      ready: true
    });
  };

  getOutDayStaff = async () => {
    const { translate } = this.props;

    const { data, success } = await GetOurDay();
    let list = [], categoriesList = [...categories];

    if (success) {
      list = data;

      if (list.length > 0) {
        let index = -1, moment = undefined;

        for (let i = 0; i < list.length; i++) {
          moment = findMoment(list[i].name);

          //Remove base "Moments" that were already selected
          if (moment) {
            index = categoriesList.indexOf(moment);

            categoriesList.splice(index, 1);
          }
          else {
            //Add "Moments" that don't exist in the defined base Moments
            if (list[i].name && list[i].name !== translate('CREATE_MOMENT') && !checkMomentExists(categoriesList, list[i].name)) {
              categoriesList = addNewMoment(categoriesList, list[i].name);
            }
          }
        }
      }
    }

    //Check if there are any important moments that need to be registered
    const importantCategories = categoriesList.filter(category => category.important ? category : null);

    this.setState({
      list,
      categoriesList,
      importantCategories
    });
  }

  onSubmit = async values => {
    try {
      const { translate, dispatch } = this.props;
      const { editMoment, categoriesList } = this.state;

      //If we're creating a New Moment
      if (values.name === translate('CREATE_MOMENT')) {
        //It's required
        if (!values.newCategory) {
          this.setState({
            requiredError: true
          })

          return false;
        }

        //Check if it already exists
        if (checkMomentExists(categoriesList, values.newCategory)) {
          Alert.new({
            type: "error",
            title: translate("ALERT_ERROR_TITLE"),
            text: translate("ALERT_MOMENT_TITLE")
          });

          return false;
        }

        //Save the New Moment
        values.name = values.newCategory;
      }

      //Double-check the selected Hour (must be the format hh:mm)
      const parts = values.time.split(':');
      let hour = parseInt(parts[0]);
      let minute = parseInt(parts[1]);

      if (!values.time.includes(':') || hour > 23 || hour < 0 || minute > 59 || minute < 0) {
        Alert.new({
          type: "error",
          title: translate("ALERT_ERROR_TITLE"),
          text: translate("TIME_INPUT_ERROR")
        });

        return false;
      }

      // Check that moment is between 13:00pm and 04:00am
      if (!(hour > 12 && hour <= 24 || hour >= 0 && hour < 4 || hour === 4 && minute === 0)) {
        Alert.new({
          type: "errorTime",
          title: translate("ALERT_ERROR_TITLE"),
          text: translate("TIME_INPUT_INVALID")
        });

        return false;
      }

      //FIX: Hours and Minutes with 2 digits
      if (hour.toString().length < 2) {
        hour = `0${hour}`;
      }
      if (minute.toString().length < 2) {
        minute = `0${minute}`;
      }

      values.time = `${hour}:${minute}`;

      this.setState({
        loading: true
      });

      //Edit a Moment
      if (editMoment) {
        const result = await EditMoment(editMoment._id, { ...values, newCategory: undefined });

        if (result.success) {
          Alert.new({
            type: "success",
            title: translate("MOMENT_EDIT")
          });
        }
      }
      //Save a new Moment
      else {
        const result = await AddMoment({ ...values, newCategory: undefined });

        if (result.success) {
          Alert.new({
            type: "success",
            title: translate("MOMENT_ADD")
          });
        }
      }

      //Get the updated list of Moments
      await this.getOutDayStaff();

      this.setState({
        openAddMoment: false,
        editMoment: undefined,
        importantMoment: undefined,
        showNew: false,
        requiredError: false,
        loading: false
      });

      //Clear the form (don't use "reset", as it will lose the Form Validations)
      dispatch(initialize('our_day_form'));

      //Closing the Modal, restore the Body scroll
      document.body.style.overflowY = 'auto';
    }
    catch (e) {
      console.error(e);
    }
  };

  deleteMoment = async (id) => {
    try {
      const { translate } = this.props;

      const { success } = await DeleteMoment(id);

      if (success) {
        Alert.new({
          type: "success",
          title: translate("MOMENT_DELETE")
        });

        //Get the updated list of Moments
        await this.getOutDayStaff();

        this.setState({
          openAddMoment: false,
          editMoment: undefined,
          importantMoment: undefined
        });
      }
    }
    catch (e) {
      console.error(e);
    }
  }

  openEditModal = (e, id) => {
    const { list } = this.state;

    e.preventDefault();
    e.stopPropagation();

    //Find the Moment we want to edit
    const editMoment = findMomentByID(list, id);

    //Opening the Modal, hide the Body scroll
    document.body.style.overflowY = 'hidden';

    this.setState({
      openAddMoment: true,
      editMoment,
      importantMoment: undefined,
    });
  }

  toggleAddMoment = () => {
    const { openAddMoment } = this.state;

    //If the Modal is open, means we're about to close so we restore the Body scroll
    if (openAddMoment) {
      document.body.style.overflowY = 'auto';
    }
    //Opening the Modal, hide the Body scroll
    else {
      document.body.style.overflowY = 'hidden';
    }

    this.setState({
      openAddMoment: !openAddMoment,
      importantMoment: undefined,
      editMoment: undefined
    });
  }

  closeAddMoment = () => {
    const { dispatch } = this.props;

    //When closing the window, clear the form (don't use "reset", as it will lose the Form Validations)
    dispatch(initialize('our_day_form'));

    //Closing the Modal, restore the Body scroll
    document.body.style.overflowY = 'auto';

    this.setState({
      openAddMoment: false,
      editMoment: undefined,
      importantMoment: undefined,
      showNew: false,
      requiredError: false
    });
  }

  setShowNew = (value) => {
    this.setState({
      showNew: value
    });
  }

  openImportantMoment = (moment) => {
    const { isProcessClosed } = this.state;

    if (!isProcessClosed) {
      document.body.style.overflowY = 'hidden';

      this.setState({
        openAddMoment: true,
        importantMoment: moment
      });
    }
  }

  truncate = (name) => {
    return name.length > 27 ? `${name.substring(0, 27)}...` : name;
  }

  render() {
    const { handleSubmit, translate, activeLanguage } = this.props;
    const { list, categoriesList, importantCategories, description, information, nextQuestion, prevChapter, openAddMoment, editMoment, importantMoment, loading, showNew, requiredError, isProcessClosed, ready } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <DecorationNavigation
          returnUrl='/our-style'
          mainCategory={translate('OUR_DAY')}
          currentQuestion={translate('OUR_DAY')}
          currentQuestionUrl='/our-day'
          nextQuestion={nextQuestion?.tag ? translate(nextQuestion?.tag) : nextQuestion?.name[activeLanguage?.code]}
          nextQuestionUrl={nextQuestion?.url}
          prevQuestion={prevChapter?.name[activeLanguage?.code]}
          prevQuestionUrl={`/our-style/${prevChapter?._id}`}
        />
        <Description dangerouslySetInnerHTML={{ __html: description && description[activeLanguage?.code] }} />
        {information && <Information dangerouslySetInnerHTML={{ __html: information[activeLanguage?.code] }} />}
        {
          importantCategories.length > 0 &&
          <React.Fragment>
            <ImportantMomentsTitle>{translate('IMPORTANT_MOMENTS_TEXT')}</ImportantMomentsTitle>
            <ImportantMomentsContainer>
              <ImportantMomentsRow>
                {
                  importantCategories.map((category, index) =>
                    <ImportantMoment key={index}>
                      <ImportantMomentText onClick={() => this.openImportantMoment(category.name[activeLanguage?.code])}>
                        {category.important ? `${this.truncate(category.name[activeLanguage?.code])} *` : this.truncate(category.name[activeLanguage?.code])}
                      </ImportantMomentText>
                    </ImportantMoment>
                  )
                }
              </ImportantMomentsRow>
            </ImportantMomentsContainer>
          </React.Fragment>
        }
        <Timeline
          list={list}
          deleteMoment={this.deleteMoment}
          openEditModal={this.openEditModal}
          isProcessClosed={isProcessClosed}
        />
        <React.Fragment>
          <FormContainer open={openAddMoment} onSubmit={handleSubmit(this.onSubmit)}>
            <CloseButton
              src={Close}
              onClick={() => this.closeAddMoment()}
            />
            <SelectContainer>
              <Field
                name='name'
                component={TimelineCategory}
                categories={categoriesList}
                editName={(editMoment && editMoment.name) || importantMoment}
                showNew={showNew}
                setShowNew={this.setShowNew}
              />
              <NewOptionContainer showNew={showNew}>
                <Field
                  name='newCategory'
                  component={TextInput}
                  placeholder={translate('ENTER_NAME')}
                  type="text"
                  requiredError={requiredError}
                />
              </NewOptionContainer>
            </SelectContainer>
            <Field
              name='time'
              component={TimeInput}
              label={translate('HOUR')}
              editTime={editMoment && editMoment.time}
            />
            {
              editMoment ?
                <ButtonsContainer>
                  <BaseButton
                    align="initial"
                    variant="raised"
                    htmlType="submit"
                    type="cancelMedium"
                    text={translate("CANCEL")}
                    onClick={() => this.closeAddMoment()}
                  />
                  <BaseButton
                    align="initial"
                    variant="raised"
                    htmlType="submit"
                    type="primaryMedium"
                    loading={loading}
                    text={translate("SAVE")}
                  />
                </ButtonsContainer>
                :
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="submit"
                  type="primaryLarge"
                  loading={loading}
                  text={translate("CREATE")}
                />
            }
          </FormContainer>
          {
            !isProcessClosed &&
            <SaveButton
              htmlType='button'
              text={translate('ADD_MOMENT')}
              textMobile={translate('ADD')}
              withBackground={true}
              onClick={() => this.toggleAddMoment()}
              icon='add'
            />
          }
        </React.Fragment>
      </React.Fragment>
    );
  }
}

OurDay = reduxForm({
  form: 'our_day_form',
  validate: validations
})(OurDay);

const mapStateToProps = state => ({
  decorationInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding
});

export default connect(mapStateToProps)(withLocalize(withRouter(OurDay)));