import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormSection from 'redux-form/lib/FormSection';
import styled from 'styled-components';
import { Spin, Form as AntForm } from 'antd';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormValidator from '../../infra/services/validations/FormValidator';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import { MaterialInputNumber } from '../../components/inputs/MaterialStyles';
import MaterialNumberInput from '../../components/inputs/MaterialNumberInput';
import { visitedLocals } from '../agenda/ReserveInfoModal';

export const Title = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: ${p => p?.theme?.primaryColor};
  font-weight: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 15px 0 0 0;
  border-bottom: 1px solid ${p => p?.theme?.primaryColor};
`

export const ErroSpan = styled.span`
  font-family: 'Titillium Web', sans-serif;
  color: red;
  font-size: 11px;
  line-height: 39px;
  margin: 0;
  padding: 0;
`

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

const validations = (values) => {
    let errors = {};

    errors = FormValidator.make({
        date: "required",
        weddingPlace: "required",
    })(values);

    if (values.visitedPlace) {
        errors = FormValidator.make({
            expectedMinPeople: "required|isInteger|minNumber:1",
            visitedPlaceName: "required",
        })(values);
    } else {
        errors = FormValidator.make({
            expectedMinPeople: "required|isInteger|minNumber:1",
        })(values);
    }

    return errors;
};

class ReserveModal extends React.Component {
    state = {
        initialValues: {},
        wedding: null,
        activeLanguage: { code: 'pt' }
    }

    componentDidMount() {
        const { initialize, dispatch } = this.props;
        dispatch(initialize('reserve_form', {}, false));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            const { initialize, dispatch, initialValues, wedding } = this.props;

            this.setState({
                initialValues: initialValues,
                wedding: wedding
            });

            dispatch(initialize('reserve_form', {}, false));
        }

        if (prevProps.activeLanguage !== this.props.activeLanguage) {
            this.setState({ activeLanguage: this.props.activeLanguage });
        }

    }

    onSubmit = (values) => {
        const { reset, dispatch, submit, onSubmit } = this.props;
        dispatch(submit('reserve_form'));
        // onSubmit(values);
    }

    // Clear the fields when submit is successful
    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('reserve_form', {}, false));
        onClose();
    }

    render() {
        const { translate, isOpen, visitedPlace } = this.props;
        const { handleSubmit, onClose, onSubmit } = this.props;

        return (
            <Modal
                visible={isOpen}
                title={translate('END_RESERVE')}
                maskClosable
                onCancel={() => {
                    this.clearFields();
                    // onClose();
                }}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={this.clearFields}>
                        Cancelar
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        onClick={handleSubmit(this.onSubmit)}>
                        Gravar
                    </Button>
                ]}
            >
                <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                        component={MaterialNumberInput}
                        label={translate('EXPECTED_MIN_PEOPLE')}
                        name={`expectedMinPeople`}
                        step={1}
                        min={1}
                        translate={translate}
                        labelFixed={true}
                    />
                    <Field
                        component={CheckboxInput}
                        name={'visitedPlace'}
                        label={translate('VISITED_PLACE')}
                    />
                    {visitedPlace && <Field
                        component={SelectInput}
                        data={visitedLocals}
                        dataKey='_id'
                        dataLabel='name'
                        translatable={true}
                        name={'visitedPlaceName'}
                        allowClear={true}
                        label={translate('VISITED_PLACE_NAME')}
                    />}
                </BaseForm>

            </Modal>
        );
    }
};

const selector = formValueSelector('reserve_form');

ReserveModal = reduxForm({
    form: 'reserve_form',
    // enableReinitialize: true,
    validate: validations,
})(ReserveModal);

const mapStateToProps = state => ({
    visitedPlace: selector(state, 'visitedPlace')
});

export default withLocalize(connect(mapStateToProps)(ReserveModal))