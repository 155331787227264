import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const Container = styled.div`
  max-width: 970px;
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 100px;
  padding: 0px 10px;
  position: relative;

  @media ${device.desktop} {
    max-width: 800px;
    margin-top: 50px;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Title = styled.div`
  color: #95928C;
  font-family: 'Titillium Web', sans-serif;
  font-size: 164px;
  font-weight: 700;
  line-height: 164px;
  padding-bottom: 50px;

  @media ${device.desktop} {
    font-size: 150px;
    line-height: 150px;
  }

  @media ${device.laptop} {
    font-size: 130px;
    line-height: 130px;
  }

  @media ${device.tablet} {
    font-size: 100px;
    line-height: 100px;
    padding-bottom: 25px;
  }

  @media ${device.tabletS} {
    font-size: 80px;
    line-height: 80px;
    padding-bottom: 10px;
  }

  @media ${device.mobileL} {
    font-size: 60px;
    line-height: 60px;
  }

  @media ${device.mobileM} {
    font-size: 40px;
    line-height: 40px;
  }
`;

export const Subtitle = styled.div`
  color: #95928C;
  font-family: 'Titillium Web', sans-serif;
  font-size: 41px;
  font-weight: 700;
  line-height: 48px;

  @media ${device.desktop} {
    font-size: 35px;
    line-height: 42px;
  }

  @media ${device.laptop} {
    font-size: 30px;
    line-height: 36px;
  }

  @media ${device.tablet} {
    font-size: 22px;
    line-height: 30px;
  }

  @media ${device.tabletS} {
    font-size: 18px;
    line-height: 25px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${device.mobileM} {
    font-size: 12px;
    line-height: 16px;
  }
`;