import React from "react";
import { withLocalize } from "react-localize-redux";
import { TranslateFromObject } from "../../infra/services/translations/AvailableTranslations";
import { StyledDraftJS } from "./DraftJsStyles";

const DraftJSContent = ({ activeLanguage, content }) => {
  return (
    <StyledDraftJS
      dangerouslySetInnerHTML={{
        __html: TranslateFromObject(content, activeLanguage.code)
      }}
    />
  );
};

export default withLocalize(DraftJSContent);
