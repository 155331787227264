import React from "react";
import {withLocalize} from 'react-localize-redux';
import {Field} from 'redux-form';
import {
  SectionContainer,
  Title,
  Description
} from "./DecorationFileSectionStyles";
import ImageInput from '../inputs/ImageInput';
import Alert from "../alert/Alert";

const DecorationFileSection = ({ file, title, description, canEditSection, isProcessClosed, input, accept, setLoading, loading, translate, hasCrop = false}) => {
  const showAlert = () => {
    if(isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if(!canEditSection) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  return (
    <SectionContainer>
      {
        title && <Title>{title}</Title>
      }
      {
        description && <Description>{description}</Description>
      }
      {
        file &&
        <Field
          component={ImageInput}
          accept={accept}
          name={`file`}
          input={input}
          label={translate("UPLOAD_YOUR_FILE")}
          setLoading={setLoading}
          loading={loading}
          onClick={() => showAlert()}
          disabled={!canEditSection || isProcessClosed}
          hasCrop={hasCrop}
          ratio={1.0}
        />
      }
    </SectionContainer>
  );
}

export default withLocalize(DecorationFileSection);