import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { bindActionCreators } from "redux";
import { Field, reduxForm, initialize } from "redux-form";
import TextInput from "../../components/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo.svg";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  BaseLink,
  TermsLink,
  TemporaryContainer,
  TemporaryText
} from "./LoginPageStyles";
import { Icon } from "antd";
import { GuestLogin, Login } from "../../infra/requests/AuthRequests";
import { GetWedding } from "../../infra/requests/MyWeddingRequests";
import { SaveUser } from "../../redux/User/user.actions";
import { SaveWedding } from "../../redux/Wedding/wedding.actions";
import { auth_token_key } from "../../infra/config/LocalStorageKeys";
import { withLocalize } from "react-localize-redux";
import TermsModal from "../../components/modals/TermsModal";
import PasswordWarningModal from "../../components/modals/PasswordWarningModal";
import Alert from "../../components/alert/Alert";
import { WeddingStatus } from "../reserve/ReservePage";
import { accessReserve } from "../../infra/services/utils/Helpers";

const formRules = FormValidator.make({
  email: "required|email",
  password: "required|min:6",
  code: "required"
});

class LoginPage extends Component {
  state = {
    openTerms: false,
    passwordWarningModal: false,
    passwordModalText: undefined,
    showPassword: false
  };

  componentDidMount() {
    const { dispatch, location } = this.props;
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });

    const code = new URLSearchParams(location.search).get('code') !== undefined
      ? new URLSearchParams(location.search).get('code')
      : '';

    dispatch(initialize("login", {code}));
  }

  onSubmit = async values => {
    const { translate } = this.props;

    try {
      const { SaveUser, SaveWedding, history } = this.props;
      const result = await GuestLogin(values);

      if (result?.success) {
        localStorage.setItem(auth_token_key, result.data.token);
        SaveUser(result.data.user);

        const wedding = await GetWedding();
        if (wedding.success) {
          SaveWedding(wedding.data);
        }

        if (accessReserve(result?.data?.user, wedding?.data?.wedding)) {
          history.push('/home');
        } else history.push("/");
      }
      else {
        //Special Case - show a modal so we make sure the user sees the message
        if (result?.message === 'MUST_DEFINE_PASSWORD') {
          this.setState({
            passwordWarningModal: true,
            passwordModalText: 'ALERT_DEFINE_PASSWORD'
          });
        }
        else {
          Alert.new({
            type: "error",
            title: translate('ALERT_ERROR_TITLE'),
            text: translate(result.message)
          });
        }
      }
    }
    catch (e) {
      console.error(e);
    }
  };

  render() {
    const { handleSubmit, submitting, translate } = this.props;
    const { openTerms, showPassword, passwordWarningModal, passwordModalText } = this.state;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label={translate("EMAIL")}
            />
            <Field
              component={TextInput}
              name="password"
              type={showPassword ? 'text' : 'password'}
              label={translate("PASSWORD")}
              suffix={<Icon type={showPassword ? 'eye-invisible' : 'eye'} onClick={() => this.setState({ showPassword: !showPassword })} />}
            />
            <Field
              component={TextInput}
              name="code"
              type="text"
              label={translate("WEDDING_CODE")}
            />
            <Row>
              <Col xs={12}>
                <TermsLink onClick={() => this.setState({ openTerms: true })}>
                  {translate("SEE_TERMS_BUTTON")}
                </TermsLink>
              </Col>
              <Col xs={12}>
                <BaseLink to="/recover-account">
                  {translate("FORGOT_PASSWORD")}
                </BaseLink>
              </Col>
            </Row>
            <Row>
              <Col xs={24} style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                <BaseLink to="/login">
                  {translate("BACK_LOGIN")}
                </BaseLink>
              </Col>
            </Row>
            {/* <TemporaryContainer>
              <TemporaryText>
                {translate("LOGIN_CONSTRUCTION_MESSAGE")}
                <div>{translate("THE_TEAM_TAG")}</div>
              </TemporaryText>
            </TemporaryContainer> */}
            <LoginButtonDiv>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="submit"
                type="primaryLarge"
                loading={submitting}
                bgColor={'primaryColor'}
                text={translate("LOGIN_WD")}
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
        <TermsModal
          open={openTerms}
          onClose={() => this.setState({ openTerms: false })}
        />
        <PasswordWarningModal
          passwordModalText={passwordModalText}
          open={passwordWarningModal}
          onClose={() => this.setState({ passwordWarningModal: false })}
        />
      </LoginContainer>
    );
  }
}

LoginPage = reduxForm({
  form: "login",
  validate: formRules
})(LoginPage);

const mapStateToProps = (state, ownProps) => ({});

const mapActionToProps = dispatch => bindActionCreators({ SaveUser, SaveWedding }, dispatch);

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(LoginPage)
);
