import React from 'react';
import {
  ModalContainer,
  ModalContent,
  HeaderContainer,
  HeaderTitle,
  HeaderIcon,
  CarouselContainer,
  CarouselButton
} from './ImageModalStyles';
import Close from '../svg/Close';
import ImageComponent from '../images/ImageComponent';
import { Button, Carousel, Icon, Tooltip } from 'antd';

const ImageModal = ({ title, image, color, handleCancel, carousel, gallery }) => {
  return (
    <ModalContent
      visible={true}
      title={title}
      centered
      maskClosable
      onCancel={() => handleCancel()}
    >
      {/* <HeaderContainer>
          <HeaderTitle>{title}</HeaderTitle>
          <HeaderIcon onClick={() => handleCancel()}><Close fill="#ffffff" /></HeaderIcon>
        </HeaderContainer> */}
      {carousel
        ? <Carousel
          arrows
          prevArrow={
            <CarouselButton left currentSlide slideCount>
              <Icon type="left" />
            </CarouselButton>
          }
          nextArrow={
            <CarouselButton right currentSlide slideCount>
              <Icon type="right" />
            </CarouselButton>
          }>
          {image && <ImageComponent inZoom url={image} color={color} />}
          {gallery?.map((image) => <ImageComponent inZoom url={image?.url} color={image?.color} />)}
        </Carousel>
        : <ImageComponent inZoom url={image} color={color} />}
    </ModalContent>
  );
}

export default ImageModal;