import React from "react";
import {
  DetailBlockContainer,
  TextContent,
  Title,
  Subtitle,
  SubtitleValue
} from "../BudgetTableExpensesStyles";

const DetailBlock = ({ title, subtitle, subtitleValue }) => {
  return (
    <DetailBlockContainer>
      {
        title &&
        <TextContent>
          <Title>{title}</Title>
        </TextContent>
      }
      {
        subtitle &&
        <TextContent>
          <Subtitle>{subtitle}</Subtitle>
          {
            subtitleValue &&
            <SubtitleValue>{subtitleValue}</SubtitleValue>
          }
        </TextContent>
      }
    </DetailBlockContainer>
  );
}

export default DetailBlock;