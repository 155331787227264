export const BillingUsersEnum = {
    GROOM: 'GROOM',
    BRIDE: 'BRIDE',
    LAST_OTHER: 'LAST_OTHER',
    OTHER: 'OTHER'
}

export const billingUsers = [
    { _id: BillingUsersEnum.GROOM, name: 'GROOM' },
    { _id: BillingUsersEnum.BRIDE, name: 'BRIDE' },
    { _id: BillingUsersEnum.OTHER, name: 'OTHER' }
];