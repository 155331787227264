import React from "react";
import {
  InspirationContainer,
  BgInspirationsContainer,
  ButtonContainer,
  ButtonImg
} from "./OptionCardStyles";
import ImageComponent from "../images/ImageComponent";
import Close from "../../assets/icons/close.svg";

const StyleInspirations = ({record, toggleOption}) => {
  return (
    <InspirationContainer>
      <BgInspirationsContainer>
        <ImageComponent url={record.image?.url ? record.image?.url : record.url} color={record.image?.color ? record.image?.color : record.color}/>
      </BgInspirationsContainer>
      <ButtonContainer onClick={() => toggleOption(record)}>
        <ButtonImg src={Close}/>
      </ButtonContainer>
    </InspirationContainer>
  );
};

export default StyleInspirations;