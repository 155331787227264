import styled from "styled-components";
import { device } from './Responsive';

export const titleH1 = styled.h1`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 39px;
  line-height: 48px;
  margin: 0;
  padding: 0;

  @media ${device.desktopXL} {
    font-size: 36px;
    line-height: 48px;
  }

  @media ${device.desktop} {
    font-size: 31px;
    line-height: 42px;
  }

  @media ${device.laptopL} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.laptop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.mobileM} {
    font-size: 20px;
    line-height: 26px;
  }
`

export const titleH2 = styled.h2`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin: 0;
  padding: 0;

  @media ${device.desktopXL} {
    font-size: 31px;
    line-height: 42px;
  }

  @media ${device.desktop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 30px;
  }
`

export const titleH3 = styled.h3`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-weight: 400;
  font-size: 31px;
  line-height: 39px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
  }
`

export const titleH4 = styled.h4`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const titleH5 = styled.h5`
  font-family: 'Titillium Web', sans-serif;
  color: #000000;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 0;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 30px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 20px;
  }
`

export const body2 = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 16px;
  }
`