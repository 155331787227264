import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH2 } from "../../styles/TextSizes";

export const PageContainer = styled.div`
  width: 100%;
  max-width: 990px;
  margin: auto;
  padding: 90px 20px;

  @media ${device.tablet} {
    padding: 50px 10px;
  }
`;

export const Title = styled(titleH2)`
  text-align: left;
  margin-bottom: 40px;

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;