import { WeddingStatus } from "../../../pages/reserve/ReservePage";

export const isUserAllowed = (route, user, wedding) => {
    if (user?.reserveUser || (user?.alternativeUser && (wedding?.status == WeddingStatus.RESERVE || wedding?.status == WeddingStatus.RESERVE_SINALIZATION)))
        return route?.reserveUserAllowed ? true : false;
    else if (route?.weddingUserAllowed)
        return true;
    else return false;
}

export const accessReserve = (user, wedding) => {
    return user?.reserveUser
        || (user?.alternativeUser && wedding?.status && (wedding?.status == WeddingStatus.RESERVE || wedding?.status == WeddingStatus.RESERVE_SINALIZATION))
        ? true
        : false;
}

export const inTest = () => {
    return process.env.REACT_APP_TEST == 'true' ? true : false;
}

export const inGuestMapTest = () => {
    return process.env.REACT_APP_GUEST_MAP_TEST == 'true' ? true : false;
}

export const inAgendaTest = () => {
    return process.env.REACT_APP_AGENDA_TEST == 'true' ? true : false;
}