import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const PageContainer = styled.div`
  width: 100%;
  max-width: 970px;
  margin-bottom: 90px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  & div {
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }
  }

  @media ${device.mobileL} {
    & div {
      margin-right: 5px;

      &:last-child {
        margin-right: 0px;
      }
    }
  }
`;

export const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: ${p => p.active ? p.theme.primaryColor : '#ffffff'};
  color: ${p => p.active ? '#ffffff' : '#000000'};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 15px;
  cursor: pointer;
  transition: background-color 0.5s linear;
  user-select: none;
  
  &:hover {
    background-color: ${p => p.theme.primaryCTA};
  }

  @media ${device.mobileL} {
    width: 30px;
    height: 30px;
    font-size: 15px;
  }
`;

export const ArrowImg = styled.img`
  height: 9px;
`;