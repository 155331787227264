import styled from "styled-components";
import { device } from '../../styles/Responsive';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export const SliderContainer = styled(Slider)`
  &.slick-slider {
    height: 367px;

    & .nextArrow {
      position: absolute;
      top: 50%;
      right: 3%;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
      width: 32px;
      height: 48px;
      opacity: 0.65;
      transition: opacity 0.5s linear;

      &:hover {
        opacity: 1;
      }

      @media ${device.tablet} {
        width: 25px;
        height: 38px;
      }

      @media ${device.tabletS} {
        right: 2px;
        width: 20px;
        height: 30px;
      }
    }

    & .prevArrow {
      position: absolute;
      top: 50%;
      left: 3%;
      transform: translateY(-50%);
      z-index: 2;
      cursor: pointer;
      width: 32px;
      height: 48px;
      opacity: 0.65;
      transition: opacity 0.5s linear;

      &:hover {
        opacity: 1;
      }

      @media ${device.tablet} {
        width: 25px;
        height: 38px;
      }

      @media ${device.tabletS} {
        left: 2px;
        width: 20px;
        height: 30px;
      }
    }

    & .slick-list {
      position: relative;
      height: 100%;

      & .slick-track {
        height: 100%;
      }

      &:before {
        content: '';
        position: absolute;
        width: 10%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;

        @media ${device.tabletS} {
          width: 25px;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 10%;
        height: 100%;
        top: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.5);
        z-index: 1;

        @media ${device.tabletS} {
          width: 25px;
        }
      }
    }
  }
`;

export const SliderItem = styled.div`
  & div {
    height: 367px;
  }
`;

export const Arrow = styled.img`
  cursor: pointer;
`;