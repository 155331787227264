import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import {
  InputLabelDiv,
  InputDiv,
  FileSection,
  FileDropSection
} from "./InputStyles";
import { Icon, Popconfirm } from "antd";
import { AsyncForEach } from "./../../infra/services/async/AsyncFunctions";
import { MaterialErrorLabel } from "./MaterialStyles";
import { withLocalize } from "react-localize-redux";

const FilesInput = ({ 
  input,
  meta: { invalid, submitFailed, error },
  handleAdd,
  handleDelete,
  maxSize,
  label,
  maxFiles,
  requiredError,
  translate,
  accept
}) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisable] = useState(false);

  const showError = invalid && submitFailed;

  let errorMsg = '';
  if (error) {
    if (Array.isArray(error) && error.length === 3) {
      errorMsg = translate(error[0]).replace(error[1], error[2]);
    }
    else {
      errorMsg = translate(error);
    }
  }

  if (requiredError) {
    errorMsg = translate('INPUT_ERROR_REQUIRED');
  }

  useEffect(() => {
    const disableUpload = input?.value?.length >= maxFiles ? true : false;
    setDisable(disableUpload);
  }, [input, maxFiles])

  const handleDrop = async accepted => {
    const saved = [];
    setLoading(true);
    await AsyncForEach(accepted, async file => {
      const { data, success } = await handleAdd(file);
      if (success) saved.push(data);
    });
    input.onChange([...input.value, ...saved]);
    setLoading(false);
  };

  const removeFromList = id => {
    if (handleDelete) handleDelete(id);
    input.onChange(input.value.filter(x => x._id !== id));
  };

  return (
    <InputDiv>
      <InputLabelDiv>{label}</InputLabelDiv>
      <Dropzone
        style={{ width: "100%", height: "100%", display: "inline-block" }}
        onDrop={handleDrop}
        maxSize={maxSize}
        disabled={disabled}
        accept={accept || ''}
      >
        {({ getRootProps, getInputProps }) => (
          <FileDropSection error={showError} disabled={disabled} {...getRootProps()}>
            <input {...getInputProps()} />
            <Icon type={loading ? "loading" : "plus"} />
            <div>
              {loading ? "loading..." : "Click here or drag your files"}
            </div>
          </FileDropSection>
        )}
      </Dropzone>
      {/* {
        ((invalid && submitFailed) || requiredError) &&
        <MaterialErrorLabel visible={(invalid && submitFailed) || requiredError}>
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      } */}
      {Array.isArray(input.value)
        ? input.value.map((file, index) => (
          <FileSection key={index}>
            {file.name || file.filename || ""}
            <Popconfirm
              placement={"topRight"}
              title="Are you sure you want to delete this file?"
              onConfirm={() => removeFromList(file._id)}
            >
              <Icon type="delete" />
            </Popconfirm>
          </FileSection>
        ))
        : ""}
    </InputDiv>
  );
};

export default withLocalize(FilesInput);
