import React, { useState } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import {
  SelectDiv,
  StyledSelectInput,
  SelectArrow,
  ArrowImg,
  OptionContainer,
  OptionContent,
  Box,
  OptionText,
  SelectLabelInput
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import { MaterialErrorLabel, MaterialInputSection, MaterialLabel, MaterialLabelFixed } from "./MaterialStyles";

const CustomSelectInput = ({
  input,
  placeholder,
  disabled,
  meta,
  languages,
  translate,
  dataKey,
  dataLabel,
  label,
  labelFixed
}) => {
  const [filled, setFilled] = useState(input && input.value !== "");
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  let errorMsg = '';
  if (meta?.error) {
    if (Array.isArray(meta.error) && meta.error.length === 3) {
      errorMsg = translate(meta.error[0]).replace(meta.error[1], meta.error[2]);
    }
    else {
      errorMsg = translate(meta?.error);
    }
  }

  const changeSelect = value => {
    input.onChange(value || "");
  };

  return (
    <MaterialInputSection noLabel={!label}>
      <SelectDiv>
        {
          label && (labelFixed ?
            <MaterialLabelFixed filled={filled} showError={showError}>
              {label}
            </MaterialLabelFixed>
            :
            <MaterialLabel filled={filled} showError={showError}>
              {label}
            </MaterialLabel>)
        }
        <StyledSelectInput
          disabled={disabled}
          placeholder={placeholder}
          allowClear={false}
          onChange={changeSelect}
          onBlur={() => setFilled(input && input.value !== "")}
          onFocus={() => setFilled(true)}
          value={input?.value || undefined}
          showArrow={false}
          showSearch={false}
          error={showError ? 1 : 0}
          className="customSelect"
        >
          {languages.map((elem, index) => (
            <OptionContainer key={elem[dataKey]}>
              <OptionContent selected={elem.code === input?.value}>
                <Box
                  className="customSelectBox"
                  selected={elem.code === input?.value}
                />
                <OptionText selected={elem.code === input?.value}>
                  {translate(`LANG_${elem.code.toUpperCase()}`)}
                </OptionText>
              </OptionContent>
            </OptionContainer>
          ))}
        </StyledSelectInput>
        <SelectArrow disabled={disabled} className="customSelectArrow">
          <ArrowImg src={ArrowDown} />
        </SelectArrow>
      </SelectDiv>
      {
        (showError) &&
        <MaterialErrorLabel visible={showError}>
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      }
    </MaterialInputSection>
  );
};

CustomSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  placeholder: PropTypes.string,
  labelFixed: PropTypes.bool,
};

CustomSelectInput.defaultProps = {
  data: [],
  allowClear: false,
  dataKey: "code",
  dataLabel: "name",
  labelFixed: false,
};

export default withLocalize(CustomSelectInput);