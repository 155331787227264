import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const TimelineItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 340px;
  margin-left: ${p => p.direction === 'right' ? 'auto' : 'unset'};
  margin-right: ${p => p.direction === 'left' ? 'auto' : 'unset'};
  margin-bottom: 8px;
  direction: ${p => p.direction === 'right' ? 'ltr' : 'rtl'};
  position: relative;
  z-index: ${p => p.zIndex};

  @media ${device.laptopL} {
    width: 270px;
  }

  @media ${device.tabletL} {
    width: 290px;
  }

  @media ${device.tabletS} {
    width: 250px;
  }

  @media ${device.mobileL} {
    display: none;
  }
`;

export const EmptyItem = styled.div`
  width: 310px;
  height: 72px;
  margin-bottom: 8px;

  @media ${device.laptopL} {
    width: 240px;
  }

  @media ${device.tabletL} {
    width: 260px;
  }

  @media ${device.tabletS} {
    width: 220px;
  }

  @media ${device.mobileL} {
    display: none;
  }
`;

export const ItemContent = styled.div`
  background-color: ${p => p.active ? p.theme.primaryColor : '#ffffff'};
  border: 1px solid ${p => p.theme.primaryCTA};
  border-radius: ${p => p.active && p.direction === 'right' ? '0px 12px 12px 0px' : p.active && p.direction === 'left' ? '12px 0px 0px 12px' : '12px'};
  width: 310px;
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.3s linear, border-radius 0.3s linear;
  cursor: pointer;

  &:hover {
    background-color: ${p => p.theme.primaryColor};

    &:before, &:after {
      background-color: ${p => p.theme.primaryColor};
    }

    & .itemName, & .itemTime {
      color: #ffffff;
    }
    
    & .itemNameContainer {
      background-color: ${p => p.theme.primaryColor};
      border-color: #ffffff;
    }
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    width: 14px;
    height: 14px; 
    background: ${p => p.active ? p.theme.primaryColor : '#ffffff'};
    border-right: 1px solid ${p => p.theme.primaryCTA};
    border-bottom: 1px solid ${p => p.theme.primaryCTA};
    transition: background-color 0.3s linear;
  }

  &:before {
    display: ${p => p.direction === 'left' ? 'block' : 'none'};
    left: -8px;
    transform: translateY(-50%) rotate(135deg);
  }

  &:after {
    display: ${p => p.direction === 'right' ? 'block' : 'none'};
    right: -8px;
    transform: translateY(-50%) rotate(-45deg);
  }

  @media ${device.laptopL} {
    width: 240px;
  }

  @media ${device.tabletL} {
    width: 260px;
  }

  @media ${device.tabletS} {
    width: 220px;
  }
`;

export const Dot = styled.div`
  background-color: ${p => p.theme.primaryColor};
  border-radius: 100%;
  width: 13px;
  height: 13px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${p => p.direction === 'right' ? '-7px' : 'auto'};
  left: ${p => p.direction === 'left' ? '-7px' : 'auto'};

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${p => p.theme.primaryColor};
    opacity: ${p => p.active ? '0.4' : '0'};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: opacity 0.3s linear;
  }

  @media ${device.tabletL} {
    &:before {
      width: 26px;
      height: 26px;
    }
  }

  @media ${device.mobileL} {
    display: none;
  }
`;

export const ItemNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 100%;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: ${p => p.direction === 'right' ? '1px' : '0px'};
  border-left-width: ${p => p.direction === 'left' ? '1px' : '0px'};
  border-style: solid;
  border-color: ${p => p.active ? '#ffffff' : p.theme.primaryCTA};
  border-radius: ${p => p.direction === 'right' ? '12px 0px 0px 12px' : '0px 12px 12px 0px'};
  overflow: hidden;
  padding: 5px;
  z-index: 2;
  background-color: ${p => p.active ? p.theme.primaryColor : '#ffffff'};
  transition: background-color 0.3s linear, border-color 0.3s linear;

  @media ${device.tabletS} {
    width: 160px;
  }
`;

export const ItemName = styled.div`
  color: ${p => p.active ? '#ffffff' : '#000000'};
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  transition: color 0.3s linear;
  direction: ltr;

  @media ${device.laptopL} {
    font-size: 16px;
  }

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;

export const ItemTime = styled.div`
  width: 130px;
  color: ${p => p.active ? '#ffffff' : '#000000'};
  font-size: 18px;
  font-weight: 400;
  line-height: 31px;
  transition: color 0.3s linear;
  z-index: 2;
  direction: ltr;

  @media ${device.laptopL} {
    font-size: 16px;
    width: 60px;
  }

  @media ${device.tabletL} {
    width: 80px;
  }

  @media ${device.tabletS} {
    font-size: 14px;
    width: 60px;
  }
`;

export const ItemActions = styled.div`
  position: absolute;
  top: -1px;
  left: ${p => p.direction === 'right' && p.active ? '-160px' : '0px'};
  right: ${p => p.direction === 'left' && p.active ? '-160px' : '0px'};
  width: 160px;
  height: 72px;
  background-color: ${p => p.theme.primaryColor};
  border: 1px solid ${p => p.theme.primaryCTA};
  border-right: ${p => p.direction === 'right' ? '0' : '1px'};
  border-left: ${p => p.direction === 'left' ? '0' : '1px'};
  border-radius: ${p => p.direction === 'right' ? '12px 0px 0px 12px' : '0px 12px 12px 0px'};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: left 0.3s linear, right 0.3s linear;
  z-index: 1;

  @media ${device.laptopL} {
    width: 80px;
    left: ${p => p.direction === 'right' && p.active ? '-80px' : '0px'};
    right: ${p => p.direction === 'left' && p.active ? '-80px' : '0px'};
  }

  @media ${device.tabletL} {
    width: 100px;
    left: ${p => p.direction === 'right' && p.active ? '-100px' : '0px'};
    right: ${p => p.direction === 'left' && p.active ? '-100px' : '0px'};
  }

  @media ${device.tabletS} {
    width: 80px;
    left: ${p => p.direction === 'right' && p.active ? '-80px' : '0px'};
    right: ${p => p.direction === 'left' && p.active ? '-80px' : '0px'};
  }
`;

export const ItemDelete = styled.div`
  width: 80px;
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: ${p => p.direction === 'right' ? '1px' : '0px'};
  border-left-width: ${p => p.direction === 'left' ? '1px' : '0px'};
  border-style: solid;
  border-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.laptopL} {
    width: 40px;
  }

  @media ${device.tabletL} {
    width: 50px;
  }

  @media ${device.tabletS} {
    width: 40px;
  }
`;

export const ItemEdit = styled.div`
  width: 80px;
  height: 100%;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: ${p => p.direction === 'right' ? '1px' : '0px'};
  border-left-width: ${p => p.direction === 'left' ? '1px' : '0px'};
  border-style: solid;
  border-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.laptopL} {
    width: 40px;
  }

  @media ${device.tabletL} {
    width: 50px;
  }

  @media ${device.tabletS} {
    width: 40px;
  }
`;

export const ItemImg = styled.div`
  width: 24px;
  height: 24px;
  transition: opacity 0.3s linear;
  margin: auto;

  &:hover {
    opacity: 0.5;
  }

  & svg {
    width: 24px;
    height: 24px;

    & #edit, & #delete {
      transition: fill 0.3s linear;
      fill: #ffffff;
    }
  }

  @media ${device.laptopL} {
    width: 20px;
    height: 20px;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  @media ${device.tabletS} {
    width: 18px;
    height: 18px;

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  @media ${device.mobileL} {
    & svg {
      & #edit, & #delete {
        fill: ${p => p.active ? '#ffffff' : '#000000'};
      }
    }
  }
`;

/********************************** MOBILE **********************************/

export const TimelineItemContainerMobile = styled.div`
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.active ? p.theme.primaryColor : '#ffffff'};
  border: 1px solid ${p => p.theme.primaryCTA};
  border-radius: 12px;
  transition: background-color 0.3s linear;
  cursor: pointer;
  position: relative;
  z-index: ${p => p.zIndex};

  &:hover {
    background-color: ${p => p.theme.primaryColor};

    & .itemName, & .itemTime {
      color: #ffffff;
    }

    & .itemNameContainer, & .itemEdit {
      border-color: #ffffff;
    }

    & svg {
      & #edit, & #delete {
        fill: #ffffff;
      }
    }
  }

  @media ${device.mobileL} {
    display: flex;
  }
`;

export const ItemNameContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 80%;
  height: 100%;
  padding: 7px;
  border-right: 1px solid ${p => p.active ? '#ffffff' : p.theme.primaryCTA};
  transition: border-color 0.3s linear;
`;

export const ItemNameMobile = styled.div`
  color: ${p => p.active ? '#ffffff' : '#000000'};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: color 0.3s linear;
  text-align: left;
`;

export const ItemTimeMobile = styled.div`
  color: ${p => p.active ? '#ffffff' : '#000000'};
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  transition: color 0.3s linear;
  text-align: left;
`;

export const ItemActionsMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
`;

export const ItemEditMobile = styled.div`
  border-bottom: 1px solid ${p => p.active ? '#ffffff' : p.theme.primaryCTA};
  transition: border-color 0.3s linear;
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemDeleteMobile = styled.div`
  width: 100%;
  height: 100%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemAnswersContainer = styled.div`
  position: absolute;
  top: 0;
  left: ${p => p.direction === 'right' ? '-455px' : '0px'};
  right: ${p => p.direction === 'left' ? '-455px' : '0px'};
  width: 290px;
  opacity: ${p => p.active && p.hasAnswers ? '1' : '0'};
  visibility: ${p => p.active && p.hasAnswers ? 'visible' : 'hidden'};
  transition: opacity 0.3s linear, visibility 0.3s linear;

  @media ${device.desktopXL} {
    top: 73px;
    width: 310px;
    left: 0px;
    right: 0px;
  }

  @media ${device.laptopL} {
    width: 240px;
  }

  @media ${device.tabletL} {
    width: 260px;
  }

  @media ${device.tabletS} {
    width: 220px;
  }

  @media ${device.mobileL} {
    width: 100%;
    top: 80px;
  }
`;

export const ItemAnswersContent = styled.div`
  border: 1px solid ${p => p.theme.primaryCTA};
  border-radius: 6px;
  padding: 0px 20px;
  background-color: ${p => p.theme.secondaryColor};
  overflow-y: auto;
  max-height: 240px;

  & div:last-child {
    border-bottom: 0;
  }
`;

export const ItemAnswerContainer = styled.div`
  max-height: 68px;
  overflow: hidden;
  padding: 10px 0px;
  border-bottom: 1px solid ${p => p.theme.primaryCTA};
`;

export const ItemAnswer = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-height: 48px;
  overflow: hidden;

  @media ${device.tabletS} {
    font-size: 14px;
  }
`;