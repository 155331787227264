import React from "react";
import { withLocalize } from "react-localize-redux";
import { connect } from 'react-redux';
import {
  BarContainer,
  Bar,
  TextContainer,
  Text
} from "./ProgressBarStyles";
import moment from "moment";

const ProgressBar = ({ weddingDate, createdAt, translate }) => {
  const wedding_date = moment(weddingDate).utc();
  const created_date = moment(createdAt).utc();
  const current_date = moment().utc();
  let days_remaining = wedding_date.diff(current_date, 'days');
  const days_since_creation = wedding_date.diff(created_date, 'days');
  let percentage = 100 - ((days_remaining * 100) / days_since_creation);

  if(percentage < 0) {
    percentage = 0;
  }
  else if(percentage > 100) {
    percentage = 100;
  }

  if(days_remaining < 0) {
    days_remaining = 0;
  }

  return (
    <BarContainer>
      <Bar percentage={percentage}/>
      <TextContainer percentage={percentage}>
        <Text>{`${days_remaining}\n${translate('DAYS')}`}</Text>
      </TextContainer>
    </BarContainer>
  );
}

const mapStateToProps = state => ({ 
  weddingDate: state.wedding.wedding.date,
  createdAt: state.wedding.wedding.createdAt
});

export default withLocalize(connect(mapStateToProps)(ProgressBar));