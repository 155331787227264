import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { 
  SelectDiv, 
  StyledSelectInput, 
  SelectArrow, 
  ArrowImg,
  OptionContainer,
  OptionContent,
  Box,
  OptionText,
  SelectErrorLabel
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const CustomSelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  disabled,
  fetchData,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  translate,
  createNew,
  selectOption,
  showNew
}) => {
  const { invalid, submitFailed, error } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = value => {
    input.onChange(value || '');
  };

  let errorMsg = '';
  if(error) {
    if(Array.isArray(error) && error.length === 3) {
      errorMsg = translate(error[0]).replace(error[1], error[2]);
    }
    else {
      errorMsg = translate(error);
    }
  }
  
  return (
    <React.Fragment>
      <SelectDiv>
        <StyledSelectInput
          disabled={disabled}
          placeholder={placeholder}
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          showArrow={false}
          showSearch={false}
          error={showError ? 1 : 0}
          className='customSelect'
        > 
          <OptionContainer
            key={translate('CREATE')}
            onClick={() => createNew()}
          >
            <OptionContent 
              selected={showNew}
            >
              <Box 
                className='customSelectBox' 
                selected={showNew}
              />
              <OptionText 
                selected={showNew}
              >
                {translate('CREATE')}
              </OptionText>
            </OptionContent>
          </OptionContainer>
          {
            data.map((elem, index) => (
              <OptionContainer
                key={dataKey ? elem[dataKey] : elem}
                onClick={() => selectOption()}
              >
                <OptionContent 
                  selected={!showNew && elem === input.value}
                >
                  <Box 
                    className='customSelectBox' 
                    selected={!showNew && elem === input.value}
                  />
                  <OptionText 
                    selected={!showNew && elem === input.value}
                  >
                    {dataLabel ? (translatable ? elem[dataLabel][activeLanguage.code] : elem[dataLabel]) : elem}
                  </OptionText>
                </OptionContent>
              </OptionContainer>
            ))
          }
        </StyledSelectInput>
        <SelectArrow 
          className='customSelectArrow'
        >
          <ArrowImg src={ArrowDown}/>
        </SelectArrow>
      </SelectDiv>
      {invalid && submitFailed && (
        <SelectErrorLabel visible={invalid && submitFailed}>
          {errorMsg.toUpperCase()}
        </SelectErrorLabel>
      )}
    </React.Fragment>
  );
};

CustomSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  createNew: PropTypes.func,
  selectOption: PropTypes.func,
  showNew: PropTypes.bool
};

CustomSelectInput.defaultProps = {
  data: [],
  allowClear: false,
};

export default withLocalize(CustomSelectInput);