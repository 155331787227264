import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const DecorationContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  padding-bottom: 0px;
  margin: auto;

  @media ${device.desktopL} {
    padding: 60px 20px;
    padding-bottom: 0px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
    padding-bottom: 0px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
    padding-bottom: 0px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    padding-bottom: 0px;
    margin-bottom: 60px;
  }
`;

export const Title = styled(titleH2)`
  
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  color: #000000;
  padding-top: 30px;
  font-size: 16px;
  line-height: 24px;
  color: #000000;

  @media ${device.desktop} {
    padding-top: 15px;
  }
`;

export const CardsContainer = styled.div`
  max-width: 1290px;
  margin: auto;
  margin-top: 100px;

  @media ${device.tablet} {
    margin-top: 80px;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;