import React from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

const ModalContainer = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-family: "Titillium Web", sans-serif;
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    padding: 15px 16px;
    text-align: right;
    background: transparent;
    border-top: none;
  }
  .ant-modal-body {
    color: #000000;
    font-size: 15px;
    word-wrap: break-word;
    max-height: 40vh;
    overflow-y: auto;
    line-height: 24px;
  }
`;

const ModalButton = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.primaryCTA};
  border-radius: 6px;
  text-align: center;
  padding: 10px 30px;
  cursor: pointer;
  font-family: "Titillium Web", sans-serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
  transition: background-color 0.5s linear;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryCTA};
  }
`;

const ModalText = styled.div`
  white-space: pre-wrap;
  font-size: 16px;
`;

const PasswordWarningModal = ({
  passwordModalText,
  translate,
  activeLanguage,
  open,
  onClose,
}) => {
  return (
    <ModalContainer
      title={translate("ALERT_ATTENTION_TEXT")}
      onCancel={onClose}
      visible={open}
      footer={<ModalButton onClick={onClose}>ok</ModalButton>}
    >
      <ModalText>
        {passwordModalText ? translate(passwordModalText) : ""}
      </ModalText>
    </ModalContainer>
  );
};

export default withLocalize(PasswordWarningModal);
