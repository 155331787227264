import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const DetailContent = styled.div`
  padding-top: 45px;
  padding-bottom: 90px;
`;

export const DetailContainer = styled.div`
  width: 100%;
  max-width: 970px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding: 0 15px;
`;

export const Title = styled(titleH2)`
  padding-bottom: 50px;
  text-align: left;

  @media ${device.tablet} {
    padding-bottom: 30px;
  }
`;

export const Description = styled.div`
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-bottom: 30px;

  & a {
    word-break: break-all;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Arrow = styled.img`
  cursor: pointer;
`;

export const ReturnContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-bottom: 50px;
  }
`;

export const MainCategory = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  text-transform: uppercase;
`;

export const DocumentsSection = styled.div`
  width: 100%;
  margin-top: 100px;
`;

export const ImageContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  margin: auto;
  padding: 0 15px;
`;