import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import {withLocalize} from "react-localize-redux";
import TextInput from "../../components/inputs/TextInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo.svg";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  FormTitle
} from "./LoginPageStyles";
import { Icon, notification } from "antd";
import { ResetPassword } from "../../infra/requests/AuthRequests";
import queryString from "query-string";

const ValidateResetForm = values => {
  const errors = {};
  errors.password = FormValidator.validateField(
    "password",
    values.password,
    "required|min:6"
  );
  errors.passwordconfirm = FormValidator.validateField(
    "passwordconfirm",
    values.passwordconfirm,
    "required|min:6"
  );
  if (values.password !== values.passwordconfirm) {

    // PASSWORD_NOT_MATCH
    errors.passwordconfirm = { _error: "The passwords don't match." };
  }
  return errors;
};

class ResetPasswordPage extends Component {
  state = {
    showPassword: false,
    showConfirmPassword: false
  };

  onSubmit = async values => {
    const {
      history,
      location: { search }
    } = this.props;
    const params = queryString.parse(search);
    const newParams = {
      password: values.password,
      token: params.token,
      email: params.email
    };

    try {
      const result = await ResetPassword(newParams);
      if (result && result.success) {
        notification.success({
          message: "Password Changed",
          description:
            "You can now enter your account with the new defined password."
        });
        history.push("/login");
      }
    } catch (e) {
      console.error(e);
    }
  };

  componentDidMount = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  render() {
    const { handleSubmit, submitting, translate } = this.props;
    const { showPassword, showConfirmPassword } = this.state;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <FormTitle>{translate('CHANGE_PASSWORD')}</FormTitle>
            <Field
              component={TextInput}
              name="password"
              type={showPassword ? 'text' : 'password'}
              label={translate('NEW_PASSWORD')}
              suffix={<Icon type={showPassword ? 'eye-invisible' : 'eye'} onClick={() => this.setState({ showPassword: !showPassword })} />}
            />
            <Field
              component={TextInput}
              name="passwordconfirm"
              type={showConfirmPassword ? 'text' : 'password'}
              label={translate('CONFIRM_PASSWORD')}
              suffix={<Icon type={showConfirmPassword ? 'eye-invisible' : 'eye'} onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })} />}
            />
            <LoginButtonDiv>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="submit"
                type="primaryLarge"
                loading={submitting}
                bgColor={'primaryColor'}
                text={translate('SAVE')}
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
      </LoginContainer>
    );
  }
}

ResetPasswordPage = reduxForm({
  form: "reset_password",
  validate: ValidateResetForm
})(ResetPasswordPage);

export default withLocalize(ResetPasswordPage);