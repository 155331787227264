import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ListCard from "../optionCard/ListCard";
import { ListContainer } from "./AboutUsListQuestionStyles";

const AboutUsListQuestion = ({ input, meta, question, wedding, isProcessClosed }) => {
  return (
    <ListContainer>
      {
        question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) =>
          <ListCard 
            key={index}
            type={1}
            record={elem}
            input={input}
            canEditSection={true}
            isProcessClosed={isProcessClosed}
            question={question}
          />
        )
      }
    </ListContainer>
  );
};

AboutUsListQuestion.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding
});

export default connect(mapStateToProps)(AboutUsListQuestion);