import styled from 'styled-components';
import { device, vertical } from '../../styles/Responsive';
import { titleH4 } from '../../styles/TextSizes';

const menuTransition = "0.5s";

export const MenuContainer = styled.div`
  position: fixed;
  top: 370px;
  right: ${({ open }) => open ? "0px" : "-325px"};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  transition: right ${menuTransition} linear;
  z-index: ${({ open }) => open ? '1000' : '999'};

  @media ${device.laptop} {
    top: 280px;
  }

  @media ${device.tablet} {
    top: 250px;
  }

  @media ${device.tabletS} {
    right: ${({ open }) => open ? "0px" : "-280px"};
  }

  @media ${device.mobileL} {
    right: ${({ open }) => open ? "0px" : "-220px"};
  }

  @media ${device.mobileM} {
    top: 280px;
  }

  @media ${vertical.smallL} {
    top: 110px;
  }
`;

export const MenuFixed = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 70px;
  height: 312px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${p => p.theme.primaryCTA};
  transition: background-color 0.5s linear;
  cursor: pointer;
  padding: 25px 0px;
  position: relative;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }

  @media ${device.laptopL} {
    width: 60px;
  }

  @media ${device.laptop} {
    width: 50px;
  }

  @media ${device.tablet} {
    height: 74px;
  }

  @media ${device.tabletS} {
    width: 40px;
  }

  @media ${vertical.medium} {
    height: 74px;
  }
`;

export const Icon = styled.div`
  width: 100%;
  padding-bottom: 40px;
  transform: ${({ open }) => open ? 'rotateY(-180deg)' : 'none'};
  transition: transform ${menuTransition} linear;
  width: 24px;
  height: 24px;

  & svg {
    width: 24px;
    height: 24px;

    & #left {
      fill: #ffffff;
      transition: fill 0.5s linear;
    }
  }

  @media ${device.tablet} {
    padding-bottom: 0px;
  }
`;

export const Text = styled(titleH4)`
  color: #ffffff;
  position: absolute;
  top: 50%;
  transform: rotateZ(-90deg);
  white-space: nowrap;

  @media ${device.laptop} {
    font-size: 20px;
  }

  @media ${device.tablet} {
    display: none;
  }

  @media ${vertical.medium} {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border: 1px solid #DFDFDF;
  width: 325px;
  max-height: 520px;
  overflow-y: auto;

  @media ${device.laptopL} {
    max-height: 495px;
  }

  @media ${device.tabletS} {
    width: 280px;
  }

  @media ${device.mobileL} {
    width: 220px;
  }

  @media ${vertical.medium} {
    max-height: 330px;
  }

  @media ${vertical.mediumS} {
    max-height: 280px;
  }
`;

export const MenuRow = styled.div`
  color: #565656;
  line-height: 24px;
  border: 0;
  border-top-width: ${p => p.borderTop === false ? '0px' : '1px'};
  border-style: solid;
  border-color: #DFDFDF;
  width: 100%;
  padding: 20px 10px;
  background-color: ${p => p.theme.thirdColor};
  cursor: pointer;
  transition: background-color 0.5s linear;

  &:hover {
    background-color: ${p => p.theme.secondaryColor};
  }

  @media ${device.laptopL} {
    padding: 15px 10px;
    font-size: 18px;
  }

  @media ${device.tablet} {
    padding: 10px 5px;
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;