import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Translate, withLocalize } from "react-localize-redux";
import { Icon, Select, Spin } from "antd";
import { ArrowImg, CloseArrow, InputDiv, InputLabelDiv, SelectArrow, SelectDiv, SelectOption, SelectOptions, StyledManualSelectInput, StyledSelectDiv, StyledSelectInput } from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import Close from "../../assets/icons/close.svg";
import { GuestAgeEnum } from "../../pages/guests/utilGuest";

const SelectAgeInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  first,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  tag,
  costumeLabel,
  horizontal = false,
  translate
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const [open, toogleOpen] = useState(false);
  const [value, setValue] = useState([]);
  const selectAgeRef = useRef(null);

  useEffect(() => {
    setValue(input.value || []);
  }, [input.value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectAgeRef.current && !selectAgeRef.current?.contains(event.target)) {
        toogleOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const formatValue = () => {
    if (!input.value) return undefined;
    if (input.value == GuestAgeEnum.ADULT) return undefined;
    else {
      const selected = data.find(f => f?._id == input.value);
      return selected
        ? (translatable ? translate(selected[dataLabel]) : selected[dataLabel])
        : undefined;
    }
  };

  const isFilled = () => {
    return input?.value && input?.value !== GuestAgeEnum.ADULT ? true : false;
  }

  const changeSelect = value => {
    input.onChange(value || GuestAgeEnum.ADULT);
    toogleOpen(!open);
  };

  const openOptions = () => {
    input.onFocus();
    if (disabled) return;
    toogleOpen(!open)
  }

  return (
    <SelectDiv first={first || !label} horizontal={horizontal} ref={selectAgeRef} >
      {label && <InputLabelDiv horizontal={horizontal}>{label}</InputLabelDiv>}
      <StyledManualSelectInput filled={isFilled()} disabled={disabled} onClick={() => openOptions()}>
        <div className="select-input">
          {formatValue()}
        </div>
        {
          !disabled && (allowClear && input?.value && formatValue() != undefined
            ? <CloseArrow smallIcon={true} onClick={() => changeSelect(null)}><ArrowImg src={Close} /></CloseArrow>
            : <SelectArrow smallIcon={true}><ArrowImg src={ArrowDown} /></SelectArrow>)
        }
      </StyledManualSelectInput>

      {open && <SelectOptions age={true}>
        {data.map((elem, index) => (
          <SelectOption key={dataKey ? elem[dataKey] : index} disabled={elem?.disabled || false} onClick={(e) => changeSelect(elem[dataKey])}>
            {dataLabel
              ? (translatable ? translate(elem[dataLabel]) : elem[dataLabel])
              : elem}
          </SelectOption>
        ))}
      </SelectOptions>}
    </SelectDiv>
  );
};

SelectAgeInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string
};

SelectAgeInput.defaultProps = {
  data: [],
  allowClear: true,
  dataLabel: 'name',
  dataKey: '_id'
};

export default withLocalize(SelectAgeInput);
