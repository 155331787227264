import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  SliderContainer,
  Arrow,
  Icon
} from "./SliderStyles";
import ArrowLeft from '../../assets/icons/arrow_left.svg';
import ArrowRight from '../../assets/icons/arrow_right.svg';
import SliderItem from './SliderItem';
import ABOUT_US from '../../assets/icons/sections/about_us.svg';
import CEREMONY from '../../assets/icons/sections/ceremony.svg';
import DECORATION from '../../assets/icons/sections/decoration.svg';
import DRINK_SELECTION from '../../assets/icons/sections/drink_selection.svg';
import FOOD_SELECTION from '../../assets/icons/sections/food_selection.svg';
import GUESTS from '../../assets/icons/sections/guests.svg';
import OUR_DAY from '../../assets/icons/sections/our_day.svg';
import OUR_STYLE from '../../assets/icons/sections/our_style.svg';
import PARTY_SELECTION from '../../assets/icons/sections/party_selection.svg';
import STAFF from '../../assets/icons/sections/staff.svg';

const NextArrow = (props) => {
  const { onClick } = props;

  return (
    <Arrow src={ArrowRight} className='nextArrow' onClick={onClick}/>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <Arrow src={ArrowLeft} className='prevArrow' onClick={onClick}/>
  );
}

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: false,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>,
  draggable: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const Slider = ({ incompleteSections, translate }) => {
  const renderIcon = (icon) => {
    switch(icon) {
      case 'ABOUT_US':
        return <Icon src={ABOUT_US}/>;
      case 'OUR_STYLE':
        return <Icon src={OUR_STYLE}/>;
      case 'OUR_DAY':
        return <Icon src={OUR_DAY}/>;
      case 'CEREMONY':
        return <Icon src={CEREMONY}/>;
      case 'STAFF':
        return <Icon src={STAFF}/>;
      case 'GUESTS':
        return <Icon src={GUESTS}/>;
      case 'FOOD_SELECTION':
        return <Icon src={FOOD_SELECTION}/>;
      case 'DRINK_SELECTION':
        return <Icon src={DRINK_SELECTION}/>;
      case 'DECORATION':
        return <Icon src={DECORATION}/>;
      case 'PARTY_SELECTION':
        return <Icon src={PARTY_SELECTION}/>;
      default:
        return null;
    }
  }

  const renderItem = (item, index) => {
    return (
      <SliderItem 
        key={index} 
        name={translate(item.name)} 
        icon={renderIcon(item.name)} 
        url={item.url}
        questions={item.questions}
        answers={item.answers}
      />
    );
  }
  
  return (
    <SliderContainer {...sliderSettings}>
      {
        incompleteSections.map((item, index) => renderItem(item, index))
      }
    </SliderContainer>
  );
}

export default withLocalize(Slider);