import client from '../config/AxiosConfig';

export const CreateInterestDate = async (data) =>
  client.put(`/my-wedding/createInterestDate`, data);

// export const CreateMultipleWaitingDate = async (data) =>
//   client.put(`/my-wedding/createMultipleWaitingDate`, data);

// export const UpdateReserve = async (data) =>
//   client.post(`/my-wedding/updateReserve`, data);

export const DeleteInterestDate = async (id, deleted) =>
  client.delete(`/my-wedding/deleteInterestDate/${id}/${deleted}`);

export const DeleteInterestDateAgenda = async (deleted, data) =>
  client.post(`/my-wedding/deleteInterestDateAgenda/${deleted}`, data);

// export const SoftDeleteMultipleWaitingDate = async (deleted, data) =>
//   client.post(`/my-wedding/softDeleteMultipleWaitingDate/${deleted}`, data);

export const GetWeddingInterestDates = async (filters) =>
  client.get(`/my-wedding/getWeddingInterestDates?filter=${filters}`);

export const GetInterestDatesPaginated = async (currentPage, limit, filters) =>
  client.get(`/my-wedding/getInterestDates/${currentPage}/${limit}?filter=${filters}`);

// export const GetInterestDate = async (id) =>
//   client.get(`/my-wedding/getInterestDate/${id}`);