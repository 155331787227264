import React, { useEffect, useState } from "react";
import { Modal, Button, Icon, Checkbox } from 'antd';
import styled from 'styled-components';
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { TranslateFromObject } from "../../infra/services/translations/AvailableTranslations";
import { AgendaType } from "./AgendaPage";
import { LinkAcceptPolicy } from "../../components/YearView/CalendarStyles";

export const StyledDraftJS = styled.div`
  display: inline-block;
  width: 100%;
  height: calc(100vh - 315px);
  overflow: auto;
  text-align: left;
  white-space: pre-line;
`;

export const Content = styled.div`
  padding: 24px;
`;

let InterestPolicyModal = ({ openModal, agendaType, initialValues, saveModal, closeModal, translate, activeLanguage }) => {

  const [content, setContent] = useState({});
  const [loading, toogleLoading] = useState(true);

  const [acceptedInterestPolicy, setAcceptedInterestPolicy] = useState(false);

  useEffect(() => {
    if (initialValues?.acceptedInterestPolicy) {
      setAcceptedInterestPolicy(initialValues.acceptedInterestPolicy);
    }
  }, [initialValues])

  useEffect(() => {
    async function getContent() {
      toogleLoading(true);
      const pageId = '6538e582b1c5920de5ac8013';
      const { data } = await GetDynamicPage(pageId);
      setContent(data);
      toogleLoading(false);
    }
    getContent();
    document.body.style.overflowY = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      visible={openModal}
      title={translate('TERMS_CONDITIONS_WD')}
      maskClosable
      onCancel={closeModal}
      className="policy-modal"
      footer={initialValues?.acceptedInterestPolicy
        ? [
          <Button
            loading={loading}
            type='default'
            onClick={() => closeModal()}>
            {translate('CLOSE')}
          </Button>
        ]
        : [
          <Button
            key='submit'
            loading={loading}
            type='default'
            onClick={() => closeModal()}>
            {translate('CANCEL')}
          </Button>,
          <Button
            key='submit'
            loading={loading}
            type='primary'
            disabled={!acceptedInterestPolicy}
            onClick={() => saveModal(acceptedInterestPolicy)}>
            {translate('SAVE')}
          </Button>
        ]}
    >
      {loading
        ? <SpinLoading />
        : <Content>
          <StyledDraftJS
            dangerouslySetInnerHTML={{
              __html: TranslateFromObject(content?.description || '', activeLanguage.code)
            }}
          />
          {agendaType === AgendaType.INTEREST && <Checkbox
            checked={acceptedInterestPolicy}
            disabled={initialValues?.acceptedInterestPolicy}
            onChange={(e) => setAcceptedInterestPolicy(e?.target?.checked)}>
            <LinkAcceptPolicy>{translate('ACCEPT_INTEREST_POLICY')}</LinkAcceptPolicy>
          </Checkbox>}
        </Content>
      }
    </Modal >
  );
};

export default InterestPolicyModal;