export const isString = (obj) => {
    return typeof obj === 'string' || obj instanceof String;
}

export const correctResponseValues = (values) => {
    if (values && values.response && Array.isArray(values.response)) {
        for (let index = 0; index < values.response.length; index++) {
            if (values.response[index]?.cost !== undefined) {
                if (!isString(values.response[index].cost)) values.response[index].cost = values.response[index].cost.toFixed(2);
            }
        }
    }
    // console.warn('val', values);

    return values;
}