import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DefaultImage from "../../assets/default_image.png";
import EnlargeIcon from "../../components/svg/Enlarge";
import { ImageIcon } from "../../components/optionCard/OptionCardStyles";

const ImageContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: ${p => p?.inZoom ? 'calc(100vh - 100px)' : '100%'};
  ${p => p?.inZoom ? `
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  `     
  : null}
`;

// 1s cubic-bezier(0.87, 0, 0.13, 1) 0s;
const Image = styled.div`
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: ${({ width }) => width ? width : '100%'};
  padding-top: ${({ inZoom, ratio, url }) => ratio ? 100 * ratio + "%" : url && !inZoom ? "100%" : '0%'};
  background-color: ${({ url, color }) => url ? 'unset' : color ? color : "#ece8e2"};
  background-image: ${({ url }) => url ? `url(${url})` : `url(${DefaultImage})`};
  background-size: ${({ bgContain, url }) => (bgContain || !url) ? 'contain' : 'cover'};
  background-position: ${({ bgContain, url }) => (bgContain || !url) ? 'unset' : 'center'};
  transition: 0.55s linear 0s;

  :hover {
    background-color: ${({ hoverUrl, hoverColor, url, color }) => hoverUrl || url ? 'unset' : hoverColor ? hoverColor : color ? color : "#ece8e2"};
    background-image: ${({ hoverUrl, url }) => hoverUrl ? `url(${hoverUrl})` : url ? `url(${url})` : `url(${DefaultImage})`};
    background-size: ${({ bgContain, hoverUrl, url }) => (bgContain || (!hoverUrl && !url)) ? 'contain' : 'cover'};
    background-position: ${({ bgContain, hoverUrl, url }) => (bgContain || (!hoverUrl && !url)) ? 'contain' : 'center'};
  }
`;

const ImageComponent = ({
  width,
  ratio,
  children,
  color,
  url,
  bgContain,
  onClick,
  zoom,
  inZoom,
  hoverUrl,
  hoverColor
}) => {
  return (
    <ImageContainer inZoom={inZoom} onClick={onClick}>
      {zoom && <ImageIcon>
        <EnlargeIcon />
      </ImageIcon>}
      <Image
        width={width}
        ratio={ratio}
        url={url}
        color={color}
        bgContain={bgContain}
        hoverUrl={hoverUrl}
        hoverColor={hoverColor}
        inZoom={inZoom}
      />
      {children}
    </ImageContainer>
  );
};

ImageComponent.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.any
};
export default ImageComponent;
