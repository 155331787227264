import React, { Fragment } from 'react';
import { notification, Icon } from 'antd';
import Dropzone from 'react-dropzone';
import { ExtraPill, FileResponse, FileSection, ResponsePill, DivMarginTop } from './InputStyles';

const { REACT_APP_IMAGES_URL } = process.env;

const FileInput = ({ input, execAfterChange, filePreview = false, addFile = true, translate, accept, meta = null }) => {
  
  let showError = 0;
  if (meta){
    const { invalid, submitFailed } = meta;
    showError = invalid && submitFailed;
  }
  
  const openFile = id => window.open(`${REACT_APP_IMAGES_URL}${id}`, '_blank');
  const handleDrop = async (accepted, rejected) => {
    if (accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return notification.error({
        message: 'Ficheiro Inválido',
        description: 'O ficheiro é inválido ou tem um tamanho superior a 12Mb'
      });
    }

    const uploaded = accepted.map(file => ({preview: URL.createObjectURL(file), size: file.size, type: file.type, blob: file}));
    input.onChange(uploaded);

    if (typeof execAfterChange === "function") {
      await execAfterChange(uploaded);
    }
  };

  const renderDropZone = () => {
    return (
      <Dropzone
        multiple={true}
        onDrop={handleDrop}
        accept={accept || ''}
        maxSize={120000000}>
          
        {({ getRootProps, getInputProps }) => {
          return (
            
            <FileSection {...getRootProps()} error={showError ? 1 : 0} >
              {addFile && <input {...getInputProps()} />}
              <Icon type="file" />
              Clique ou arraste um ficheiro
            </FileSection>
          );
        }}
      </Dropzone>
    );
  };

  if (input?.value) {
    return (
      <Fragment>
        {input?.value?.map(file => (
        <FileResponse>
          <DivMarginTop value="5px" key={file._id}>
          <ResponsePill single={false}>{file.filename || file.blob?.name}</ResponsePill>
          <ExtraPill last={filePreview ? false : true}>{((file.size || file.length) / (1024 * 1024)).toFixed(2)}MB</ExtraPill>
          {filePreview && <ExtraPill last={true} link onClick={() => openFile(file._id)}>{translate('OPEN_FILE')}</ExtraPill>}
          </DivMarginTop>
        </FileResponse>
        ))}
        {addFile && renderDropZone()}
      </Fragment>
    );
  }

  return renderDropZone();
};

export default FileInput;