import React from "react";
import { Field } from "redux-form";
import { withLocalize } from "react-localize-redux";
import {
  QuestionTitle,
  QuestionDescription
} from '../decoration/PageDetailStyles';
import {
  FieldContainer
} from './AboutUsStyles';
import AboutUsListQuestion from '../../components/aboutUsListQuestion/AboutUsListQuestion';

const QuestionsForm = ({ fields, questions, isProcessClosed, activeLanguage }) => {
  const renderQuestion = (field, question) => {
    return (
      <FieldContainer key={question._id}>
        <QuestionTitle>{question.title[activeLanguage?.code]} *</QuestionTitle>
        {
          question.description &&
          <QuestionDescription dangerouslySetInnerHTML={{__html: question.description[activeLanguage?.code]}}/>
        }
        <Field
          component={AboutUsListQuestion}
          name={`${field}.answer.response`}
          question={question}
          isProcessClosed={isProcessClosed}
        />
      </FieldContainer>
    );
  };

  return (
    <React.Fragment>
      { 
        fields.length > 0 && fields.map((question, index) => renderQuestion(question, questions[index]))
      }
    </React.Fragment>
  );
};

export default withLocalize(QuestionsForm);