export const GuestAgeEnum = {
    NEWBORN: 'NEWBORN', // Baby: from 0 months to 5 months old
    BABY: 'BABY', // Baby: from 6 months to 2 years old
    CHILD: 'CHILD', // Child: from 3 to 7 years old
    ADULT: 'ADULT' // Adult: 8 years old or above
}

export const AgeTypes = [
    { _id: 'NEWBORN', name: 'NEWBORN_AGE' },
    { _id: 'BABY', name: 'BABY_AGE' },
    { _id: 'CHILD', name: 'CHILD_AGE' },
    // { _id: 'ADULT', name: 'ADULT_AGE' },
];

export const TablePurposeEnum = {
    GUEST: 'GUEST',
    STAFF: 'STAFF',
    CHILDREN: 'CHILD',
    COUPLE: 'COUPLE'
}

export const TableTypesEnum = {
    OVAL: 'OVAL',
    ROUND: 'ROUND',
    SQUARE: 'SQUARE',
    RECTANGULAR: 'RECTANGULAR'
}

export const SidesEnum = {
    LEFT: 'LEFT',
    RIGHT: 'RIGHT',
    ND: 'ND',
}

export const setRestrictionsBasedOnAge = (oldAge, newAge, guestRestrictions, foodRestrictions) => {
    let restrictionsToUpdate = [...guestRestrictions];

    // Unselect restrictions that were selected by default based on old selected guestAge
    const restrictionsByOldAge = foodRestrictions.filter(f => f?.guestAge?.length > 0 && f?.guestAge?.includes(oldAge));
    if (restrictionsByOldAge?.length > 0) {
        restrictionsByOldAge.forEach(oldCheckR => {
            const index = restrictionsToUpdate.findIndex(f => f == oldCheckR?.id);
            if (index > -1) {
                restrictionsToUpdate.splice(index, 1);
            }
        });
    }

    // Select restrictions by default based on new selected guestAge
    const restrictionsByNewAge = foodRestrictions.filter(f => f?.guestAge?.length > 0 && f?.guestAge?.includes(newAge));
    if (restrictionsByNewAge?.length > 0) {
        restrictionsByNewAge.forEach(checkR => {
            const found = restrictionsToUpdate.find(f => f == checkR?.id);
            if (!found) {
                restrictionsToUpdate.push(checkR?.id);
            }
        });
    }

    return restrictionsToUpdate;
}