import React, { Component } from "react";
import {withLocalize} from 'react-localize-redux';
import {
  UpgradeContainer,
  Title,
  Subtitle,
  CardsContainer,
  CardsRow
} from "./UpgradeStyles";
import UpgradeCard from '../../components/upgradeCard/UpgradeCard';
import { GetUpgradeList } from '../../infra/requests/UpgradeRequests';
import { GetByTag } from '../../infra/requests/MyWeddingRequests';
import { SpinLoading } from "../../styles/BasicStyles";

class Upgrade extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      upgradeCards: undefined,
      upgradeText: undefined,
      ready: false,
    };
  }

  componentDidMount = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    document.body.style.overflowY = 'auto';
    
    this._isMounted = true;
    this._isMounted && this.getUpgradeCards();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUpgradeCards = async () => {
    let upgradeCards, upgradeText = undefined;

    let {data, success} = await GetUpgradeList();

    if(success) {
      upgradeCards = data;
    }

    ({data, success} = await GetByTag('UPGRADE'));

    if(success) {
      upgradeText = data;
    }

    this._isMounted && this.setState({
      upgradeCards,
      upgradeText,
      ready: true
    });
  }

  render() {
    const { translate, activeLanguage } = this.props;
    const { upgradeCards, upgradeText, ready } = this.state;

    if(!ready) return <SpinLoading/>;

    return (
      <UpgradeContainer>
        <Title>{translate("UPGRADE")}</Title>
        <Subtitle dangerouslySetInnerHTML={{__html: upgradeText.description && upgradeText.description[activeLanguage?.code]}}/>
        {
          upgradeCards?.length > 0 &&
          <CardsContainer>
            <CardsRow>
              {
                upgradeCards.map((card, index) => <UpgradeCard key={index} card={card}/>)
              }
            </CardsRow>
          </CardsContainer>
        }
      </UpgradeContainer>
    );
  }
}

export default withLocalize(Upgrade);