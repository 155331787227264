import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";

function useOutsideAlerter(ref, callback) {
  /**
   * Alert if clicked on outside of element
   */
  function handleClickOutside(event) {
    /*
    * Add a specific fix for "Our Day" Timeline, so we can close the Items when clicking them
    */
    if(
      ref.current && 
      !ref.current.contains(event.target) && 
      ((event.target.className &&
      typeof event.target.className.includes !== 'undefined' &&
      !event.target.className?.includes('itemNameContainer') &&
      !event.target.className?.includes('itemName') &&
      !event.target.className?.includes('itemTime'))
      ||
      !event.target.className)
    ) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.close);
  
  return <div ref={wrapperRef} style={{ height: props.section === 'OUR_DAY' ? 'auto' : '100%' }}>{props.children}</div>;
}

OutsideAlerter.propTypes = {
  children: PropTypes.element.isRequired
};

export default OutsideAlerter;