import React from "react";
import {withLocalize} from "react-localize-redux";
import CustomSelectInput from "../inputs/CustomSelectInput";
import {findCategoryIndex} from "./Categories";

const StaffCategory = ({ input, meta, categories, showNew, toggleShowNew, staffIndex, disabled, translate, activeLanguage }) => {
  if(input.value && input.value !== translate('CREATE') && !categories[activeLanguage?.code].includes(input.value)) {
    const index = findCategoryIndex(input.value);

    if(input.value !== categories[activeLanguage?.code][index]) {
      input.onChange(categories[activeLanguage?.code][index]);
    }
  }

  const selectOption = () => {
    toggleShowNew(staffIndex, false);
  }

  const createNew = () => {
    toggleShowNew(staffIndex, true);
  }

  return (
    <CustomSelectInput
      data={categories[activeLanguage?.code]}
      selectOption={selectOption}
      createNew={createNew}
      showNew={showNew}
      placeholder={translate('CHOOSE_CATEGORY')}
      meta={meta}
      input={input}
      disabled={disabled}
    />
  );
}

export default withLocalize(StaffCategory);