import React, { useState } from 'react';
import { Modal, Button, Icon, Radio } from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import momentTimezone from "moment-timezone";
import { dateTimeFormat } from './VisitAgendaPage';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
`;

let ServiceSchedulesModal = ({ openModal, closeModal, onSubmit, loading, initialValues, translate, activeLanguage }) => {

  const [service, setService] = useState(null);

  const onChange = (event) => {
    setService(event?.target?.value);
  }

  return (
    <Modal
      visible={openModal}
      title={translate('SCHEDULE_SERVICES_AVAILABLE')}
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          {translate('CANCEL')}
        </Button>,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          disabled={!service}
          onClick={() => onSubmit({ date: initialValues?.date, startTime: service })}
        >
          {translate('SAVE')}
        </Button>
      ]}
    >
      {initialValues && <ModalContent>
        <p><b>{translate('DATE')}:</b> {moment.utc(initialValues.date).format('DD/MM/YYYY')}</p>
        <span>{translate('LISBON_TIME_SCHEDULES')}</span>
        <Radio.Group onChange={onChange} value={service}>
          {initialValues?.services?.map(m => <Radio value={m._id}>{momentTimezone.utc(m?.startDate).tz("Europe/Lisbon").format('HH:mm')} ({m?.user?.name})</Radio>)}
        </Radio.Group>

        {initialValues?.showWarning && <p style={{ paddingTop: 10 }}>
          <Icon style={{ marginRight: 5 }} type="warning" />
          <b style={{ marginRight: 5 }}>{translate('ATTENTION')}:</b>
          {translate('NO_SERVICE_SCHEDULES_WEDDING_ORGANIZER')}
        </p>}
      </ModalContent>}
    </Modal>
  );
};

export default ServiceSchedulesModal;