import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const DashboardContainer = styled.div`
  width: 100%;
  max-width: 1305px;
  padding: 80px 0px;
  margin: auto;

  @media ${device.desktopL} {
    padding: 60px 0px;
  }

  @media ${device.laptop} {
    padding: 50px 0px;
  }

  @media ${device.tablet} {
    padding: 40px 0px;
  }

  @media ${device.mobileL} {
    padding: 30px 0px;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  padding: 0px 15px;
  padding-bottom: 110px;

  @media ${device.desktopL} {
    padding-bottom: 80px;
  }

  @media ${device.desktop} {
    padding-bottom: 70px;
  }

  @media ${device.tablet} {
    padding-bottom: 60px;
  }
`;

export const ProgressTitle = styled(titleH2)`
  width: 100%;
  text-align: center;
  padding-bottom: 60px;

  @media ${device.tablet} {
    padding-bottom: 40px;
  }
`;

export const NewsContainer = styled.div`
  width: 100%;
`;

export const NewsTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 15px;
  padding-bottom: 50px;
`;

export const NewsTitle = styled(titleH2)`
  text-align: left;
`;

export const SeeAll = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${p => p.theme.secondaryCTA};
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const CardsContainer = styled.div`
  max-width: 1305px;
  margin: auto;
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ChoicesContainer = styled.div`
  width: 100%;
`;


export const ChoicesTitle = styled(titleH2)`
  width: 100%;
  padding: 0px 15px;
  padding-bottom: 50px;
  text-align: left;
`;

export const ChoicesContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  padding-bottom: 140px;
  flex-wrap: wrap;

  @media ${device.desktopL} {
    padding-bottom: 100px;
  }

  @media ${device.desktop} {
    padding-bottom: 80px;
  }

  @media ${device.tablet} {
    padding-bottom: 60px;
  }
`;

export const SliderContainer = styled.div`
  width: calc(100% - 302px);

  @media ${device.laptopL} {
    width: calc(100% - 270px);
  }

  @media ${device.laptop} {
    width: 100%;
  }
`;

export const ChoicesBoxContainer = styled.div`
  padding: 0px 10px;
  width: 302px;
  height: 302px;

  @media ${device.desktopL} {
    width: 270px;
    height: 270px;
  }
  
  @media ${device.laptopL} {
    width: 270px;
  }

  @media ${device.laptop} {
    width: 100%;
    height: 180px;
    padding-top: 20px;
  }
`;

export const ChoicesBox = styled.div`
  background-color: ${p => p.theme.primaryCTA};
  height: 100%;
  padding: 20px;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.5s linear;
  
  &:hover {
    box-shadow: 8px 0 12px -4px ${p => p.theme.secondaryCTA}, -8px 0 12px -4px ${p => p.theme.secondaryCTA};
  }

  @media ${device.mobileM} {
    padding: 10px;
  }
`;

export const ChoicesBoxTitle = styled(titleH2)`
  width: 100%;
  text-align: left;
  color: #ffffff;
  padding-bottom: 20px;

  @media ${device.laptopL} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 24px;
    padding-bottom: 5px;
  }
`;

export const ChoicesBoxText = styled.div`
  color: #ffffff;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  width: 100%;
  text-align: left;

  @media ${device.laptopL} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Arrow = styled.span`
  position: absolute;
  bottom: 20px;
  right: 20px;

  & svg {
    width: 24px;
    height: 24px;

    & #arrowright {
      fill: #ffffff;
    }
  }

  @media ${device.mobileM} {
    bottom: 10px;
    right: 10px;
  }
`;