import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  CardContainer,
  CardContent,
  BgContainerColor,
  BgImage,
  BgColor,
  DetailContainer,
  Title,
  SelectContainer,
  CheckboxContainer,
} from "./OptionCardStyles";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import Alert from "../alert/Alert";

const ColorCard = ({
  question,
  record,
  canEditSection,
  isProcessClosed,
  disabled,
  input,
  translate,
  activeLanguage,
}) => {
  const selected = input?.value && input.value.find((id) => id === record._id);

  const toggleColor = (id) => {
    if (!disabled) {
      if (isProcessClosed) {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("PROCESS_CLOSED_TEXT"),
        });
      } else if (canEditSection) {
        const list = [...input.value];
        const index = list.findIndex((x) => x === id);

        if (index >= 0) list.splice(index, 1);
        else list.push(id);

        input.onChange(list);
      } else {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("BLOCKED_ANSWER_TEXT"),
        });
      }
    }
  };

  return (
    <CardContainer
      type={question.type}
      disabled={disabled}
      onClick={() => toggleColor(record._id)}
    >
      <CardContent selected={selected}>
        <SelectContainer>
          <CheckboxContainer>
            <CheckboxInput
              checkboxType="round"
              meta={{}}
              input={{ value: selected, onChange: () => null }}
            />
          </CheckboxContainer>
        </SelectContainer>
        {record.hex ? (
          <BgColor color={record.hex} />
        ) : record.image?.file ? (
          <BgContainerColor border={true}>
            <BgImage
              src={`${process.env.REACT_APP_IMAGES_URL}${record.image.file}`}
            />
          </BgContainerColor>
        ) : null}
        <DetailContainer>
          <Title>{record.name[activeLanguage?.code]}</Title>
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default withLocalize(ColorCard);
