import React from "react";
import {withLocalize} from 'react-localize-redux';
import {
  SectionContainer,
  TitleSection,
  CardsContainer,
  CardsRow
} from "./ChoicesStyles";
import TextCard from "../../components/choicesCards/TextCard";
import ObservationsCard from "../../components/choicesCards/ObservationsCard";

const ChoicesAboutStaff = ({ aboutUs, notes = null, translate }) => {
  return (
    <SectionContainer>
      <TitleSection>{translate('ABOUT_US')}</TitleSection>
      <CardsContainer>
        <CardsRow>
          {
            notes &&
            <ObservationsCard
              notes={notes}
            />
          }
          {
            aboutUs.map((elem, index) => 
              <TextCard
                key={index}
                question={elem.question}
                answer={elem.answer}
              />
            )
          }
        </CardsRow>
      </CardsContainer>
    </SectionContainer>
  );
}

export default withLocalize(ChoicesAboutStaff);