import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
  DashboardContainer,
  NewsContainer,
  NewsTitleContainer,
  NewsTitle,
  SeeAll,
  CardsContainer,
  CardsRow,
  ProgressContainer,
  ProgressTitle,
  ChoicesContainer,
  ChoicesContent,
  ChoicesTitle,
  ChoicesBox,
  ChoicesBoxTitle,
  ChoicesBoxText,
  Arrow,
  SliderContainer,
  ChoicesBoxContainer
} from "./DashboardStyles";
import NewsItem from '../../components/newsItem/NewsItem';
import Slider from '../../components/sliderDashboard/Slider';
import ProgressBar from '../../components/progressBarDashboard/ProgressBar';
import LongArrowRight from '../../components/svg/LongArrowRight';
import { sliderItems } from "./Items";
import { GetCurrentInspirations } from '../../infra/requests/OurStyleRequests';
import { GetGuests } from '../../infra/requests/GuestsRequests';
import { getSectionStatus } from '../../infra/services/utils/PageDashboard';
import {
  findPageByTag,
  findPageByID
} from '../../infra/services/utils/PageInfo';
import { SpinLoading } from "../../styles/BasicStyles";
import { GetNewsPaginated } from "../../infra/requests/MyWeddingRequests";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";

class DashboardPage extends Component {
  state = {
    newsList: [],
    incompleteSections: [],
    ready: false
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    this.updateSection();
  }

  updateSection = async () => {
    const { sectionsInfo, weddingInfo } = this.props;

    let incompleteSections = [];

    //OUR STYLE
    let { data, success } = await GetCurrentInspirations();
    let currentInspirations = [];
    let currentSuggestions = [];

    if (success) {
      currentInspirations = data.inspirations;
      currentSuggestions = data.images;
    }

    //About (payment data)
    const resAbout = findPageByTag(sectionsInfo, 'ABOUT_US');
    const aboutUs = findPageByID(resAbout.chapters, '5e735eeb29196b814fb983b6');

    //STAFF (Internal)
    const { chapters } = findPageByTag(sectionsInfo, 'STAFF');
    const staff = findPageByID(chapters, '5e3181e69621e5d6a3d89740');

    //GUESTS
    ({ data, success } = await GetGuests());
    let provisoryGuests = 0, guestsLocation = '', roomPlan = undefined;

    if (success) {
      provisoryGuests = data.provisory_guests;
      guestsLocation = data.guests_location;
      roomPlan = data.room_plan;
    }

    //Check every section to see if it's complete or not
    let { questions, answers } = { 'questions': 0, 'answers': 0 };

    for (let i = 0; i < sliderItems.length; i++) {
      ({ questions, answers } = this.checkStatus(sliderItems[i].name, currentInspirations, currentSuggestions, staff, provisoryGuests, guestsLocation, roomPlan, aboutUs));

      if (questions > 0) {
        incompleteSections.push({ ...sliderItems[i], 'questions': questions, 'answers': answers });
      }
    }

    //NEWS
    ({ data, success } = await GetNewsPaginated(1, 8));
    let newsList = [];

    if (success) {
      newsList = data.items;
    }

    this.setState({
      newsList,
      incompleteSections,
      ready: true
    });
  };

  checkStatus = (item, currentInspirations, currentSuggestions, staff, provisoryGuests, guestsLocation, roomPlan, aboutUs) => {
    const { sectionsInfo, weddingInfo } = this.props;
    let questions = 0, answers = 0;

    switch (item) {
      case 'ABOUT_US':
        questions = 7;

        if (weddingInfo?.weddingDayLanguage !== '') {
          answers++;
        }

        if (weddingInfo?.bride?.name && weddingInfo?.bride?.name !== '' 
          && weddingInfo?.bride?.contact && weddingInfo?.bride?.contact !== '') {
          answers++;
        }

        if (weddingInfo?.groom?.name && weddingInfo?.groom?.name !== '' 
          && weddingInfo?.groom?.contact && weddingInfo?.groom?.contact !== '') {
          answers++;
        }

        if (weddingInfo?.alternative?.name && weddingInfo?.alternative?.name !== '' 
          && weddingInfo?.alternative?.contact && weddingInfo?.alternative?.contact !== '') {
          answers++;
        }

        if (weddingInfo?.alternative2?.name && weddingInfo?.alternative2?.name !== '' 
          && weddingInfo?.alternative2?.contact && weddingInfo?.alternative2?.contact !== '') {
          answers++;
        }

        for (let i = 0; i < aboutUs.questions.length; i++) {
          if ((aboutUs.questions[i]._id === '5e7360f129196b814fb9885b' || aboutUs.questions[i]._id === '5e73610729196b814fb9887f') && aboutUs.questions[i].answer?.response?.length > 0) {
            answers++;
          }
        }

        return { 'questions': questions, 'answers': answers };
      case 'OUR_STYLE':
        questions = 2;

        if (currentInspirations.length > 0) {
          answers++;
        }
        if (currentSuggestions.length > 0) {
          answers++;
        }

        return { 'questions': questions, 'answers': answers };
      case 'STAFF':
        questions = 4;

        for (let i = 0; i < staff.questions.length; i++) {
          if ((staff.questions[i]._id === '5e3184b59621e5d6a3d89744' || staff.questions[i]._id === '5e3185a69621e5d6a3d89749' || staff.questions[i]._id === '5e6a0a0629196b814faa7ce9' || staff.questions[i]._id === '60e2dbe612cef67e129c0d33') && staff.questions[i].answer?.response?.length > 0) {
            answers++;
          }
        }

        return { 'questions': questions, 'answers': answers };
      case 'GUESTS':
        questions = 3;

        if (provisoryGuests > 0) {
          answers++;
        }
        if (guestsLocation) {
          answers++;
        }
        if (inGuestMapTest() && roomPlan) {
          answers++;
        }

        return { 'questions': questions, 'answers': answers };
      case 'CEREMONY':
        ({ questions, answers } = getSectionStatus(sectionsInfo, 'CEREMONY'));

        return { 'questions': questions, 'answers': answers };
      case 'FOOD_SELECTION':
        ({ questions, answers } = getSectionStatus(sectionsInfo, 'FOOD'));

        return { 'questions': questions, 'answers': answers };
      case 'DRINK_SELECTION':
        ({ questions, answers } = getSectionStatus(sectionsInfo, 'DRINKS'));

        return { 'questions': questions, 'answers': answers };
      case 'DECORATION':
        ({ questions, answers } = getSectionStatus(sectionsInfo, 'DECORATION'));

        return { 'questions': questions, 'answers': answers };
      case 'PARTY_SELECTION':
        ({ questions, answers } = getSectionStatus(sectionsInfo, 'PARTY'));

        return { 'questions': questions, 'answers': answers };
      default:
        return { 'questions': 0, 'answers': 0 };
    }
  }

  render() {
    const { translate, history } = this.props;
    const { newsList, incompleteSections, ready } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <DashboardContainer>
        <ProgressContainer>
          <ProgressTitle>{translate('TO_DO')}</ProgressTitle>
          <ProgressBar />
        </ProgressContainer>
        <ChoicesContainer>
          <ChoicesTitle>{translate('WHATS_MISSING')}</ChoicesTitle>
          <ChoicesContent>
            <SliderContainer>
              <Slider incompleteSections={incompleteSections} />
            </SliderContainer>
            <ChoicesBoxContainer onClick={() => history.push('/choices')}>
              <ChoicesBox>
                <ChoicesBoxTitle>{translate('OUR_CHOICES')}</ChoicesBoxTitle>
                <ChoicesBoxText>{translate('CONSULT_CHOICES')}</ChoicesBoxText>
                <Arrow><LongArrowRight /></Arrow>
              </ChoicesBox>
            </ChoicesBoxContainer>
          </ChoicesContent>
        </ChoicesContainer>
        {
          newsList.length > 0 &&
          <NewsContainer>
            <NewsTitleContainer>
              <NewsTitle>{translate('NEWS')}</NewsTitle>
              <SeeAll onClick={() => history.push('/news')}>{translate('SEE_ALL')}</SeeAll>
            </NewsTitleContainer>
            <CardsContainer>
              <CardsRow>
                {
                  newsList.map((news, index) =>
                    <NewsItem
                      key={index}
                      id={news._id}
                      title={news.title}
                      date={news.date}
                      group={news.category}
                      image={news.image}
                    />
                  )
                }
              </CardsRow>
            </CardsContainer>
            <NewsTitleContainer>
              <NewsTitle>&nbsp;</NewsTitle>
              <SeeAll onClick={() => history.push('/news')}>{translate('SEE_ALL')}</SeeAll>
            </NewsTitleContainer>
          </NewsContainer>
        }
      </DashboardContainer>
    );
  }
}

const mapStateToProps = state => ({
  sectionsInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding
});

export default withLocalize(withRouter(connect(mapStateToProps)(DashboardPage)));