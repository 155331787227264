import moment from "moment";
import "moment/locale/pt";
import "moment/locale/fr";
import "moment/locale/es";
import { GetInfo, GetTranslations } from "../../infra/requests/BaseRequests";
import { GetWedding } from "../../infra/requests/MyWeddingRequests";
import { initialize, addTranslationForLanguage } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import { SaveUser } from "../User/user.actions";
import { SaveWedding } from "../Wedding/wedding.actions";
import { SaveInfo } from "../Info/info.actions";
import { SaveUpgrade } from "../Upgrade/upgrade.actions";
import { GetUpgradeDetail } from "../../infra/requests/UpgradeRequests";
import { CloseMenu } from "../Menu/menu.actions";

const DefineLanguage = (languages, default_language) => {
  const cookieLang = localStorage.getItem("LANG_COOKIE");
  if (cookieLang && languages.find(x => x === cookieLang)) return cookieLang;
  const browserLang = navigator.language || navigator.userLanguage;
  if (browserLang) {
    const code = browserLang.substring(0, 2);
    if (languages.find(x => x === code)) return code;
  }
  if (default_language) return default_language;
  return "en";
};

export default function start() {
  return async dispatch => {
    try {
      const info = await GetInfo();
      const { data } = await GetTranslations();

      dispatch(SaveUser(info?.data?.user));

      if (info?.data?.user?._id) {
        const wedding = await GetWedding();
        dispatch(SaveWedding(wedding?.data));

        if (wedding?.data?.wedding?.upgrade) {
          const upgradeDetail = await GetUpgradeDetail(wedding?.data?.wedding?.upgrade._id ? wedding?.data?.wedding?.upgrade._id : wedding?.data?.wedding?.upgrade._id ? wedding?.data?.wedding?.upgrade._id : wedding?.data?.wedding?.upgrade);
          dispatch(SaveUpgrade(upgradeDetail?.data?.name));
        }
      }

      const languages = info?.data?.languages || [];
      const defaultLanguage = DefineLanguage(
        languages,
        info?.data?.default_language
      );
      moment.locale(defaultLanguage);

      dispatch(
        initialize({
          languages,
          translation: [],
          options: {
            renderToStaticMarkup,
            defaultLanguage
          }
        })
      );
      languages.map(code =>
        dispatch(addTranslationForLanguage(data[code], code))
      );

      dispatch(SaveInfo(info?.data));
    } catch (e) {
      console.error(e);
    }
  };
}
