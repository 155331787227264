import styled from 'styled-components';

export const BillingContainer = styled.div`
  width: 100%;
  padding: 20px ${p => p.type === 'filled' ? '10px' : '0px'};
  background-color: ${p => p.type === 'filled' ? p.theme.thirdColor : '#ffffff'};
  margin-top: ${p => p.type === 'filled' ? '40px' : '0px'};
`;

export const BillingContent = styled.div`
  
`;

export const RemoveContainer = styled.div`
  width: 100%;
  text-align: right;
`;

export const RemoveImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 0.5;
  }
`;