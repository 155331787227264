import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-height: 250px;
`;

const InputImageComponent = ({ width, ratio, children, color, url }) => {
  return (
    <ImageContainer>
      <Image src={url}/>
      {children}
    </ImageContainer>
  );
};

InputImageComponent.propTypes = {
  url: PropTypes.string,
  ratio: PropTypes.number,
  width: PropTypes.string,
  color: PropTypes.string
};
export default InputImageComponent;
