import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { body2, titleH2 } from '../../styles/TextSizes';

export const CardContainer = styled.div`
  flex-basis: 25%;
  width: 296px;
  height: 380px;
  position: relative;
  padding: 0 15px;
  box-sizing: border-box;
  margin-bottom: 130px;

  @media ${device.desktopL} {
    height: 366px;
    margin-bottom: 100px;
  }

  @media ${device.laptopL} {
    flex-basis: 33.333%;
    height: 350px;
  }

  @media ${device.tablet} {
    flex-basis: 50%;
    height: 280px;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
    height: 260px;
    margin-bottom: 75px;
  }
`;

export const CardContent = styled.div`
  background-color: ${p => p.theme.thirdColor};
  height: 100%;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: box-shadow 0.5s linear;

  &:hover {
    box-shadow: 5px 20px 14px 0 rgba(160, 157, 157, 0.5);
  }
`;

export const Number = styled(titleH2)`
  position: absolute;
  top: -30px;
  right: 15px;
  width: 73px;
  height: 60px;
  background-color: #ffffff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 14px 0 rgba(160, 157, 157, 0.5);

  @media ${device.laptopL} {
    width: 65px;
    height: 50px;
  }

  @media ${device.tabletS} {
    width: 55px;
    height: 45px;
  }
`;

export const Title = styled.div`
  height: 65px;
  overflow: hidden;
  font-family: 'Titillium Web', sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  @media ${device.laptopL} {
    font-size: 22px;
    line-height: 26px;
    height: 56px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 25px;
    height: 52px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 23px;
    height: 48px;
  }
`;

export const Line = styled.div`
  border: 2px solid ${p => p.theme.secondaryColor};
  width: 100%;
  max-width: 225px;
  margin: 20px 0px; 

  @media ${device.tablet} {
    margin: 15px 0px; 
  }
`;

export const Text = styled(body2)`
  display: block;
  height: 150px;
  overflow-y: auto;

  @media ${device.tablet} {
    height: 100px;
  }
`;

export const Icon = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 20px;

  & svg {
    width: 24px;
    height: 24px;

    & #arrowright {
      fill: #000000;
    }
  }

  @media ${device.tablet} {
    margin-top: 10px;
  }
`;