import LoginPage from "../../pages/auth/LoginPage";
import ResetPasswordPage from "../../pages/auth/ResetPasswordPage";
import RecoverAccountPage from "../../pages/auth/RecoverAccountPage";
import RegisterPage from "../../pages/auth/RegisterPage";
import GuestLoginPage from "../../pages/auth/GuestLoginPage";

export default [
  {
    path: "/login",
    component: LoginPage,
    isAuthenticated: false
  },
  {
    path: "/login-guest",
    component: GuestLoginPage,
    isAuthenticated: false
  },
  {
    path: "/register",
    component: RegisterPage,
    isAuthenticated: false
  },
  {
    path: "/recover-account",
    component: RecoverAccountPage,
    isAuthenticated: false
  },
  {
    path: "/recover-password",
    component: ResetPasswordPage,
    isAuthenticated: false
  },
];
