import { findQuestionByID, findResponseByID, findColorByID, findFoodByID } from '../../infra/services/utils/PageChoices';
import { getPlateSidedishesName } from '../../infra/services/utils/SidedishesUtils';

export const checkAnswer = (sectionsInfo, foodInfo, colorsInfo, answer, index, weddingPlace, translate, activeLanguage, sidedishes) => {
  const question = findQuestionByID(sectionsInfo, answer.question);
  let response = undefined;

  if (question) {
    //Specific TAG to show Observations as a new card
    if (answer.showObs) {
      return {
        'id': `${answer._id}_${index}`,
        'type': 1,
        'question': question.title[activeLanguage?.code],
        'response': answer.observations
      };
    }
    else {
      if ((question.type === 1 || question.type === 2 || question.type === 3) && answer.response) {
        return {
          'id': `${answer._id}_${index}`,
          'type': 1,
          'question': question.title[activeLanguage?.code],
          'response': answer.response
        };
      }
      else if (question.type === 4 && answer.response) {
        //FIX: check every answer and return the first valid one. There might be invalid questions over the time
        for (let i = 0; i < answer.response.length; i++) {
          if (answer.response[i].id) {
            response = findResponseByID(question, answer.response[i].id, weddingPlace);
          }
          else {
            response = findResponseByID(question, answer.response[i], weddingPlace);
          }

          if (response) {
            break;
          }
        }

        if (response) {
          return {
            'id': `${answer._id}_${index}`,
            'type': 2,
            'question': question.title[activeLanguage?.code],
            'response': response.title[activeLanguage?.code],
            'image': response.image?.url,
            'color': response.image?.color
          };
        }
        else {
          return null;
        }
      }
      else if (question.type === 5 && answer.response) {
        return answer.response.map(elem => renderAnswers(question, elem.id, weddingPlace, activeLanguage));
      }
      else if (question.type === 6 && answer.response) {
        //FIX: check every answer and return the first valid one. There might be invalid questions over the time
        for (let i = 0; i < answer.response.length; i++) {
          if (answer.response[i].id) {
            response = findResponseByID(question, answer.response[i].id, weddingPlace);
          }
          else {
            response = findResponseByID(question, answer.response[i], weddingPlace);
          }

          if (response) {
            break;
          }
        }

        if (response) {
          return {
            'id': `${answer._id}_${index}`,
            'type': 1,
            'question': question.title[activeLanguage?.code],
            'response': response.title[activeLanguage?.code]
          };
        }
        else {
          return null;
        }
      }
      else if ((question.type === 7 || question.type === 8) && answer.response) {
        return {
          'id': `${answer._id}_${index}`,
          'type': 4,
          'question': question.title[activeLanguage?.code],
          'response': answer.response
        };
      }
      else if (question.type === 9 && answer.observations) {
        return {
          'id': `${answer._id}_${index}`,
          'type': 1,
          'question': question.title[activeLanguage?.code],
          'response': question.answer.observations
        };
      }
      else if (question.type === 10 && answer.response) {
        return {
          'id': `${answer._id}_${index}`,
          'type': 1,
          'question': question.title[activeLanguage?.code],
          'response': question.answer.response[0].id === 'true' ? translate('CONFIRM_YES') : question.answer.response[0].id === 'false' ? translate('CONFIRM_NO') : null
        };
      }
      else if (question.type === 11 && answer.response) {
        return answer.response.map(elem => renderColors(question, elem, colorsInfo, activeLanguage));
      }
      else if (question.type === 12 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.entrees, question, elem, activeLanguage));
      }
      else if (question.type === 13 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.meat, question, elem, activeLanguage, sidedishes));
      }
      else if (question.type === 14 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.fish, question, elem, activeLanguage, sidedishes));
      }
      else if (question.type === 15 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.dessert, question, elem, activeLanguage));
      }
      else if (question.type === 16 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.cake, question, elem, activeLanguage));
      }
      else if (question.type === 17 && answer.response) {
        return answer.response.map(elem => renderFood(foodInfo.supper, question, elem, activeLanguage));
      }
      else {
        return null;
      }
    }
  }
  else {
    return null;
  }
}

const renderAnswers = (question, answer, weddingPlace, activeLanguage) => {
  const response = findResponseByID(question, answer, weddingPlace);

  if (response) {
    return {
      'id': answer,
      'type': 2,
      'question': question.title[activeLanguage?.code],
      'response': response.title[activeLanguage?.code],
      'image': response.image?.url,
      'color': response.image?.color
    };
  }
  else {
    return null;
  }
}

const renderColors = (question, answer, colorsInfo, activeLanguage) => {
  const response = findColorByID(colorsInfo, answer);

  if (response) {
    return {
      'id': answer,
      'type': 3,
      'question': question.title[activeLanguage?.code],
      'response': response.name[activeLanguage?.code],
      'image': response.image?.url,
      'color': response.image?.color,
      'bgColor': response.hex
    };
  }
  else {
    return null;
  }
}

const renderFood = (food, question, answer, activeLanguage, sidedishes) => {
  const response = findFoodByID(food, answer, sidedishes);

  if (response && response.food) {
    const foodName = response.food?.name[activeLanguage?.code];
    let sidedishesName = '';
    if (response && response.sidedishes && response.sidedishes.length > 0) {
      sidedishesName = getPlateSidedishesName(response.sidedishes, sidedishes, activeLanguage);
    }

    return {
      'id': answer,
      'type': 2,
      'question': question.title[activeLanguage?.code],
      'response': `${foodName?.trim()}${sidedishesName}`,  
      'image': response.food?.image?.url,
      'color': response.food?.image?.color
    };
  } else if (response && !response.food) {
    return {
      'id': answer,
      'type': 2,
      'question': question.title[activeLanguage?.code],
      'response': response.name[activeLanguage?.code],
      'image': response.image?.url,
      'color': response.image?.color
    };
  }
  else {
    return null;
  }
}