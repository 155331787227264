import { SAVE_WEDDING, UPDATE_QUESTION, UPDATE_ABOUT_US } from "../ActionsType";

const wedding = {};

const findQuestion = (structure, tag, id) => {
  let found = false;
  let sectionIndex = 0;
  let ChapterIndex = 0;
  let QuestionIndex = 0;

  structure.forEach((section, sIndex) => {
    if(section.tag === tag) {
      sectionIndex = sIndex;
      section.chapters.forEach((chapter, cIndex) => {
        chapter.questions.forEach((question, qIndex) => {
          if(question._id === id) {
            found = true;
            ChapterIndex = cIndex;
            QuestionIndex = qIndex;
          }
        });
      });
    }
  });

  return { sectionIndex, ChapterIndex, QuestionIndex, found };
};

const updateQuestion = (state, tag, data) => {
  const result = { ...state };
  const { sectionIndex, ChapterIndex, QuestionIndex, found } = findQuestion(result.structure, tag, data.question);

  if(found) {
    result.structure[sectionIndex].chapters[ChapterIndex].questions[QuestionIndex].answer = data;
  }

  return result;
};

const updateAboutUs = (state, data) => {
  const result = { ...state };
  
  result.wedding = data;

  return result;
};

export default (state = wedding, action) => {
  switch (action.type) {
    case SAVE_WEDDING:
      return { ...action.wedding };
    case UPDATE_QUESTION:
      return updateQuestion(state, action.section, action.data);
    case UPDATE_ABOUT_US:
      return updateAboutUs(state, action.data);
    default:
      return state;
  }
};
