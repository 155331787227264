import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const DetailContent = styled.div`
  margin-bottom: 120px;

  @media ${device.laptopL} {
    margin-bottom: 95px;
  }

  @media ${device.tablet} {
    margin-bottom: 48px;
  }
`;

export const UpgradeContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  margin: auto;
  margin-bottom: 40px;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
`;

export const Title = styled(titleH2)`
  padding-bottom: 30px;

  @media ${device.desktop} {
    padding-bottom: 20px;
  }
`;

export const Subtitle = styled.div`
  width: 100%;
  text-align: left;
  padding: 0px 15px;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
`;

export const DetailContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  padding: 0 15px;
`;

export const Description = styled.div`
  text-align: left;
  margin-bottom: 100px;
  white-space: pre-line;
  color: ${p => p.color ? p.color : '#816655'};
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.desktopL} {
    margin-bottom: 70px;
  }

  @media ${device.desktop} {
    margin-bottom: 50px;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  max-width: 950px;
  margin: auto;
  margin-top: 100px;

  @media ${device.desktopL} {
    margin-top: 70px;
  }

  @media ${device.desktop} {
    margin-top: 50px;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const AddUpgradeContainer = styled.div`
  width: 100%;
  height: 120px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  padding: 30px;
  background-color: #ffffff;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);

  @media ${device.desktopL} {
    padding: 20px;
    height: 95px;
  }

  @media ${device.tablet} {
    padding: 10px;
    height: 48px;
  }
`;

export const AddUpgradeContent = styled.div`
  width: ${p => p.menuOpen ? 'calc(100% - 300px)' : 'calc(100% - 105px)'};

  @media ${device.laptopL} {
    width: ${p => p.menuOpen ? 'calc(100% - 250px)' : 'calc(100% - 105px)'};
  }

  @media ${device.laptop} {
    width: calc(100% - 105px);
  }

  @media ${device.tablet} {
    width: calc(100% - 50px);
  }
`;

export const AddUpgradeDesktop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${device.laptop} {
    & button {
      padding: 10px 16px !important;
    }
  }
  
  @media ${device.tablet} {
    display: none;
  }
`;

export const AddUpgradeMobile = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:hover {
    & div {
      opacity: 1;
    }
  }
  
  @media ${device.tablet} {
    display: flex;
  }
`;

export const UpgradeTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 10px;
`;

export const UpgradePriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media ${device.tablet} {
    padding: 0px 40px;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  @media ${device.tabletS} {
    padding: 0px 20px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
    margin-bottom: 5px;
    margin-top: 0px;
  }

  @media ${device.mobileS} {
    padding: 0px 0px;
  }
`;

export const UpgradePrice = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  color: ${p => p?.theme?.primaryColor};
  text-align: left;

  @media ${device.laptop} {
    font-size: 20px;
  }

  @media ${device.tablet} {
    color: #000000;
    font-size: 23px;
  }

  @media ${device.mobileL} {
    font-size: 20px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const UpgradeText = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-size: 23px;
  font-weight: 300;
  line-height: 28px;
  color: ${p => p?.theme?.primaryColor};
  text-align: left;
  text-transform: uppercase;

  @media ${device.laptop} {
    font-size: 20px;
  }

  @media ${device.tablet} {
    color: #000000;
    font-size: 23px;
  }

  @media ${device.mobileL} {
    font-size: 20px;
  }

  @media ${device.mobileS} {
    font-size: 16px;
  }
`;

export const UpgradeNote = styled.div`
  color: ${p => p?.theme?.primaryColor};
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;
  overflow: hidden;

  @media ${device.desktop} {
    font-size: 18px;
  }

  @media ${device.laptopL} {
    font-size: 16px;
    line-height: 26px;
  }

  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }

  @media ${device.tablet} {
    padding: 0px 40px;
    margin-bottom: 40px;
    color: #000000;
    font-size: 16px;
    line-height: 22px;
  }

  @media ${device.tabletS} {
    padding: 0px 20px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
  }

  @media ${device.mobileS} {
    padding: 0px 0px;
  }
`;

export const UpgradeTextMobile = styled.div`
  font-family: 'Titillium Web', sans-serif;
  font-size: 23px;
  font-weight: 700;
  line-height: 28px;
  color: #000000;
  text-align: left;
  opacity: 0.65;
  transition: opacity 0.5s linear;

  @media ${device.mobileL} {
    font-size: 20px;
  }

  @media ${device.mobileS} {
    font-size: 14px;
  }
`;

export const ArrowUpgrade = styled.img`
  opacity: 0.65;
  transform: ${p => p.open ? 'rotateZ(90deg)' : 'rotateZ(-90deg)'};
  transition: opacity 0.5s linear;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  @media ${device.mobileS} {
    height: 16px;
  }
`;

export const SlideUpgradeMobile = styled.div`
  display: none;
  height: 240px;
  width: calc(100% - 50px);
  background-color: #ffffff;
  padding: 10px;
  position: fixed;
  left: 50px;
  bottom: ${p => p.open ? '0px' : '-240px'};
  transition: bottom 0.5s linear;
  z-index: 101;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.2), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.14);

  & button {
    padding: 12px 100px !important;
  }

  @media ${device.tablet} {
    display: block;
  }

  @media ${device.tabletS} {
    & button {
      padding: 10px 80px !important;
    }
  }

  @media ${device.mobileL} {
    height: 200px;
    bottom: ${p => p.open ? '0px' : '-200px'};
  }

  @media ${device.mobileS} {
    & button {
      padding: 8px 30px !important;
    }
  }
`;

export const ArrowContainer = styled.div`
  width: 100%;
  text-align :right;
`;