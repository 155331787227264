import React from "react";
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import {
  SectionContainer,
  TitleSection,
  CardsContainer,
  CardsRow
} from "./ChoicesStyles";
import TextCard from "../../components/choicesCards/TextCard";
import ImageCard from "../../components/choicesCards/ImageCard";
import ColorCard from "../../components/choicesCards/ColorCard";
import FileCard from "../../components/choicesCards/FileCard";
import ObservationsCard from "../../components/choicesCards/ObservationsCard";
import { checkAnswer } from "./ChoicesFunctions";

const ChoicesDecoration = ({ tag, answers = [], notes = null, sectionsInfo, foodInfo, colorsInfo, weddingInfo, translate, activeLanguage, sidedishes = [] }) => {
  const getInfo = (answer, index) => {
    const result = checkAnswer(sectionsInfo, foodInfo, colorsInfo, answer, index, weddingInfo.wedding_place, translate, activeLanguage, sidedishes);

    if(result) {
      if(Array.isArray(result)) {
        return result.map(elem => renderAnswer(elem));
      }
      else {
        return renderAnswer(result);
      }
    }
    else {
      return null;
    }
  }

  const renderAnswer = (answer) => {
    if(answer) {
      const { id, type, question, response, image, color, bgColor } = answer;
        
      /*
      * TYPE
      * 1 - Text / Observations Card
      * 2 - Image Card
      * 3 - Color Card
      * 4 - File Card
      */
      switch (type) {
        case 1:
          return (
            <TextCard
              key={id}
              question={question}
              answer={response}
            />
          );
        case 2:
          return (
            <ImageCard
              key={id}
              question={question}
              answer={response}
              image={image}
              color={color}
            />
          );
        case 3:
          return (
            <ColorCard
              key={id}
              question={question}
              answer={response}
              image={image}
              bgColor={bgColor}
            />
          );
        case 4:
          return (
            <FileCard
              key={id}
              question={question}
              answer={response}
            />
          );
        default:
          return null;
      }
    }
    else {
      return null;
    }
  }

  return (
    <SectionContainer>
      <TitleSection>{translate(tag)}</TitleSection>
      <CardsContainer>
        <CardsRow>
          {
            notes &&
            <ObservationsCard
              notes={notes}
            />
          }
          {
            answers.map((answer, index) => getInfo(answer, index))
          }
        </CardsRow>
      </CardsContainer>
    </SectionContainer>
  );
}

const mapStateToProps = state => ({ 
  weddingInfo: state.wedding.wedding,
  sectionsInfo: state.wedding.structure,
  colorsInfo: state.info.colours,
  foodInfo: state.info.food
});

export default withLocalize(connect(mapStateToProps)(ChoicesDecoration));