import styled from 'styled-components';
import { AlertColour, SuccessColour, WarningColour } from '../../styles/Colours';
import { device } from '../../styles/Responsive';
import { titleH4, titleH5 } from '../../styles/TextSizes';

export const CardContainer = styled.div`
  flex-basis: 33.333%;
  height: ${p => p.type === 11 ? '330px' : p.type === 'styleCard' ? '470px' : '435px'};
  position: relative;
  padding: 0 15px;
  margin-bottom: ${p => p?.inModal ? '20px' : '50px'};
  opacity: ${p => p.disabled ? '0.5' : '1'};
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};

  @media ${device.desktopL} {
    height: ${p => p?.inModal ? '300px' : p.type === 11 ? '310px' : p.type === 'styleCard' ? '460px' : '435px'};
  }

  @media ${device.desktop} {
    height: ${p => p?.inModal ? '300px' : p.type === 11 ? '300px' : p.type === 'styleCard' ? '460px' : '435px'};
  }

  @media ${device.laptop} {
    height: ${p => p?.inModal ? '300px' : p.type === 11 ? '300px' : p.type === 'styleCard' ? '460px' : '435px'};
  }

  @media ${device.laptopS} {
    flex-basis: 50%;
    height: ${p => p?.inModal ? '300px' : p.type === 11 ? '300px' : p.type === 'styleCard' ? '460px' : '420px'};
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
    height: ${p => p?.inModal ? '300px' : p.type === 11 ? '280px' : p.type === 'styleCard' ? '460px' : '400px'};
  }
`;

export const CardContent = styled.div`
  background-color: ${p => p.selected ? p.theme.secondaryColor : '#ffffff'};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s linear;
  
  &:hover {
		background-color: ${p => p.selected ? p.theme.secondaryColor : p.theme.thirdColor};

    .optionText {
      background-color: ${p => p.selected ? p.theme.secondaryColor : p.theme.thirdColor};
    }
	}
`;

export const BgContainer = styled.div`
  border-width: ${p => p.border ? '1px' : '0px'};
  border-style: solid;
  border-color: ${p => p.theme.primaryBorderColor};
  width: 100%;
  max-width: ${p => p?.inModal ? '200px' : '300px'};
  position: relative;
`;

export const ImageIcon = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 27px;
  height: 27px;
  z-index: 2;

  & svg {
    & #icon_rect, #icon_path {
      transition: fill-opacity 0.5s linear;
    }
  }

  &:hover {
    & svg {
      & #icon_rect, #icon_path {
        fill-opacity: 1;
      }
    }
  }
`;

export const BgContainerColor = styled.div`
  border-width: ${p => p.border ? '1px' : '0px'};
  border-style: solid;
  border-color: ${p => p.theme.primaryBorderColor};
  width: 100%;
  max-width: 300px;
  height: 170px;
`;

export const BgImage = styled.img`
  height: 100%;
  max-width: 100%;
`;

export const BgColor = styled.div`
  background-color: ${p => p.color ? p.color : 'transparent'};
  width: 100%;
  max-width: 300px;
  height: 170px;
`;

export const DetailContainer = styled.div`
  padding: 0px 5px;
  width: 100%;
`;

export const Title = styled(titleH5)`
  color: #757575;
  line-height: 23px;
  margin-top: 10px;
  width: 100%;
  text-align: left;
  height: ${p => p?.inModal ? '43px' : '80px'};
  overflow-y: auto;

  @media ${device.desktop} {
    font-size: ${p => p?.inModal ? '18px' : '20px'} ;
    line-height: ${p => p?.inModal ? '20px' : '23px'};
  }

  @media ${device.laptopL} {
    font-size: ${p => p?.inModal ? '18px' : '20px'} ;
    line-height: ${p => p?.inModal ? '20px' : '23px'};
    height: ${p => p?.inModal ? '38px' : '75px'};
  }

  @media ${device.laptop} {
    font-size: ${p => p?.inModal ? '18px' : '20px'} ;
    line-height: ${p => p?.inModal ? '20px' : '23px'};
    height: ${p => p?.inModal ? '33px' : '70px'};
  }

  @media ${device.tablet} {
    font-size: ${p => p?.inModal ? '18px' : '20px'} ;
    line-height: ${p => p?.inModal ? '20px' : '23px'};
    height: ${p => p?.inModal ? '33px' : '70px'};
  }
`;

export const Subtitle = styled(titleH5)`
  color: #757575;
  line-height: 20px;
  margin-top: 5px;
  width: 100%;
  text-align: left;
  height: 60px;
  overflow-y: auto;

  @media ${device.desktop} {
    font-size: 18px;
    line-height: 20px;
  }

  @media ${device.laptopL} {
    font-size: 18px;
    line-height: 20px;
    height: 75px;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 20px;
    height: 70px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 20px;
    height: 70px;
  }
`;

export const OptionTextContainer = styled.div`
  height: ${({ showFullDesc }) => showFullDesc ? 'auto' : '64px'};
  overflow-y: ${({ showFullDesc }) => showFullDesc ? 'unset' : 'auto'};
  margin-top: 10px;
  position: relative;
  z-index: ${({ index }) => index};
  width: 100%;
`;

export const Text = styled.div`
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  padding-bottom: ${({ showFullDesc }) => showFullDesc ? '20px' : '0px'};
  text-align: left;
  color: #000000;
  font-size: 20px;
  line-height: 31px;
  font-weight: 400;
  background-color: ${p => p.selected ? p.theme.secondaryColor : '#ffffff'};
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: background-color 0.3s linear;

  @media ${device.laptopL} {
    font-size: 18px;
    line-height: 25px;
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: 25px;
  }
`;

export const ViewFullDesc = styled.div`
  display: inline-block;

  & span:first-child {
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${p => p?.theme?.primaryColor};
    transition: color 0.5s linear;
    padding-right: 3px;
  }

  & span:last-child {
    & svg {
      transform: rotateZ(-90deg);
      width: 8px;
      height: 10px;

      & #left {
        transition: fill 0.5s linear;
        fill: ${p => p?.theme?.primaryColor};
      }
    }
  }

  &:hover {
    & span:first-child {
      color: ${p => p.theme.secondaryCTA};
    }

    & span:last-child {
      & svg {
        & #left {
          fill: ${p => p.theme.secondaryCTA};
        }
      }
    }
  }
`;

export const HideFullDescContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const PriceQuantityRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  height: ${p => p?.inModal ? '20px' : '34px'};
`;

export const Price = styled(titleH4)`
  font-size: ${p => p?.inModal ? '20px' : '22px'};
  line-height: ${p => p?.inModal ? '25px' : '33px'};

  @media ${device.laptopL} {
    font-size: ${p => p?.inModal ? '18px' : '20px'};
    line-height: ${p => p?.inModal ? '20px' : '25px'};
  }

  @media ${device.laptop} {
    font-size: 16px;
    line-height: ${p => p?.inModal ? '20px' : '22px'};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  width: 100%;
`;

export const TextAvailable = styled.div`
  line-height: 25px;
  opacity: 0.7;
  font-weight: ${p => p?.onlyOne ? 600 : 'inherit'};
`;

export const CheckboxContainer = styled.div`
  & div {
    display: block;
    margin-top: 0px;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  cursor: ${p => p.disabled ? 'not-allowed' : 'pointer'};
  opacity: ${p => p.disabled ? '0.5' : '1'};
  pointer-events: ${p => p.disabled ? 'none' : 'auto'};
`;

export const ListTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 40px;

  @media ${device.tablet} {
    padding-left: 20px;
  }

  @media ${device.tabletS} {
    flex-wrap: wrap;
  }
`;

export const ListText = styled.div`
  padding-right: 40px;
  color: ${p => p.color ? p.color : '#000000'};
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 22px;
    padding-right: 20px;
  }
`;

export const ListQuantity = styled.div`
  flex-shrink: 0;

  @media ${device.tabletS} {
    padding-top: 5px;
  }
`;

export const ListWarningMessage = styled.div`
  color: ${WarningColour};
  font-size: 14px;
`;

export const ListSuccessMessage = styled.div`
  color: ${SuccessColour};
  font-size: 14px;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 970px;
  padding: 0 15px;
`;

export const TagsContainer = styled.div`
  margin-top: 20px;
  max-height: 100px;
  overflow-y: auto;
`;

export const Tag = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.3;
  cursor: pointer;
  transition: opacity 0.5s linear;
  width: 100%;
  text-align: left;
  
  &:hover {
    opacity: 1;
  }

  @media ${device.tablet} {
    font-size: 18px;
  }

  @media ${device.mobileL} {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const InspirationContainer = styled.div`
  flex-basis: 33.333%;
  /*height: 300px;*/
  position: relative;
  padding: 0 15px;
  margin-bottom: 40px;

  @media ${device.laptopS} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: -18px;
  right: ${p => p.type === 'suggestions' ? '0px' : '15px'};
  background-color: #ffffff;
  border-radius: 50%;
  opacity: 0.75;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.5s linear;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }
`;

export const ButtonImg = styled.img`

`;

export const BgInspirationsContainer = styled.div`
  width: 100%;
  /*height: 300px;*/
`;

export const SuggestionsContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const SuggestionsImage = styled.img`
  width: 100%;
`;