import Alerts from "../../components/alert/Alert";
import { inGuestMapTest } from "../../infra/services/utils/Helpers";
import { GuestAgeEnum, TablePurposeEnum } from "./utilGuest";

// Helper function to check if two arrays have the same elements (unordered)
const arraysEqualUnordered = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.find(f => f == arr1[i])) return false;
  }

  return true;
};

export const getSummaryFoodRestrictions = (seatsWithFoodRestrictions, activeLanguage) => {
  const result = [];
  // Iterate through each seat
  for (let i = 0; i < seatsWithFoodRestrictions.length; i++) {
    const currentSeat = seatsWithFoodRestrictions[i];

    // Check if the seat has already been added to the result
    let found = false;
    for (let j = 0; j < result.length; j++) {
      const existingSeat = result[j];

      // Check if the current seat has the same restrictions as the existing seat
      if (arraysEqualUnordered(currentSeat.foodRestrictions.map(m => m?._id), existingSeat.foodRestrictions)) {
        found = true;
        // Add the current seat's index to the existing seat's matchingSeats array
        existingSeat.matchingSeats.push(i);
        break;
      }
    }

    // If the seat hasn't been found in the result array, add it as a new object
    if (!found) {
      result.push({
        foodRestrictions: currentSeat.foodRestrictions?.map(m => m?._id),
        label: currentSeat.foodRestrictions?.map(m => m?.subtitle[activeLanguage.code]).join(', '),
        matchingSeats: [i],
      });
    }
  }

  const observations = result.map(m => `<div>${m?.matchingSeats?.length || 1} PAX - ${m?.label}</div>`).join('');
  return observations;
}

export const checkExtraCostByTables = (tables) => {
  let extraTables = 0;

  if (!inGuestMapTest()) return extraTables;

  const seats = tables?.length > 0
    ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '')
    : [];

  const totalTables = tables.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF)
    .filter(f => f?.seats?.length > 0 && f?.seats.find(f2 => f2?.guestName?.trim() != ''))
    .map(m => m?.value).reduce((a, b) => a + b, 0);
  const totalSeats = seats?.length || 0;
  const coupleTable = tables?.find(f => f?.tablePurpose == TablePurposeEnum.COUPLE);

  const totalGuests =  seats.filter(f => f?.guestAge == GuestAgeEnum.ADULT)?.length;
  const totalChild = seats.filter(f => f?.guestAge == GuestAgeEnum.CHILD)?.length;

  if (totalSeats >= 0 && totalTables > 14) {
    const avgPerTable = totalSeats / totalTables;
    const tableCouple = coupleTable && coupleTable?.seats?.length > 0
      ? coupleTable?.seats?.length
      : 0;

    if ((tableCouple > 8 && avgPerTable < 9.5) || (tableCouple <= 8 && avgPerTable < 9.8)) {
      const tablesNeeded = Math.ceil((parseInt(totalGuests) + parseInt(totalChild)) / 10);
      extraTables = totalTables - tablesNeeded;
    } else {
      extraTables = 0;
    }
  } else extraTables = 0;

  return extraTables;
}

export const calculateExtraTables = (tables) => {
  let extraTables = 0;

  if (!inGuestMapTest()) return extraTables;

  const seats = tables?.length > 0
    ? tables?.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF).map(m => m?.seats).flat().filter(f => f?.guestName?.trim() != '')
    : [];

  const totalTables = tables.filter(f => f?.active && !f?.removeBecauseOfOvalL && f?.tablePurpose != TablePurposeEnum.STAFF)
    .filter(f => f?.seats?.length > 0 && f?.seats.find(f2 => f2?.guestName?.trim() != ''))
    .map(m => m?.value).reduce((a, b) => a + b, 0);
  const totalSeats = seats?.length || 0;
  const coupleTable = tables?.find(f => f?.tablePurpose == TablePurposeEnum.COUPLE);

  const totalGuests = seats.filter(f => f?.guestAge == GuestAgeEnum.ADULT)?.length;
  const totalChild = seats.filter(f => f?.guestAge == GuestAgeEnum.CHILD)?.length;

  if (totalSeats >= 0 && totalTables > 14) {
    const avgPerTable = totalSeats / totalTables;
    const tableCouple = coupleTable && coupleTable?.seats?.length > 0
      ? coupleTable?.seats?.length
      : 0;

    if ((tableCouple > 8 && avgPerTable < 9.5) || (tableCouple <= 8 && avgPerTable < 9.8)) {
      const tablesNeeded = Math.ceil((parseInt(totalGuests) + parseInt(totalChild)) / 10);
      extraTables = totalTables - tablesNeeded;
    } else {
      extraTables = 0;
    }
  } else extraTables = 0;

  return extraTables;
}


export const canChangeSeats = (table1, table2) => {
  // Extract seat arrays from tables
  const seatsTable1 = table1?.seats || [];
  const seatsTable2 = table2?.seats || [];

  // Count the number of guests at each table
  const guestsTable1 = seatsTable1.filter(seat => seat?.guestName?.trim() != '').length || 0;
  const guestsTable2 = seatsTable2.filter(seat => seat?.guestName?.trim() != '').length || 0;

  // Check if there are enough seats at table 2 for all guests from table 1
  if (guestsTable1 > seatsTable2.length) {
    return false; // Not enough seats at table 2
  }

  // Check if there are enough seats at table 1 for all guests from table 2
  if (guestsTable2 > seatsTable1.length) {
    return false; // Not enough seats at table 1
  }

  return true; // All guests can be seated at both tables
}

////// Aux functions for guestMap
export const convertNumberToLetter = (code) => {
  if (!Number.isInteger(code) || code < 0) {
    return;
  }

  // Calculate the position in the alphabet
  let position = code % 26;

  // Calculate the number of repetitions
  let repetitions = Math.floor(code / 26) + 1;

  return String.fromCharCode(position + 65).repeat(repetitions);
}