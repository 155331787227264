import React from "react";
import { withRouter } from "react-router-dom";
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MenuToggle } from "../../redux/Menu/menu.actions";
import { 
  Menu,
  ToggleMenu,
  ArrowImg,
  MenuItem,
  MenuContent,
  MenuBullet,
  MenuText
} from "./MenuStyles";
import ArrowLeft from '../svg/ArrowLeft';
import { sideMenuItems } from './MenuOptions';
import Alert from '../../components/alert/Alert';

const MenuComponent = ({ menuOpen, MenuToggle, history, location, translate }) => {
  const setActive = (index, itemUrl, location) => {
    if(index === 0) {
      return itemUrl === location;
    }
    else {
      return location.indexOf(itemUrl) !== -1;
    }
  }

  const handleClick = (url) => {
    MenuToggle(false);

    //When Menu is closed, clicking on "bullets" should open the Menu and not go to the page directly
    if(!menuOpen) {
      MenuToggle(true);
    }
    else {
      if(url === 'DISABLED') {
        Alert.new({
          type: "success",
          title: translate("AVAILABLE_SOON_ALERT")
        });
      }
      else {
        history.push(url);
      }
    }
  }

  const handleMenuClick = () => {
    //When Menu is closed, clicking on any part of the menu should open
    if(!menuOpen) {
      MenuToggle(true);
    }
  }

  return (
    <Menu open={menuOpen} onClick={() => handleMenuClick()}>
      <ToggleMenu open={menuOpen}>
        <ArrowImg open={menuOpen} onClick={() => MenuToggle(!menuOpen)}><ArrowLeft/></ArrowImg>
      </ToggleMenu>
      {
        sideMenuItems.map((item, index) => 
          <MenuItem 
            key={item.name} 
            first={index === 0} 
            last={index === sideMenuItems.length - 1}
          >
            <MenuContent 
              first={index === 0} 
              last={index === sideMenuItems.length - 1} 
              active={setActive(index, item.url, location.pathname)} 
              onClick={() => handleClick(item.url)}
            >
              <MenuBullet active={setActive(index, item.url, location.pathname)}/>
              <MenuText open={menuOpen}>{translate(item.name)}</MenuText>
            </MenuContent>
          </MenuItem>
        )
      }
    </Menu>
  );
};

const mapStateToProps = state => ({ 
  menuOpen: state.menu
});

const mapActionToProps = dispatch => bindActionCreators({ MenuToggle }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(withLocalize(withRouter(MenuComponent)));