import styled from "styled-components";
import { device } from '../../styles/Responsive';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { titleH4 } from '../../styles/TextSizes';

export const SliderContainer = styled(Slider)`
  &.slick-slider {
    height: 302px;

    @media ${device.desktopL} {
      height: 270px;
    }

    @media ${device.tablet} {
      height: 220px;
    }

    & .nextArrow {
      position: absolute;
      top: 38%;
      right: 3%;
      transform: translateY(-38%);
      z-index: 2;
      cursor: pointer;
      width: 24px;
      height: 24px;
      opacity: 1;
      transition: opacity 0.5s linear;

      &:hover {
        opacity: 0.65;
      }

      @media ${device.tablet} {
        width: 18px;
        height: 18px;
        top: 33%;
        transform: translateY(-33%);
      }
    }

    & .prevArrow {
      position: absolute;
      top: 38%;
      left: 3%;
      transform: translateY(-38%);
      z-index: 2;
      cursor: pointer;
      width: 24px;
      height: 24px;
      opacity: 1;
      transition: opacity 0.5s linear;

      &:hover {
        opacity: 0.65;
      }

      @media ${device.tablet} {
        width: 18px;
        height: 18px;
        top: 33%;
        transform: translateY(-33%);
      }
    }

    & .slick-list {
      position: relative;
      height: 100%;
      
      & .slick-track {
        height: 100%;
      }
    }
  }
`;

export const Arrow = styled.img`
  
`;

export const ItemContainer = styled.div`
  height: 302px;
  padding: 0px 10px;
  cursor: pointer;

  &:hover {
    & > div {
      border: 1px solid ${p => p.theme.secondaryBorderColor};
    }
  }

  @media ${device.desktopL} {
    height: 270px;
  }

  @media ${device.tablet} {
    height: 220px;
  }
`;

export const ItemIcon = styled.div`
  border: 1px solid ${p => p.theme.primaryBorderColor};
  height: 228px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 0.5s linear;
  position: relative;

  @media ${device.desktopL} {
    height: 210px;
  }

  @media ${device.tablet} {
    height: 150px;
  }
`;

export const ItemTitle = styled(titleH4)`
  line-height: 29px;
  padding-top: 20px;
`;

export const Icon = styled.img`
  height: 49px;

  @media ${device.tablet} {
    height: 40px;
  }
`;

export const ProgressBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;