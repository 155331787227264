import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const BarContainer = styled.div`
  width: 100%;
  height: 12px;
  background-image: linear-gradient(90deg, #ECE8E2 0%, rgba(236, 232, 226, 0.17) 100%);
  position: relative;

  @media ${device.tablet} {
    height: 7px;
  }
`;

export const Bar = styled.div`
  width: ${p => p.percentage ? p.percentage+'%' : '0%' };
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(270deg, #CACACA 0%, rgba(202, 202, 202, 0.24) 100%);
`;

export const TextContainer = styled.div`
  border-radius: 100%;
  box-shadow: 0 2px 6px 0 rgba(188, 188, 188, 0.5);
  width: 88px;
  height: 88px;
  position: absolute;
  left: ${ p => p.percentage ? (p.percentage < 6 ? '0%' : (p.percentage > 93 ? 'calc('+p.percentage+'% - 86px)' : (p.percentage - 6)+'%')) : '0%' };
  right: 0;
  top: -38px;
  bottom: 0;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.laptop} {
    width: 65px;
    height: 65px;
    top: -30px;
    left: ${ p => p.percentage ? (p.percentage < 6 ? '0%' : (p.percentage > 93 ? 'calc('+p.percentage+'% - 63px)' : (p.percentage - 6)+'%')) : '0%' };
  }

  @media ${device.tablet} {
    width: 44px;
    height: 44px;
    top: -20px;
    left: ${ p => p.percentage ? (p.percentage < 6 ? '0%' : (p.percentage > 93 ? 'calc('+p.percentage+'% - 42px)' : (p.percentage - 6)+'%')) : '0%' };
  }
`

export const Text = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.2;
  width: 60px;
  overflow: hidden;
  white-space: pre-wrap;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 22px;
    width: 40px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 16px;
    width: 30px;
  }
`