import React, {Fragment} from 'react';
import Routes from './routes/routes';
import { BasicStyles } from './styles/BasicStyles';

const App = () => {
    return (
      <Fragment>
        <Routes />
        <BasicStyles />
      </Fragment>
    );
  
}

export default App;
