import React from 'react'

const LongArrowLeft = ({ fill }) => {
  return (
    <svg width="18px" height="16px" viewBox="0 0 18 16">
      <g id="Componentes-e-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Assets" transform="translate(-352.000000, -1892.000000)">
          <g id="01-Icons" transform="translate(78.000000, 101.000000)">
            <g id="Outros-icons" transform="translate(33.000000, 1715.000000)">
              <g id="setalonga_esquerda" transform="translate(238.000000, 72.000000)">
                <g id="arrowleft" transform="translate(2.953125, 4.312500)" fill={ fill } fillRule="nonzero">
                  <path d="M17.034375,6.65625 L3.58828125,6.65625 C3.5859375,6.65625 3.58359375,6.6515625 3.5859375,6.6515625 L8.6578125,1.7765625 C9.06796875,1.3828125 9.08203125,0.72890625 8.6859375,0.31875 C8.2921875,-0.09140625 7.63828125,-0.10546875 7.228125,0.290625 L0.3234375,6.92578125 C0.121875,7.1203125 0.00703125,7.38984375 0.00703125,7.66875 C0.00703125,7.95 0.121875,8.2171875 0.3234375,8.41171875 L7.265625,15.084375 C7.46484375,15.2765625 7.72265625,15.3726562 7.98046875,15.3726562 C8.25234375,15.3726562 8.521875,15.2671875 8.7234375,15.05625 C9.1171875,14.6460938 9.10546875,13.9921875 8.6953125,13.5984375 L3.6234375,8.7234375 C3.62109375,8.72109375 3.6234375,8.71875 3.62578125,8.71875 L17.0578125,8.71875 C17.60625,8.71875 18.0539063,8.28984375 18.0867188,7.75078125 C18.121875,7.153125 17.6320313,6.65625 17.034375,6.65625 Z" id="Shape"></path>
                </g>
                <rect id="Rectangle-37-Copy-2" x="0" y="0" width="24" height="24"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LongArrowLeft
