import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Checkbox, Spin, Tooltip } from "antd";
import {
  SelectDiv,
  StyledSelectInput,
  StyledOption,
  SelectArrow,
  CloseArrow,
  ArrowImg,
  StyledOptionContent,
  SelectLabelInput,
  MultipleStyledSelectInput,
  MultipleSelectDiv,
  SelectOptions,
  SelectOption
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import Close from "../../assets/icons/close.svg";
import { MaterialInput } from "./MaterialStyles";

const SelectRestrictionInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  label,
  disabled,
  meta,
  translatable = true,
  activeLanguage,
  allowClear,
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;
  const [open, toogleOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setOptions(data.map(m => ({ ...m, checked: false })))
  }, [data]);

  useEffect(() => {
    setValue(input.value || []);

    const value = input?.value || [];
    const options = data.map(m => ({ ...m, checked: value?.includes(m?._id) }));
    setOptions(options);
  }, [input.value]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current?.contains(event.target)) {
        toogleOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const formatValue = () => {
    const value = input.value;
    if (!value) return undefined;
    if (Array.isArray(value)) {
      const selected = value?.length > 0
        ? options.filter(f => value.includes(f?._id))
        : [];
      return selected?.length > 0
        ? selected?.map(m => m?.acronym[activeLanguage.code])?.join(', ')
        : undefined;
    }
    return value.toString();
  };

  const isFilled = () => {
    return input?.value && Array.isArray(input?.value) && input?.value?.length > 0 ? true : false;
  }

  const changeSelect = value => {
    input.onChange(value || '');
  };

  const changeChecked = (event, elem) => {
    let value = input.value || [];
    if (event?.target.checked) {
      value.push(elem._id);
    } else {
      value = value.filter(f => f != elem._id);
    }

    const options = data.map(m => ({ ...m, checked: value?.includes(m?._id) }));
    setOptions(options);

    changeSelect(value);
  }

  const openOptions = () => {
    input.onFocus();
    if (disabled) return;
    toogleOpen(!open)
  }

  return (
    <MultipleSelectDiv ref={dropdownRef}>
      {label && <SelectLabelInput>{label}</SelectLabelInput>}
      <MultipleStyledSelectInput filled={isFilled()} disabled={disabled} onClick={() => openOptions()}>
        <div className="select-input">
          {formatValue()}
        </div>
        {
          !disabled && (allowClear && input?.value && input?.value?.length > 0 && formatValue() != undefined
            ? <CloseArrow smallIcon={true} onClick={() => changeSelect([])}><ArrowImg src={Close} /></CloseArrow>
            : <SelectArrow smallIcon={true}><ArrowImg src={ArrowDown} /></SelectArrow>)
        }
      </MultipleStyledSelectInput>
      {open && <SelectOptions>
        {options.map((elem, index) => (
          <Tooltip key={dataKey ? elem[dataKey] : index} title={elem['subtitle'][activeLanguage.code]}>
            <SelectOption key={dataKey ? elem[dataKey] : index} disabled={elem?.disabled || false}>
              <Checkbox checked={elem.checked} onChange={(e) => changeChecked(e, elem)}>
                {dataLabel
                  ? (translatable ? elem[dataLabel][activeLanguage.code] : elem[dataLabel])
                  : elem}
              </Checkbox>
            </SelectOption>
          </Tooltip>
        ))}
      </SelectOptions>}
    </MultipleSelectDiv>
  );
};

SelectRestrictionInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string
};

SelectRestrictionInput.defaultProps = {
  data: [],
  allowClear: true,
  dataLabel: 'acronym',
  dataKey: '_id'
};

export default withLocalize(SelectRestrictionInput);
