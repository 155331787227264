import React from 'react';
import PropTypes from 'prop-types';
import { StyledRoundButton, StyledRoundButtonIcon } from './ButtonsStyles';
import ArrowLeft from '../svg/ArrowLeft';

export const RoundButton = ({
  text,
  onClick
}) => (
  <StyledRoundButton
    onClick={onClick}
    htmlType='button'
  >
    {text}
    <StyledRoundButtonIcon><ArrowLeft/></StyledRoundButtonIcon>
  </StyledRoundButton>
);

RoundButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default RoundButton;