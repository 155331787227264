import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import DraftJSContent from "../draftjs/DraftJSContent";

const ModalContainer = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }
  .ant-modal-title {
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    word-wrap: break-word;
  }
  .ant-modal-footer {
    padding: 15px 16px;
    text-align: right;
    background: transparent;
    border-top: none;
  }
  .ant-modal-body {
    color: #000000;
    font-size: 15px;
    word-wrap: break-word;
    max-height: 40vh;
    overflow-y: auto;
    line-height: 24px;
  }
`;

const ModalButton = styled.div`
  display: inline-block;
  background-color: ${({ theme }) => theme.primaryCTA};
  border-radius: 6px;
  text-align: center;
  padding: 10px 30px;
  cursor: pointer;
  font-family: 'Titillium Web', sans-serif;
  color: #ffffff;
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  text-transform: uppercase;
  transition: background-color 0.5s linear;

  &:hover {
    background-color: ${({ theme }) => theme.secondaryCTA};
  }
`;

const TermsModal = ({ translate, activeLanguage, open, onClose }) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const { data } = await GetDynamicPage("5e1edae729f787cb8a9eecd8");
      setContent(data);
    }
    getContent();
  }, []);

  return (
    <ModalContainer
      title={translate("TERMS_AND_CONDITIONS")}
      onCancel={onClose}
      visible={open}
      footer={<ModalButton onClick={onClose}>ok</ModalButton>}
    >
      <DraftJSContent
        activeLanguage={activeLanguage.code}
        content={content?.description}
      />
    </ModalContainer>
  );
};

export default withLocalize(TermsModal);
