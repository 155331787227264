import React, { Component } from "react";
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import {
  ChoicesContainer,
  Title
} from "./ChoicesStyles";
import { groupAnswers, findQuestionByID, findResponseByID, findChapterByID, groupPublicNotes } from '../../infra/services/utils/PageChoices';
import { GetGuests } from '../../infra/requests/GuestsRequests';
import { GetStaff } from '../../infra/requests/StaffRequests';
import { GetUpgradeDetail } from '../../infra/requests/UpgradeRequests';
import ChoicesDecoration from './ChoicesDecoration';
import ChoicesUpgrade from './ChoicesUpgrade';
import ChoicesStaff from './ChoicesStaff';
import ChoicesGuests from './ChoicesGuests';
import ChoicesGeneral from './ChoicesGeneral';
import ChoicesAboutUs from './ChoicesAboutUs';
import { SpinLoading } from "../../styles/BasicStyles";
import { GetSidedishes } from "../../infra/requests/MyWeddingRequests";

class Choices extends Component {
  state = {
    aboutUs: undefined,
    ceremony: undefined,
    food: undefined,
    drinks: undefined,
    decoration: undefined,
    party: undefined,
    guests: undefined,
    staff: undefined,
    upgrade: undefined,
    notes: undefined,
    ready: false,
    sidedishes: []
  }

  componentDidMount = async () => {
    document.body.style.overflowY = 'auto';
    this.getAnswers();
    const result = await GetSidedishes();
    if (result.success && result.data) this.setState({ sidedishes: result.data });
  }

  getAnswers = async () => {
    const { sectionsInfo, weddingInfo, publicNotes, translate, activeLanguage, sidedishesInfo } = this.props;
    const { ceremony, food, drinks, decoration, party } = groupAnswers(sectionsInfo, weddingInfo.wedding_place);

    const notes = groupPublicNotes(publicNotes);

    const aboutUs = [], guests = [], staff = [];
    let upgrade = undefined;

    /************** ABOUT US **************/

    //Get question and answer for "Métodos de pagamento: até 15 dias antes da data da cerimónia"
    const aboutUsQuestion1 = findQuestionByID(sectionsInfo, '5e7360f129196b814fb9885b');

    let aboutUsAnswer1 = undefined;
    if (aboutUsQuestion1?.answer?.response) {
      for (let i = 0; i < aboutUsQuestion1?.answer?.response.length; i++) {
        aboutUsAnswer1 = findResponseByID(aboutUsQuestion1, aboutUsQuestion1.answer?.response[i].id, weddingInfo.wedding_place);

        if (aboutUsAnswer1) {
          break;
        }
      }

      if (aboutUsAnswer1) {
        aboutUs.push({ question: aboutUsQuestion1.title[activeLanguage?.code], answer: aboutUsAnswer1.title[activeLanguage?.code] });
      }
    }

    //Get question and answer for "Métodos de pagamento: após a cerimónia"
    const aboutUsQuestion2 = findQuestionByID(sectionsInfo, '5e73610729196b814fb9887f');

    let aboutUsAnswer2 = undefined;
    if (aboutUsQuestion2?.answer?.response) {
      for (let i = 0; i < aboutUsQuestion2?.answer?.response.length; i++) {
        aboutUsAnswer2 = findResponseByID(aboutUsQuestion2, aboutUsQuestion2.answer?.response[i].id, weddingInfo.wedding_place);

        if (aboutUsAnswer2) {
          break;
        }
      }

      if (aboutUsAnswer2) {
        aboutUs.push({ question: aboutUsQuestion2.title[activeLanguage?.code], answer: aboutUsAnswer2.title[activeLanguage?.code] });
      }
    }

    /************** GUESTS **************/

    //Get question and answer for "Minimum number of guests"
    const guestQuestion1 = findQuestionByID(sectionsInfo, '5e2adf6947f38787fd9e8426');
    const guestAnswer1 = `${weddingInfo.min_people} ${translate('GUESTS')}`;

    if (guestAnswer1) {
      guests.push({ question: guestQuestion1.title[activeLanguage?.code], answer: guestAnswer1 });
    }

    let { data, success } = await GetGuests();

    if (success) {
      if (data.provisory_guests > 0) {
        //Get question and answer for "Provisory number of guests"
        const guestQuestion2 = findQuestionByID(sectionsInfo, '5e2adf9947f38787fd9e8427');

        if (guestQuestion2) {
          guests.push({ question: guestQuestion2.title[activeLanguage?.code], answer: `${data.provisory_guests} ${translate('GUESTS')}` });
        }
      }

      if (data.guests_location) {
        //Get question and answer for "Guests location"
        const guestQuestion3 = findQuestionByID(sectionsInfo, '5e2adfbf47f38787fd9e8428');

        if (guestQuestion3) {
          guests.push({ question: guestQuestion3.title[activeLanguage?.code], answer: data.guests_location });
        }
      }

      if (data.room_plan?._id) {
        //Get question and answer for "Room Plan" (We show the name of the question as "Room Plan" instead of "Add Room Plan")
        const guestQuestion4 = findChapterByID(sectionsInfo, '5e2adaa747f38787fd9e8424');

        if (guestQuestion4) {
          guests.push({ question: guestQuestion4.name[activeLanguage?.code], answer: data.room_plan });
        }
      }
    }

    /************** STAFF **************/

    //Get question and answer for "Animadora Infantil"
    const staffQuestion1 = findQuestionByID(sectionsInfo, '5e3184b59621e5d6a3d89744');

    let staffAnswer1 = undefined;
    if (staffQuestion1?.answer?.response) {
      for (let i = 0; i < staffQuestion1.answer?.response.length; i++) {
        staffAnswer1 = findResponseByID(staffQuestion1, staffQuestion1.answer?.response[i].id, weddingInfo.wedding_place);

        if (staffAnswer1) {
          break;
        }
      }

      if (staffAnswer1) {
        staff.push({ question: staffQuestion1.title[activeLanguage?.code], answer: staffAnswer1.title[activeLanguage?.code] });
      }
    }

    //Get question and answer for "Funcionários extra"
    const staffQuestion2 = findQuestionByID(sectionsInfo, '5e3185a69621e5d6a3d89749');

    let staffAnswer2 = undefined;
    if (staffQuestion2?.answer?.response) {
      for (let i = 0; i < staffQuestion2.answer?.response.length; i++) {
        staffAnswer2 = findResponseByID(staffQuestion2, staffQuestion2.answer?.response[i].id, weddingInfo.wedding_place);

        if (staffAnswer2) {
          break;
        }
      }

      if (staffAnswer2) {
        staff.push({ question: staffQuestion2.title[activeLanguage?.code], answer: staffAnswer2.title[activeLanguage?.code] });
      }
    }

    //Get question and answer for "Utilização do Cofre"
    const staffQuestion3 = findQuestionByID(sectionsInfo, '5e6a0a0629196b814faa7ce9');

    let staffAnswer3 = undefined;
    if (staffQuestion3?.answer?.response) {
      for (let i = 0; i < staffQuestion3.answer?.response.length; i++) {
        staffAnswer3 = findResponseByID(staffQuestion3, staffQuestion3.answer?.response[i].id, weddingInfo.wedding_place);

        if (staffAnswer3) {
          break;
        }
      }

      if (staffAnswer3) {
        staff.push({ question: staffQuestion3.title[activeLanguage?.code], answer: staffAnswer3.title[activeLanguage?.code] });
      }
    }

    //Get question and answer for "Animação musical"
    const staffQuestion4 = findQuestionByID(sectionsInfo, '60e2dbe612cef67e129c0d33');

    let staffAnswer4 = undefined;
    if (staffQuestion4?.answer?.response) {
      for (let i = 0; i < staffQuestion4.answer?.response.length; i++) {
        staffAnswer4 = findResponseByID(staffQuestion4, staffQuestion4.answer?.response[i].id, weddingInfo.wedding_place);

        if (staffAnswer4) {
          break;
        }
      }

      if (staffAnswer4) {
        staff.push({ question: staffQuestion4.title[activeLanguage?.code], answer: staffAnswer4.title[activeLanguage?.code] });
      }
    }

    //Get external Staff
    ({ data, success } = await GetStaff());

    if (success && data.list?.length > 0) {
      const staffQuestion3 = findChapterByID(sectionsInfo, '5e3182279621e5d6a3d89741');
      let externalStaff = '';

      if (staffQuestion3) {
        for (let i = 0; i < data.list.length; i++) {
          externalStaff = externalStaff.concat(`${data.list[i].category} - ${data.list[i].name}<br>`);
        }

        staff.push({ question: staffQuestion3.name[activeLanguage?.code], answer: externalStaff });
      }
    }

    /************** UPGRADE **************/

    if (weddingInfo.upgrade) {
      ({ data, success } = await GetUpgradeDetail(weddingInfo.upgrade._id ? weddingInfo.upgrade._id : weddingInfo.upgrade));

      if (success) {
        upgrade = data;
      }
    }

    this.setState({
      aboutUs,
      ceremony,
      food,
      drinks,
      decoration,
      party,
      guests,
      staff,
      upgrade,
      notes,
      ready: true
    });
  }

  render() {
    const { translate } = this.props;
    const { aboutUs, ceremony, food, drinks, decoration, party, guests, staff, upgrade, notes, ready } = this.state;
    const { sidedishes } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <ChoicesContainer>
        <Title>{translate("CHOICES")}</Title>
        {
          (aboutUs.length > 0 || (notes && notes.ABOUT_US)) &&
          <ChoicesAboutUs
            aboutUs={aboutUs}
            notes={notes.ABOUT_US}
          />
        }
        {
          notes && notes.STYLE &&
          <ChoicesGeneral
            tag='OUR_STYLE'
            notes={notes.STYLE}
          />
        }
        {
          notes && notes.DAY &&
          <ChoicesGeneral
            tag='OUR_DAY'
            notes={notes.DAY}
          />
        }
        {
          (ceremony.length > 0 || (notes && notes.CEREMONY)) &&
          <ChoicesDecoration
            tag='CEREMONY'
            answers={ceremony}
            notes={notes.CEREMONY}
          />
        }
        {
          (staff.length > 0 || (notes && notes.STAFF)) &&
          <ChoicesStaff
            staff={staff}
            notes={notes.STAFF}
          />
        }
        {
          (guests.length > 0 || (notes && notes.GUESTS)) &&
          <ChoicesGuests
            guests={guests}
            notes={notes.GUESTS}
          />
        }
        {
          (food.length > 0 || (notes && notes.FOOD)) &&
          <ChoicesDecoration
            tag='FOOD_SELECTION'
            answers={food}
            notes={notes.FOOD}
            sidedishes={sidedishes}
          />
        }
        {
          (drinks.length > 0 || (notes && notes.DRINKS)) &&
          <ChoicesDecoration
            tag='DRINK_SELECTION'
            answers={drinks}
            notes={notes.DRINKS}
          />
        }
        {
          (decoration.length > 0 || (notes && notes.DECORATION)) &&
          <ChoicesDecoration
            tag='DECORATION'
            answers={decoration}
            notes={notes.DECORATION}
          />
        }
        {
          (party.length > 0 || (notes && notes.PARTY)) &&
          <ChoicesDecoration
            tag='PARTY_SELECTION'
            answers={party}
            notes={notes.PARTY}
          />
        }
        {
          upgrade &&
          <ChoicesUpgrade
            upgrade={upgrade}
          />
        }
        {
          notes && notes.BUDGET &&
          <ChoicesGeneral
            tag='BUDGET'
            notes={notes.BUDGET}
          />
        }
      </ChoicesContainer>
    );
  }
}

const mapStateToProps = state => ({
  sectionsInfo: state.wedding.structure,
  colorsInfo: state.info.colours,
  foodInfo: state.info.food,
  sidedishesInfo: state.info.sidedishes,
  weddingInfo: state.wedding.wedding,
  publicNotes: state.wedding.notes
});

export default withLocalize(connect(mapStateToProps)(Choices));