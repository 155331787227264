import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import TextInput from '../../components/inputs/TextInput';
import FormValidator from '../../infra/services/validations/FormValidator';
import BaseButton from '../../components/buttons/BaseButton';
import Logo from '../../assets/icons/logo.svg';
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  BaseLink
} from './LoginPageStyles';
import { Icon } from 'antd';
import { RecoverAccount } from '../../infra/requests/AuthRequests';
import { withLocalize } from 'react-localize-redux';
import Alert from "../../components/alert/Alert";
import PasswordWarningModal from "../../components/modals/PasswordWarningModal";

const formRules = FormValidator.make({
  email: 'required|email'
});

class RecoverAccountPage extends Component {
  state = {
    passwordWarningModal: false,
    passwordModalText: undefined
  };

  onSubmit = async values => {
    const { history, translate } = this.props;

    try {
      const { message, success } = await RecoverAccount(values);
      
      if(success) {
        Alert.new({
          type: "success",
          title: translate('ALERT_SUCCESS_TITLE'),
          text: translate('ALERT_RECOVER_PASSWORD')
        });

        history.push('/login');
      }
      else {
        //Special Case - show a modal so we make sure the user sees the message
        if(message === 'RECOVER_MUST_DEFINE_PASSWORD') {
          this.setState({ 
            passwordWarningModal: true,
            passwordModalText: 'ALERT_RECOVER_DEFINE_PASSWORD'
          });
        }
        else {
          Alert.new({
            type: "error",
            title: translate('ALERT_ERROR_TITLE'),
            text: translate(message)
          });
        }
      }
    } 
    catch (e) {
      console.error(e);
    }
  };

  componentDidMount = () => {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  render() {
    const { handleSubmit, submitting, translate } = this.props;
    const { passwordWarningModal, passwordModalText } = this.state;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <Field
              component={TextInput}
              name="email"
              type="email"
              label={ translate("EMAIL") }
              prefix={<Icon type="mail" />}
            />
            <BaseLink to="/login">{ translate("LOGIN_WD") }</BaseLink>
            <LoginButtonDiv>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="submit"
                type="primaryLarge"
                loading={submitting}
                bgColor={'primaryColor'}
                text={ translate("SEND_EMAIL") }
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
        <PasswordWarningModal
          passwordModalText={passwordModalText}
          open={passwordWarningModal}
          onClose={() => this.setState({ passwordWarningModal: false })}
        />
      </LoginContainer>
    );
  }
}

RecoverAccountPage = reduxForm({
  form: 'recover_account',
  validate: formRules
})(RecoverAccountPage);

export default withLocalize(RecoverAccountPage);
