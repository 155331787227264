import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

export const GraphContainer = styled.div`

`;

export const GraphContent = styled.div`
  position: relative;

  & .recharts-responsive-container {
    margin: auto;

    & svg {
      overflow: inherit !important;

      @media ${device.mobileL} {
        transform: scale(0.75);
      }
    }
  }
`;

export const CenterLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFFFFF;
  border-radius: 100%;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  width: 140px;
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.mobileL} {
    width: 100px;
    height: 100px;
  }
`;

export const CenterLabelText = styled(titleH5)`
  line-height: 29px;
  height: 29px;
  overflow: hidden;
  color: #757575;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
    height: 26px;
  }

  @media ${device.tabletS} {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
  }

  @media ${device.mobileL} {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
  }
`;

export const LabelsContainer = styled.div`
  width: 60%;
  margin-left: auto;

  @media ${device.laptop} {
    width: 65%;
  }

  @media ${device.mobileL} {
    width: 100%;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0px;
`;

export const LabelColor = styled.div`
  background-color: ${p => p.color ? p.color : p.theme.primaryCTA};
  border-radius: 100%;
  width: 24px;
  height: 24px;
  margin-right: 20px;
  flex-shrink: 0;

  @media ${device.laptop} {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  @media ${device.tablet} {
    width: 20px;
    height: 20px;
  }
`;

export const LabelName = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 28px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TooltipContent = styled.div`
  background-color: ${p => p.theme.thirdColor};
  padding: 10px;
  border-radius: 5px;
`;

export const TooltipText = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;