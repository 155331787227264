import React from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  NewsContainer,
  NewsContent,
  Title,
  BgContainer,
  GroupContainer,
  Group,
  Arrow,
  Date
} from "./NewsItemStyles";
import ImageComponent from "../images/ImageComponent";
import ArrowLeft from "../../assets/icons/ic_arrow_left.svg";
import { formatDate } from "../../infra/services/utils/FormatDate";

const NewsItem = ({ id, title, date, group, image, translate, activeLanguage, history }) => {
  return (
    <NewsContainer onClick={() => history.push(`/news/detail/${id}`)}>
      <NewsContent>
        <BgContainer>
          <ImageComponent ratio={0.6} url={image.url} color={image.color}/>
        </BgContainer>
        <GroupContainer>
          <Group>{translate(group)}</Group>
          <Arrow src={ArrowLeft} />
        </GroupContainer>
        <Title>{title[activeLanguage?.code]}</Title>
        <Date>{formatDate(date)}</Date>
      </NewsContent>
    </NewsContainer>
  );
}

export default withLocalize(withRouter(NewsItem));