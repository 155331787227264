import React from 'react';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import {
  WeddingDetailContainer,
  WeddingDetailContent,
  WeddingDetail,
  DateColumnDetail,
  MainColumnDetail,
  Day,
  Month,
  Year,
  QuestionContent,
  Question,
  QuestionDetail,
  Answer,
  QuestionDetailColorsContent,
  ColorsDetailContainer,
  ColorsRow,
  ColorDetail,
  ColorDetailContent,
  ColorDetailBg,
  ColorDetailName,
  ImageDetailBg,
  CloseButton,
  CloseImg,
  AnswerContainer
} from './WeddingStyles';
import { formatSpace } from '../../infra/services/utils/SpaceName';
import { 
  getWeddingDate, 
  getArrivalDate,
  findAnswer
} from '../../infra/services/utils/NextEvents';
import ImageComponent from "../images/ImageComponent";
import Close from "../../assets/icons/close.svg";
import TextCard from "../weddingCards/TextCard";
import ImageCard from "../weddingCards/ImageCard";
import FileCard from "../weddingCards/FileCard";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";

const WeddingDetailModal = ({ wedding, colorsAnswer, styleAnswer, closeModal, sectionsInfo, translate, activeLanguage }) => {
  const { day, month, year } = getWeddingDate(wedding.date);
  const { arrival_time, visit_time } = getArrivalDate(wedding.dates, translate);

  const buildAnswers = (array, tag) => {
    let answers = [];

    //Valid questions types in this section: 1 - 8, everything else is ignored
    if(array.length > 0) {
      for(let i = 0; i < array.length; i++) {
        //Ignore "Colors" and "Wedding Style" questions
        if(array[i].question._id !== '5e0f63f86b06b564f132421c' && array[i].question._id !== '5e4bb17ff0bc781a2e26a476') {
          if(array[i].response) {
            if(array[i].question.type === 1 || array[i].question.type === 2 || array[i].question.type === 3) {
              //Card Type: Text
              answers.push({
                ...array[i],
                answer: array[i].response,
                cardType: 1
              });
            }
            else if(array[i].question.type === 4 || array[i].question.type === 5 || array[i].question.type === 6) {
              if(array[i].response.length > 0) {
                for(let j = 0; j < array[i].response.length; j++) {
                  let answer = findAnswer(sectionsInfo, array[i].question._id, array[i].response[j].id, wedding.wedding_place, tag);

                  if(answer) {                  
                    let cardType = 0;
                    if(array[i].question.type === 4 || array[i].question.type === 5) {
                      //Card Type: Image
                      cardType = 2;
                    }
                    else {
                      //Card Type: Text
                      cardType = 4;
                    }

                    answers.push({
                      ...array[i],
                      answer,
                      cardType
                    });
                  }
                }
              }
            }
            else if(array[i].question.type === 7 || array[i].question.type === 8) {
              //Card Type: File
              answers.push({
                ...array[i],
                answer: array[i].response,
                cardType: 3
              });
            }
          }
        }
      }
    }

    return answers;
  }

  const renderAnswer = (answerObj, index) => {
    if(answerObj) {
      const { cardType, question, response, answer } = answerObj;
        
      /*
      * TYPE
      * 1 - Text Card
      * 2 - Image Card
      * 3 - File Card
      * 4 - Text Card (for list)
      */
      switch (cardType) {
        case 1:
          return (
            <TextCard
              key={index}
              question={question.title[activeLanguage?.code]}
              answer={response}
            />
          );
        case 2:
          return (
            <ImageCard
              key={index}
              question={question.title[activeLanguage?.code]}
              answer={answer.title[activeLanguage?.code]}
              image={answer.image?.url}
              color={answer.image?.color}
            />
          );
        case 3:
          return (
            <FileCard
              key={index}
              question={question.title[activeLanguage?.code]}
              answer={response}
            />
          );
        case 4:
          return (
            <TextCard
              key={index}
              question={question.title[activeLanguage?.code]}
              answer={answer.title[activeLanguage?.code]}
            />
          );
        default:
          return null;
      }
    }
    else {
      return null;
    }
  }

  const answers = buildAnswers(wedding.answers, 'DECORATION');
  const ceremonyAnswers = buildAnswers(wedding.ceremonyOptions, 'CEREMONY');

  return (
    <WeddingDetailContainer>
      <WeddingDetailContent>
        <OutsideAlerter close={closeModal}>
          <WeddingDetail>
            <CloseButton onClick={() => closeModal()}>
              <CloseImg src={Close}/>
            </CloseButton>
            {
              wedding.date &&
              <DateColumnDetail>
                <Day>{day}</Day>
                <Month>{month}</Month>
                <Year>{year}</Year>
              </DateColumnDetail>
            }
            <MainColumnDetail hasDate={wedding.date ? true : false}>
              {
                wedding.wedding_place &&
                <QuestionContent>
                  <Question>{translate('WEDDING_PLACE')}:</Question>
                  <Answer>{formatSpace(wedding.wedding_place)}</Answer>
                </QuestionContent>
              }
              {
                wedding.ceremonyPlace?.length > 0 &&
                <QuestionContent>
                  <Question>{translate('CEREMONY_PLACE')}:</Question>
                  <AnswerContainer>
                    {
                      wedding.ceremonyPlace.map((elem, index) => <Answer key={index}>{elem[activeLanguage?.code]}</Answer>)
                    }
                  </AnswerContainer>
                </QuestionContent>
              }
              <QuestionContent>
                <Question>{translate('ARRIVAL_HOUR')}:</Question>
                <Answer>{arrival_time}</Answer>
              </QuestionContent>
              <QuestionContent>
                <Question>{translate('VISIT_HOURS')}:</Question>
                <Answer>{visit_time}</Answer>
              </QuestionContent>
              {
                styleAnswer &&
                <QuestionContent>
                  <Question>{translate('WEDDING_STYLE')}:</Question>
                  <Answer>{styleAnswer.title[activeLanguage?.code]}</Answer>
                </QuestionContent>
              }
              {
                ceremonyAnswers.length > 0 &&
                <QuestionDetailColorsContent>
                  <QuestionDetail>{wedding.ceremonyOptions[0]?.question?.title[activeLanguage?.code]}</QuestionDetail>
                  <ColorsDetailContainer>
                    <ColorsRow>
                      {
                        ceremonyAnswers.map((answer, index) => renderAnswer(answer, index))
                      }
                    </ColorsRow>
                  </ColorsDetailContainer>
                </QuestionDetailColorsContent>
              }
              {
                colorsAnswer.length > 0 && 
                <QuestionDetailColorsContent>
                  <QuestionDetail>{translate('WEDDING_COLORS')}:</QuestionDetail>
                  <ColorsDetailContainer>
                    <ColorsRow>
                      {
                        colorsAnswer.map((color, index) => 
                          <ColorDetail key={index}>
                            <ColorDetailContent>
                              {
                                color.hex ?
                                <ColorDetailBg bgColor={color.hex}/>
                                :
                                color.image?.url ?
                                <ImageDetailBg>
                                  <ImageComponent width='75px' url={color.image.url} color={color.image.color}/>
                                </ImageDetailBg>
                                :
                                null
                              }
                              <ColorDetailName>{color.name[activeLanguage?.code]}</ColorDetailName>
                            </ColorDetailContent>
                          </ColorDetail>
                        )
                      }
                    </ColorsRow>
                  </ColorsDetailContainer>
                </QuestionDetailColorsContent>
              }
              {
                answers.length > 0 &&
                <QuestionDetailColorsContent>
                  <QuestionDetail>{translate('DECORATION')}</QuestionDetail>
                  <ColorsDetailContainer>
                    <ColorsRow>
                      {
                        answers.map((answer, index) => renderAnswer(answer, index))
                      }
                    </ColorsRow>
                  </ColorsDetailContainer>
                </QuestionDetailColorsContent>
              }
            </MainColumnDetail>
          </WeddingDetail>
        </OutsideAlerter>
      </WeddingDetailContent>
    </WeddingDetailContainer>
  );
}

const mapStateToProps = state => ({ 
  sectionsInfo: state.wedding.structure
});

export default withLocalize(connect(mapStateToProps)(WeddingDetailModal));