import React from "react";
import {withLocalize} from 'react-localize-redux';
import {
  SectionContainer,
  TitleSection,
  CardsContainer,
  CardsRow
} from "./ChoicesStyles";
import ObservationsCard from "../../components/choicesCards/ObservationsCard";

const ChoicesGeneral = ({ tag, notes = null, translate }) => {
  return (
    <SectionContainer>
      <TitleSection>{translate(tag)}</TitleSection>
      <CardsContainer>
        <CardsRow>
          {
            notes &&
            <ObservationsCard
              notes={notes}
            />
          }
        </CardsRow>
      </CardsContainer>
    </SectionContainer>
  );
}

export default withLocalize(ChoicesGeneral);