import React from "react";
import {withLocalize} from "react-localize-redux";
import {Field} from 'redux-form';
import {
  StaffContainer,
  StaffContent,
  Category,
  TextAreaContainer,
  RemoveContainer,
  RemoveImg,
  Container,
  SelectContainer,
  NewOptionContainer
} from "./StaffStyles";
import TextInput from "../inputs/TextInput";
import TextAreaInput from "../inputs/TextAreaInput";
import Close from "../../assets/icons/close.svg";
import StaffCategory from "../staffCategory/StaffCategory";

const Staff = ({ type, categories, removeElement, index, field, requiredError, toggleShowNew, showNewCategory, isProcessClosed, translate }) => {
  return (
    <StaffContainer type={type}>
      <StaffContent>
        {
          type === 'filled' && !isProcessClosed &&
          <RemoveContainer>
            <RemoveImg src={Close} onClick={() => removeElement(index)}/>
          </RemoveContainer>
        }
        <Category>{translate('ADD_EXTERNAL_STAFF')}</Category>
        <Container>
          <SelectContainer>
            <Field
              component={StaffCategory} 
              name={`${field}.category`}
              categories={categories}
              showNew={showNewCategory}
              toggleShowNew={toggleShowNew}
              staffIndex={index}
              disabled={isProcessClosed}
            />
          </SelectContainer>
          <NewOptionContainer showNew={showNewCategory}>
            <Field
              name={`${field}.newCategory`}
              component={TextInput}
              placeholder={translate('ENTER_NAME')}
              type="text"
              requiredError={requiredError}
            />
          </NewOptionContainer>
        </Container>
        <Field
          component={TextInput}
          name={`${field}.name`}
          type='text'
          label={translate('NAME_WD')}
          disabled={isProcessClosed}
        />
        <Field
          component={TextInput}
          name={`${field}.email`}
          type='text'
          label={translate('EMAIL')}
          disabled={isProcessClosed}
        />
        <Field
          component={TextInput}
          name={`${field}.contact`}
          type='text'
          label={translate('TELEPHONE')}
          disabled={isProcessClosed}
        />
        <TextAreaContainer>
          <Field
            component={TextAreaInput}
            name={`${field}.observations`}
            label={translate('OBSERVATIONS_WD')}
            placeholder={translate('MESSAGE_WD')}
            readOnly={isProcessClosed}
          />
        </TextAreaContainer>
      </StaffContent>
    </StaffContainer>
  );
}

export default withLocalize(Staff);