import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import "cropperjs/dist/cropper.css";
import InputImageComponent from "../images/InputImageComponent";
import {
  InputDiv,
  ImageInputSection,
  ImageIcon,
  ImageMessage,
  SelectButton,
  ImageButton
} from "./InputStyles";
import ToTop from "../../assets/icons/to_top.svg";
import BaseButton from '../../components/buttons/BaseButton';
import {withLocalize} from 'react-localize-redux';
import Alert from '../alert/Alert';
import Document from '../document/Document';
import { Modal } from 'antd';
import Cropper from 'react-cropper';

const MAX_SIZE = 6000000;
const MAX_SIZE_TEXT = "6MB";

class ImageInput extends Component {
  state = {
    openModal: false,
    uploaded: undefined
  };

  onCancel = () => {
    const { setLoading } = this.props;

    this.setState({openModal: false});

    if(setLoading) {
      setLoading(false);
    }
  };

  buildImageObject = blob => ({
    preview: URL.createObjectURL(blob),
    size: blob.size,
    type: blob.type,
    blob: blob
  });

  handleImageDrop = (accepted, rejected) => {
    const { input, translate, setLoading, hasCrop } = this.props;

    if(setLoading) {
      setLoading(true);
    }

    if(rejected.length > 1) {
      return Alert.new({
        type: "error",
        title: translate("ALERT_ERROR_TITLE"),
        text: translate("ALERT_MULTIPLE_FILES")
      });
    }
    else if(rejected.length > 0 && rejected[0].size > MAX_SIZE) {
      let text = translate("ALERT_FILE_SIZE");
      if(text.indexOf("#MAX#") !== -1) {
        text = text.replace("#MAX#", MAX_SIZE_TEXT);
      }

      return Alert.new({
        type: "error",
        title: translate("ALERT_ERROR_TITLE"),
        text: text
      });
    }
    else if(accepted.length <= 0 || (rejected && rejected.length > 0)) {
      return Alert.new({
        type: "error",
        title: translate("ALERT_ERROR_TITLE"),
        text: translate("ALERT_FILE_TYPE")
      });
    }

    const uploaded = this.buildImageObject(accepted[0]);

    if(hasCrop) {
      this.setState({openModal: true, uploaded});
    }
    else {
      input.onChange(uploaded);
    }
  };

  onCropComplete = () => {
    const {input} = this.props;
    const { uploaded } = this.state;
    this.refs.cropper.getCroppedCanvas().toBlob(blob => {
      //Convert to File so we can specify the filename, otherwise it would save always as "blob"
      var file = new File([blob], uploaded?.blob?.name, { type: uploaded?.type });
      const image = this.buildImageObject(file);
      input.onChange(image);
      this.setState({openModal: false, uploaded: undefined});
    });
  };

  renderSelectedMedia = ({ getRootProps, getInputProps }) => {
    const { input, meta, width, ratio, label, loading, translate } = this.props;
    const { invalid, submitFailed } = meta;
    const showError = invalid && submitFailed ? 1 : 0;
    
    if(input?.value?.preview && input?.value?.type.indexOf('image/') !== -1) {
      return (
        <ImageInputSection error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <InputImageComponent
            url={input.value.preview}
            width={width}
            ratio={ratio}
          />
          <ImageButton>
            <BaseButton
              align="initial"
              variant="raised"
              htmlType="button"
              type="primarySmall"
              loading={loading}
              text={translate("REPLACE")}
            />
          </ImageButton>
        </ImageInputSection>
      );
    }

    if(input?.value?.url) {
      return (
        <ImageInputSection error={showError} {...getRootProps()}>
          <input {...getInputProps()} />
          <InputImageComponent 
            url={input.value?.url} 
            color={input.value?.color}
            width={width} 
            ratio={ratio} 
          />
          <ImageButton>
            <BaseButton
              align="initial"
              variant="raised"
              htmlType="button"
              type="primarySmall"
              loading={loading}
              text={translate("REPLACE")}
            />
          </ImageButton>
        </ImageInputSection>
      );
    }

    if(input?.value?._id) {
      return (
        <React.Fragment>
          <Document document={input?.value} style={{marginTop: 0, marginBottom: 30}}/>
          <ImageInputSection error={showError} {...getRootProps()}>
            <input {...getInputProps()} />
            <ImageIcon src={ToTop}/>
            <ImageMessage error={showError}>{label}</ImageMessage>
            <SelectButton>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="button"
                type="primaryLarge"
                loading={loading}
                text={translate("SELECT")}
              />
            </SelectButton>
          </ImageInputSection>
        </React.Fragment>
      );
    }

    if(input?.value?.preview && input?.value?.type.indexOf('image/') === -1) {
      return (
        <React.Fragment>
          <Document filePreview={true} document={input?.value} style={{marginTop: 0, marginBottom: 30}}/>
          <ImageInputSection error={showError} {...getRootProps()}>
            <input {...getInputProps()} />
            <ImageIcon src={ToTop}/>
            <ImageMessage error={showError}>{label}</ImageMessage>
            <SelectButton>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="button"
                type="primaryLarge"
                loading={loading}
                text={translate("SELECT")}
              />
            </SelectButton>
          </ImageInputSection>
        </React.Fragment>
      );
    }

    return (
      <ImageInputSection error={showError} {...getRootProps()}>
        <input {...getInputProps()} />
        <ImageIcon src={ToTop}/>
        <ImageMessage error={showError}>{label}</ImageMessage>
        <SelectButton>
          <BaseButton
            align="initial"
            variant="raised"
            htmlType="button"
            type="primaryLarge"
            loading={loading}
            text={translate("SELECT")}
          />
        </SelectButton>
      </ImageInputSection>
    )
  };

  render() {
    const { accept, disabled, onClick, ratio, translate } = this.props;
    const { openModal, uploaded } = this.state;

    return (
      <InputDiv onClick={onClick}>
        <Dropzone
          multiple={false}
          onDrop={this.handleImageDrop}
          accept={accept}
          maxSize={MAX_SIZE}
          disabled={disabled}
        >
          {this.renderSelectedMedia}
        </Dropzone>
        <Modal
          maskClosable={false}
          closable={false}
          title={translate('CROP_TITLE')}
          visible={openModal}
          onOk={this.onCropComplete}
          onCancel={this.onCancel}
          bodyStyle={{padding: 0}}
          width="600px">
          <Cropper
            ref="cropper"
            viewMode={2}
            autoCropArea={1}
            aspectRatio={1 / ratio}
            style={{height: 400, width: '100%'}}
            guides={true}
            src={uploaded ? uploaded.preview : ''}
          />
        </Modal>
      </InputDiv>
    );
  }
}

ImageInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  hasCrop: PropTypes.bool
};

ImageInput.defaultProps = {
  hasCrop: false
};

export default withLocalize(ImageInput);
