import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH2 } from '../../styles/TextSizes';

export const Content = styled.div`
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: ${({ confirmed }) => confirmed ? '0 65px' : '0 100px'};

  .reserve-column {
    position: fixed;
    overflow: auto;
    overflow-x: hidden;
    right: 65px;
    top: 60px;
    height: calc(100vh - 60px);
  }

  .reserve-title {
    display: flex; 
    flex-direction: row; 
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .ant-row-flex {
    width: 100%;
  }
    
  @media (max-width: 991px) {
    .reserve-column {
      position: relative;
      right: unset;
      top: unset;
      height: auto;
    }
  }

  @media ${device.tablet} {
    padding: 0 10px;
  }
`;

export const Loading = styled.img`
  width: ${({ width }) => width || 100}px;
  height: auto !important;
`;

export const Title = styled(titleH2)`
  text-align: left;
  margin-bottom: 30px;
  text-align: center;

  @media ${device.desktop} {
    margin-bottom: 20px;
  }

  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`;

export const Legend = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  font-size: 16px;
  justify-content: center;
  margin: 10px 0 !important;
  flex-wrap: wrap;
  padding-inline-start: 0;
`;

export const LegendItem = styled.li`
  padding: 0 10px;
  display: flex;
  align-content: center;
  align-items: center;

  b {
    margin-right: 3px;
  }
`;

export const ReserveContent = styled.div`
    border-left: 2px solid #f3f3f3;
    padding: 20px 0 20px 6px;
    height: 100%;

    .agenda-options {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .agenda-options .ant-radio-wrapper {
      line-height: 1.2;
    }

    .agenda-options .ant-radio-wrapper span {
      font-size: 19px;
    }

    @media (max-width: 991px) {
        border-left: unset;
    }
`;

export const ReserveTitle = styled.div`
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;
    text-align: left;
    margin-top: 38px;
    margin-bottom: 5px;
    color: #000000;
`;

export const ReserveSubtitle = styled.div`
    color: ${p => p?.theme?.primaryColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5px;
`;

export const ReserveDescription = styled.div`
    color: black;
    opacity: 0.3;    
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin-bottom: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;

    span {
      display: block;
    }

    span:first-letter {
      text-transform: uppercase;
    }
`;

export const ReserveList = styled.ul`
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0;
`;

export const ReserveItem = styled.li`
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    background-color: ${({ edit, theme }) => (edit ? theme?.primaryColor : '#f3f3f3')};
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: center;

    .anticon {
        font-size: 16px;
        padding: 0 2px;
    }

    b {
      font-weight: 600;
    }
`;

export const VisitList = styled.ul`
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    list-style-type: none;
    padding-inline-start: 0;
`;

export const VisitItem = styled.li`
    color: #000000;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    background-color: #f3f3f3;
    padding: 5px;
    margin: 5px 0;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-end;

    &:hover {
      background-color: #f3f3f3;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .info-clock {
      display: inline-flex;
    }

    .info .clock {
      margin: auto 0 auto 15px;
    }

    .anticon {
        font-size: 16px;
        padding: 0 2px;
    }

    b {
      font-weight: 600;
    }
`;

export const ColorBox = styled.div`
    height: 15px;
    width: 15px;
    border-radius: 4px;
    margin-right: 3px;
    background-color: ${({ color }) => color};
`;

export const Arrow = styled.span`
  position: absolute;
  bottom: 20px;
  right: 20px;

  & svg {
    width: 24px;
    height: 24px;

    & #arrowright {
      fill: #ffffff;
    }
  }

  @media ${device.mobileM} {
    bottom: 10px;
    right: 10px;
  }
`;