import client from '../config/AxiosConfig';

// export const CreateReserve = async (data) =>
//   client.put(`/my-wedding/createReserve`, data);

export const MngMultipleReserves = async (data) =>
  client.put(`/my-wedding/mngMultipleReserve`, data);

// export const UpdateReserve = async (data) =>
//   client.post(`/my-wedding/updateReserve`, data);

export const ReactivateReserve = async (id) =>
  client.post(`/my-wedding/reactivateReserve/${id}`);

export const ReorderReserve = async (id) =>
  client.post(`/my-wedding/reorderReserve/${id}`);

export const ConfirmReserve = async (id, data) =>
  client.post(`/my-wedding/confirmReserve/${id}`, data);

export const SoftDeleteReserve = async (id, deleted) =>
  client.delete(`/my-wedding/softDeleteReserve/${id}/${deleted}`);

export const GetReserveAgenda = async (filters) =>
  client.post(`/my-wedding/getAgenda`, filters);

export const GetReserves = async (filters) =>
  client.get(`/my-wedding/getReserves?filter=${filters}`);

export const GetWeddingReserves = async (filters) =>
  client.get(`/my-wedding/getWeddingReserves?filter=${filters}`);

export const GetReserve = async (id) =>
  client.get(`/my-wedding/getReserve/${id}`);

export const CheckReservesActions = async (reserveList = []) =>
  client.post(`/my-wedding/checkReservesActions`, {reserves: reserveList});

export const GetReservesWithOrder = async (data) =>
  client.post(`/my-wedding/getWithOrder`, data);

export const CheckReservesReactivations = async () =>
  client.get(`/my-wedding/checkReservesReactivations`);

  export const CheckDatesAvailable = async (data) =>
  client.post(`/my-wedding/checkDatesAvailable`, data);