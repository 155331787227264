import React, {Component} from 'react';
import MainPage from './MainPage';
import { GetCurrentInspirations } from '../../infra/requests/OurStyleRequests';

class OurStyle extends Component {
  state = {
    styleInfo: undefined,
    ready: false
  };

  componentDidMount() {
    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if(this.props.id !== prevProps?.id) {
      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    let styleInfo = undefined;
    const {data, success} = await GetCurrentInspirations();

    if(success) {
      styleInfo = data;
    }

    this.setState({
      styleInfo,
      ready: true
    });
  };

  render() {
    const { styleInfo, ready } = this.state;

    if(!ready) return false;

    return (
      <MainPage tag='STYLE' title='OUR_STYLE' url='/our-style' styleInfo={styleInfo}/>
    );
  }
}

export default OurStyle;