import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {InputDiv, InputLabelDiv, DateInputField} from './InputStyles';
import moment from 'moment';
import { DatePicker } from 'antd';
const { MonthPicker, RangePicker } = DatePicker;

const MonthInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  dateFormat,
  allowClear,
  disabledDate
}) => {
  const [opened, setOpened] = useState(false);
  const {invalid, submitFailed} = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <MonthPicker
        value={input.value && input?.value !== null && input?.value !== '' ? moment.utc(input.value) : null}
        open={opened}
        // mode="month"
        placeholder={placeholder}
        format="MM/YYYY"
        onOpenChange={(status) => {
          if (status) {
            setOpened(true);
          } else {
            setOpened(false);
          }
        }}
        onPanelChange={(v) => {
          // console.log(v);
          // setOpened(false);
          input.onChange(moment(v).utc(true));
        }}
        onChange={(date, dateString) => {
         date ? input.onChange(moment(date).utc(true)) : input.onChange('');
        }}
        disabled={disabled}
        disabledDate={disabledDate}
        allowClear={allowClear}
      />
    </InputDiv>
  );
};

MonthInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default MonthInput;
