import PropTypes from 'prop-types';
import React, { useState, useEffect, Fragment, useReducer, useCallback } from 'react';
import { Row, Col, Icon, Tooltip, Modal, Input } from 'antd';
import { Field, FieldArray } from 'redux-form';
import { SectionTitle } from '../../styles/BasicStyles';
import { DeleteSeatButton, SeatAge, SeatFoodRestrict, SeatName, SeatRow, TableSeatCode } from './RoomPlanStyles';
import Alerts from '../../components/alert/Alert';
import SelectAgeInput from '../../components/inputs/SelectAgeInput';
import SelectRestrictionInput from '../../components/inputs/SelectRestrictionInput';
import { MaterialInput } from '../../components/inputs/MaterialStyles';
import { AgeTypes, GuestAgeEnum, TablePurposeEnum, setRestrictionsBasedOnAge } from './utilGuest';

const GuestSeatComponent = ({
  validated,
  roomPlanClosed,
  index,
  seat,
  table,
  foodRestrictions,
  parentUpdateSeat,
  parentRemoveSeat,
  translate
}) => {
  const [tableObj, setTable] = useState(table);

  const [number, setNumber] = useState(seat?.number);
  const [guestName, setGuestName] = useState(seat?.guestName);
  const [guestAge, setGuestAge] = useState(seat?.guestAge);
  const [guestRestrictions, setGuestRestrictions] = useState(seat?.foodRestrictions);
  const [isCouple, setIsCouple] = useState(seat.couple);
  const [frontCouple, setFrontCouple] = useState(seat.frontCouple);

  const [disabled, setDisabled] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [isValidated, setValidated] = useState(validated);
  const [isRoomPlanClosed, setRoomPlanClosed] = useState(roomPlanClosed);

  useEffect(() => {
    setNumber(seat?.number);
    setGuestName(seat?.guestName);
    setGuestAge(seat?.guestAge);
    setGuestRestrictions(seat?.foodRestrictions);
    setIsCouple(seat?.couple);
    setFrontCouple(seat?.frontCouple);
  }, [seat, seat?.guestName, seat?.guestAge, seat?.couple, seat?.frontCouple, seat?.foodRestrictions]);

  useEffect(() => {
    setTable(table);
    setDisabled(!table?.active);
    setIsStaff(table?.tablePurpose == TablePurposeEnum.STAFF);
  }, [table]);

  useEffect(() => {
    setValidated(validated);
  }, [validated]);

  useEffect(() => {
    setRoomPlanClosed(roomPlanClosed);
  }, [roomPlanClosed]);

  const getSeatCode = () => {
    return (number || 0) + 1;
  }

  const removeSeat = () => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    if (isStaff) {
      parentRemoveSeat(index);
    } else {
      setGuestName('');
      setGuestAge(GuestAgeEnum.ADULT);
      setGuestRestrictions([]);
      parentUpdateSeat('UPDATE_SEAT', index, {
        number,
        couple: isCouple,
        frontCouple,
        guestName: '',
        guestAge: GuestAgeEnum.ADULT,
        foodRestrictions: []
      });
    }
  }

  const handleNameChange = (event) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    setGuestName(event.target.value);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName: event.target.value,
      guestAge,
      foodRestrictions: guestRestrictions
    });
  };

  const handleAgeChange = (value) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    const originalGuestAge = guestAge;
    const restrictionsToUpdate = setRestrictionsBasedOnAge(originalGuestAge, value, guestRestrictions, foodRestrictions);
    setGuestAge(value);
    setGuestRestrictions(restrictionsToUpdate);

    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge: value,
      foodRestrictions: restrictionsToUpdate
    });
  }

  const handleChangeRestriction = (values) => {
    if (isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)) {
      return;
    }

    setGuestRestrictions(values);
    parentUpdateSeat('UPDATE_SEAT', index, {
      number,
      couple: isCouple,
      frontCouple,
      guestName,
      guestAge,
      foodRestrictions: values
    });
  }

  const showAlert = () => {
    if (isValidated) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('ROOM_PLAN_APPROVED')
      });
    }
    else if (isRoomPlanClosed) {
      return Alerts.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        // text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  return (
    <SeatRow key={index} disabled={disabled}>
      <TableSeatCode seat={true} couple={isCouple}>
        {getSeatCode() || 'X'}
      </TableSeatCode>

      <SeatName>
        <MaterialInput
          type="text"
          translate="no"
          placeholder={translate('NAME')}
          noLabel={true}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          value={guestName}
          onChange={handleNameChange}
          onClick={() => showAlert()} />
      </SeatName>
      <SeatAge staff={isStaff}>
        <SelectAgeInput
          placeholder={translate('AGE')}
          meta={{ invalid: false, submitFailed: false }}
          data={AgeTypes}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          translatable={true}
          translate={translate}
          input={{
            value: guestAge,
            onChange: handleAgeChange,
            onFocus: () => showAlert()
          }} />
      </SeatAge>
      <SeatFoodRestrict staff={isStaff}>
        <SelectRestrictionInput
          placeholder={translate('ALLERGIAS')}
          meta={{ invalid: false, submitFailed: false }}
          readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
          data={foodRestrictions}
          input={{
            value: guestRestrictions,
            onChange: handleChangeRestriction,
            onFocus: () => showAlert()
          }}
        />
      </SeatFoodRestrict>
      <DeleteSeatButton
        readOnly={isValidated || isRoomPlanClosed || disabled || (!tableObj?.seatsFrontCouple && seat?.frontCouple)}
        onClick={removeSeat}>
        <Icon type="delete" />
      </DeleteSeatButton>
    </SeatRow>
  );
}
export default GuestSeatComponent;