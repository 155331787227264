import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import Pagination from "../../components/pagination/Pagination";
import { 
  NewsContainer,
  CardsRow, 
  NoResults, 
  Title,
  Description
} from "./NewsStyles";
import NewsItem from '../../components/newsItem/NewsItem';
import { SpinLoading } from "../../styles/BasicStyles";
import { GetNewsPaginated } from "../../infra/requests/MyWeddingRequests";

const limitPage = 20;

class News extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      newsList: [],
      page: props.match.params.page > 0 ? props.match.params.page : 1,
      total: 0,
      ready: false
    };
  }
  
  newsContainerRef = React.createRef();

  componentDidMount() {
    const { page } = this.state;

    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    document.body.style.overflowY = 'auto';

    this.changePage(page, 0);
  }

  changePage = async (page, changingPage = 1) => {
    const { history } = this.props;

    const { data, success } = await GetNewsPaginated(page, limitPage);
    let newsList = [];
    let total = 0;

    if(success) {
      newsList = data.items;
      total = data.total;
    }

    this.setState({
      newsList,
      page,
      total,
      ready: true
    });

    //Only scroll's to top of results on page change, not uppon entering the Section
    if(changingPage === 1) {
      window.scrollTo({ top: this.newsContainerRef.current.offsetTop, behavior: 'smooth' });
    }

    history.replace(`/news/${page}`);
  }

  render() {
    const { translate } = this.props;
    const { newsList, page, total, ready } = this.state;

    if(!ready) return <SpinLoading/>;

    return(
      <NewsContainer>
        <Title>{translate('NEWS')}</Title>
        <Description ref={this.newsContainerRef} dangerouslySetInnerHTML={{__html: translate('NEWS_DESCRIPTION')}}/>
        <CardsRow>
          { 
            newsList.length > 0 ?
            newsList.map((news, index) =>
              <NewsItem 
                key={index}
                id={news._id}
                title={news.title}
                date={news.date}
                group={news.category} 
                image={news.image}
              />
            )
            :
            <NoResults>{translate('NO_RESULTS')}</NoResults>
          }
        </CardsRow>
        <Pagination
          changePage={this.changePage}
          totalItems={parseInt(total)} 
          currentPage={parseInt(page)} 
          perPage={parseInt(limitPage)}
        />
      </NewsContainer>
    );
  }
};

export default withLocalize(withRouter(News));