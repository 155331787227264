import React from 'react';
import { Modal, Button, Icon } from 'antd';
import styled from 'styled-components';
import moment from 'moment';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: center;

  .anticon {
    font-size: 3rem;
    margin-right: 1rem;
  }
`;

export const ModalText = styled.div`
  font-size: 15px;
`;

let NoResourcesModal = ({ openModal, closeModal, onSubmit, loading, initialValues, translate, activeLanguage }) => {

  return (
    <Modal
      visible={openModal}
      title={translate('ATTENTION')}
      maskClosable
      onCancel={closeModal}
      footer={[
        <Button
          key='cancel'
          onClick={() => closeModal()}
        >
          {translate('CANCEL')}
        </Button>,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => onSubmit()}
        >
          {translate('YES_CONTACT')}
        </Button>
      ]}
    >
      <ModalContent>
        <Icon type='exclamation-circle' style={{ color: '#faad14' }} />
        <ModalText>
          <p>{translate('NO_RESOURCES_TITLE')}</p>
          <p><b>{translate('NO_RESOURCES_QUESTION')}</b></p>
        </ModalText>
      </ModalContent>
    </Modal>
  );
};

export default NoResourcesModal;