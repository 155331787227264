import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  CardContainer,
  CardContent,
  DetailContainer,
  Question,
  FileContaner,
  ImageIcon,
  FileText
} from "./ChoicesCardStyles";
import ToTop from "../../assets/icons/to_top.svg";

const FileCard = ({ question, answer, translate }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{question}</Question>
        <DetailContainer>
          <FileContaner 
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_IMAGES_URL}${answer._id}`,
                "_blank"
              )
            }
          >
            <ImageIcon src={ToTop}/>
            <FileText>{translate('VIEW_FILE')}</FileText>
          </FileContaner>
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default withLocalize(FileCard);