import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  DocumentContainer,
  DocIcon,
  Info,
  Name,
  Size,
  DocumentColumn1,
  DocumentColumn2
} from "./DocumentStyles";
import BaseButton from "../../components/buttons/BaseButton";
import { getDocumentType } from "../../infra/services/utils/DocumentType";

const Document = ({ filePreview, document, style, translate }) => {
  const fileName = filePreview ? document.blob.name : document.filename;
  const fileContentType = filePreview ? document.type : document.contentType;
  const fileSize = filePreview ? document.size : document.length;

  return (
    <DocumentContainer style={style}>
      <DocumentColumn1>
        <DocIcon type={getDocumentType(fileContentType)} />
        <Info>
          <Name>{fileName}</Name>
          <Size>{(fileSize / (1024 * 1024)).toFixed(2)}M</Size>
        </Info>
      </DocumentColumn1>
      {
        !filePreview &&
        <DocumentColumn2>
          <BaseButton
            align="initial"
            variant="raised"
            htmlType="button"
            type="primaryMedium"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_IMAGES_URL}${document._id}`,
                "_blank"
              )
            }
            text={translate("DOWNLOAD")}
          />
        </DocumentColumn2>
      }
    </DocumentContainer>
  );
};

export default withLocalize(Document);
