import React from "react";
import { withLocalize } from "react-localize-redux";
import { Field } from "redux-form";
import TextInput from "../inputs/TextInput";
import TextAreaInput from "../inputs/TextAreaInput";
import {
  TextContainer
} from "./OptionCardStyles";
import Alert from "../alert/Alert";

const TextCard = ({ component, type, canEditSection, isProcessClosed, translate }) => {
  const showAlert = () => {
    if(isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if(!canEditSection) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  return (
    <TextContainer>
      <Field
        component={component === 'Text' ? TextInput : TextAreaInput}
        type={type}
        placeholder={component === 'TextArea' ? translate("MESSAGE_WD") : ''}
        name="response"
        label={component === 'Text' ? translate(type.toUpperCase()) : null}
        onClick={() => showAlert()}
        readOnly={!canEditSection || isProcessClosed}
      />
    </TextContainer>
  );
};

export default withLocalize(TextCard);
