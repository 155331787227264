import styled from 'styled-components';
import { titleH3 } from '../../styles/TextSizes';
import { device } from '../../styles/Responsive';

export const SectionContainer = styled.div`
  margin-bottom: 80px;
  padding: 0px 15px;
  width: 100%;

  @media ${device.desktop} {
    margin-bottom: 60px;
  }

  @media ${device.tablet} {
    margin-bottom: 40px;
  }
`;

export const Title = styled(titleH3)`
  text-align: left;
  margin-bottom: 20px;
`;

export const Description = styled.div`
  text-align: left;
`;