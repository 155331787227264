import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { InputDiv, InputLabelDiv, InputNumberField } from './InputStyles';
import { MaterialInputSection, MaterialLabel, MaterialLabelFixed, MaterialInputNumber } from './MaterialStyles';

const MaterialNumberInput = ({
  input,
  meta: { invalid, submitFailed, error },
  size,
  label,
  max,
  min,
  step,
  placeholder,
  disabled,
  tag,
  translate,
  requiredError,
  labelFixed = false
}) => {
  const [filled, setFilled] = useState(input && input.value !== "");
  const showError = invalid && submitFailed;

  let errorMsg = '';
  if (error) {
    if (Array.isArray(error) && error.length === 3) {
      errorMsg = translate(error[0]).replace(error[1], error[2]);
    }
    else {
      errorMsg = translate(error);
    }
  }

  if (requiredError) {
    errorMsg = translate('INPUT_ERROR_REQUIRED');
  }

  return (
    <MaterialInputSection>
      {
        <MaterialLabelFixed tag={tag} filled={true} showError={showError || requiredError}>
          {label}
        </MaterialLabelFixed>
      }
      <MaterialInputNumber
        disabled={disabled}
        size={size}
        max={max}
        type="number"
        min={min}
        step={step}
        placeholder={placeholder}
        value={input.value}
        onChange={input.onChange}
        // blur={() => setFilled(input && input.value !== "")}
        // focus={() => setFilled(true)}
        showError={showError || requiredError}
        tag={tag}
        labelFixed={true}
      />
    </MaterialInputSection>
  );
};

MaterialNumberInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default MaterialNumberInput;
