import React from "react";
import { withLocalize } from "react-localize-redux";
import BG_404 from "../../assets/bg_404.png";
import {
  Container,
  Image,
  TextContainer,
  Title,
  Subtitle
} from './NotFoundPageStyles';

const NotFoundComponent = ({ translate }) => {
  return (
    <Container>
      <Image src={BG_404}/>
      <TextContainer>
        <Title>404</Title>
        <Subtitle>{translate('PAGE_NOT_FOUND')}</Subtitle>
      </TextContainer>
    </Container>
  );
};

export default withLocalize(NotFoundComponent);