import React from "react";
import {withLocalize} from 'react-localize-redux';
import {
  SectionContainer,
  TitleSection,
  CardsContainer,
  CardsRow
} from "./ChoicesStyles";
import TextCard from "../../components/choicesCards/TextCard";
import FileCard from "../../components/choicesCards/FileCard";
import ObservationsCard from "../../components/choicesCards/ObservationsCard";

const ChoicesGuests = ({ guests, notes = null, translate }) => {
  return (
    <SectionContainer>
      <TitleSection>{translate('GUESTS')}</TitleSection>
      <CardsContainer>
        <CardsRow>
          {
            notes &&
            <ObservationsCard
              notes={notes}
            />
          }
          {
            guests.map((elem, index) =>
              elem.answer.filename ?
              <FileCard
                key={index}
                question={elem.question}
                answer={elem.answer}
              />
              :
              <TextCard
                key={index}
                question={elem.question}
                answer={elem.answer}
              />
            )
          }
        </CardsRow>
      </CardsContainer>
    </SectionContainer>
  );
}

export default withLocalize(ChoicesGuests);