import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import TextInput from "../../components/inputs/TextInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import FormValidator from "../../infra/services/validations/FormValidator";
import BaseButton from "../../components/buttons/BaseButton";
import Logo from "../../assets/icons/logo.svg";
import {
  LoginContainer,
  LoginCard,
  LoginForm,
  LogoImg,
  LogoSection,
  LoginButtonDiv,
  BaseLink
} from "./LoginPageStyles";
import { TermsAndConditionsCheckbox } from "./RegisterPageStyles";
import { Register } from "../../infra/requests/AuthRequests";
import TermsModal from "../../components/modals/TermsModal";
import { withLocalize } from "react-localize-redux";
import Alert from "../../components/alert/Alert";
import { Icon } from "antd";
const qs = require("query-string");

const formRules = FormValidator.make({
  email: "required|email",
  password: "required|min:6",
  repeat_password: "required|min:6|repeat_password",
  terms_and_conditions: "required"
});

class RegisterPage extends Component {
  state = {
    openTerms: false,
    showPassword: false,
    showConfirmPassword: false
  };

  onSubmit = async values => {
    const { translate } = this.props;

    try {
      const { history, location } = this.props;
      const parsed = qs.parse(location.search);
      const token = parsed.token;

      const result = await Register({ ...values, token });

      if (result?.success) {
        if (result?.data?.alternativeUser) {
          const code = new URLSearchParams(location.search).get('code') !== undefined
            ? new URLSearchParams(location.search).get('code')
            : '';

            if(code) {
              history.push(`/login-guest?code=${code}`);
            } else history.push('/login-guest');
        } else history.push("/login");
      }
      else {
        Alert.new({
          type: "error",
          title: translate('ALERT_ERROR_TITLE'),
          text: translate(result.message)
        });
      }
    }
    catch (e) {
      console.error(e);
    }
  };

  componentDidMount = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {
    const { handleSubmit, submitting, translate } = this.props;
    const { openTerms, showPassword, showConfirmPassword } = this.state;

    return (
      <LoginContainer>
        <LoginForm onSubmit={handleSubmit(this.onSubmit)}>
          <LoginCard>
            <LogoSection>
              <LogoImg src={Logo} alt="logo" />
            </LogoSection>
            <BaseLink to="/login" style={{ textAlign: 'left' }}>{translate("ALREADY_HAVE_ACCOUNT")}</BaseLink>

            <Field
              component={TextInput}
              name="email"
              disabled
              type="email"
              label={translate("EMAIL")}
            />
            <Field
              component={TextInput}
              name="password"
              type={showPassword ? 'text' : 'password'}
              label={translate("PASSWORD")}
              suffix={<Icon type={showPassword ? 'eye-invisible' : 'eye'} onClick={() => this.setState({ showPassword: !showPassword })} />}
            />
            <Field
              component={TextInput}
              name="repeat_password"
              type={showConfirmPassword ? 'text' : 'password'}
              label={translate("REPEAT_PASSWORD")}
              suffix={<Icon type={showConfirmPassword ? 'eye-invisible' : 'eye'} onClick={() => this.setState({ showConfirmPassword: !showConfirmPassword })} />}
            />
            <Field
              component={CheckboxInput}
              name="terms_and_conditions"
              type="checkbox"
              style={TermsAndConditionsCheckbox}
              label={translate("ACCEPT_TERMS_WD")}
              onClickLabel={() => this.setState({ openTerms: true })}
            />

            <LoginButtonDiv>
              <BaseButton
                align="initial"
                variant="raised"
                htmlType="submit"
                type="primaryLarge"
                loading={submitting}
                bgColor={'primaryColor'}
                text={translate("REGISTER_WD")}
              />
            </LoginButtonDiv>
          </LoginCard>
        </LoginForm>
        <TermsModal
          open={openTerms}
          onClose={() => this.setState({ openTerms: false })}
        />
      </LoginContainer>
    );
  }
}

RegisterPage = reduxForm({
  form: "register",
  validate: formRules
})(RegisterPage);

const mapStateToProps = (state, ownProps) => {
  const parsed = qs.parse(ownProps.location.search);
  return {
    initialValues: {
      email: parsed.email
    }
  };
};

const mapActionToProps = dispatch => bindActionCreators({}, dispatch);

export default withLocalize(
  connect(mapStateToProps, mapActionToProps)(RegisterPage)
);
