export const categories = { 
  "pt": [
    "Fotografia e Vídeo",
    "Animação Musical",
    "Animação Infantil",
    "Celebrante / Conservatória",
    "Motorista",
    "Segurança",
    "Make-up / Massagem"
  ],
  "en": [
    "Photography and Video",
    "Musical Animation",
    "Children's Animation",
    "Celebrant / Conservatory",
    "Driver",
    "Security",
    "Make-up / Massage"
  ],
  "fr": [
    "Photographie et Vidéo",
    "Animation Musicale",
    "Animation pour Enfants",
    "Célébrant / Conservatoire",
    "Chauffeur",
    "Sécurité",
    "Maquillage / Massage"
  ],
  "es": [
    "Fotografía y Video",
    "Animación Musical",
    "Animación Infantil",
    "Celebrante / Conservatorio",
    "Conductor",
    "Seguridad",
    "Maquillaje / Masaje"
  ]
};

export const findCategoryIndex = (category) => {
  let res = -1, temp_index = -1;

  Object.keys(categories).forEach(function(key) {
    temp_index = categories[key].indexOf(category);

    if(res === -1 && temp_index >= 0) {
      res = temp_index;
    }
  });

  return res;
}