import React from "react";
import PropTypes from "prop-types";
import {
  StyledSaveButton,
  TextContainer,
  IconContainer,
  IconText,
  Icon,
  LoadingImage,
} from "./ButtonsStyles";
import LongArrowRight from "../svg/LongArrowRight";
import Add from "../svg/Add";
import Save from "../svg/Save";
import LoadingIcon from "../../assets/icons/loading_save.svg";

export const SaveButton = ({
  text,
  textMobile,
  htmlType,
  icon,
  onClick,
  withBackground,
  loading,
  error,
}) => (
  <StyledSaveButton
    type={htmlType}
    onClick={onClick}
    withBackground={withBackground}
    loading={loading}
    error={error}
  >
    {text ? <TextContainer>{text}</TextContainer> : null}
    {icon === "add" ? (
      <IconContainer error={error}>
        {textMobile ? <IconText>{textMobile}</IconText> : null}{" "}
        <Icon>{loading ? <LoadingImage src={LoadingIcon} /> : <Add />}</Icon>
      </IconContainer>
    ) 
    : icon === "save" ? (
      <IconContainer error={error}>
        {textMobile ? <IconText>{textMobile}</IconText> : null}{" "}
        <Icon>{loading ? <LoadingImage src={LoadingIcon} /> : <Save />}</Icon>
      </IconContainer>
    )
    : (
      <IconContainer error={error}>
        {textMobile ? <IconText>{textMobile}</IconText> : null}{" "}
        <Icon>
          {loading ? <LoadingImage src={LoadingIcon} /> : <LongArrowRight />}
        </Icon>
      </IconContainer>
    )}
  </StyledSaveButton>
);

SaveButton.propTypes = {
  htmlType: PropTypes.oneOf(["reset", "submit", "button"]).isRequired,
  text: PropTypes.string,
  textMobile: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  withBackground: PropTypes.bool,
  loading: PropTypes.bool,
};

SaveButton.defaultProps = {
  htmlType: "button",
};

export default SaveButton;
