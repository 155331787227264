import React, { useEffect, useState } from 'react';
import { reduxForm, Field } from "redux-form";
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import { editObservations, editSection, processClosed } from '../../infra/services/utils/BlockAnswers';
import { findPageByTag, findQuestionByID } from '../../infra/services/utils/PageInfo';
import Alerts from '../../components/alert/Alert';
import { Description } from './PageDetailStyles';
import ManageQuestionType from '../../components/manageQuestionType/ManageQuestionType';
import DecorationObservation from '../../components/decorationObservation/DecorationObservation';
import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const ModalContent = styled(Modal)`
  &.ant-modal {
    width: 100% !important;
    max-width: 1000px;
    padding: 0px 40px;

    @media ${device.tablet} {
      padding: 0px 15px;
    }

    @media ${device.mobileL} {
      padding: 0px 10px;
    }
  }

  .ant-modal-content {
    border-radius: 6px;
  }
`;

const FormValidations = (values) => {
  //console.log('FORM', values);
  return {};
};

let SectionModal = ({
  open,
  loading,
  initialValues,
  handleSubmit,
  onSubmit,
  closeModal,
  question,
  canEditSection,
  isProcessClosed,
  translate,
  activeLanguage,
}) => {
  return (
    <ModalContent
      visible={open}
      title={question?.title ? question?.title[activeLanguage?.code] : 'Mesa dos Noivos'}
      maskClosable={false}
      onCancel={closeModal}
      footer={canEditSection && !isProcessClosed
        ? [
          <Button key="back" onClick={closeModal}>
            {translate("CANCEL")}
          </Button>,
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={handleSubmit(onSubmit)}>
            {translate("SAVE")}
          </Button>
        ]
        : [
          <Button key="back" onClick={closeModal}>
            {translate("CANCEL")}
          </Button>
        ]}>
      <BaseForm onSubmit={handleSubmit(onSubmit)}>
        {question?.description && (
          <Description inModal={true}
            dangerouslySetInnerHTML={{
              __html:
                question.description &&
                question.description[activeLanguage?.code],
            }}
          />
        )}
        <Field
          name="response"
          component={ManageQuestionType}
          question={question}
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
          inModal={true}
        />
        {/* {question.observations && (
          <DecorationObservation
            inputLabel={translate("OBSERVATIONS_WD")}
            canEditSection={canEditSection ? editObservations(question) : canEditSection}
            isProcessClosed={isProcessClosed}
          />
        )} */}
      </BaseForm>
    </ModalContent>
  );
};

SectionModal = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(SectionModal);

export default SectionModal;
