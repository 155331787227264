import React from "react";
import {withLocalize} from "react-localize-redux";
import {
  DetailBlockContainer,
  TextContent,
  Question,
  Answer
} from "../BudgetTableExpensesStyles";

const BaseServiceBlock = ({ minGuests, price = null, pax = null, seats = null, translate }) => {
  return (
    <DetailBlockContainer>
      {
        minGuests > 0 &&
        <TextContent>
          <Question>{translate('MIN_GUESTS')}:</Question>
          <Answer>{minGuests}</Answer>
        </TextContent>
      }
      {
        price &&
        <TextContent>
          <Question small>{translate('PRICE_PAX')}:</Question>
          <Answer small>{price}</Answer>
        </TextContent>
      }
      {
        pax > 0 &&
        <TextContent>
          <Question small>PAX:</Question>
          <Answer small>{pax}</Answer>
        </TextContent>
      }
      {
        seats > 0 &&
        <TextContent>
          <Question small>{translate('MOUNTED_PLACES')}:</Question>
          <Answer small>{seats}</Answer>
        </TextContent>
      }
    </DetailBlockContainer>
  );
}
  
export default withLocalize(BaseServiceBlock);