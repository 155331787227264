import React from "react";
import styled from "styled-components";
import LoadingIcon from "../../assets/icons/ic_loading.svg";

const Container = styled.div`
  display: flex;
  width: ${({ page }) => (page ? "100%" : "100vw")};
  height: ${({ page }) => (page ? "100%" : "100vh")};
  justify-content: center;
  align-items: center;
`;

const Loading = styled.img`
  width: ${({ width }) => width || 100}px;
  animation-name: wed-animation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;

  animation {
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  @keyframes wed-animation {
    70% {
      opacity: 1;
      -webkit-transform: scale(1.5);
      transform: scale(1.5);
    }
  }
`;

const LoadingComponent = ({ page, width }) => {
  return (
    <Container page={page}>
      <Loading width={width} src={LoadingIcon} />
    </Container>
  );
};

export default LoadingComponent;
