import React, { useState } from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Spin } from "antd";
import {
  SelectDiv,
  StyledSelectInput,
  StyledOption,
  SelectArrow,
  CloseArrow,
  ArrowImg,
  StyledOptionContent,
  SelectLabelInput
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import Close from "../../assets/icons/close.svg";
import { MaterialErrorLabel, MaterialInputSection, MaterialLabel, MaterialLabelFixed } from "./MaterialStyles";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const SelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  labelFixed,
  disabled,
  fetchData,
  loading,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  removeSelection,
  tag,
  costumeLabel,
  smallIcon,
  translate
}) => {
  const [filled, setFilled] = useState(input && input.value !== "");
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  let errorMsg = '';
  if (meta?.error) {
    if (Array.isArray(meta.error) && meta.error.length === 3) {
      errorMsg = translate(meta.error[0]).replace(meta.error[1], meta.error[2]);
    }
    else {
      errorMsg = translate(meta?.error);
    }
  }

  const changeSelect = value => {
    input.onChange(value || '');
  };

  return (
    <MaterialInputSection noLabel={!label}>
      <SelectDiv>
        {
          label && (labelFixed ?
            <MaterialLabelFixed tag={tag} filled={filled} showError={showError}>
              {label}
            </MaterialLabelFixed>
            :
            <MaterialLabel tag={tag} filled={filled} showError={showError}>
              {label}
            </MaterialLabel>)
        }
        <StyledSelectInput
          disabled={disabled}
          mode={mode}
          placeholder={placeholder}
          notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          onBlur={() => setFilled(input && input.value !== "")}
          onFocus={() => setFilled(true)}
          showArrow={false}
          showSearch={false}
          loading={loading}
          error={showError ? 1 : 0}
        >
          {data.map((elem, index) => (
            <StyledOption key={dataKey ? elem[dataKey] : index} disabled={elem.disabled}>
              <StyledOptionContent>
                {costumeLabel
                  ? costumeLabel(elem)
                  : dataLabel
                    ? (translatable ? elem[dataLabel][activeLanguage.code] : elem[dataLabel])
                    : elem}
              </StyledOptionContent>
            </StyledOption>
          ))}
        </StyledSelectInput>
        {
          allowClear && input?.value
            ? <CloseArrow
              disabled={disabled}
              smallIcon={smallIcon}
              onClick={() => removeSelection(tag)}>
              <ArrowImg src={Close} />
            </CloseArrow>
            : <SelectArrow
              disabled={disabled}
              smallIcon={smallIcon}>
              <ArrowImg src={ArrowDown} />
            </SelectArrow>
        }
      </SelectDiv>
      {
        (showError) &&
        <MaterialErrorLabel visible={showError}>
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      }
    </MaterialInputSection>
  );
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string,
  labelFixed: PropTypes.bool,
};

SelectInput.defaultProps = {
  data: [],
  allowClear: false,
  smallIcon: false,
  labelFixed: false,
  dataKey: '_id',
  dataLabel: 'name'
};

export default withLocalize(SelectInput);