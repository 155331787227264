import React from "react";
import {withLocalize} from 'react-localize-redux';
import {
  SectionContainer,
  TitleSection,
  CardsContainer,
  CardsRow
} from "./ChoicesStyles";
import UpgradeCard from "../../components/choicesCards/UpgradeCard";

const ChoicesUpgrade = ({ upgrade, translate, activeLanguage }) => {
  return (
    <SectionContainer>
      <TitleSection>{translate('UPGRADE')}</TitleSection>
      <CardsContainer>
        <CardsRow>
          <UpgradeCard
            question={upgrade.name[activeLanguage?.code]}
            image={upgrade.icon?.url}
          />
        </CardsRow>
      </CardsContainer>
    </SectionContainer>
  );
}

export default withLocalize(ChoicesUpgrade);