import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { Form } from "antd";
import { titleH3, titleH4 } from '../../styles/TextSizes';

export const DetailContainer = styled.div`
  width: 100%;
  max-width: 970px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-bottom: 130px;
  padding: 0 10px;
`;

export const Description = styled.div`
  text-align: left;
  padding: 0px 15px;
  margin-bottom: ${p => p?.inModal ? '20px' : '50px'} ;
  white-space: pre-line;
  color: #000000;
  font-size:  ${p => p?.inModal ? '16px' : '20px'};
  font-weight: 400;
  line-height: 31px;

  @media ${device.desktopL} {
    margin-bottom: ${p => p?.inModal ? '20px' : '30px'};
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: ${p => p?.inModal ? '20px' : '30px'};
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;

  & > div {
    padding: 0px 15px;
    max-width: ${p => 100 / p.total}%;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const QuestionTitle = styled(titleH3)`
  text-align: left;
  margin-top: 60px;

  @media ${device.desktopL} {
    margin-top: 40px;
  }

  @media ${device.desktop} {
    margin-top: 30px;
  }
`;

export const QuestionTitleGuests = styled(titleH4)`
  text-align: left;
  margin-top: 60px;

  @media ${device.desktopL} {
    margin-top: 40px;
  }

  @media ${device.desktop} {
    margin-top: 30px;
  }
`;

export const QuestionDescription = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 30px 0px;
`;

export const NumGuestsContainer = styled.div`
  width: 100%;
  padding: 0px 15px;
`;

export const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: 120px;

  @media ${device.desktopL} {
    margin-bottom: 70px;
  }

  @media ${device.desktop} {
    margin-bottom: 50px;
  }
`;

export const StaffFormContainer = styled.div`
  padding: 0px 15px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & button:nth-child(2) {
    margin-left: 20px;
  }
`;