import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH4 } from '../../styles/TextSizes';

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  margin-bottom: 85px;

  @media ${device.desktopL} {
    margin-bottom: 60px;
  }

  @media ${device.desktop} {
    margin-bottom: 40px;
  }

  @media ${device.tablet} {
    margin-top: 15px;
  }
`;

export const TableLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 2px solid ${p => p.theme.primaryCTA};
`;

export const TableColumn = styled(titleH4)`
  flex-basis: 50%;
  text-align: left;
  line-height: 48px;

  &:first-child {
    padding-right: 5px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 48px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 40px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
    line-height: 35px;
  }
`;