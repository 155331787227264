import { Redirect, Route } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import React from "react";
import { connect } from "react-redux";
import * as loadash from "lodash";
import LoadingComponent from "../../components/loading/LoadingComponent";
import { accessReserve, isUserAllowed } from "../../infra/services/utils/Helpers";
import NotFoundPage from "../../pages/notFound/NotFoundPage";

const AuthenticatedRoute = ({
  user,
  wedding,
  component: Component,
  props: cProps,
  ready,
  route,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (!ready) return <LoadingComponent />;
        if (!loadash.isEmpty(user))
          return (
            <Layout {...props} {...cProps}>
              {isUserAllowed(route, user, wedding)
                ? <Component {...props} {...cProps} />
                : (route.path == '/' && accessReserve(user, wedding)
                  ? <Redirect to={"/home"} />
                  : <Route component={NotFoundPage} />)
              }
            </Layout>
          );
        return <Redirect to={"/login"} />;
      }}
    />
  );
};

const mapStateToProps = state => ({
  user: state.user,
  ready: state.info.ready,
  wedding: state.wedding.wedding,
});

export default connect(mapStateToProps)(AuthenticatedRoute);
