import styled from "styled-components";

// TODO: add styles for H1, H2, H3, p, a
export const StyledDraftJS = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: left;
  white-space: pre-line;
`;
