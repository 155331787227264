import React from 'react'

const Add = ({ fill }) => {
  return (
    <svg width="19px" height="20px" viewBox="0 0 19 20">
      <g id="Componentes-e-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="0.65">
        <g id="Assets" transform="translate(-666.000000, -4178.000000)">
          <g id="Flutuante-Copy" transform="translate(640.000000, 4152.000000)">
            <g id="Group-54" transform="translate(24.000000, 24.000000)">
              <rect id="Rectangle-36" x="0" y="0" width="24" height="24"></rect>
              <g id="plus" transform="translate(2.909091, 2.909091)" fill={ fill } fillRule="nonzero">
                <path d="M17.0233378,8.21496212 L9.68151914,8.21496212 L9.68151914,0.864109848 C9.68151914,0.392992424 9.31039424,0.0118371212 8.85167464,0.0118371212 L8.85167464,0.0118371212 C8.39295504,0.0118371212 8.02183014,0.392992424 8.02183014,0.864109848 L8.02183014,8.21496212 L0.841370116,8.21496212 C0.382650518,8.21496212 0.011525618,8.59611742 0.011525618,9.06723485 L0.011525618,9.06723485 C0.011525618,9.53835227 0.382650518,9.91950758 0.841370116,9.91950758 L8.02183014,9.91950758 L8.02183014,17.2940341 C8.02183014,17.7651515 8.39295504,18.1463068 8.85167464,18.1463068 L8.85167464,18.1463068 C9.31039424,18.1463068 9.68151914,17.7651515 9.68151914,17.2940341 L9.68151914,9.91950758 L17.0233378,9.91950758 C17.4820574,9.91950758 17.8531823,9.53835227 17.8531823,9.06723485 L17.8531823,9.06723485 C17.8531823,8.59611742 17.4820574,8.21496212 17.0233378,8.21496212 Z" id="Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Add