import TextInput from "../../components/inputs/TextInput";
import NumberInput from "../../components/inputs/NumberInput";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";
import MaterialNumberInput from "../../components/inputs/MaterialNumberInput";
import SelectInput from "../../components/inputs/SelectInput";
import { getCountryOptions, getCountryTranslation } from "../../infra/services/utils/Countries";

export const brideFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    contract: true,
    name: "fullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "nationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "identification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "zipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "street",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "country",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "personalEmail",
    type: "text",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "personalPhone",
    type: "text",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const groomFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    contract: true,
    name: "fullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "nationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "identification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "zipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "street",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "country",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "personalEmail",
    type: "text",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "personalPhone",
    type: "text",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const contactFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    asterisc: false
  }
];

export const guestFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    asterisc: false,
    disabled: true,
  },
];

export const weddingFields = [
  {
    name: "date",
    type: "text",
    label: "DATE",
    disabled: true,
    component: TextInput
  },
  {
    name: "wedding_place",
    type: "text",
    label: "SPACE",
    disabled: true,
    component: TextInput
  },
  {
    name: "organizer",
    type: "type",
    label: "ORGANIZER",
    disabled: true,
    component: TextInput
  },
  {
    name: "coordinator",
    type: "text",
    label: "COORDINATOR",
    disabled: true,
    component: TextInput
  },
  {
    name: "language",
    type: "select",
    label: "COMMUNICATION_LANGUAGE",
    disabled: false,
    component: SelectLanguageInput
  },
  // {
  //   name: "language",
  //   type: "select",
  //   label: "LANGUAGE_ON_WEDDING_DAY",
  //   disabled: false,
  //   component: SelectLanguageInput,
  //   language: [
  //     {code: 'pt', name: 'Português'},
  //     {code: 'en', name: 'English'},
  //     {code: 'pt_en', name: 'Português e English'},
  //   ]
  // }
];

export const weddingContractBrideFields = [
  {
    name: "brideFullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "brideNationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "brideIdentification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "brideIdentificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "brideStreetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "brideZipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "brideStreet",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true,
    // xs: 24,
    // sm: 24,
    // md: 12,
    // lg: 12,
    // xl: 12,
  },
  {
    name: "brideCountry",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    // xs: 24,
    // sm: 24,
    // md: 12,
    // lg: 12,
    // xl: 12,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "bridePersonalEmail",
    type: "email",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "bridePersonalPhone",
    type: "tel",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  }
];

export const weddingContractGroomFields = [
  {
    name: "groomFullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "groomNationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "groomIdentification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "groomIdentificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "groomStreetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "groomZipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "groomStreet",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true,
    // xs: 24,
    // sm: 24,
    // md: 12,
    // lg: 12,
    // xl: 12,
  },
  {
    name: "groomCountry",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: false,
    asterisc: true,
    // xs: 24,
    // sm: 24,
    // md: 12,
    // lg: 12,
    // xl: 12,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "groomPersonalEmail",
    type: "email",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "groomPersonalPhone",
    type: "tel",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: false,
    asterisc: true
  }
];
