import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH4 } from '../../styles/TextSizes';
import { titleH3 } from '../../styles/TextSizes';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export const TableCollapseContainer = styled(Collapse)`
  &&& {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 0;

    & .ant-collapse-item {
      border-bottom: 2px solid ${p => p.theme.primaryCTA};
    }

    & .ant-collapse-header {
      padding: 0px;
    }

    & .ant-collapse-content {
      border-top: 0;
    }

    @media ${device.tablet} {
      margin-top: 15px;
    }

    @media ${device.tabletS} {
      & .ant-collapse-content-box {
        padding: 5px;
      }
    }
  }
`;

export const TablePanel = styled(Panel)`
  
`;

export const TableLine = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TableColumn = styled(titleH4)`
  flex-basis: 50%;
  text-align: left;
  line-height: 48px;

  &:first-child {
    padding-right: 5px;
  }

  @media ${device.laptop} {
    font-size: 20px;
    line-height: 48px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 40px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
    line-height: 35px;
  }
`;

export const ArrowImg = styled.img`
  transition: transform 0.3s linear;
  transform: translateY(-50%) ${p => p.isActive ? 'rotateZ(-180deg)' : 'rotateZ(0deg)'} !important;
`;

export const DetailBlockContainer = styled.div`
  padding: 10px 20px;
  background-color: ${p => p.theme.thirdColor};
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;

  @media ${device.tabletS} {
    padding: 10px 5px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
`;

export const Question = styled.div`
  color: #000000;
  font-size: ${p => p.small ? '16px' : '20px'};
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 30%;

  @media ${device.laptop} {
    font-size: ${p => p.small ? '15px' : '18px'};
    width: 40%;
  }

  @media ${device.tablet} {
    font-size: ${p => p.small ? '13px' : '15px'};
  }

  @media ${device.tabletS} {
    width: 60%;
  }
`;

export const Answer = styled.div`
  color: #000000;
  font-size: ${p => p.small ? '16px' : '20px'};
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-left: 10px;

  @media ${device.laptop} {
    font-size: ${p => p.small ? '15px' : '18px'};
  }

  @media ${device.tablet} {
    font-size: ${p => p.small ? '13px' : '15px'};
  }
`;

export const Title = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const Subtitle = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const SubtitleValue = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding-left: 35px;

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const SectionTitle = styled(titleH3)`
  text-align: left;
  width: 100%;
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &&& {
    & button {
      overflow: visible;
      padding: 10px 10px;
      margin-left: 5px;

      @media ${device.laptop} {
        & span {
          font-size: 20px;
        }
      }

      @media ${device.tablet} {
        padding: 6px 10px;
        
        & span {
          font-size: 18px;
        }
      }

      @media ${device.tabletS} {
        & span {
          font-size: 16px;
        }
      }
    }
  }
`;

export const BudgetGuestsNote = styled.div`
  padding: 10px 20px;
  background-color: ${p => p.theme.primaryCTA};
  margin-bottom: 25px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;
  color: #000000;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }

  @media ${device.tabletS} {
    padding: 10px 5px;
  }
`;