import React from "react";
import {
  CardContainer,
  CardContent,
  DetailContainer,
  Question,
  Answer
} from "./ChoicesCardStyles";

const TextCard = ({ question, answer }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{question}</Question>
        <DetailContainer>
          {
            answer && <Answer hasImage={false} dangerouslySetInnerHTML={{__html: answer}}/>
          }
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default TextCard;