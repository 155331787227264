import client from "../config/AxiosConfig";

export const Login = async data => client.post("/auth/login", data);

export const Register = async data => client.post("/auth/register", data);

export const RecoverAccount = async data => client.post("/auth/recover", data);

export const ResetPassword = async data => client.post("/auth/reset", data);

export const GuestLogin = async data => client.post("/auth/login-guest", data);
