import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const Container = styled.div`
  flex-basis: 33.333%;
  height: 300px;
  padding: 0 15px;
  margin-bottom: 40px;

  @media ${device.laptopS} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px dashed #666666;
  background-color: #ffffff;
`;

export const Text = styled.span`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  padding: 0px 5px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 30px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 22px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 22px;
  }
`;