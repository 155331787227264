import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  StaffFormContainer,
  ButtonContainer
} from '../decoration/PageDetailStyles';
import Staff from "../../components/staff/Staff";
import BaseButton from "../../components/buttons/BaseButton";
import {categories} from "../../components/staffCategory/Categories";

const EmptyObject = {name: '', category: '', email: '', contact: '', observations: ''};

const StaffExternalForm = ({ fields, addNewStaff, toggleNewStaff, toggleShowNew, showNewCategory, addShowNew, removeShowNew, requiredError, isProcessClosed, translate }) => {
  const list = fields.getAll() ? fields.getAll() : [];

  if(list.length > 0) {
    for(let i = 0; i < list.length; i++) {
      if(
        list[i].category && 
        list[i].category !== translate('CREATE') && 
        !categories['pt'].includes(list[i].category) &&
        !categories['en'].includes(list[i].category) &&
        !categories['fr'].includes(list[i].category) &&
        !categories['es'].includes(list[i].category)
      ) {
        categories['pt'].push(list[i].category);
        categories['en'].push(list[i].category);
        categories['fr'].push(list[i].category);
        categories['es'].push(list[i].category);
      }
    }
  }
  
  //Can only add 1 Staff at the time (add to beggining of array)
  const addNew = () => {
    if(addNewStaff) {
      fields.unshift(EmptyObject)
    }

    toggleNewStaff(false);

    //Add a "false" to the array that controls the visibility of the "Create Category" field
    addShowNew();
  }

  //Cancel add new Staff and remove first element of array
  const cancelAddNew = () => {
    fields.shift();

    toggleNewStaff(true);

    //Removes the first element of the array that controls the visibility of the "Create Category" field
    removeShowNew();
  }

  const removeElement = (index) => {
    fields.splice(index, 1);
  }

  return (
    <StaffFormContainer>
      {
        addNewStaff && !isProcessClosed &&
        <ButtonContainer>
          <BaseButton
            align="initial"
            variant="raised"
            htmlType="button"
            type="primaryMedium"
            onClick={() => addNew()}
            text={translate("ADD_MORE")}
          />
        </ButtonContainer>
      }
      {
        fields.length > 0 &&
        fields.map((field, index) =>
          <div key={index}>
            <Staff
              key={index}
              type={list[index]._id ? 'filled' : 'empty'}
              categories={categories}
              removeElement={removeElement}
              index={index}
              field={field}
              requiredError={requiredError}
              showNewCategory={showNewCategory[index]}
              toggleShowNew={toggleShowNew}
              isProcessClosed={isProcessClosed}
            />
            {
              !list[index]._id &&
              <ButtonContainer>
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="submit"
                  type="primaryMedium"
                  text={translate("SAVE")}
                />
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="button"
                  type="cancelMedium"
                  text={translate("CANCEL")}
                  onClick={() => cancelAddNew()}
                />
              </ButtonContainer>
            }
          </div>
        )
      }
    </StaffFormContainer>
  );
};

export default withLocalize(StaffExternalForm);