import React from 'react'

const Save = ({ fill }) => {
  return (
    <svg width="18px" height="16px" viewBox="0 0 18 16">
      <g id="Componentes-e-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="nonzero">
        <g id="Assets" transform="translate(-400.000000, -1892.000000)">
          <g id="01-Icons" transform="translate(78.000000, 101.000000)">
            <g id="Outros-icons" transform="translate(33.000000, 1715.000000)">
              <g id="setalonga_direita" transform="translate(286.000000, 72.000000)">
                <g id="arrowright" transform="translate(2.953125, 4.312500)" fill={fill} fillRule="evenodd">
                  <path d="M0 1.5C0 0.671573 0.671573 0 1.5 0H10.0858C10.4836 0 10.8651 0.158035 11.1464 0.43934L14.5607 3.85355C14.842 4.13486 15 4.51639 15 4.91421V13.5C15 14.3284 14.3284 15 13.5 15H11V11.5C11 10.6716 10.3284 10 9.5 10H5.5C4.67157 10 4 10.6716 4 11.5V15H1.5C0.671573 15 0 14.3284 0 13.5V1.5Z" id="shape" />
                  <path d="M5 15H10V11.5C10 11.2239 9.77614 11 9.5 11H5.5C5.22386 11 5 11.2239 5 11.5V15Z" id="shape" />
                  {/* <line x1="3" y1="5" x2="10" y2="5" id="shape" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></line> */}
                  {/* <circle cx="8" cy="10" r="2" stroke="#000000" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" id="shape" /> */}
                </g>
                <rect id="Rectangle-37-Copy" x="0" y="0" width="24" height="24"></rect>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Save