import React, { Fragment } from "react";
import {
  BudgetSectionContainer,
  Title,
  Section,
  Description,
  Amount,
  Date,
  TitleWithOptions
} from "./BudgetSectionStyles";
import { Field } from "redux-form";
import FilesInput from "../inputs/FilesInput";
import { ExtraPill, FileSection, ResponsePill } from "../inputs/InputStyles";
import { Icon, Popconfirm } from "antd";
import Dropzone from "react-dropzone";
import FileInput from "../inputs/FileInput";
import { OptionButton } from "../../pages/budget/BudgetStyles";

const BudgetSection = ({ title, section, text, amount, date, type, document, translate, onDelete, onOpenView, billing }) => {
  return (
    <Fragment>
      <BudgetSectionContainer>
        {
          title && type != 'PAYMENT_PROOF' && <Title>{title}</Title>
        }
        {
          billing && billing?.fullName && billing?.identificationNumber && type === 'PAYMENT_PROOF' && <TitleWithOptions>
            <Title>{billing?.fullName} - {billing?.identificationNumber}</Title>
            <Icon type="eye" onClick={onOpenView}/>
            {/* <Popconfirm
              title={translate('CONFIRM_PAYMENT_PROOF_REMOVE')}
              onConfirm={onDelete}>
              <Icon type="delete" />
            </Popconfirm> */}
            </TitleWithOptions>
        }
        {
          section && <Section>{section}</Section>
        }
        {
          text && <Description dangerouslySetInnerHTML={{ __html: text }} />
        }
        {
          amount && type != 'PAYMENT_PROOF' && <Amount>{amount}</Amount>
        }
        {
          type === 'PAYMENT_PROOF' && <FileInput translate={translate} filePreview={true} addFile={false} input={{ value: document, onChange: null }}></FileInput>
        }
        {
          date && <Date>{date}</Date>
        }
      </BudgetSectionContainer>
    </Fragment>
  );
}

export default BudgetSection;