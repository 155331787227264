import React from "react";
import { withLocalize } from "react-localize-redux";
import { 
  BillingFormContainer, 
  ButtonContainer 
} from './AboutUsStyles';
import BaseButton from "../../components/buttons/BaseButton";
import Billing from "../../components/billing/Billing";

const EmptyObject = {nif: '', name: '', address: '', observations: '', new: true};

const BillingForm = ({ fields, addNewBilling, toggleNewBilling, isProcessClosed, translate }) => {
  const list = fields.getAll() ? fields.getAll() : [];

  //Can only add 1 record at the time (add to beggining of array)
  const addNew = () => {
    if(addNewBilling) {
      fields.unshift(EmptyObject)
    }

    toggleNewBilling(false);
  }

  //Cancel add new record and remove first element of array
  const cancelAddNew = () => {
    fields.shift();

    toggleNewBilling(true);
  }

  const removeElement = (index) => {
    fields.splice(index, 1);
  }

  return (
    <BillingFormContainer>
      {
        addNewBilling && !isProcessClosed &&
        <ButtonContainer>
          <BaseButton
            align="initial"
            variant="raised"
            htmlType="button"
            type="primaryMedium"
            onClick={() => addNew()}
            text={translate("ADD_MORE_BILLING")}
          />
        </ButtonContainer>
      }
      {
        fields.length > 0 &&
        fields.map((field, index) =>
          <div key={index}>
            <Billing
              key={index}
              type={list[index].new ? 'empty' : 'filled'}
              removeElement={removeElement}
              index={index}
              field={field}
              isProcessClosed={isProcessClosed}
            />
            {
              list[index].new &&
              <ButtonContainer>
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="submit"
                  type="primaryMedium"
                  text={translate("SAVE")}
                />
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="button"
                  type="cancelMedium"
                  text={translate("CANCEL")}
                  onClick={() => cancelAddNew()}
                />
              </ButtonContainer>
            }
          </div>
        )
      }
    </BillingFormContainer>
  );
};

export default withLocalize(BillingForm);