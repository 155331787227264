import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form, Card } from "antd";
import { device } from "../../styles/Responsive";

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoginForm = styled(Form)`
  display: flex;
  justify-content: center;
`;

export const LoginCard = styled(Card)`
  &&& {
    max-width: 530px;
    padding: 30px;
    display: inline-block;
    border: 0;

    & .ant-card-body {
      padding: 0;
    }

    @media ${device.mobileS} {
      padding: 15px;
    }
  }
`;

export const LogoSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 52px;

  @media ${device.mobileL} {
    margin-bottom: 22px;
  }
`;

export const LogoImg = styled.img`
  width: 100%;
  max-width: 301px;
`;

export const LoginButtonDiv = styled.div`
  text-align: center;
  margin-top: 93px;

  @media ${device.mobileL} {
    margin-top: 50px;
  }
`;

export const FormTitle = styled.div`
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
`;

export const BaseLink = styled(Link)`
  margin-top: 10px;
  display: block;
  text-align: right;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;

  &:hover {
    color: #000000;
    opacity: 0.6;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;

export const TemporaryContainer = styled.div`
  background-color: #faf9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
`;

export const TemporaryText = styled.span`
  text-align: left;
  font-family: font-family: 'Abel',  'sans-serif';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  div{
    margin-top: 24px;
  }
`;

export const TermsLink = styled.div`
  margin-top: 10px;
  display: block;
  text-align: left;
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #000000;
    opacity: 0.6;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }

  @media ${device.mobileS} {
    font-size: 12px;
  }
`;
