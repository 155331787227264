export const categories = [
  {
    name: {
      pt: "Cerimónia Religiosa",
      en: "Religious Cerimony",
      fr: "Cérémonie Religieuse",
      es: "Ceremonia Religiosa"
    },
    question: {
      id: '5e2980c4f980c67ce59d8072',
      type: 'chapter',
      section: 'ceremony'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Cerimónia na Quinta",
      en: "Cerimony at Wedding Place",
      fr: "Cérémonie sur le lieu du mariage",
      es: "Ceremonia en el lugar de la boda"
    },
    question: {
      id: '5e2980c4f980c67ce59d8072',
      type: 'chapter',
      section: 'ceremony'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Saída da Igreja",
      en: "Exit from the Church",
      fr: "Sortir de 'église",
      es: "Salir de la Iglesia"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: false
  },
  {
    name: {
      pt: "Chegada à Quinta",
      en: "Arrival at Wedding Place",
      fr: "Arrivée sur le lieu du mariage",
      es: "Llegada a Quinta"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Início dos Aperitivos",
      en: "Beginning of appetizers",
      fr: "Début des apéritifs",
      es: "Comienzo de aperitivos"
    },
    question: {
      id: '5e1e55866c3074ec5f29e568',
      type: 'question',
      section: 'food'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Fotos com os Convidados",
      en: "Photos with guests",
      fr: "Photos avec des invités",
      es: "Fotos con invitados"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: false
  },
  {
    name: {
      pt: "Sunset",
      en: "Sunset",
      fr: "Sunset",
      es: "Sunset"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: false
  },
  {
    name: {
      pt: "Lançamento do Ramo",
      en: "Bouquet Launch",
      fr: "Lancement du Bouquet",
      es: "Lanzamiento de Ramo"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: false
  },
  {
    name: {
      pt: "Entrada na Sala de Jantar",
      en: "Entrance to the dining room",
      fr: "Entrée de la salle à manger",
      es: "Entrada al comedor"
    },
    question: {
      id: '5e171e77fe9a64658f951372',
      type: 'chapter',
      section: 'food'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Fim de Jantar e Início Buffets (doces, outros ...)",
      en: "End of Dinner and Start of Buffets (sweets, others, ...)",
      fr: "Fin du Dîner et Début des Buffets (bonbons, autres, ...)",
      es: "Fin de la Cena y Comienzo de los Buffets (dulces, otros, ...)"
    },
    question: {
      id: '5e358dd3c51c94faab29de3a',
      type: 'question',
      section: 'food'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Abertura do Baile",
      en: "Opening of the Ball",
      fr: "Ouverture du bal",
      es: "Apertura de la Pelota"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Cerimónia do Bolo dos Noivos",
      en: "Wedding Cake Ceremony",
      fr: "Cérémonie de Gâteau des Mariés",
      es: "Ceremonia de Pastel de Novios"
    },
    question: {
      id: '5e1e5ea16c30744d0929e57a',
      type: 'chapter',
      section: 'food'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Serviço: Ceia",
      en: "Service: Supper",
      fr: "Service: Souper",
      es: "Servicio: Cena"
    },
    question: {
      id: '5e1ecc966c3074ae7229e586',
      type: 'chapter',
      section: 'food'
    },
    fixed: true,
    important: true
  },
  {
    name: {
      pt: "Fim do Evento",
      en: "End of Event",
      fr: "Fin de l'événement",
      es: "Fin de Evento"
    },
    question: {
      id: '',
      type: '',
      section: ''
    },
    fixed: true,
    important: true
  }
];

export const addNewMoment = (categories, moment) => {
  categories.push(
    {
      name: {
        pt: moment,
        en: moment,
        fr: moment,
        es: moment
      },
      question: {
        id: '',
        type: '',
        section: ''
      },
      fixed: false,
      important: false
    }
  );

  return categories;
}

export const checkMomentExists = (categories, moment) => {
  let exists = false;
  
  for(let i = 0; i < categories.length; i++) {
    if(
      categories[i].name.pt === moment || 
      categories[i].name.en === moment ||
      categories[i].name.fr === moment ||
      categories[i].name.es === moment
    ) {
      exists = true;
      break;
    }
  }

  return exists;
}

//Only for fixed Moments
export const findMoment = (moment) => {
  let found = undefined;

  for(let i = 0; i < categories.length; i++) {
    if(
      (categories[i].name.pt === moment || 
      categories[i].name.en === moment ||
      categories[i].name.fr === moment ||
      categories[i].name.es === moment) &&
      categories[i].fixed
    ) {
      found = categories[i];
      break;
    }
  }

  return found;
}