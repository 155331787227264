import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { TextAreaDiv, TextAreaLabel, TextAreaInputField, TextAreaLabelRow, CheckboxContainer, InputLabelDiv } from "./InputStyles";
import CheckboxInput from "./CheckboxInput";

const TextAreaInput = ({
  input,
  meta,
  prefix,
  size,
  suffix,
  onPressEnter,
  label,
  placeholder,
  minRows,
  maxRows,
  hasCheckbox,
  onClick,
  readOnly = true,
  inForm = false,
  disabled = false,
  fillError = false
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  return (
    <TextAreaDiv inForm={inForm}>
      {label && <TextAreaLabelRow inForm={inForm}>
        {
          hasCheckbox ?
            <CheckboxContainer>
              <Field
                component={CheckboxInput}
                checkboxType="round"
                checked={input.value ? true : false}
                name={`question_obs`}
                type="checkbox"
              />
            </CheckboxContainer>
            : null
        }
        {inForm
          ? <InputLabelDiv>{label}</InputLabelDiv>
          : <TextAreaLabel hasCheckbox={hasCheckbox}>{label}</TextAreaLabel>}
      </TextAreaLabelRow>}
      <TextAreaInputField
        prefix={prefix}
        size={size}
        suffix={suffix}
        value={input.value}
        onChange={input.onChange}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        autoSize={{
          minRows: minRows ? minRows : 3,
          maxRows: maxRows ? maxRows : undefined
        }}
        error={showError ? 1 : 0}
        fillError={fillError && showError ? 1 : 0}
        readOnly={readOnly}
        disabled={disabled}
        onClick={onClick}
      />
    </TextAreaDiv>
  );
};

TextAreaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  onPressEnter: PropTypes.func,
  hasCheckbox: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool
};

export default TextAreaInput;
