import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

export const NewsContainer = styled.div`
  flex-basis: 50%;
  padding: 0 15px;
  margin-bottom: 60px;

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const NewsContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
  transition: box-shadow 0.5s linear;
  
  &:hover {
    box-shadow: 8px 0 12px -4px ${p => p.theme.secondaryCTA}, -8px 0 12px -4px ${p => p.theme.secondaryCTA};
  }
`;

export const BgContainer = styled.div`
  width: 100%;
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.inputBorderColour};
  padding: 10px 5px;
`;

export const Group = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  height: 31px;
  overflow: hidden;
  text-align: left;
  padding-right: 5px;

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }

  @media ${device.mobileL} {
    font-size: 14px;
  }
`;

export const Arrow = styled.img`
  width: 24px;
  transform: rotateZ(180deg);

  @media ${device.laptopL} {
    width: 20px;
  }
`;

export const Title = styled(titleH5)`
  line-height: 29px;
  text-align: left;
  height: 85px;
  overflow: hidden;
  padding: 0px 5px;
  margin-top: 10px;
  margin-bottom: 20px;

  @media ${device.laptop} {
    font-size: 22px;
  }

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 29px;
  }

  @media ${device.mobileL} {
    font-size: 18px;
    line-height: 24px;
    height: 70px;
  }
`;

export const Date = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 0px 5px;
  padding-bottom: 10px;

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.mobileL} {
    font-size: 13px;
  }
`;