import React from "react";
import { withLocalize } from 'react-localize-redux';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import {
  GraphContainer,
  GraphContent,
  LabelsContainer,
  Label,
  LabelColor,
  LabelName,
  CenterLabel,
  CenterLabelText,
  TooltipContent,
  TooltipText
} from "./BudgetGraphStyles";
import { numberFormat } from '../../infra/services/utils/FormatNumber';

const BudgetGraph = ({ title, data, translate }) => {
  const renderTooltip = ({ active, payload }) => {
    if(active) {
      return (
        <TooltipContent>
          <TooltipText>
            {`${translate(payload[0].name)}: ${numberFormat(payload[0].value, 2, ',', '.')}€`}
          </TooltipText>
        </TooltipContent>
      );
    }
  
    return null;
  };

  return (
    <GraphContainer>
      <GraphContent>
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie 
              dataKey="value"
              data={data.filter(elem => elem.value > 0)} 
              outerRadius={100}
              paddingAngle={1}
            >
              {
                data.filter(elem => elem.value > 0).map((entry, index) => <Cell key={index} fill={entry.color} />)
              }
            </Pie>
            <Tooltip content={renderTooltip} />
          </PieChart>
        </ResponsiveContainer>
        <CenterLabel>
          <CenterLabelText>{title}</CenterLabelText>
        </CenterLabel>
      </GraphContent>
      <LabelsContainer>
        {
          data.map((label, index) => 
            <Label key={index}>
              <LabelColor color={label.color}/>
              <LabelName>{translate(label.name)}</LabelName>
            </Label>
          )
        }
      </LabelsContainer>
    </GraphContainer>
  );
}

export default withLocalize(BudgetGraph);