export const PrimaryColour = "#9AA89A";
export const SecondaryColour = "#DFE0DF";
export const ThirdColour = "#FAF9F9";
export const PrimaryCTA = "#CACACA";
export const SecondaryCTA = "#A5A5A5";
export const AlertColour = "#DE8F8F";
export const InputBorderColour = "#979797";
export const PrimaryBorderColor = "#F3F3F3";
export const SecondaryBorderColor = "#718271";
export const MenuBorderColor = "#E7E7E7";
export const LightPrimary = '#9AA89A';
export const SectionColor = '#DECBB3';

export const SuccessColour = "#50bd3d";
export const WarningColour = "#FFDEB8";
export const ErrorColor = '#db7b80';
export const InfoColor = '#92a0a7';

export const GoldColor = '#BC9222';

export const GraphColors = {
    paid: PrimaryColour,
    missing: '#ECE8E2',
    base: PrimaryColour,
    roomPlan: '#C3CEC0',
    ceremony: '#9AC1C6',
    staff: '#DCCDA8',
    food: '#DADADA',
    drink: '#BCA9A9',
    decor: '#D2B2D7',
    party: '#FACCCC',
    upgrade: '#ADAFD2',
    expense: '#D99B9B',
}