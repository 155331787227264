import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { Form } from "antd";
import { titleH3, titleH4 } from '../../styles/TextSizes';

export const DetailContainer = styled.div`
  width: 100%;
  max-width: 970px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  margin-bottom: 130px;
  padding: 0 10px;
`;

export const Description = styled.div`
  text-align: left;
  padding: 0px 15px;
  margin-bottom: 50px;
  white-space: pre-line;
  color: #000000;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  & > div {
    padding: 0px 15px;
    max-width: ${p => 100 / p.total}%;
  }
`;

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const Subtitle = styled(titleH3)`
  text-align: left;
  padding: 0px 15px;
  margin-bottom: 40px;
  color: #000000;
`;

export const CardsContainer = styled.div`
  max-width: 970px;
  margin: auto;
  background-color: ${p => p.theme.thirdColor};
  margin: 0px 15px;
  padding: 30px 0px;
`;

export const CardsTitle = styled(titleH4)`
  line-height: 29px;
  width: 100%;
  text-align: center;
  padding-bottom: 30px;
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0px -15px;
`;

export const EmptyRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const FiltersRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-flow: row wrap;
  padding: 50px 0px;
`;

export const Filter = styled.div`
  flex-basis: 33.333%;
  padding: 0px 15px;

  @media ${device.laptopS} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
`;

export const OurStyleContainer = styled.div`
  max-width: 970px;
  margin: auto;
`;

export const NoResults = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  padding: 0px 15px;
  opacity: 0.6;
  text-align: left;
`;