import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { InputDiv, InputLabelDiv, DateInputField } from './InputStyles';
import moment from 'moment';

const XpertGoYearInput = ({
  input,
  meta,
  label,
  first,
  placeholder,
  disabled,
  allowClear
}) => {
  const [opened, setOpened] = useState(false);
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed ? 1 : 0;

  return (
    <InputDiv first={first || !label}>
      {label ? <InputLabelDiv>{label}</InputLabelDiv> : null}
      <DateInputField
        disabled={disabled}
        error={showError}
        placeholder={placeholder}
        format={'YYYY'}
        open={opened}
        value={input.value ? moment.utc(input.value, 'YYYY') : null}
        onOpenChange={(status) => {
          if (status) {
            setOpened(true);
          } else {
            setOpened(false);
          }
        }}
        onPanelChange={date => {
          setOpened(false);
          input.onChange(
            moment(date)
              .utc(true)
              .format('YYYY')
          );
        }}
        onChange={(date, dateString) => {
          setOpened(false);
          date ? input.onChange(moment(date).utc(true).format('YYYY')) : input.onChange('');
        }}
        mode="year"
        allowClear={allowClear}
      />
    </InputDiv>
  );
};

XpertGoYearInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default XpertGoYearInput;
