import PropTypes from 'prop-types';
import React from 'react';
import { InputDiv, InputLabelDiv, RadioGroup } from './InputStyles';

const RadioButtons = ({ label, input, first, meta, options, disabled }) => {
  return (
    <InputDiv first={first}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <RadioGroup
        disabled={disabled}
        options={options}
        onChange={input.onChange}
        value={input.value}
      />
    </InputDiv>
  );
};

RadioButtons.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired
};

export default RadioButtons;