import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2, titleH4 } from '../../styles/TextSizes';

export const NewsContainer = styled.div`
  max-width: 970px;
  margin: auto;
  padding: 90px 15px;

  @media ${device.tablet} {
    padding: 50px 15px;
  }
`;

export const Title = styled(titleH2)`
  text-align: left;
  margin-bottom: 40px;
  color: #000000;

  @media ${device.tablet} {
    margin-bottom: 30px;
  }
`;

export const Description = styled(titleH4)`
  text-align: left;
  line-height: 29px;
  margin-bottom: 70px;
  white-space: pre-line;
  color: #000000;

  @media ${device.tablet} {
    margin-bottom: 50px;
  }
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0px -15px;
`;

export const NoResults = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  padding: 0px 15px;
  opacity: 0.6;
  text-align: left;
`;