import React from "react";
import {
  CardContainer,
  CardContent,
  BgColorContainer,
  BgColor,
  BgImage,
  DetailContainer,
  Question,
  Answer
} from "./ChoicesCardStyles";

const ColorCard = ({ question, answer, image, bgColor }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{question}</Question>
        <DetailContainer>
          {
            (image || bgColor) &&
            <BgColorContainer>
              {
                bgColor ?
                <BgColor color={bgColor}/>
                :
                <BgImage src={image} />
              }
            </BgColorContainer>
          }
          {
            answer && <Answer hasImage={true} dangerouslySetInnerHTML={{__html: answer}}/>
          }
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default ColorCard;