export const CostTypes = {
  TABLE: 'TABLE',
  GUEST: 'GUEST',
  PEOPLE: 'PEOPLE',
  GLOBAL: 'GLOBAL',
  QUANTITY: 'QUANTITY'
};

export const GetPriceTag = (tag, translate) => {
  if (tag === CostTypes.TABLE) return `/${translate("QUANTITY_TABLE")}`;
  if (tag === CostTypes.GUEST) return '/pax';
  if (tag === CostTypes.PEOPLE) return `/${translate('QUANTITY_PEOPLE')}`;
  if (tag === CostTypes.QUANTITY) return '/un';
  if (tag === CostTypes.GLOBAL) return '';
  return undefined;
};

//Check if a option still exists in the question
export const checkOption = (value, question, weddingPlace) => {
  let res = false;

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (
    question &&
    (question.type === 4 || question.type === 5 || question.type === 6) &&
    question.options &&
    question.options.length > 0 &&
    question.options.find(x => x._id === id && (!x.local || x.local === weddingPlace))) {
    res = true;
  }
  else if (question && question.type === 10 && (id === 'true' || id === 'false')) {
    res = true;
  }

  return res;
}

//Check if a food option still exists in the question
//We pass directly the Food group (meat, fish, etc...)
export const checkFoodOption = (value, food) => {
  let res = false;

  let id = value;
  if (value.id) {
    id = value.id;
  }

  if (food && food.length > 0 && food.find(x => x._id === id)) {
    res = true;
  }

  return res;
}

export const checkCoupleTableAnswer = (answer, question, weddingPlace) => {
  const newTable = answer?.response?.length > 0
    ? answer?.response.find(resp => resp?.id && checkOption(resp, question, weddingPlace))
    : null;

  const data = {
    newTable: newTable?.id
  };

  return data;
}

export const isFoodQuestion = (type) => {
  return type === 12 ||
    type === 13 ||
    type === 14 ||
    type === 15 ||
    type === 16 ||
    type === 17
    ? true
    : false;
}

const foodType = type => {
  switch (type) {
    case 12:
      return 'entrees';
    case 13:
      return 'meat';
    case 14:
      return 'fish';
    case 15:
      return 'dessert';
    case 16:
      return 'cake';
    case 17:
      return 'supper';

    default:
      return 'entrees';
  }
};

export const removeNotExistingOptions = (answer, question, weddingPlace, food) => {
  const fixedAnswer = { ...answer };
  const response = Array?.isArray(fixedAnswer?.response)
    ? fixedAnswer?.response?.length > 0
      ? fixedAnswer?.response.filter(resp => {
        return isFoodQuestion(question?.type)
          ? resp?.id && checkFoodOption(resp, food[foodType(question?.type)])
          : resp?.id && checkOption(resp, question, weddingPlace) ? true : false;
      })
      : []
    : fixedAnswer?.response;

  fixedAnswer.response = response;
  return fixedAnswer;
}