import { findPageByTag } from './PageInfo';

const findAnswers = (section) => {
  let questions = 0, answers = 0;

  if(section && section.chapters?.length > 0) {
    for(let i = 0; i < section.chapters.length; i++) {
      let chapter = section.chapters[i];
      
      if(chapter && chapter.questions.length > 0) {
        for(let j = 0; j < chapter.questions.length; j++) {
          let question = chapter.questions[j];
          
          //Type "Information" has no answer, so we don't count those
          if(question.type !== 9) {
            questions++;

            if(question.answer?.response) {
              answers++;
            }
          }
        }
      }
    }
  }

  return {'questions': questions, 'answers': answers};
}

export const getSectionStatus = (sections, section) => {
  const page = findPageByTag(sections, section);
  const { questions, answers } = findAnswers(page);

  return {'questions': questions, 'answers': answers};
};
