import {
  PrimaryColour,
  SecondaryColour,
  ThirdColour,
  PrimaryCTA,
  SecondaryCTA,
  AlertColour,
  InputBorderColour,
  PrimaryBorderColor,
  SecondaryBorderColor,
  MenuBorderColor,
  LightPrimary,
  SuccessColour,
  WarningColour,
  ErrorColor,
  InfoColor,
  GoldColor
} from './Colours'

export default {
  //colors
  primaryColor: PrimaryColour,
  secondaryColor: SecondaryColour,
  thirdColor: ThirdColour,
  primaryCTA: PrimaryCTA,
  secondaryCTA: SecondaryCTA,

  inputBorderColor: InputBorderColour,
  inputFocusColor: InputBorderColour,
  inputErrorColor: AlertColour,
  inputErrorFocusColor: '#d65151',
  inputErrorBorderColor: '#d43e3e',

  primaryBorderColor: PrimaryBorderColor,
  secondaryBorderColor: SecondaryBorderColor,
  menuBorderColor: MenuBorderColor,

  primaryBackgroundColor: "#ffffff",
  secondaryBackgroundColor: "#f3f3f3",

  successColor: SuccessColour,
  warmingColor: WarningColour,
  errorColor: ErrorColor,
  infoColor: InfoColor,

  goldColor: GoldColor,
  staffColor: '#718271',
  coupleTableColor: '#FBF6EA',
  staffTableColor: '#D3DFDE',
  disabledTableColor: '#fafafa', 
  tableColor: '#F5F5F5',

  bgColor: '#d0ab499e',

  primaryLighter: LightPrimary,

  //text size
  titleSize: "30px",
  sectionSize: "24px",
  textSize: "14px",
  textColor: '#718271'
};
