import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import OptionCard from "../optionCard/OptionCard";
import ColorCard from "../optionCard/ColorCard";
import TextCard from "../optionCard/TextCard";
import FoodCard from "../optionCard/FoodCard";
import ListCard from "../optionCard/ListCard";
import DecorationFileSection from "../../components/decorationFileSection/DecorationFileSection";
import { CardsContainer, CardsRow, ListContainer } from "./ManageQuestionTypeStyles";
import TableThemeCard from "../optionCard/TableThemeCard";

const ManageQuestionType = ({ input, meta, question, wedding, colors, food, canEditSection, isProcessClosed, inModal = false, translate }) => {
  // console.warn('question', question);

  switch (question.type) {
    case 1: {
      if (question?.isTableTheme) {
        return <TableThemeCard
          input={input}
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
          translate={translate} />
      } else {
        return (
          <TextCard
            component='Text'
            type='text'
            canEditSection={canEditSection}
            isProcessClosed={isProcessClosed}
          />
        );
      }
    }
    case 2:
      return (
        <TextCard
          component='TextArea'
          type='text'
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
        />
      );
    case 3:
      return (
        <TextCard
          component='Text'
          type='number'
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
        />
      );
    case 4:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer inModal={inModal}>
          <CardsRow>
            {
              question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) =>
                <OptionCard
                  key={index}
                  index={question.options?.filter(option => !option.local || option.local === wedding.wedding_place).length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  inModal={inModal}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 5:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) =>
                <OptionCard
                  key={index}
                  index={question.options?.filter(option => !option.local || option.local === wedding.wedding_place).length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 6:
      return (
        <ListContainer>
          {
            question.options?.filter(option => !option.local || option.local === wedding.wedding_place).map((elem, index) =>
              <ListCard
                key={index}
                type={1}
                record={elem}
                input={input}
                canEditSection={canEditSection}
                isProcessClosed={isProcessClosed}
                question={question}
              />
            )
          }
        </ListContainer>
      );
    case 7:
      return (
        <DecorationFileSection
          file={true}
          title=""
          description=""
          input={input}
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
        />
      );
    case 8:
      //"Bolo dos Noivos" needs to have crop when uploading image
      return (
        <DecorationFileSection
          file={true}
          title=""
          description=""
          input={input}
          accept="image/jpeg, image/png"
          canEditSection={canEditSection}
          isProcessClosed={isProcessClosed}
          hasCrop={question._id === '5e6f5699cac5db4fce6b1f40' ? true : false}
        />
      );
    case 10:
      return (
        <ListContainer>
          <ListCard
            input={input}
            type={2}
            record="true"
            text={translate('CONFIRM_YES')}
            canEditSection={canEditSection}
            isProcessClosed={isProcessClosed}
            question={question}
          />
          <ListCard
            input={input}
            type={2}
            record="false"
            text={translate('CONFIRM_NO')}
            canEditSection={canEditSection}
            isProcessClosed={isProcessClosed}
            question={question}
          />
        </ListContainer>
      );
    case 11:
      return (
        <CardsContainer>
          <CardsRow>
            {
              colors?.map((elem, index) =>
                <ColorCard
                  key={index}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 12:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.entrees?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.entrees.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.entrees}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 13:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.meat?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.meat.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.meat}
                  sidedishes={food.sidedishes}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 14:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.fish?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.fish.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.fish}
                  sidedishes={food.sidedishes}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 15:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.dessert?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.dessert.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.dessert}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 16:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.cake?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.cake.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.cake}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    case 17:
      //The index has to be a decreasing number starting from the number of existing elements all the way to 0, but the minimum has to be 3 
      return (
        <CardsContainer>
          <CardsRow>
            {
              food.supper?.map((elem, index) =>
                <FoodCard
                  key={index}
                  index={food.supper.length - index + 2}
                  question={question}
                  record={elem}
                  input={input}
                  canEditSection={canEditSection}
                  isProcessClosed={isProcessClosed}
                  options={food.supper}
                />
              )
            }
          </CardsRow>
        </CardsContainer>
      );
    default:
      return null;
  }
};

ManageQuestionType.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  colors: state.info.colours,
  food: state.info.food
});

export default connect(mapStateToProps)(withLocalize(ManageQuestionType));