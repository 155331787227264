import React from "react";
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import { Field, formValueSelector } from "redux-form";
import { withLocalize } from "react-localize-redux";
import TextInput from "../../components/inputs/TextInput";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import {
  QuestionTitleGuests,
  FieldContainer,
  QuestionDescription
} from '../decoration/PageDetailStyles';

const calculateNumberTables = (number, translate) => {
  if(number && parseInt(number) > 0) {
    const tables = Math.ceil(number / 10);
  
    return `${tables} ${translate('TABLES')}`;
  }
  
  return '';
}

const GuestsProvisionalListForm = ({ input, meta, questions, weddingInfo, provisoryGuests, isProcessClosed, translate, activeLanguage }) => {
  const renderQuestion = (question) => {
    return (
      <FieldContainer key={question._id}>
        <QuestionTitleGuests>{question.title[activeLanguage?.code]}</QuestionTitleGuests>
        {
          question.description &&
          <QuestionDescription dangerouslySetInnerHTML={{__html: question.description[activeLanguage?.code]}}/>
        }
        { 
          //Minimum number of Guests
          question._id === '5e2adf6947f38787fd9e8426' ?
          <TextInput
            meta={{}}
            input={{value: `${weddingInfo.min_people} ${translate('GUESTS')}`, onChange: () => null}} 
            type="text"
            disabled={true}
            tag="GUESTS"
          />
          :
          //Predicted number of Guests
          question._id === '5e2adf9947f38787fd9e8427' ?
          <Field
            component={TextInput}
            type="number"
            placeholder={translate('GUESTS')}
            name="provisory_guests"
            disabled={isProcessClosed}
          />
          :
          //Guests location
          question._id === '5e2adfbf47f38787fd9e8428' ?
          <Field
            component={TextAreaInput}
            placeholder={translate('MESSAGE_WD')}
            name="guests_location"
            readOnly={isProcessClosed}
          />
          :
          //Estimated number of tables
          question._id === '5e2adfea47f38787fd9e8429' ?
          <TextInput
            meta={{}}
            input={{value: calculateNumberTables(provisoryGuests, translate), onChange: () => null}} 
            type="text"
            disabled={true}
            tag="GUESTS"
          />
          : null
        }
      </FieldContainer>
    );
  };

  return (
    <React.Fragment>
      { 
        questions.length > 0 && questions.map(question => renderQuestion(question))
      }
    </React.Fragment>
  );
};

const selector = formValueSelector('section_form');

GuestsProvisionalListForm.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  provisoryGuests: selector(state, 'provisory_guests')
});

export default withLocalize(connect(mapStateToProps)(GuestsProvisionalListForm));