import React from "react";
import {
  QuantityContainer,
  ButtonSubtract,
  ButtonAdd,
  QuantityInput
} from "./InputStyles";

const numberRegex = /^[0-9\b]+$/;

const Quantity = ({ optionID, input, isProcessClosed, canEditSection }) => {
  const changeQuantity = (id, type, qtd) => {
    const list = [...input.value];
    const index = list.findIndex(x => x.id === id);

    if(!isProcessClosed && canEditSection && index >= 0) {
      //Subtract
      if(type === 1) {
        list[index] = {'id': id, 'qtd': parseInt(list[index].qtd) - 1 <= 0 ? 1 : parseInt(list[index].qtd) - 1};
      }
      //Add
      else if(type === 2) {
        list[index] = {'id': id, 'qtd': parseInt(list[index].qtd) + 1};
      }
      //Input value directly
      else if(type === 0) {
        if(numberRegex.test(qtd)) {
          list[index] = {'id': id, 'qtd': parseInt(qtd)};
        }
        else if(qtd === '') {
          list[index] = {'id': id, 'qtd': ''};
        }
      }

      input.onChange(list);
    }
  }

  const getQuantity = () => {
    let qtd = 1;

    if(input?.value) {
      let found = input.value.find(x => x.id === optionID);

      if(found?.qtd) {
        qtd = found.qtd;
      }
    }

    return qtd;
  }

  return (
    <QuantityContainer onClick={(e) => e.stopPropagation()}>
      <ButtonSubtract onClick={() => changeQuantity(optionID, 1)}>-</ButtonSubtract>
      <QuantityInput onChange={(e) => changeQuantity(optionID, 0, e.target.value)} value={getQuantity()} readOnly={isProcessClosed || !canEditSection}/>
      <ButtonAdd onClick={() => changeQuantity(optionID, 2)}>+</ButtonAdd>
    </QuantityContainer>
  );
};

export default Quantity;