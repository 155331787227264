import React from "react";
import { 
  SliderContainer,
  SliderItem,
  Arrow
} from "./SliderStyles";
import ArrowLeft from '../../assets/icons/arrow_left.svg';
import ArrowRight from '../../assets/icons/arrow_right.svg';
import ImageComponent from '../../components/images/ImageComponent';

const NextArrow = (props) => {
  const { onClick } = props;

  return (
    <Arrow src={ArrowRight} className='nextArrow' onClick={onClick}/>
  );
}

const PrevArrow = (props) => {
  const { onClick } = props;

  return (
    <Arrow src={ArrowLeft} className='prevArrow' onClick={onClick}/>
  );
}

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: false,
  centerMode: true,
  centerPadding: '10%',
  initialSlide: 1,
  nextArrow: <NextArrow/>,
  prevArrow: <PrevArrow/>,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1,
        centerPadding: '25px'
      }
    }
  ]
};

const Slider = ({ gallery }) => {
  return (
    gallery && gallery.length > 0 &&
    <SliderContainer {...sliderSettings}>
      {
        gallery.map((item, index) => 
          <SliderItem key={index}>
            <ImageComponent ratio={0.6} url={item.url} color={item.color}/>
          </SliderItem>
        )
      }
    </SliderContainer>
  );
}

export default Slider;