import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Spin } from "antd";
import {
  SelectDiv,
  StyledOption,
  ArrowImg,
  StyledOptionContent,
  SelectLabelInput,
  XpertGoStyledSelectInput,
  InputLabelDiv,
  InputDiv,
  XpertGoSelectArrow,
  XpertGoCloseArrow
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import Close from "../../assets/icons/close.svg";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const XpertGoSelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  meta,
  translatable,
  translate,
  allowClear,
  removeSelection,
  tag,
  first,
  noBorder = false,
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = value => {
    input.onChange(value || '');
  };

  return (
    <InputDiv first={first || !label}>
      {label && <InputLabelDiv>{label}</InputLabelDiv>}
      <XpertGoStyledSelectInput
        noBorder={noBorder}
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
        filterOption={filterOption}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        showArrow={false}
        showSearch={false}
        loading={loading}
        error={showError ? 1 : 0}
      >
        {data.map((elem, index) => (
          <StyledOption key={dataKey ? elem[dataKey] : index} disabled={elem.disabled}>
            <StyledOptionContent>
              {dataLabel ? (translatable ? translate(elem[dataLabel]) : elem[dataLabel]) : elem}
            </StyledOptionContent>
          </StyledOption>
        ))}
      </XpertGoStyledSelectInput>
      {
        allowClear && input?.value ?
          <XpertGoCloseArrow onClick={() => changeSelect('')}><ArrowImg src={Close} /></XpertGoCloseArrow>
          :
          <XpertGoSelectArrow><ArrowImg src={ArrowDown} /></XpertGoSelectArrow>
      }
    </InputDiv>
  );
};

XpertGoSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string,
  first: PropTypes.bool,
};

XpertGoSelectInput.defaultProps = {
  data: [],
  dataKey: '_id',
  dataLabel: 'name',
  allowClear: false,
  translatable: true,
  meta: { invalid: false, submitFailed: false }
};

export default withLocalize(XpertGoSelectInput);
