import React from 'react'
import moment from 'moment'
import momentTimezone from "moment-timezone";
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Badge, Button, Col, Dropdown, Icon, Menu, Row, Tooltip } from 'antd'
import CurrencyComponent from '../currency/CurrencyComponent'
import Bookmark from "../../assets/icons/bookmark.svg";
import SelectedBookmark from "../../assets/icons/selected_bookmark.svg";
import Star from "../../assets/icons/star.svg";
import SelectedStar from "../../assets/icons/selected_star.svg";
import { WaitingIcon } from '../inputs/InputStyles'
import { AgendaType, WaitingActionType } from '../../pages/agenda/AgendaPage'
import { WeddingStatus } from '../../pages/reserve/ReservePage'
import { VisitVenuesTypes, VisitsBlockedType, dateTimeFormat } from '../../pages/agenda/VisitAgendaPage'
import { PrimaryColour } from '../../styles/Colours';

function createCalendar(currentDate) {
    if (!currentDate) {
        currentDate = moment()
    } else {
        currentDate = moment(currentDate)
    }

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    const weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day())
            date.calendar = calendar
            week.push(date)
        }
    }

    return calendar
}

function getHolidayName(holiday, activeLanguage) {
    // console.warn('activeLanguage', activeLanguage);
    return activeLanguage.code === 'pt' ? holiday.localName : holiday.name;
}

function CalendarDate(props) {
    // console.warn('Props Date', props);
    const { activeLanguage, translate } = props;
    const { dateToRender, dateOfMonth, events, schedules, selecting, wedding } = props;
    const { reserveView, agendaType } = props;
    const blockedAccess = wedding?.status == WeddingStatus.CONFIRMED
        ? (wedding?.meetingsBlocked || false)
        : wedding?.visitsBlocked !== VisitsBlockedType.NONE;

    const date = moment.utc(dateToRender.toISOString());
    const tmpId = moment.utc(date).format('DDDYYYY');
    const event = events.find(f => f.id === tmpId);
    const schedule = agendaType === AgendaType.VISIT
        ? schedules?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === tmpId)
        : null;
    // const weddingSchedule = event && event.resource && event.resource.weddingSchedule ? event.resource.weddingSchedule : null;

    // const today = dateToRender.format('YYYY-MM-DD') === moment.utc().format('YYYY-MM-DD') ? 'today' : '';
    const selected = (event && event?.resource?.selected) || schedule ? 'selected' : '';

    const style = {};
    if (event?.resource?.holiday || event?.resource?.localHoliday) style.color = PrimaryColour;
    if (event?.resource?.weddings?.length > 0) {
        if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.length > 1) {
            style.background = `linear-gradient(90deg ,${event?.resource?.colors[0]}, ${event?.resource?.colors[1]})`;
        } else if (Array.isArray(event?.resource?.colors) && event?.resource?.colors?.[0]) {
            style.background = `${event?.resource?.colors[0]}`;
        }
    }

    const borderBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0
        ? '1px solid rgb(255 255 255 / 90%)'
        : 'none';

    const marginBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0
        ? 5
        : 0;

    if (dateToRender.month() < dateOfMonth.month() || dateToRender.month() > dateOfMonth.month()) {
        return (<button disabled={true} className="date next-month prev-month"></button>)
    }

    const tooltip = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday, activeLanguage)}
            </p>
        }

        <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {date.isBefore(moment.utc(), 'date')
                ? translate('UNAVAILABLE_DATE')
                : event && event?.title
                    ? translate(event.title)
                    : ''}
        </p>

        {selected && <React.Fragment>
            <p>
                <Icon type="clock-circle" />&nbsp;
                {momentTimezone.utc(schedule.startDate).tz("Europe/Lisbon").format('HH:mm')}
                <Icon type="swap-right" />
                {momentTimezone.utc(schedule.endDate).tz("Europe/Lisbon").format('HH:mm')}
            </p>
            <p>
                <Icon type="environment" />&nbsp;
                {schedule.weddingPlace === 3
                    ? translate(VisitVenuesTypes.BOTH)
                    : schedule.weddingPlace === 2
                        ? translate(VisitVenuesTypes.LAGO)
                        : translate(VisitVenuesTypes.SOLAR)
                }
            </p>
        </React.Fragment>}

        {event && !event.resource.isCompanyRestDay && !selected
            ? <React.Fragment>
                <div className='mobile' style={{ textAlign: 'center', marginTop: '5px' }}>
                    <Button
                        type="primary"
                        loading={selecting}
                        onClick={(e) => !blockedAccess
                            ? props.onClick({ date, event })
                            : e?.preventDefault()}>
                        {translate('SELECT')}
                    </Button>
                </div>
            </React.Fragment>
            : null
        }
    </React.Fragment>

    return (event && event?.resource?.isCompanyRestDay) || date.isBefore(moment.utc(), 'date')
        ? <React.Fragment>
            <Tooltip title={tooltip} trigger='click' className='mobile'>
                <button
                    className={`date in-month disabled`}
                    style={style}>
                    {event && event.resource.isCompanyRestDay
                        ? translate('UNAVAILABLE_DATE_SHORT')
                        : dateToRender.date()
                    }
                </button>
            </Tooltip>
            <Tooltip title={tooltip} trigger='hover' className='desktop'>
                <button
                    className={`date in-month disabled`}
                    style={style}>
                    {event && event.resource.isCompanyRestDay
                        ? translate('UNAVAILABLE_DATE_SHORT')
                        : dateToRender.date()
                    }
                </button>
            </Tooltip>
        </React.Fragment>
        : <React.Fragment>
            <Tooltip
                title={tooltip}
                trigger='click'
                className='mobile'>
                <button
                    className={`date in-month ${selected}`}
                    style={style}
                >
                    {event && event.resource.localHoliday
                        ? translate('LOCAL_HOLIDAY_SHORT')
                        : event && event.resource.holiday
                            ? translate('HOLIDAY_SHORT')
                            : (<b>{dateToRender.date()}</b>)}
                </button>
            </Tooltip>
            <Tooltip
                title={tooltip}
                trigger='hover'
                className='desktop'>
                <button
                    className={`date in-month ${selected}`}
                    style={style}
                    onClick={(e) => !blockedAccess
                        ? props.onClick({ date, event })
                        : e.preventDefault()
                    }>
                    {event && event.resource.localHoliday
                        ? translate('LOCAL_HOLIDAY_SHORT')
                        : event && event.resource.holiday
                            ? translate('HOLIDAY_SHORT')
                            : (<b>{dateToRender.date()}</b>)}
                </button>
            </Tooltip>
        </React.Fragment>;

}
class ThreeMonthsView extends React.Component {
    state = {
        date: moment(),
        events: [],
        schedules: [],
        wedding: null,
        reserveView: true,
        agendaType: AgendaType.AGENDA,
        menuOpen: false,
        months: [],
        activeLanguage: {},
        translate: [],
    }

    componentDidMount() {
        this.setState({
            date: this.props.date,
            events: this.props.events || [],
            schedules: this.props.schedules || [],
            wedding: this.props.wedding,
            reserveView: this.props.reserveView,
            agendaType: this.props.agendaType,
            menuOpen: this.props.menuOpen,
            activeLanguage: this.props.activeLanguage,
            translate: this.props.translate
        }, () => {
            this.setMonthsCalendar();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date) {
            this.setState({ date: this.props.date })
        }

        if (this.props.loading !== prevProps.loading
            || this.props.menuOpen !== prevProps.menuOpen) {
            this.setState({
                events: this.props.events || [],
                schedules: this.props.schedules || [],
                wedding: this.props.wedding,
                reserveView: this.props.reserveView,
                agendaType: this.props.agendaType,
                menuOpen: this.props.menuOpen
            }, () => this.setMonthsCalendar())
        }

        if (this.props.activeLanguage?.code !== prevProps.activeLanguage?.code) {
            this.setState({
                activeLanguage: this.props.activeLanguage,
                translate: this.props.translate
            }, () => this.setMonthsCalendar());
        }
    }

    setMonthsCalendar = () => {
        const { date, events, schedules, activeLanguage } = this.state;
        const months = [];
        moment().locale(activeLanguage ? activeLanguage?.code : 'pt')

        for (let i = 0; i < 3; i++) {
            const startDate = moment.utc(date).add(i, 'month').startOf('month');
            const monthInfo = {
                date: startDate,
                month: startDate.month(),
                calendar: createCalendar(startDate),
                events: events.filter(f => moment.utc(f.start).month() === startDate.month()),
                schedules: schedules && Array.isArray(schedules) && schedules.length > 0
                    ? schedules.filter(f => moment.utc(f.date).month() === startDate.month())
                    : [],
            };
            months.push(monthInfo);
        }
        this.setState({ months });
    }

    getMonthName = (date) => {
        const { activeLanguage } = this.state;
        return moment.utc(date).locale(activeLanguage ? activeLanguage.code : 'pt').format('MMMM/YYYY').toUpperCase();
    }

    getWeekdays = () => {
        const { activeLanguage } = this.state;
        moment.locale(activeLanguage ? activeLanguage?.code : 'pt');
        // return [this.props.translate('WEEK_SHORT'), ...moment.weekdaysShort()];
        return moment.weekdaysShort();
    }

    render() {
        let { months, translate, activeLanguage, wedding, reserveView, agendaType, menuOpen } = this.state;
        const { selecting } = this.props;
        const reservePhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) ? true : false;
        const reserveSinalizationPhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE_SINALIZATION)) ? true : false;

        return <Row gutter={[12, 12]} className="year">
            {months.map((month, monthIndex) => {
                return <Col key={monthIndex} xs={24} sm={12} md={8} lg={8} xl={menuOpen && !reservePhase && !reserveSinalizationPhase ? 10 : 8} xxl={8} className="month">
                    <div className="month-name">{this.getMonthName(month.date)}</div>
                    <div className='weekdays'>
                        {this.getWeekdays().map((day, index) => {
                            return <span key={index} className="weekday">
                                {day}
                            </span>
                        })}
                    </div>
                    {month.calendar.map((week, index) => {
                        return <div key={index} className='month-days'>
                            {week.map(date => {
                                return <CalendarDate
                                    key={date.date()}
                                    selecting={selecting}
                                    dateToRender={date}
                                    events={month.events}
                                    schedules={month.schedules}
                                    dateOfMonth={month.calendar.currentDate}
                                    wedding={wedding}
                                    reserveView={reserveView}
                                    agendaType={agendaType}
                                    translate={translate}
                                    activeLanguage={activeLanguage}
                                    onClick={date => this.props.onDayClick(date)}
                                />
                            })}
                        </div>
                    })}
                </Col>
            })}
        </Row>
    }
}

// Day.propTypes = {
//     date: PropTypes.instanceOf(Date).isRequired,
// }

ThreeMonthsView.range = date => {
    //     return [dates.startOf(date, 'year')]
}

ThreeMonthsView.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment(date).add(1, 'year') //dates.add(date, -1, 'year')
        case navigate.NEXT:
            return moment(date).subtract(1, 'year')
        default:
            return date
    }
}

ThreeMonthsView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default ThreeMonthsView
