import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import { DetailContainer, Description, FormContainer } from "./OurStyleStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import {
  findPageByTag,
  findPageByID,
} from "../../infra/services/utils/PageInfo";
import { findNextChapter } from "../../infra/services/utils/PageNavigation";
import OurSuggestionsForm from "./OurSuggestionsForm";
import { updateQuestionAction } from "../../redux/Wedding/wedding.actions";
import {
  GetTags,
  GetInspirations,
  GetCurrentInspirations,
  EditInspirations,
} from "../../infra/requests/OurStyleRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";

const limitPage = 12;
const limitInspirations = 10;
const sectionTag = "STYLE";
const chapterId = "5e42e52b29196b814f617d18";
const returnURL = "/our-style";

const FormValidations = (values) => {
  //console.log('FORM', values);
  return {};
};

class OurStyle extends Component {
  state = {
    chapter: undefined,
    nextChapter: undefined,
    filterCategories: undefined,
    filterTags: undefined,
    inspirations: undefined,
    filters: undefined,
    currentInspirations: undefined,
    currentSuggestions: undefined,
    isProcessClosed: undefined,
    ready: false,
    loading: false,
  };

  cardContainerRef = React.createRef();

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.updateSection();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.updateSection();
    }
  }

  updateSection = async () => {
    const { weddingInfo, ourStyleInfo, info } = this.props;

    const { chapters } = findPageByTag(ourStyleInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterId);
    const nextChapter = findNextChapter(
      ourStyleInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    const resultTags = await GetTags();
    let tags = [];
    if (resultTags.success) {
      tags = resultTags.data.map((tag) => ({ name: tag }));
    }

    const resultInspirations = await GetCurrentInspirations();
    let currentInspirations = [];

    if (resultInspirations.success) {
      currentInspirations = resultInspirations.data.inspirations;
    }

    this.changePage(1, 0);

    this.setState({
      chapter,
      nextChapter,
      filterCategories: info.categories,
      filterTags: tags,
      currentInspirations,
      isProcessClosed,
      ready: true,
    });
  };

  changePage = async (page, changingPage = 1) => {
    const { filters } = this.state;

    const resultInspirations = await GetInspirations(page, limitPage, filters);
    let inspirations = [];
    if (resultInspirations.success) {
      inspirations = resultInspirations.data;
    }

    this.setState({
      inspirations: inspirations,
    });

    //Only scroll's to top of results on page change, not uppon entering the Section
    if (changingPage === 1) {
      window.scrollTo({
        top: this.cardContainerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  handleFilterChange = (filters) => {
    this.setState({ filters }, () => this.changePage(1, 0));
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history } = this.props;
      const { nextChapter } = this.state;

      let inspirationsList = [];
      Object.keys(values.response).forEach(function (key) {
        inspirationsList.push(values.response[key]["_id"]);
      });

      const { success } = await EditInspirations({
        inspirations: inspirationsList,
      });

      if (success) {
        history.push(`${returnURL}/${nextChapter?._id}`);
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      handleSubmit,
      translate,
      activeLanguage,
      history,
      dirty,
      submitting,
    } = this.props;
    const {
      chapter,
      nextChapter,
      filterCategories,
      filterTags,
      inspirations,
      currentInspirations,
      isProcessClosed,
      ready,
      loading,
    } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirty && !submitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <ProgressBar
          type="total"
          num_questions={1}
          num_answers={currentInspirations.length > 0 ? 1 : 0}
        />
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={translate("OUR_STYLE")}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${chapterId}`}
          nextQuestion={nextChapter?.name[activeLanguage?.code]}
          nextQuestionUrl={`${returnURL}/${nextChapter?._id}`}
          prevQuestion={translate("ABOUT_US")}
          prevQuestionUrl="/about-us"
        />
        <DetailContainer>
          <Description
            dangerouslySetInnerHTML={{
              __html:
                chapter.description &&
                chapter.description[activeLanguage?.code],
            }}
          />
          <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="response"
              component={OurSuggestionsForm}
              filterCategories={filterCategories}
              filterTags={filterTags}
              inspirations={inspirations}
              changePage={this.changePage}
              currentInspirations={currentInspirations}
              limitInspirations={limitInspirations}
              queryChange={this.handleFilterChange}
              containerRef={this.cardContainerRef}
              isProcessClosed={isProcessClosed}
            />
            {!isProcessClosed && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
          </FormContainer>
          {isProcessClosed && (
            <SaveButton
              htmlType="button"
              text={translate("CONTINUE")}
              textMobile={translate("CONTINUE")}
              withBackground={true}
              onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
            />
          )}
        </DetailContainer>
      </React.Fragment>
    );
  }
}

const OurStyleForm = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(OurStyle);

const mapStateToProps = (state) => ({
  ourStyleInfo: state.wedding.structure,
  info: state.info,
  weddingInfo: state.wedding.wedding,
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ updateQuestionAction }, dispatch);

export default connect(
  mapStateToProps,
  mapActionToProps
)(withLocalize(withRouter(OurStyleForm)));
