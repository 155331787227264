import moment from "moment";

/**
 * RULES
 * All observations fields must be blocked
 * Exception: Section "Food Selection", chapter "Opções e Informações Complementares", question "Terão algum aniversário a festejar nesse dia? *"
 * can be edited by all (couple and admins)
 * 
 * @param {*} question
 * @returns 
 */
export const editObservations = (question) => {
  return question?.observationsBlockedForCouple ? false : true;
}

/*
* RESTRICTIONS:
* CEREMONY, FOOD SELECTION, DRINK SELECTION, DECORATION, PARTY SELECTION and UPGRADE
*
* UNLOCKS EDITING:
* 15 December year before
*
* TRUE: if is allowed to edit a section
* FALSE: otherwise
*/
export const editSection = (date, tag) => {
  const weddingYear = moment(date).utc().year();
  const currentYear = moment().utc().year();

  const currentDate = moment().utc();
  const targetDate = moment().utc().format("YYYY-12-14");

  if (tag === 'CEREMONY' || tag === 'FOOD' || tag === 'DRINKS' || tag === 'DECORATION' || tag === 'PARTY' || tag === 'UPGRADE') {
    if (weddingYear === currentYear || (weddingYear === (currentYear + 1) && currentDate.isAfter(targetDate))) {
      return true;
    }
    else {
      return false;
    }
  }
  else {
    return true;
  }
}

/*
* BLOCKS EDITING:
* check block date by wedding
*
* TRUE: Process is closed, cannot edit anymore
* FALSE: otherwise
*/
export const processClosed = (blockDate) => {
  const currentDate = moment().utc();
  // const targetDate = moment(date).subtract(30, 'days').utc();
  const targetDate = moment(blockDate).endOf('day').utc();

  if (currentDate.isAfter(targetDate)) {
    return true;
  }
  else {
    return false;
  }
}

/*
* BLOCKS EDITING:
* check wedding date
*
* TRUE: Room plan is blocked, cannot edit anymore
* FALSE: otherwise
*/
export const roomPlanClosed = (date) => {
  const currentDate = moment().utc(true);
  const targetDate = moment(date).subtract(75, 'days').utc(true).startOf('day');
  const weddingDate = moment(date).utc();

  return currentDate.isBefore(targetDate) || currentDate?.isAfter(weddingDate)
    ? true
    : false;
}

/*
* BLOCKS submit guest list:
* check wedding date
*
* TRUE: Submit guest list is blocked, cannot submit it
* FALSE: otherwise
*/
export const blockedSubmitGuestList = (date) => {
  const currentDate = moment().utc(true);
  const targetDate = moment(date).subtract(20, 'days').utc(true).startOf('day');

  return currentDate.isBefore(targetDate)
    ? true
    : false;
}