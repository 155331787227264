import React, {useState} from 'react';
import {connect} from 'react-redux';
import {withLocalize} from 'react-localize-redux';
import {
  WeddingContainer,
  DateColumn,
  MainColumn,
  Day,
  Month,
  Year,
  QuestionContent,
  Question,
  Answer,
  QuestionColorsContent,
  ColorsContainer,
  ColorsRow,
  Color,
  ColorSquare,
  ColorBg,
  ColorName,
  Plus,
  ImageBg,
  ViewMoreContainer,
  ViewMore,
  AnswerContainer
} from './WeddingStyles';
import { formatSpace } from '../../infra/services/utils/SpaceName';
import { 
  getWeddingDate, 
  getArrivalDate, 
  findQuestionByID,
  findColors,
  findAnswer
} from '../../infra/services/utils/NextEvents';
import ImageComponent from "../images/ImageComponent";
import WeddingDetailModal from "./WeddingDetailModal";
import LongArrowRight from '../../components/svg/LongArrowRight';

const Wedding = ({ wedding, colorsInfo, sectionsInfo, translate, activeLanguage }) => {
  const [showModal, setOpenModal] = useState(false);

  const { day, month, year } = getWeddingDate(wedding.date);
  const { arrival_time, visit_time } = getArrivalDate(wedding.dates, translate);
  
  //Color question
  const colorsQuestion = findQuestionByID(wedding.answers, '5e0f63f86b06b564f132421c');
  let colorsAnswer = [], fullColorsAnswer = [], colorsSize = 0;

  if(colorsQuestion && colorsQuestion.response && colorsQuestion.response.length > 0 && colorsInfo.length > 0) {
    colorsAnswer = findColors(colorsInfo, colorsQuestion.response);
    fullColorsAnswer = [...colorsAnswer];

    colorsSize = colorsAnswer.length;

    if(colorsSize > 0) {
      colorsAnswer = colorsAnswer.slice(0, 6);
    }
  }

  //Wedding Style question
  const styleQuestion = findQuestionByID(wedding.answers, '5e4bb17ff0bc781a2e26a476');
  let styleAnswer = undefined;

  if(styleQuestion && styleQuestion.response && styleQuestion.response.length > 0) {
    //Check every answer until it returns a valid answer
    for(let i = 0; i < styleQuestion.response.length; i++) {
      /*
      * Params:
      * 0 - Structure
      * 1 - "Wedding Style" question ID
      * 2 - Answer ID
      */
      styleAnswer = findAnswer(sectionsInfo, '5e4bb17ff0bc781a2e26a476', styleQuestion.response[i].id, wedding.wedding_place, 'DECORATION');

      //If the answer exists, return it
      if(styleAnswer) {
        break;
      }
    }
  }

  const openModal = () => {
    document.body.style.overflowY = 'hidden';
    
    setOpenModal(true);
  }

  const closeModal = () => {
    document.body.style.overflowY = 'auto';

    setOpenModal(false);
  }

  return (
    <React.Fragment>
      <WeddingContainer onClick={() => openModal()}>
        {
          wedding.date &&
          <DateColumn>
            <Day>{day}</Day>
            <Month>{month}</Month>
            <Year>{year}</Year>
          </DateColumn>
        }
        <MainColumn hasDate={wedding.date ? true : false}>
          {
            wedding.wedding_place &&
            <QuestionContent>
              <Question>{translate('WEDDING_PLACE')}:</Question>
              <Answer>{formatSpace(wedding.wedding_place)}</Answer>
            </QuestionContent>
          }
          {
            wedding.ceremonyPlace?.length > 0 &&
            <QuestionContent>
              <Question>{translate('CEREMONY_PLACE')}:</Question>
              <AnswerContainer>
                {
                  wedding.ceremonyPlace.map((elem, index) => <Answer key={index}>{elem[activeLanguage?.code]}</Answer>)
                }
              </AnswerContainer>
            </QuestionContent>
          }
          <QuestionContent>
            <Question>{translate('ARRIVAL_HOUR')}:</Question>
            <Answer>{arrival_time}</Answer>
          </QuestionContent>
          <QuestionContent>
            <Question>{translate('VISIT_HOURS')}:</Question>
            <Answer>{visit_time}</Answer>
          </QuestionContent>
          {
            styleAnswer &&
            <QuestionContent>
              <Question>{translate('WEDDING_STYLE')}:</Question>
              <Answer>{styleAnswer.title[activeLanguage?.code]}</Answer>
            </QuestionContent>
          }
          {
            colorsSize > 0 && 
            <QuestionColorsContent>
              <Question>{translate('WEDDING_COLORS')}:</Question>
              <ColorsContainer>
                <ColorsRow>
                  {
                    colorsAnswer.map((color, index) => 
                      <Color key={index}>
                        <ColorSquare>
                          {
                            color.hex ?
                            <ColorBg bgColor={color.hex}/>
                            :
                            color.image?.url ?
                            <ImageBg>
                              <ImageComponent url={color.image.url} color={color.image.color}/>
                            </ImageBg>
                            :
                            null
                          }
                        </ColorSquare>
                        <ColorName>{color.name[activeLanguage?.code]}</ColorName>
                      </Color>
                    )
                  }
                  {
                    colorsSize > 6 &&
                    <Color>
                      <ColorSquare>
                        <ColorBg bgColor='transparent'>
                          <Plus>+</Plus>
                        </ColorBg>
                      </ColorSquare>
                      <ColorName>{`+ ${colorsSize - 6} ${translate('COLORS')}`}</ColorName>
                    </Color>
                  }
                </ColorsRow>
              </ColorsContainer>
            </QuestionColorsContent>
          }
        </MainColumn>
        <ViewMoreContainer className='view_more'>
          <ViewMore>{translate('VIEW_MORE')}</ViewMore>
          <LongArrowRight/>
        </ViewMoreContainer>
      </WeddingContainer>
      {
        showModal &&
        <WeddingDetailModal
          wedding={wedding}
          colorsAnswer={fullColorsAnswer}
          styleAnswer={styleAnswer}
          closeModal={closeModal}
        />
      }
    </React.Fragment>
  );
}

const mapStateToProps = state => ({ 
  sectionsInfo: state.wedding.structure,
  colorsInfo: state.info.colours
});

export default withLocalize(connect(mapStateToProps)(Wedding));