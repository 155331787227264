import React from "react";
import { withRouter } from "react-router-dom";
import {
  ItemContainer,
  ItemIcon,
  ItemTitle,
  ProgressBarContainer
} from "./SliderStyles";
import ProgressBar from '../progressBar/ProgressBar';

const SliderItem = ({ name, icon, url, questions, answers, history }) => {
  return (
    <ItemContainer onClick={() => history.push(url)}>
      <ItemIcon>
        {icon}
        <ProgressBarContainer>
          <ProgressBar type='dashboard' num_questions={questions} num_answers={answers}/>
        </ProgressBarContainer>
      </ItemIcon>
      <ItemTitle>{name}</ItemTitle>
    </ItemContainer>
  );
}

export default withRouter(SliderItem);