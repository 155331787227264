import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const ModalContainer = styled.div`
  
`;

export const ModalContent = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
  }

  .ant-modal-title {
    font-family: 'Titillium Web', sans-serif;
    text-transform: uppercase;
    color: #000000;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
  }

  .ant-modal-footer {
    border-top: none;

    & button {
      transition: background-color 0.5s linear, border-color 0.5s linear;
      border-color: ${p => p.theme.secondaryCTA};
      padding: 8px 15px;
      height: 38px;
      text-shadow: none;
      box-shadow: none;

      & span {
        font-family: 'Titillium Web', sans-serif;
        color: ${p => p.theme.secondaryCTA};
        font-size: 18px;
        font-weight: 300;
        line-height: 20px;
        transition: color 0.5s linear;
      }

      &.ant-btn-primary {
        background-color: ${p => p.theme.primaryCTA};
        border-color: ${p => p.theme.primaryCTA};

        & span {
          color: #ffffff;
        }
      }

      &:hover {
        background-color: ${p => p.theme.secondaryCTA};
        border-color: ${p => p.theme.secondaryCTA};
        
        & span {
          color: #ffffff;
        }
      }
    }
  }

  .ant-modal-body {
    color: #000000;
    font-size: 15px;
  }
`;

export const ModalButton = styled(Button)`
  overflow: hidden;
`;