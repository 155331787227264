import styled from "styled-components";
import {
  Input,
  Select,
  InputNumber,
  Checkbox,
  DatePicker,
  Icon,
  Collapse,
  Radio
} from "antd";
import { device } from "../../styles/Responsive";
import { titleH4 } from "../../styles/TextSizes";

const { TextArea } = Input;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;
const Option = Select.Option;

const defineBorderColor = props => {
  if (props.error) return props.theme.inputErrorColor;
  if (props.active) return props.theme.primaryColor;
  return props.theme.inputBorderColor;
};

export const InputDiv = styled.div`
  display: inline-block;
  width: 100%;
  margin-top: ${p => (p.first ? "0px" : "30px")};
  text-align: start;
  position: relative;

  @media ${device.tablet} {
    margin-top: ${p => (p.first ? "0px" : "20px")};
  }
`;

export const SampleButtonSpace = styled.div`
  display: inline-block;
  width: 100%;
  height: 32px;
`;

export const SampleInputSpace = styled.div`
  width: 100%;
  margin-top: ${p => (p.first ? "0px" : "10px")};
  height: 64px;
`;

export const InputLabelDiv = styled.div`
  display: inline-block;
  font-size: 16px;
  width: ${({ horizontal }) => horizontal ? 'auto' : '100%'};
  ${({ error }) => error && "color: red"};
  margin-top: ${({ sublabel }) => (sublabel ? "10px" : 0)};
  ${({ horizontal }) => horizontal ? `
  margin-right: 5px;
  white-space: nowrap;` : ''}
`;

export const InputField = styled(Input)`
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `
      border: 1px solid ${p.theme.inputErrorColor} !important;
      .ant-input-prefix, .ant-input-suffix{
        color: ${p.theme.inputErrorColor};
      }
  `}
`;

export const InputNumberField = styled(InputNumber)`
  width: 100% !important;

  &:focus-within,
  &:hover {
    .ant-input-prefix,
    .ant-input-suffix {
      color: ${p => p.theme.inputFocusColor};
    }
    .ant-input-number-input {
      border: 1px solid ${p => p.theme.inputFocusColor};
    }
  }

  ${p =>
    p.error &&
    `.ant-input-number-input{
        border: 1px solid ${p.theme.inputErrorColor};
      }
  `}
`;

export const LanguagePill = styled.div`
  display: inline-block;
  margin: 10px 10px 0 0;
  padding: 6px 10px;
  border: 1px solid ${props => defineBorderColor(props)};
  color: ${({ active }) => (active ? "white" : "rgba(0, 0, 0, 0.65)")};
  background-color: ${({ active, theme }) =>
    active ? theme.primaryColor : "transparent"};
  cursor: pointer;
  border-radius: 3px;
`;

export const Flag = styled.img`
  width: 20px;
  margin-right: 10px;
`;

export const DateInputField = styled(DatePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const RatingInputBox = styled.div`
  display: inline-block;
  width: 100%;
`;

export const RateImage = styled.img`
  margin: 5px;
  width: 30px;
  cursor: pointer;
`;

export const ImageButton = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.5s linear;
`;

export const ImageInputSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
  border: 1px dashed ${p => p.theme.inputBorderColor};
  cursor: pointer;
  ${({ filled }) => (filled ? "border: none" : "")}

  &:hover {
    ${ImageButton} {
      opacity: 1;
    }
  }
`;

export const ImageMessage = styled.div`
  color: ${({ error, theme }) => (error ? theme.inputErrorColor : "#000000")};
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: 0.2;
  margin-top: 25px;
  padding: 0px 5px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
  }
`;

export const ImageIcon = styled.img`
  width: 63px;
`;

export const WaitingIcon = styled.img`
  width: 16px;
`;

export const SelectButton = styled.div`
  margin-top: 25px;

  & button {
    @media ${device.tablet} {
      padding: 12px 80px !important;
    }

    @media ${device.mobileL} {
      padding: 12px 50px !important;
    }

    @media ${device.mobileS} {
      padding: 12px 10px !important;
    }
  }
`;

export const LanguagesComponent = styled.div`
  display: inline-block;
  width: 100%;
  margin: 20px 0;
`;

export const AddLangButton = styled.div`
  display: inline-block;
  width: 300px;
  margin-top: 10px;
`;

export const DeleteLangIcon = styled.div`
  display: inline-block;
  position: relative;
`;

export const FormCollapse = styled(Collapse)`
  &&.ant-collapse-header {
    color: red;
  }
`;

export const FormPanel = styled(Panel)`
  .ant-collapse-header {
    color: ${({ error }) => (error ? "red !important" : "")};
  }
`;

export const ImageSlideComponent = styled.div`
  display: inline-block;
  width: 100%;

  .ant-carousel {
    margin-top: 10px;
    .slick-slide {
      position: relative;
    }
  }
`;

export const ImageSlideButton = styled.div`
  display: inline-block;
  width: 100%;
  cursor: pointer;
  margin-top: 12px;
`;

export const RemoveImage = styled.div`
  position: absolute;
  top: 0;
  right: 6px;
  width: 40px;
  height: 40px;
  display: flex;
  background: rgba(0, 0, 0, 0.4);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  color: white;
`;

export const AddIcon = styled(Icon)`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  font-size: 50px;
`;

export const EmptySlide = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 66%;
  border: 1px solid
    ${({ showError, theme }) =>
    showError ? theme.inputErrorColor : theme.inputBorderColor};

  ${AddIcon} {
    color: ${({ showError, theme }) =>
    showError ? theme.inputErrorColor : theme.inputBorderColor};
  }
`;

export const MapSearchInput = styled(Input)`
  &&& {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 350px;
  }
`;

export const PreviewVideo = styled.div`
  display: inline-block;
  width: 100%;
  height: 400px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
`;

export const FileSection = styled.div`
  margin-top: 3px;
  background-color: ${p => p.theme.secondaryColor};
  padding: 5px;
  text-transform: uppercase;
  position: relative;

  & i {
    position: absolute;
    right: 1%;
    top: 25%;
    cursor: pointer;
  }
    
  ${p =>
    p.error &&
    `border: 1px solid ${p.theme.inputErrorColor} !important;
  `}
`;

export const ResponsePill = styled.div`
  display: inline-block;
  text-align: left;
  padding: 5px 10px;
  background: ${({transparent}) => (transparent ? 'transparent' : '#E0E0E0')};
  border-radius: 4px;
  border: 2px solid #E0E0E0;
  border-top-right-radius: ${({single}) => (single ? 4 : 0)}px;
  border-bottom-right-radius: ${({single}) => (single ? 4 : 0)}px;
  white-space: pre-line;
  position: relative;
  font-weight: bold;
`;

export const ExtraPill = styled.div`
  border: 2px solid #E0E0E0;
  background: white;
  display: inline-block;
  text-align: center;
  padding: 5px 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: ${({last}) => (last ? 4 : 0)}px;
  border-bottom-right-radius: ${({last}) => (last ? 4 : 0)}px;
  border-right: ${({last}) => (last ? 2 : 0)}px solid #E0E0E0;
  cursor: ${({link}) => (link ? 'pointer' : 'default')};
`;

export const FileResponse = styled.div`
  display: flex;
  flex-direction: row;
  
`;

export const FileDropSection = styled.div`
  display: flex;
  width: 100%;
  color: ${({ theme, disabled }) => disabled ? 'rgba(0, 0, 0, 0.25)' : theme.inputBorderColor};
  border: 1px solid ${({ theme, disabled, error }) => error ? theme?.errorColor : disabled ? 'rgba(0, 0, 0, 0.25)' : theme.inputBorderColor};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
    border-color: ${p => p?.disabled ? 'rgba(0, 0, 0, 0.25)' : p?.theme?.primaryColor};
    
    .ant-icon {
      color: ${({ theme, disabled }) => disabled ? 'rgba(0, 0, 0, 0.25)' : theme.inputBorderColor};
    }
  }

  i, .ant-icon {
    font-size: 50px;
  }
`;

export const DownloadFileButton = styled.div`
  background-color: ${p => p.theme.primaryCTA};
  border-radius: 6px;
  width: auto;
  height: auto;
  padding: 10px 15px;
  font-size: 16px;
  color: #ffffff;
  pointer-events: auto !important;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }
`;

/******************************* CHECKBOX INPUT *******************************/

export const CheckboxField = styled(Checkbox)`
  & .ant-checkbox {
    width: 24px;
    height: 24px;

    & + span {
      padding-left: 0px;
      padding-right: 0px;
    }

    & .ant-checkbox-input {
      width: 24px;
      height: 24px;
    }

    & .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      background-color: #ffffff;
      border-color: ${p => p.theme.primaryColor};
      border-radius: ${p => (p.checkboxType === "square" ? "0px" : "50%")};
      opacity: ${p => (p?.disabled || p?.readOnly) ? 0.6 : 1};

      &:after {
        top: ${p => (p.checkboxType === "square" ? "42%" : "43%")};
        left: ${p => (p.checkboxType === "square" ? "14%" : "16%")};
        width: ${p => (p.checkboxType === "square" ? "9px" : "8px")};
        height: ${p => (p.checkboxType === "square" ? "20px" : "17px")};
      }
    }

    &.ant-checkbox-checked {
      &:after {
        border: 0px;
      }

      & .ant-checkbox-inner {
        background-color: ${p => p.theme.primaryColor};
        border-color: ${p => p.theme.primaryColor};
      }
    }

    &:hover {
      & .ant-checkbox-inner {
        border-color: ${p => p.theme.primaryCTA};
      }
    }
  }

  &.ant-checkbox-wrapper {
    &:hover {
      & .ant-checkbox-inner {
        border-color: ${p => p.theme.primaryCTA};
      }
    }
  }

  & .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.primaryCTA};
  }
`;

/******************************* RADIO INPUT *******************************/

export const RadioGroup = styled(Radio.Group)`
  &.ant-radio-group {
    & .ant-radio-wrapper {
      margin-right: 0px;
    }
  }
`;

/******************************* TEXT AREA INPUT *******************************/

export const TextAreaDiv = styled.div`
  text-align: left;
  margin-top: ${p => (p.inForm ? "15px" : "0px")};

  @media ${device.tablet} {
    margin-top: ${p => (p.inForm ? "20px" : "0px")};
  }
`;

export const TextAreaLabelRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${p => p?.inForm ? '0' : '10px'};
`;

export const CheckboxContainer = styled.div`
  & div {
    display: block;
    margin-top: 0px;
  }
`;

export const TextAreaLabel = styled.div`
  margin-left: ${p => (p.hasCheckbox ? "20px" : "0px")};
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const TextAreaInputField = styled(TextArea)`

&.ant-input {
  width: 100%;
  border: 1px solid ${p => p?.error ? '#de8f8f' : p.theme.primaryCTA};
  border-radius: 4px;
  opacity: ${p => (p?.readonly || p?.disabled) ? 0.6 : 1};
  height: 160px !important;
  // ${p => (p?.disabled || p?.readOnly) && `
  // color: rgba(0, 0, 0, 0.25);
  // background-color: #f5f5f5;
  // cursor: not-allowed;
  // `}

  ${p =>
    p.fillError &&
    `background-color: #fff5f6a6 !important;
`}

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primaryCTA};
      box-shadow: none;
    }
  }
`;

/******************************* QUANTITY INPUT *******************************/

export const QuantityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ButtonSubtract = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  background-color: #D1CABF;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  transition: background-color 0.5s linear;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }
`;

export const QuantityInput = styled(Input)`
  max-width: 30px;

  &.ant-input {
    background-color: #D1CABF;
    border: 0;
    border-radius: 0px;
    height: 34px;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding: 0px 1px;

    &:focus {
      box-shadow: none;
    }
  }
`;

export const ButtonAdd = styled.span`
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 5px 10px;
  background-color: #D1CABF;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  transition: background-color 0.5s linear;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }
`;

/******************************* SELECT INPUT *******************************/

export const SelectDiv = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  background: transparent;
  text-align: start;

  @media ${device.tablet} {
    margin-top: ${({ noLabel }) => noLabel ? '0' : '20px'}
  }
`;

export const SelectArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
  pointer-events: none;
  opacity: ${p => (p?.readonly || p?.disabled) ? 0.4 : 1};

  &.customSelectArrow {
    bottom: 8px;
  }

  & img {
    height: ${({ smallIcon }) => smallIcon ? '7px' : '12px'};
  }
`;

export const XpertGoSelectArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 15px;
  cursor: pointer;
  pointer-events: none;
  opacity: ${p => (p?.readonly || p?.disabled) ? 0.4 : 1};

  &.customSelectArrow {
    bottom: 8px;
  }

  & img {
    height: 8px;
  }
`;

export const CloseArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 0;
  cursor: pointer;
  z-index: 1;
  opacity: ${p => (p?.readonly || p?.disabled) ? 0.4 : 1};

  & img {
    height: ${({ smallIcon }) => smallIcon ? '7px' : '16px'};
  }
`;

export const XpertGoCloseArrow = styled.span`
  position: absolute;
  bottom: 5px;
  right: 16px;
  cursor: pointer;
  z-index: 1;
  opacity: ${p => (p?.readonly || p?.disabled) ? 0.4 : 1};

  & img {
    height: 8px;
  }
`;

export const ArrowImg = styled.img``;

export const StyledSelectInput = styled(Select)`
  width: 100%;

  & .ant-select-selection {
    border: 0;
    border-bottom: 1px solid ${p => p.theme.inputBorderColor};
    border-radius: 0px;
    background-color: transparent !important;
    opacity: ${p => (p?.disabled || p?.readOnly) ? 0.6 : 1};
    margin-top: ${({ noLabel, labelFixed }) => noLabel ? '0' : labelFixed ? '5px' : '16px'};

    &:hover {
      border-bottom: 1px solid ${p => p.theme.inputFocusColor} !important;
      border-color: ${p => p.theme.inputFocusColor} !important;
      cursor: default;
    }

    &:focus {
      border-bottom: 1px solid ${p => p.theme.inputFocusColor} !important;
      border-color: ${p => p.theme.inputFocusColor} !important;
      cursor: default;
    }

    & .ant-select-selection__rendered {
      margin-right: 0px;
      margin-left: 0px;
      line-height: 32px;

      & .ant-select-selection__placeholder {
        color: rgba(0,0,0,0.65);
        opacity: 1;
        font-size: ${({ small }) => small ? '16px' : '20px'};
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        top: 0px;
        right: 0px;
        margin-top: 0px;
      }

      & .ant-select-selection-selected-value {
        color: rgba(0,0,0,0.65);
        font-size: ${({ small }) => small ? '16px' : '20px'};
        font-weight: 400;
        line-height: 32px;
        height: 32px;
        ${({ small }) => small && `
        width: calc(100% - 10px);
        
        & span {
          display: block;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        `}
      }
    }
  }

  .ant-select-selection:hover .ant-select-selection__clear {
    opacity: 0;
  }

  .ant-select-open .ant-select-selection,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: ${p => p.theme.inputFocusColor};
    box-shadow: none;
  }

  ${p =>
    p.error &&
    `.ant-select-selection {
        border: 0;
        border-bottom: 1px solid ${p.theme.inputErrorColor} !important;
      }
     
  `}
`;

export const StyledOption = styled(Option)``;

export const StyledOptionContent = styled.div`
  padding: 5px 12px;
`;

export const MultipleSelectDiv = styled.div`
  position: relative;
  max-width: 100%;
  margin-top: 0px;
`;

export const StyledManualSelectInput = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  box-sizing: border-box;
  background-color: #fff;
  border-bottom: 1px solid ${p => p?.theme?.inputBorderColor};
  outline: none;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  color: ${p => p?.disabled ? 'rgba(0, 0, 0, 0.25)' : p?.filled ? 'black' : 'rgba(0, 0, 0, 0.65)'};
  opacity: ${p => p?.disabled ? 0.6 : 1};

  &:hover {
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
    border-color: ${p => p?.disabed ? '#d9d9d9' : p?.theme?.primaryColor};
  }
  
  div.select-input {
    padding: 0 24px 0 11px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    text-align: left;
  }
`;


export const MultipleStyledSelectInput = styled.div`
  border-bottom: 1px solid #979797;
  display: flex;
  justify-content: space-between;
  height: 32px;
  opacity: ${p => p?.disabled ? 0.6 : 1};
  color: ${p => p?.disabled ? 'rgba(0, 0, 0, 0.25)' : p?.filled ? p?.theme?.primaryColor : 'rgba(0, 0, 0, 0.65)'};
  font-weight: ${p => p?.filled ? 600 : 'inherit'};

  &:hover {
    cursor: ${p => p?.disabled ? 'not-allowed' : 'pointer'};
  }

  div.select-input {
    border-bottom: unset;
    width: calc(100% - 10px);
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: auto 0;
    text-align: left;
  }
`;

export const SelectOptions = styled.ul`
  padding-inline-start: 0;
  background-color: white;
  position: absolute;
  z-index: 30;
  padding: 6px 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: ${p => p?.age ? 'auto' : '150px'};
  width: 100%;
  overflow: auto;
  border: 1px solid ${p => p.theme.inputBorderColor};

  @media ${device.mobileL} {
    width: 90px;
    left: 0px;
  }
`;

export const SelectOption = styled.li`
    cursor: ${({ disabled }) => disabled ? 'blocked' : 'pointer'};
    text-align: start;

    &:hover {
      background-color: #FAF9F9;
    }
 `;

export const XpertGoStyledSelectInput = styled(Select)`
  width: 100%;

  ${p =>
    p.noBorder &&
    `.ant-select-selection {
        border: unset !important;
        box-shadow: none !important;
      }
  `}

  & .ant-select-selection {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    background-color: transparent;

    &:hover, &:focus {
      border-bottom: 1px solid ${p => p.theme.inputFocusColor};
    }

    & .ant-select-selection__clear {
      display: none;
    }

    & .ant-select-selection__rendered {
      line-height: 31px;

      & .ant-select-selection__placeholder {
        color: rgba(0, 0, 0, 0.45);
        opacity: 1;
        font-size: 14px;
        font-weight: 400;
        line-height: 31px;
        height: 31px;
        top: 0px;
        right: 0px;
        margin-top: 0px;
      }

      & .ant-select-selection-selected-value {
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
        font-weight: 400;
        line-height: 31px;
        height: 31px;
      }
    }
  }

  .ant-select-open .ant-select-selection,
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: ${p => p.theme.inputFocusColor};
    box-shadow: none;
  }

  ${p =>
    p.error &&
    `.ant-select-selection {
        border: 0;
        border-bottom: 1px solid ${p.theme.inputErrorColor} !important;
      }
    
`}
`;

/******************************* CUSTOM SELECT INPUT *******************************/

export const OptionContainer = styled(Option)``;

export const OptionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 20px;
  cursor: pointer;
  background-color: ${p =>
    p.selected ? p.theme.secondaryColor : "transparent"};

  &:hover {
    & span {
      opacity: 0.6;
    }
  }

  @media ${device.tabletS} {
    padding: 7px 10px;
  }
`;

export const Box = styled.div`
  background-color: ${p =>
    p.selected ? p.theme.secondaryCTA : p.theme.primaryCTA};
  width: 24px;
  height: 24px;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.mobileM} {
    margin-right: 5px;
  }
`;

export const OptionText = styled.span`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${p => (p.selected ? "1" : "0.3")};
  text-align: left;
  transition: opacity 0.5s linear;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const SelectErrorLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({ filled }) => (filled ? "0" : "10px")};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`;

/******************************* CUSTOM SELECT INPUT "OUR DAY" *******************************/

export const OptionContentOurDay = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  background-color: ${p =>
    p.selected ? p.theme.secondaryColor : "transparent"};
  border-top: ${p => (p.hasBorder ? "1px" : "0px")} solid
    ${p => p.theme.primaryCTA};

  &:hover {
    & span {
      opacity: 0.6;
    }
  }

  @media ${device.tabletS} {
    padding: 7px 10px;
  }
`;

export const BoxOurDay = styled.div`
  background-color: ${p =>
    p.selected ? p.theme.secondaryCTA : p.theme.secondaryColor};
  width: 24px;
  height: 24px;
  margin-right: 15px;
  flex-shrink: 0;

  @media ${device.mobileM} {
    margin-right: 5px;
  }
`;

export const OptionTextOurDay = styled.span`
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${p => (p.selected ? "1" : "0.3")};
  text-align: left;
  transition: opacity 0.5s linear;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const CustomSelectLabel = styled(titleH4)`
  line-height: 29px;
  padding-bottom: 15px;
  text-align: left;
`;

/******************************* TIME INPUT *******************************/

export const TimeInputSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  background: transparent;
`;

export const TimeInputLabel = styled(titleH4)`
  line-height: 29px;
  padding-bottom: 15px;
  text-align: left;
`;

export const TimeInputError = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({ filled }) => (filled ? "0" : "10px")};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`;

export const TimeInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 140px;
  height: 45px;
  background-color: #ffffff;
  border: 1px solid ${p => p.theme.primaryCTA};
  border-radius: 12px;

  @media ${device.tablet} {
    height: 40px;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
`;

export const TimeInput = styled.input`
  width: 100%;
  border: 0;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  opacity: ${p => (p.filled ? "1" : "0.3")};

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
  border-left: 1px solid ${p => p.theme.primaryCTA};
`;

export const ArrowUp = styled.img`
  transform: rotateZ(180deg);
  padding-top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media ${device.tablet} {
    padding-top: 3px;
  }
`;

export const ArrowDown = styled.img`
  padding-top: 4px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  @media ${device.tablet} {
    padding-top: 3px;
  }
`;

export const SelectLabelInput = styled.div`
  font-weight: 400;
  font-size: 16px;
  color: black;
  opacity: 0.2;
  margin-top: ${({ agenda }) => agenda ? '10px' : '30px'};
  text-align: left;
`;


export const DateRangeInputField = styled(RangePicker)`
  display: inline-block;
  width: 100%;

  &:focus-within,
  &:hover {
    .ant-calendar-picker-icon {
      color: ${({ theme }) => theme.inputFocusColor};
    }
    .ant-input {
      border: 1px solid ${({ theme }) => theme.inputFocusColor};
    }
  }

  ${({ error, theme }) =>
    error &&
    `.ant-calendar-picker-input{
        border: 1px solid ${theme.inputErrorColor};
      }
      
      .ant-calendar-picker-icon{
        color: ${theme.inputErrorColor};
      }
  `}
`;

export const DivMarginTop = styled.div`
  margin-bottom: 0px;
  margin-top: ${({ value }) => value ? value : '0px'};
`;
