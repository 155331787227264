import { SAVE_WEDDING, UPDATE_QUESTION, UPDATE_ABOUT_US } from "../ActionsType";

export const SaveWedding = wedding => {
  return {
    type: SAVE_WEDDING,
    wedding
  };
};

export const DeleteWedding = () => {
  return {
    type: SAVE_WEDDING,
    wedding: {}
  };
};

export const updateQuestionAction = (section, data) => {
  return {
    type: UPDATE_QUESTION,
    section,
    data
  };
};

export const updateAboutUsAction = (data) => {
  return {
    type: UPDATE_ABOUT_US,
    data
  };
};