import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import {
  TimelineItemContainer,
  ItemContent,
  Dot,
  EmptyItem,
  ItemNameContainer,
  ItemName,
  ItemTime,
  ItemActions,
  ItemDelete,
  ItemEdit,
  ItemImg,
  TimelineItemContainerMobile,
  ItemNameContainerMobile,
  ItemNameMobile,
  ItemTimeMobile,
  ItemActionsMobile,
  ItemEditMobile,
  ItemDeleteMobile,
  ItemAnswersContainer,
  ItemAnswersContent,
  ItemAnswerContainer,
  ItemAnswer
} from './TimelineItemStyles';
import Edit from '../svg/Edit';
import Delete from '../svg/Delete';
import ConfirmModal from '../modals/ConfirmModal';
import { findMoment } from "../../pages/ourDay/Categories";
import { findMomentAnswers } from "../../infra/services/utils/PageOurDay";
import OutsideAlerter from "../outsideAlerter/OutsideAlerter";

const TimelineItem = ({ id, name, time, direction, active, zIndex, deleteMoment, toggleActive, closeActive, openEditModal, answersInfo, food, colors, isProcessClosed, translate, activeLanguage }) => {
  const [showDeleteModal, toggleDeleteModal] = useState(false);
  const [deleteID, saveDeleteID] = useState(undefined);
  const [momentAnswers, saveMomentAnswers] = useState([]);

  const checkItem = () => {
    let momentAnswers = [];

    //Check if the Moment is one of the defaults. If so return the code
    const moment = findMoment(name);

    if(moment?.question?.id) {
      momentAnswers = findMomentAnswers(moment.question, answersInfo, food, colors);
    }

    saveMomentAnswers(momentAnswers);
    toggleActive(id);
  }

  const handleModalCancel = () => {
    toggleDeleteModal(false);
  };

  const handleModalConfirm = () => {
    deleteMoment(deleteID);
    toggleDeleteModal(false);
  };

  const confirmDelete = (e, id) => {
    e.preventDefault();
    e.stopPropagation();

    saveDeleteID(id);
    toggleDeleteModal(true);
  }

  const truncate = (name) => {
    return name.length > 30 ? `${name.substring(0, 30)}...` : name;
  }

  return (
    name ?
    <OutsideAlerter section='OUR_DAY' close={closeActive}>
      <React.Fragment>
        {
          showDeleteModal &&
          <ConfirmModal
            title={translate('MOMENT_DELETE_TITLE')} 
            text={translate('MOMENT_REMOVE_TEXT')} 
            handleConfirm={handleModalConfirm} 
            handleCancel={handleModalCancel}
          />
        }
        <TimelineItemContainer zIndex={zIndex} direction={direction} onClick={() => checkItem()}>
          <ItemContent direction={direction} active={active}>
            {
              !isProcessClosed &&
              <ItemActions direction={direction} active={active}>
                <ItemDelete direction={direction} onClick={(e) => confirmDelete(e, id)}>
                  <ItemImg active={active}>
                    <Delete/>
                  </ItemImg>
                </ItemDelete>
                <ItemEdit direction={direction} onClick={(e) => openEditModal(e, id)}>
                  <ItemImg active={active}>
                    <Edit/>
                  </ItemImg>
                </ItemEdit>
              </ItemActions>
            }
            <ItemNameContainer className='itemNameContainer' direction={direction} active={active}>
              <ItemName className='itemName' active={active}>
                {truncate(name)}
              </ItemName>
            </ItemNameContainer>
            <ItemTime className='itemTime' active={active}>
              {time}
            </ItemTime>
          </ItemContent>
          <Dot active={active} direction={direction}/>
          <ItemAnswersContainer active={active} hasAnswers={momentAnswers.length > 0} direction={direction}>
            <ItemAnswersContent>
              {
                momentAnswers.map((answer, index) => 
                  <ItemAnswerContainer key={index}>
                    <ItemAnswer>
                      {
                        answer.title ? answer.title[activeLanguage?.code] : answer.name ? answer.name[activeLanguage?.code] : answer ? answer : null
                      }
                    </ItemAnswer>
                  </ItemAnswerContainer>
                )
              }
            </ItemAnswersContent>
          </ItemAnswersContainer>
        </TimelineItemContainer>
        <TimelineItemContainerMobile zIndex={zIndex} active={active} onClick={() => checkItem()}>
          <ItemNameContainerMobile active={active} className='itemNameContainer'>
            <ItemNameMobile active={active} className='itemName'>
              {truncate(name)}
            </ItemNameMobile>
            <ItemTimeMobile active={active} className='itemTime'>
              {time}
            </ItemTimeMobile>
          </ItemNameContainerMobile>
          {
            !isProcessClosed &&
            <ItemActionsMobile>
              <ItemEditMobile active={active} className='itemEdit' onClick={(e) => openEditModal(e, id)}>
                <ItemImg active={active}>
                  <Edit/>
                </ItemImg>
              </ItemEditMobile>
              <ItemDeleteMobile onClick={(e) => confirmDelete(e, id)}>
                <ItemImg active={active}>
                  <Delete/>
                </ItemImg>
              </ItemDeleteMobile>
            </ItemActionsMobile>
          }          
          <ItemAnswersContainer active={active} hasAnswers={momentAnswers.length > 0} direction={direction}>
            <ItemAnswersContent>
              {
                momentAnswers.map((answer, index) => 
                  <ItemAnswerContainer key={index}>
                    <ItemAnswer>
                      {
                        answer.title ? answer.title[activeLanguage?.code] : answer.name ? answer.name[activeLanguage?.code] : answer ? answer : null
                      }
                    </ItemAnswer>
                  </ItemAnswerContainer>
                )
              }
            </ItemAnswersContent>
          </ItemAnswersContainer>
        </TimelineItemContainerMobile>
      </React.Fragment>
    </OutsideAlerter>
    :
    <EmptyItem/>
  );
}

const mapStateToProps = state => ({
  food: state.info.food,
  colors: state.info.colours,
  answersInfo: state.wedding.structure
});

export default withLocalize(connect(mapStateToProps)(TimelineItem));