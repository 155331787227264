import PropTypes from 'prop-types';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';

const OrdinalNumberComponent = ({ value, activeLanguage }) => {

  const suffix = activeLanguage.code === 'en'
    ? (value >= 4
      ? 'th'
      : value === 3
        ? 'rd'
        : value === 2
          ? 'nd'
          : value === 1
            ? 'st'
            : '')
    : activeLanguage.code === 'fr'
      ? (value >= 2
        ? 'ème'
        : value === 1
          ? 'er'
          : '')
      : 'º';

  return (
    <NumberFormat
      value={value || 1}
      defaultValue={'00.00'}
      displayType={'text'}
      suffix={suffix}
      decimalScale={0}
      fixedDecimalScale
      allowNegative={false}
    />
  );
};

OrdinalNumberComponent.propTypes = {
  value: PropTypes.number,
  activeLanguage: PropTypes.any,
};

export default OrdinalNumberComponent;
