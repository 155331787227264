import React from "react";
import {
  CardContainer,
  CardContent,
  BgContainer,
  DetailContainer,
  Question,
  Answer,
} from "./WeddingCardsStyles";
import ImageComponent from "../images/ImageComponent";

const ImageCard = ({ question, answer, image, color }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{question}</Question>
        <DetailContainer>
          <BgContainer>
            <ImageComponent ratio={0.6} url={image} color={color}/>
          </BgContainer>
          {
            answer && <Answer hasImage={true} dangerouslySetInnerHTML={{__html: answer}}/>
          }
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default ImageCard;