import { findPageByTag, findQuestionByID, findAnswerByID, findChapterByID } from './PageInfo';
import moment from "moment";

export const findMomentByID = (list, id) => {
  let res = undefined;

  for(let i = 0; i < list.length; i++) {
    if(list[i]._id === id) {
      res = list[i];
      break;
    }
  }

  return res;
};

//Slit the array of moments into 2 using a defined Hour
export const splitTimeline = (list, startHour) => {
  const tempArray1 = [];
  const tempArray2 = [];
  let res = [];

  if(list.length > 0) {
    for(let i = 0; i < list.length; i++) {
      if(moment(list[i].time, 'HH:mm').utc() >= moment(startHour, 'HH:mm').utc()) {
        tempArray1.push(list[i]);
      }
      else {
        tempArray2.push(list[i]);
      }
    }

    //Order the 2 arrays
    tempArray1.sort((a, b) => moment(a.time, 'HH:mm').utc() > moment(b.time, 'HH:mm').utc() ? 1 : moment(a.time, 'HH:mm').utc() < moment(b.time, 'HH:mm').utc() ? -1 : 0);
    tempArray2.sort((a, b) => moment(a.time, 'HH:mm').utc() > moment(b.time, 'HH:mm').utc() ? 1 : moment(a.time, 'HH:mm').utc() < moment(b.time, 'HH:mm').utc() ? -1 : 0);

    //Build the final array
    res = tempArray1.concat(tempArray2);
  }

  return res;
}

const findAnswers = (code, chapters, food, colors) => {
  let res = [], answer = undefined;

  if(code.type === 'question') {
    const question = findQuestionByID(chapters, code.id);

    if(question && question.answer?.response && !question.answer?.response.filename) {
      if(Array.isArray(question.answer.response)) {
        for(let i = 0; i < question.answer.response.length; i++) {
          if(question.answer.response[i].id) {
            answer = findAnswerByID(chapters, food, colors, question.answer.response[i].id);

            if(answer) {
              res.push(answer);
            }
          }
          else {
            answer = findAnswerByID(chapters, food, colors, question.answer.response[0]);

            if(answer) {
              res.push(answer);
            }
          }
        }
      }
      else {
        res.push(question.answer.response);
      }
    }
  }
  else if(code.type === 'chapter') {
    const chapter = findChapterByID(chapters, code.id);
    let question = undefined;

    if(chapter && chapter.questions.length > 0) {
      for(let i = 0; i < chapter.questions.length; i++) {
        question = findQuestionByID(chapters, chapter.questions[i]._id);

        if(question && question.answer?.response && !question.answer?.response.filename) {
          if(Array.isArray(question.answer.response)) {
            for(let j = 0; j < question.answer.response.length; j++) {
              if(question.answer.response[j].id) {
                answer = findAnswerByID(chapters, food, colors, question.answer.response[j].id);

                if(answer) {
                  res.push(answer);
                }
              }
              else {
                answer = findAnswerByID(chapters, food, colors, question.answer.response[0]);

                if(answer) {
                  res.push(answer);
                }
              }
            }
          }
          else {
            res.push(question.answer.response);
          }
        }
      }
    }
  }

  return res;
}

//Find the answers for a specific Moment
export const findMomentAnswers = (code, info, food, colors) => {
  let chapters = [];

  switch(code.section) {
    case 'ceremony':
      ({ chapters } = findPageByTag(info, 'CEREMONY'));

      return findAnswers(code, chapters, food, colors);
    case 'food':
      ({ chapters } = findPageByTag(info, 'FOOD'));

      return findAnswers(code, chapters, food, colors);
    case 'drinks':
      ({ chapters } = findPageByTag(info, 'DRINKS'));

      return findAnswers(code, chapters, food, colors);
    case 'decoration':
      ({ chapters } = findPageByTag(info, 'DECORATION'));

      return findAnswers(code, chapters, food, colors);
    case 'party':
      ({ chapters } = findPageByTag(info, 'PARTY'));

      return findAnswers(code, chapters, food, colors);
    default:
      return [];
  }
}