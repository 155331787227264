import React from "react";
import { withLocalize } from "react-localize-redux";
import { Field } from "redux-form";
import { SectionContainer } from "./DecorationObservationStyles";
import TextAreaInput from "../inputs/TextAreaInput";
import Alert from "../alert/Alert";

const DecorationObservation = ({ inputLabel, canEditSection, isProcessClosed, translate }) => {
  const showAlert = () => {
    if(isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if(!canEditSection) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        // text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  return (
    <SectionContainer>
      <Field
        component={TextAreaInput}
        name="observations"
        placeholder={translate("MESSAGE_WD")}
        label={inputLabel}
        hasCheckbox={false}
        onClick={() => showAlert()}
        readOnly={!canEditSection || isProcessClosed}
      />
    </SectionContainer>
  );
};

export default withLocalize(DecorationObservation);
