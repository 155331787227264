import client from "../config/AxiosConfig";

export const GetUpgradeList = async () =>
  await client.get('/my-wedding/upgrades');

export const GetUpgradeDetail = async id =>
  await client.get(`/my-wedding/upgrades/${id}`);

export const SelectUpgrade = async data =>
  client.put("/my-wedding/upgrades", data);

export const RemoveUpgrade = async data =>
  client.delete("/my-wedding/upgrades", data);

export const MngUpgrade = async (id, data) =>
  client.post(`/my-wedding/upgrade`, data);