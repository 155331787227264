import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { Alert } from "antd";
import SelectInput from "../inputs/SelectInput";
import TextInput from "../inputs/TextInput";

export const TableThemeLabel = {
  NUMBER_FULL: 'TABLE_THEME_NUMBERS_FULL',
  NUMBER: 'TABLE_THEME_NUMBERS',
  OTHER: 'TABLE_THEME_OTHER',
}

const TableThemeLabelValue = {
  NUMBER_FULL: 'Números por extenso',
  NUMBER: 'Números (algarismo)',
  OTHER: 'Outro',
}

export const TableThemeTypes = {
  NUMBER_FULL: 'NUMBER_FULL',
  NUMBER: 'NUMBER',
  OTHER: 'OTHER',
}

const ThemeOptions = [
  { _id: TableThemeTypes.NUMBER_FULL, name: TableThemeLabel[TableThemeTypes.NUMBER_FULL] },
  { _id: TableThemeTypes.NUMBER, name: TableThemeLabel[TableThemeTypes.NUMBER] },
  { _id: TableThemeTypes.OTHER, name: TableThemeLabel[TableThemeTypes.OTHER] },
];

const TableThemeCard = ({ input, canEditSection, isProcessClosed, translate }) => {
  const [optionId, setOptionId] = useState(null);
  const [other, setOther] = useState('');

  useEffect(() => {
    console.log('input', input);
    setOptionId(input?.value?.id || null);
    setOther(input?.value?.value || '');
  }, []);

  const formatThemeOptionLabel = (item) => {
    console.log('item', item);
    return translate(item?.name);
  }

  const onChangeTheme = (value) => {
    console.log('onChangeTheme', value);
    setOptionId(value);
    const otherText = value != TableThemeTypes.OTHER
      ? TableThemeLabelValue[value]
      : '';
    setOther(otherText);
    input.onChange({ id: value, value: otherText });
  }

  const onChangeOther = (value) => {
    console.log('onChangeOther', value);
    setOther(value);
    input.onChange({ id: optionId, value: value });
  }

  const showAlert = () => {
    if (isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if (!canEditSection) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('BLOCKED_ANSWER_TEXT')
      });
    }
  }

  return (
    <React.Fragment>
      <SelectInput
        placeholder={translate('SELECT_OPTION')}
        data={ThemeOptions}
        dataKey={'_id'}
        dataLabel={'name'}
        meta={{}}
        input={{
          value: optionId,
          onChange: onChangeTheme
        }}
        costumeLabel={formatThemeOptionLabel}
        onClick={() => showAlert()}
        readOnly={!canEditSection || isProcessClosed}
      />

      {optionId == TableThemeTypes.OTHER && <TextInput
        label={translate('ANOTHER_OPTION')}
        placeholder={translate('WRITE_TABLE_THEME')}
        meta={{}}
        type="text"
        input={{
          value: other,
          onChange: e => onChangeOther(e.target.value)
        }}
        onClick={() => showAlert()}
        readOnly={!canEditSection || isProcessClosed}
      />}
    </React.Fragment>
  );
};

export default TableThemeCard;