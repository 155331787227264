import React, {Component} from "react";
import StackGrid from "react-stack-grid";
import sizeMe from 'react-sizeme';
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import StyleSuggestions from "../../components/optionCard/StyleSuggestions";
import { 
  OurStyleContainer,
  CardsContainer,
  CardsTitle, 
  EmptyRow,  
  Subtitle
} from "./OurStyleStyles";
import NoImage from "../../components/images/NoImage";
import Alert from '../../components/alert/Alert';
import DecorationFileSection from "../../components/decorationFileSection/DecorationFileSection";
import { AddSuggestion, DeleteSuggestion } from '../../infra/requests/OurStyleRequests';
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData"

class YourSuggestionsForm extends Component {
  state = {
    suggestionsList: [],
    loading: false,
  };

  setLoading = (newState) => {
    this.setState({
      loading: newState
    });
  }

  componentDidMount() {
    const { currentSuggestions, input } = this.props;
    const suggestionsList = [];

    if(currentSuggestions?.length > 0) {
      for(let i = 0; i < currentSuggestions.length; i++) {
        suggestionsList.push(currentSuggestions[i]);
      }
    }

    input.onChange(suggestionsList);
  }

  removeSuggestion = async (elem) => {
    try {
      const { input, isProcessClosed, translate } = this.props;
      
      if(isProcessClosed) {
        Alert.new({
          type: "success",
          title: translate('BLOCKED_ANSWER_TITLE'),
          text: translate('PROCESS_CLOSED_TEXT')
        });
      }
      else {
        const { data, success } = await DeleteSuggestion(elem._id);

        if(success) {
          input.onChange(data.images);

          Alert.new({
            type: "success",
            title: translate("ALERT_SUCCESS_TITLE"),
            text: translate("ALERT_IMAGE_DELETED")
          });
        }
      }
    } 
    catch(e) {
      console.error(e);
    }
  }

  saveSuggestion = async (elem) => {
    try {
      const { input, limitSuggestions, translate } = this.props;

      //Limit of Inspirations: defined in "YourSuggestions.js"
      if(input?.value?.length < limitSuggestions) {
        const payload = TransformToFormData({image: elem});
        const { data, success } = await AddSuggestion(payload);

        if(success) {
          input.onChange(data.images);

          Alert.new({
            type: "success",
            title: translate("ALERT_SUCCESS_TITLE"),
            text: translate("ALERT_IMAGE_SAVED")
          });
        }
      }
      else {
        Alert.new({
          type: "error",
          title: translate("ALERT_ERROR_TITLE"),
          text: translate("ALERT_MAX_SUGGESTIONS")
        });
      }

      this.setLoading(false);
    } 
    catch(e) {
      console.error(e);
    }
  }

  render() {
    const { input, isProcessClosed, translate, size: { width } } = this.props;
    const { loading } = this.state;

    return(
      <OurStyleContainer>
        <Subtitle>{translate('YOUR_SUGGESTIONS')}</Subtitle>
        <CardsContainer>
          <CardsTitle>{translate('SUGGESTIONS_TITLE')}</CardsTitle>
          { 
            input?.value && input.value.length > 0 ?
            <StackGrid 
              columnWidth={width <= 450 ? '100%' : (width <= 768 ? '50%' : '33.33%')}
              gutterWidth={30}
              gutterHeight={30}
              monitorImagesLoaded={true}
              duration={0}
            >
              {
                input.value.map((elem, index) =>
                  <StyleSuggestions
                    key={index}
                    record={elem}
                    input={input}
                    toggleOption={this.removeSuggestion}
                  />
                )
              }
            </StackGrid>
            :
            <EmptyRow>
              <NoImage tag='NO_SUGGESTIONS'/>
            </EmptyRow>
          }
        </CardsContainer>
        <DecorationFileSection
          file={true}
          title=""
          description=""
          input={{
            onChange: value => this.saveSuggestion(value)
          }}
          accept="image/jpeg, image/png"
          setLoading={this.setLoading}
          loading={loading}
          canEditSection={!isProcessClosed}
          hasCrop={true}
        />
      </OurStyleContainer>
    );
  }
};

YourSuggestionsForm.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default withLocalize(sizeMe()(YourSuggestionsForm));