import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

export const Container = styled.div`
  display: flex;
  width: 100%;
  
  background-color: ${p => p.bgColor ? p.bgColor : '#ffffff'};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: ${p => p.type === 1 ? 'row' : 'column'};
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  max-width: 970px;
  width: 100%;
  padding: 70px 30px;
  margin: auto;

  @media ${device.desktopL} {
    padding: 50px 20px;
  }

  @media ${device.laptop} {
    padding: 30px 15px;
  }
`;

export const FirstColumn = styled.div`
  flex-basis: 50%;
  padding-right: 30px;

  @media ${device.tabletS} {
    flex-basis: 100%;
    padding-right: 0px;
    padding-bottom: 30px;
  }
`;

export const SecondColumn = styled.div`
  flex-basis: 50%;

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const FirstRow = styled.div`
  width: 100%;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-flow: row wrap;
`;

export const FirstTextColumn = styled.div`
  flex-basis: ${p => p.hasSecondText ? '50%' : '100%'};
  padding-right: 15px;

  @media ${device.tabletS} {
    flex-basis: 100%;
    padding-right: 0px;
    padding-bottom: 20px;
  }
`;

export const SecondTextColumn = styled.div`
  flex-basis: 50%;
  padding-left: 15px;

  @media ${device.tabletS} {
    flex-basis: 100%;
    padding-left: 0px;
  }
`;

export const BgContainer = styled.div`
  width: 100%;
  max-height: 500px;
  padding: 12px;
  border: 1px solid ${p => p.theme.secondaryBorderColor};
`;

export const Title = styled(titleH5)`
  color: ${p => p.color ? p.color : '#000000'};
  line-height: 29px;
  text-align: left;
  padding-bottom: 45px;
  text-transform: uppercase;

  @media ${device.tabletS} {
    padding-bottom: 20px;
  }
`;

export const Text = styled.div`
  color: ${p => p.color ? p.color : '#000000'};
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  text-align: left;

  & h1, h2, h3, h4, h5, h6 {
    color: ${p => p.color ? p.color : '#000000'} !important;
    margin: 0;
    padding: 0;
  }

  & h1, h2, h5 {
    font-weight: 700;
  }

  & h3 {
    font-weight: 400;
  }

  & h4 {
    font-weight: 300;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 14px;
    line-height: 20px;
  }
`;