import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { SpinLoading } from "../../styles/BasicStyles";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import {
  Content,
  Container,
  Title,
  Legend,
  LegendItem,
  ReserveContent,
  ReserveTitle,
  ReserveSubtitle,
  ReserveList,
  ReserveItem,
  Loading,
  VisitList,
  VisitItem,
  ColorBox,
  ReserveDescription
} from "./AgendaStyles";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import BaseButton from "../../components/buttons/BaseButton";
import moment from "moment";
import Alerts from "../../components/alert/Alert";
import { Button, Checkbox, Col, Dropdown, Icon, Menu, Modal, Popconfirm, Radio, Row, Tooltip } from "antd";
import { CalendarBox, CalendarPicker, LinkAcceptPolicy, RadioPlace, RadioYear } from "../../components/YearView/CalendarStyles";
import YearView from "../../components/YearView/YearView";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import YearInput from "../../components/inputs/YearInput";
import XpertGoYearInput from "../../components/inputs/XpertGoYearInput";
import LoadingIcon from "../../assets/icons/loading.svg";
import SaveButton from "../../components/buttons/SaveButton";
import ReserveInfoModal, { visitedLocals } from "./ReserveInfoModal";
import { GetWedding, GetWeddingInfo, ReservesBlock, UpdateInterestInfo, UpdateReserveRelatedInfo, UpdateWaitingDatePeriodNotification, UpdateWaitingInfo } from "../../infra/requests/MyWeddingRequests";
import DateWarningModal from "./DateWarningModal";
import { ReserveStatus, WeddingStatus } from "../reserve/ReservePage";
import XpertGoLeavePageBlocker from "../../components/pageBlocker/XpertGoLeavePageBlocker";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import { MngMultipleReserves, GetReserveAgenda, GetWeddingReserves, GetReserve, GetReservesWithOrder, CheckDatesAvailable } from "../../infra/requests/ReserveRequests";
import { CreateMultipleWaitingDate, CreateWaitingDate, GetWeddingWaitingDates, SoftDeleteMultipleWaitingDate, SoftDeleteWaitingDate, softDeleteWaitingDateAgenda } from "../../infra/requests/WaintingDateRequests";
import { WaitingIcon } from "../../components/inputs/InputStyles";
import Bookmark from "../../assets/icons/bookmark.svg";
import Star from "../../assets/icons/star.svg";
import { CreateInterestDate, DeleteInterestDateAgenda, GetWeddingInterestDates } from "../../infra/requests/InterestDateRequests";
import WaitingInterestPolicyModal from "./WaitingDatesPolicyModal";
import { AgendaType, locals } from "./AgendaPage";
import { CheckSchedulesAccess, CreateMeetingSchedule, CreateSchedule, FinishSchedule, GetScheduleAgenda, GetServiceSchedules, GetVenueColors, GetWeddingSchedules, GetWeddingSchedulesToAgenda, NotifySchdeduleNoResources, SoftDeleteSchedule } from "../../infra/requests/ScheduleRequests";
import ThreeMonthsView from "../../components/YearView/ThreeMonthsView";
import MonthInput from "../../components/inputs/MonthInput";
import { MaterialLabelFixed } from "../../components/inputs/MaterialStyles";
import momentTimezone from "moment-timezone";
import { calculateColor } from "./utilAgenda";
import NoResourcesModal from "./NoResourcesModal";
import { inAgendaTest } from "../../infra/services/utils/Helpers";
import ServiceSchedulesModal from "./ServiceSchedulesModal";
import { PrimaryColour } from "../../styles/Colours";
const { confirm } = Modal;

export const dateTimeFormat = 'YYYY-MM-DD HH:mm';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
localizer.formats.yearHeaderFormat = 'YYYY'
// localizer.messages.year = 'Year'

export const VisitsBlockedType = {
  NONE: 'NONE',
  SEMI: 'SEMI',
  COMPLETED: 'COMPLETED',
};

export const VisitLanguages = [
  { code: "pt", name: "LANG_PT" },
  { code: "en", name: "LANG_EN" },
  { code: "pt_en", name: "VISIT_LANG_PT_EN" },
];

export const VisitVenues = [
  { _id: 1, name: "SOLAR_VENUE" },
  { _id: 2, name: "LAGO_VENUE" },
  { _id: 3, name: 'BOTH_VENUES' },
];

export const VisitVenuesTypes = {
  SOLAR: "SOLAR_VENUE",
  LAGO: "LAGO_VENUE",
  BOTH: 'BOTH_VENUES',
};
class VisitAgendaPage extends Component {
  state = {
    loading: true,
    content: {},

    agendaType: AgendaType.VISIT,
    loadingAgenda: false,
    month: moment.utc(),
    monthFilter: null,
    local: 3,
    language: null,
    dataSource: [],
    visits: [],
    selectingDay: false,

    visitsLoading: false,
    visitList: [],

    editVisit: null,
    wedding: null,

    serviceList: [],

    colorsLoading: false,
    colors: {
      solar: '#f7b5d6',
      lago: '#b6d7a9',
      both: '#4a90e2'
    },

    acceptedPolicy: false,
    savingWedding: false,
    showPolicyModal: false,

    showNoResourcesModal: false,
    valueNoResourcesModal: null,
    sendingNotificationToAdmin: false,

    showServiceModal: false,
    valueServiceModal: null,
    savingService: false,
  };

  componentDidMount() {
    const { location, translate, history } = this.props;
    const { wedding } = this.props;
    let { agendaType } = this.state;

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    if (!inAgendaTest()) return history.push('/404');

    agendaType = AgendaType.VISIT;
    const blockedAccess = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
      ? wedding?.meetingsBlocked
      : wedding?.visitsBlocked !== VisitsBlockedType.NONE;
    if (blockedAccess) return history.push('/404');

    // Modal.info({
    //   title: translate('ATTENTION'),
    //   content: translate('INTEREST_DATES_INFO'),
    //   onOk: () => { }
    // });

    this.setState({
      agendaType,
      showAgenda: true,
      loading: false,
      wedding,
    }, async () => {
      this.getColors();
      this.getAgenda(false);
    });
  }

  componentDidUpdate(prevProps, props) {

  }

  getColors = async () => {
    let { colors } = this.state;
    this.setState({ colorsLoading: true });

    const result = await GetVenueColors();
    const resultColors = result?.success && result?.data ? result?.data : null;
    if (resultColors) {
      colors.solar = resultColors.solar;
      colors.lago = resultColors.lago;
      colors.both = resultColors.both;
    }

    this.setState({
      colors,
      colorsLoading: false
    });
  }

  getAgenda = async (changeAgenda = true) => {
    const { history, translate, location } = this.props;
    let { month, local, language, agendaType } = this.state;
    let { visitList, editVisit } = this.state;

    this.setState({ loadingAgenda: true, visitsLoading: changeAgenda });

    try {
      const resultWedding = await GetWeddingInfo();
      const wedding = resultWedding?.success && resultWedding?.data ? resultWedding.data : this.props.wedding;
      if (!changeAgenda) {
        local = wedding?.status == WeddingStatus.CONFIRMED
          && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
          ? wedding?.wedding_place
          : wedding?.weddingPlaceToVisit;
        language = wedding?.status == WeddingStatus.CONFIRMED
          && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
          ? wedding?.language
          : wedding?.languageToVisit;
      }

      if (resultWedding?.success) {
        const blockedAccess = wedding?.status == WeddingStatus.CONFIRMED
          && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
          ? (wedding?.meetingsBlocked || false)
          : wedding?.visitsBlocked !== VisitsBlockedType.NONE;
        if (wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED && location?.pathname != '/meeting-agenda') {
          return history.push('/meeting-agenda');
        } else if (wedding?.visitsBlocked == VisitsBlockedType.NONE && location?.pathname != '/visit-agenda') {
          return history.push('/visit-agenda');
        } else if (blockedAccess) {
          return history.push('/404');
        }
      }

      const resultAccess = await CheckSchedulesAccess();
      const canAccess = resultAccess?.success ? resultAccess?.data : false;
      // If user without interestDates or reserves
      if (!canAccess) {
        Modal.info({
          title: translate('ATTENTION'),
          content: (
            <div>
              <p>{translate('STEP1_CREATE_VISIT')}</p>
              <p>{translate('STEP2_CREATE_VISIT')}</p>
            </div>
          ),
          onOk() {
            return history.push('/interest-agenda?return=V');
          },
        });
      }

      const filters = { month, local };
      const result = await GetScheduleAgenda(filters);
      const data = result && result.data ? result.data : [];

      await this.getSchedules();

      this.setState({
        dataSource: data,
        loadingAgenda: false,
        visitsLoading: false,
        wedding,
        local,
        language,
        acceptedPolicy: wedding?.acceptedSchedulePolicy || false,
      });
    } catch (e) {
      this.setState({ loadingAgenda: false, visitsLoading: false });
      console.error(e);
    }
  }

  getSchedules = async () => {
    this.setState({ visitsLoading: true });

    const result = await GetWeddingSchedules(JSON.stringify({}));

    this.setState({
      visitList: result?.success && result?.data ? result?.data : [],
      visitsLoading: false
    });
  }

  changeMonth = (event) => {
    const { month } = this.state;
    this.setState({ month: moment.utc(month).add(parseInt(event.target.value), 'month') }, () => this.getAgenda(true));
  }

  /** Dates */
  onDayClick = async (value) => {
    const { translate } = this.props;
    const { wedding } = this.state;
    const { local, language, visitList, editVisit } = this.state;

    // Check if wedding/couple is in reserve phase or is allowed to schedule a visit
    const blockedAccess = wedding?.status == WeddingStatus.CONFIRMED
      ? (wedding?.meetingsBlocked || false)
      : wedding?.visitsBlocked !== VisitsBlockedType.NONE;
    if (!wedding || blockedAccess) return;

    if (moment.utc(value?.date).isBefore(moment.utc().startOf('date'))) {
      this.setState({ selectingDay: false });
      return Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_VISITS_BEFORE_TODAY')
      });
    }

    // Check if date is already selected
    if (visitList?.length > 0
      && visitList?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === moment.utc(value.date).format('DDDYYYY'))) {

      if (wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED) {
        const meeting = visitList?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === moment.utc(value.date).format('DDDYYYY'));
        confirm({
          title: translate('DELETE_MEETING_QUESTION'),
          content: translate('DELETE_MEETING_INFO'),
          okType: 'danger',
          okText: translate('DELETE'),
          onOk: () => this.removeDate(meeting),
          onCancel: () => null,
        });
        return;
      } else {
        return Alerts.new({
          type: 'warning',
          title: translate('ATTENTION'),
          text: translate('ERROR_DATE_ALREADY_SELECTED')
        });
      }
    }

    if (wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED) {
      this.getServiceSchedules(value);
    } else this.saveVisit(value);
  }

  saveVisit = async (value) => {
    const { translate } = this.props;
    const { wedding } = this.state;
    const { local, language, visitList, editVisit } = this.state;
    let { dataSource } = this.state;
    this.setState({ selectingDay: true });

    if (!language && wedding?.visitsBlocked !== VisitsBlockedType.COMPLETED) {
      this.setState({ selectingDay: false });
      return Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_SELECT_VISIT_LANGUAGE')
      });
    }

    if (!local && wedding?.visitsBlocked !== VisitsBlockedType.COMPLETED) {
      this.setState({ selectingDay: false });
      return Alerts.new({
        type: 'warning',
        title: translate('ATTENTION'),
        text: translate('ERROR_SELECT_VISIT_VENUE')
      });
    }

    this.setState({ visitsLoading: true });

    const data = {
      weddingPlace: local,
      date: value.date,
      language: language
    };
    const result = await CreateSchedule(data);

    if (result?.success) {
      Modal.info({
        title: translate('SUCCESS_SCHEDULE_VISIT'),
        okText: translate('UNDERSTOOD'),
        centered: true,
        onOk() { },
      });
      this.setState({
        visitsLoading: false,
        selectingDay: false
      }, () => this.getSchedules());
    } else {
      if (result?.message === 'ERROR_NO_RESOURCES') {
        this.setState({
          visitsLoading: false,
          selectingDay: false,
          showNoResourcesModal: true,
          valueNoResourcesModal: value
        });
      } else if (result?.message == 'NOT_AUTHORIZED_REROUTE') {
        Alerts.new({
          type: "warning",
          title: translate("ATTENTION"),
          text: translate('WAIT_RELOAD_PAGE'),
        });
        this.getAgenda();
      } else {
        Alerts.new({
          type: 'error',
          title: translate('ERROR'),
          text: translate(result?.message || 'GENERIC_ERROR')
        });
        this.setState({
          visitsLoading: false,
          selectingDay: false,
        });
      }
    }
  }

  getServiceSchedules = async (value) => {
    const { translate } = this.props;
    const filter = { date: value.date };
    const result = await GetServiceSchedules(filter);

    if (result?.success) {
      // Open modal with services
      this.setState({
        showServiceModal: true,
        valueServiceModal: {
          date: value?.date,
          services: result?.data?.scheduleServices || [],
          showWarning: result?.data?.showOrganizerWarning || false
        }
      });
    } else {
      if (result?.message === 'ERROR_NO_RESOURCES') {
        this.setState({
          visitsLoading: false,
          selectingDay: false,
          showNoResourcesModal: true,
          valueNoResourcesModal: value
        });
      } else if (result?.message == 'NOT_AUTHORIZED_REROUTE') {
        Alerts.new({
          type: "warning",
          title: translate("ATTENTION"),
          text: translate('WAIT_RELOAD_PAGE'),
        });
        this.getAgenda();
      }
    }
  }

  saveMeeting = async (values) => {
    const { translate, history } = this.props;
    this.setState({ savingService: true });

    const result = await CreateMeetingSchedule(values);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('MEETING_SCHEDULED')
      })
      this.setState({
        savingService: false,
        showServiceModal: false,
        valueServiceModal: null
      }, () => history.push('/meeting-list'));
    } else {
      if (result?.message == 'NOT_AUTHORIZED_REROUTE') {
        Alerts.new({
          type: "warning",
          title: translate("ATTENTION"),
          text: translate('WAIT_RELOAD_PAGE'),
        });
        this.getAgenda();
      } else {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: translate(result.message),
        });
      }
      this.setState({ savingService: false });
    }
  }

  getRemoveMessage = () => {
    const { translate } = this.props;

    return <div>
      <div>{translate('DELETE_VISIT_QUESTION')}</div>
      <div>{translate('DELETE_VISIT_INFO')}</div>
    </div>
  }

  removeDate = async (schedule) => {
    const { translate } = this.props;
    const { wedding } = this.state;

    // Check if wedding/couple is in reserve phase or is allowed to schedule a visit
    const date = moment.utc(schedule.startDate).startOf('day');
    const blockedAccess = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
      ? wedding?.meetingsBlocked
      : wedding?.visitsBlocked !== VisitsBlockedType.NONE;
    if (!wedding || blockedAccess || (!schedule?.presence && moment.utc().isSameOrAfter(date, 'day'))) {
      return;
    }

    this.setState({ visitsLoading: true });

    const result = await SoftDeleteSchedule(schedule?._id, true);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.CONFIRMED ? translate('MEETING_DELETED') : translate('VISIT_DELETED')
      });
      this.getAgenda();
    } else {
      if (result?.message == 'NOT_AUTHORIZED_REROUTE') {
        Alerts.new({
          type: "warning",
          title: translate("ATTENTION"),
          text: translate('WAIT_RELOAD_PAGE'),
        });
        this.getAgenda();
      } else {
        Alerts.new({
          type: "error",
          title: translate("ERROR"),
          text: translate(result.message),
        });
      }
      this.setState({ visitsLoading: false });
    }
  }

  // changeWeddingInfo = async (event) => {
  //   const { translate } = this.props;
  //   const { acceptedPolicy } = this.state;
  //   this.setState({ savingPolicy: true });

  //   const data = {
  //     accepted: acceptedPolicy
  //   };
  //   const result = await UpdateInterestInfo(data);

  //   if (result?.success) {
  //     Alerts.new({
  //       type: 'success',
  //       title: translate('SUCCESS'),
  //       text: translate('INTEREST_DADES_INFO_UPDATED')
  //     });
  //     this.setState({
  //       wedding: result?.data,
  //       acceptedPolicy: result?.data?.acceptedSchedulePolicy || false,
  //       savingPolicy: false
  //     });
  //   } else {
  //     this.setState({ savingPolicy: false });
  //     Alerts.new({
  //       type: 'error',
  //       title: translate('ERROR'),
  //       text: translate('GENERIC_ERROR')
  //     });
  //   }

  // }

  notifyAdminsOfNoResources = async () => {
    const { translate } = this.props;
    const { valueNoResourcesModal, wedding } = this.state;

    this.setState({ sendingNotificationToAdmin: true });

    const inOrganizationAgenda = wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
      ? true
      : false;
    const data = {
      visit: inOrganizationAgenda ? false : true,
      ...valueNoResourcesModal
    };
    const result = await NotifySchdeduleNoResources(data);

    if (result?.success) {
      Alerts.new({
        type: 'success',
        title: translate('SUCCESS'),
        text: translate('INFO_REGISTERED_SUCCESS')
      });
      this.setState({
        sendingNotificationToAdmin: false,
        showNoResourcesModal: false,
        valueNoResourcesModal: null
      });
    } else {
      Alerts.new({
        type: "error",
        title: translate("ERROR"),
        text: translate(result.message),
      });
      this.setState({
        sendingNotificationToAdmin: false,
      });
    }
  }

  // Disable agenda by month
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current.isSameOrBefore(moment.utc().startOf('month'), 'date');
  }

  checkDisabledPreviousMonth = () => {
    const { month } = this.state;
    return month.startOf('month').isSameOrBefore(moment.utc().startOf('month'), 'date');
  }

  render() {
    const { translate, history, activeLanguage, location, menuOpen } = this.props;
    const { content, loading, wedding } = this.state;
    const { acceptedPolicy, savingPolicy } = this.state;
    const { loadingAgenda, agendaType } = this.state;
    const { month, monthFilter, local, language, dataSource } = this.state;
    const { colorsLoading, colors } = this.state;
    const { visitList, visitsLoading } = this.state;
    const { showPolicyModal } = this.state;
    const { showNoResourcesModal, valueNoResourcesModal, sendingNotificationToAdmin } = this.state;
    const { showServiceModal, valueServiceModal, savingService } = this.state;
    const { selectingDay } = this.state;

    const reservePhase = (!wedding || wedding?.status === WeddingStatus.RESERVE) ? true : false;
    const reserveSinalizationPhase = wedding && wedding?.status === WeddingStatus.RESERVE_SINALIZATION ? true : false;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        {/* <XpertGoLeavePageBlocker
          when={visitList.filter(f=> f?.temporary).length > 0}
          message={translate("UNSAVED_OPTION_CHANGES")}
        /> */}
        <Content>
          <Container confirmed={!reservePhase && !reserveSinalizationPhase}>
            <Row gutter={[12, 12]} type='flex'>
              <Col
                xl={{ span: wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? 24 : reservePhase ? 20 : 18, order: 1 }}
                lg={{ span: wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? 24 : reservePhase ? 19 : 17, order: 1 }}
                md={{ span: 24, order: 2 }}
                sm={{ span: 24, order: 2 }}
                xs={{ span: 24, order: 2 }}>
                <CalendarBox reserves={true}>
                  <Title>{wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? translate('MNG_MEETINGS') : translate('MNG_VISITS')}</Title>

                  <Row gutter={[12, 12]}>
                    {wedding?.visitsBlocked !== VisitsBlockedType.COMPLETED && <React.Fragment>
                      <Col xs={24} style={{ textAlign: 'start' }}>
                        <MaterialLabelFixed>{translate('VISIT_LANGUAGE')}</MaterialLabelFixed>
                        <Radio.Group
                          onChange={(e) => this.setState({ language: e.target.value })}
                          value={language}
                          disabled={loadingAgenda}>
                          {VisitLanguages.map(m => <Radio key={m?.code} value={m.code}>{translate(m.name)}</Radio>)}</Radio.Group>
                      </Col>
                      <Col xs={24} style={{ textAlign: 'start' }}>
                        <MaterialLabelFixed>{translate('VISIT_VENUE')}</MaterialLabelFixed>
                        <Radio.Group
                          onChange={(e) => this.setState({ local: e.target.value }, () => this.getAgenda(true))}
                          value={local}
                          style={{ marginBottom: 10 }}
                          disabled={loadingAgenda}>
                          {VisitVenues.map(m => <Radio key={m?._id} value={m._id}>{translate(m.name)}</Radio>)}</Radio.Group>
                      </Col>
                    </React.Fragment>}
                    <Col xs={24} md={12} lg={11} xl={!reservePhase && menuOpen ? 12 : !reservePhase ? 13 : 15} xxl={!reservePhase && menuOpen ? 9 : 15}>
                      <MaterialLabelFixed>{wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED ? translate('MEETING_DATE') : translate('VISIT_DATE')}</MaterialLabelFixed>
                    </Col>
                    <Col xs={24} md={7} lg={5} xl={!reservePhase && menuOpen ? 8 : !reservePhase ? 7 : 5} xxl={!reservePhase && menuOpen ? 10 : 5}>
                      <RadioYear menuOpen={menuOpen} reservePhase={reservePhase}>
                        <Radio.Group value={0} disabled={loadingAgenda} onChange={(event) => this.changeMonth(event)}>
                          <Radio.Button value={-1} disabled={this.checkDisabledPreviousMonth()}>
                            <Tooltip title={translate('PREVIOUS_MONTH')}>
                              <Icon type="left" />
                            </Tooltip>
                          </Radio.Button>
                          <Radio.Button value={0}>{month.format('MMM/YYYY').toUpperCase()}</Radio.Button>
                          <Radio.Button value={1}>
                            <Tooltip title={translate('NEXT_MONTH')}>
                              <Icon type="right" />
                            </Tooltip>
                          </Radio.Button>
                        </Radio.Group>
                      </RadioYear>
                    </Col>
                    <Col xs={24} md={5} lg={4} xl={!reservePhase && menuOpen ? 4 : !reservePhase ? 4 : 4} xxl={!reservePhase && menuOpen ? 5 : 4}>
                      <CalendarPicker menuOpen={menuOpen} reservePhase={reservePhase}>
                        <MonthInput
                          first={true}
                          placeholder={translate('SELECT_MONTH')}
                          input={{
                            value: monthFilter,
                            onChange: (value) => {
                              this.setState({
                                monthFilter: value,
                                month: value !== '' ? value : moment.utc()
                              }, this.getAgenda);
                            }
                          }}
                          disabled={loadingAgenda}
                          disabledDate={this.disabledDate}
                          allowClear={true}
                          meta={{}} />
                      </CalendarPicker>
                    </Col>
                  </Row>

                  <Row>
                    <div style={{ marginTop: '2rem', position: 'relative' }}>
                      {loadingAgenda ?
                        <Loading src={LoadingIcon} /> :
                        <React.Fragment>
                          {(visitsLoading || selectingDay || colorsLoading)
                            && <div className='backdrop'>
                              <Loading src={LoadingIcon} style={{ position: 'absolute', minHeight: 30, height: 30, top: '40%', right: '45%' }} />
                            </div>}
                          <Calendar
                            loading={loadingAgenda || visitsLoading || selectingDay}
                            localizer={localizer}
                            activeLanguage={activeLanguage}
                            translate={translate}
                            selecting={selectingDay}
                            defaultDate={moment.utc(month).toDate()}
                            events={dataSource}
                            schedules={visitList}
                            wedding={wedding}
                            reserveView={agendaType === AgendaType.AGENDA}
                            agendaType={agendaType}
                            menuOpen={menuOpen}
                            toolbar={false}
                            defaultView='threemonth'
                            views={{
                              // day: true,
                              // week: true,
                              // month: true,
                              threemonth: ThreeMonthsView
                            }}
                            messages={{ year: 'threemonth' }}
                            onDayClick={this.onDayClick}
                          />
                          <Legend>
                            {wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
                              ? <LegendItem><ColorBox color={PrimaryColour} /> {translate('MEETING_CONFIRMED')}</LegendItem>
                              : <React.Fragment>
                                <LegendItem><ColorBox color={colors?.solar} /> {translate('EVENT_SOLAR_VENUE')}</LegendItem>
                                <LegendItem><ColorBox color={colors?.lago} /> {translate('EVENT_LAGO_VENUE')}</LegendItem>
                              </React.Fragment>}
                            <LegendItem><b>{translate('HOLIDAY_SHORT')}:</b> {translate('HOLIDAY_LEGEND')}</LegendItem>
                            <LegendItem><b>{translate('LOCAL_HOLIDAY_SHORT')}:</b> {translate('LOCAL_HOLIDAY_LEGEND')}</LegendItem>
                            <LegendItem><b>{translate('UNAVAILABLE_DATE_SHORT')}:</b> {translate('UNAVAILABLE_DATE')}</LegendItem>
                          </Legend>
                        </React.Fragment>
                      }
                    </div>
                  </Row>
                </CalendarBox>
              </Col>
              {wedding?.visitsBlocked === VisitsBlockedType.NONE && <Col className='reserve-column'
                xl={{ span: 4, order: 2 }}
                lg={{ span: 5, order: 2 }}
                md={{ span: 24, order: 1 }}
                sm={{ span: 24, order: 1 }}
                xs={{ span: 24, order: 1 }}>
                <ReserveContent>
                  {wedding && (reservePhase || reserveSinalizationPhase) && <React.Fragment>
                    <Row gutter={[12, 12]} className='reserve-title'>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <ReserveTitle>{translate('SELECT_AGENDA')}</ReserveTitle>
                      </Col>
                    </Row>
                    <Row gutter={[12, 12]} className='reserve-title'>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <Radio.Group
                          onChange={(e) => this.setState({ agendaType: e?.target.value }, () => this.props.history.push('/' + e?.target.value))}
                          value={agendaType}
                          disabled={loadingAgenda}
                          className="agenda-options">
                          <Radio value={AgendaType.AGENDA}>{translate('RESERVE_AGENDA')}</Radio>
                          {wedding?.visitsBlocked == VisitsBlockedType.NONE && <Radio value={AgendaType.VISIT}>{translate('RESERVE_AGENDA_PLUS_VISITS')}</Radio>}
                          {reservePhase && <Radio value={AgendaType.INTEREST}>{translate('RESERVE_AGENDA_PLUS_INTEREST_DATES')}</Radio>}
                          <Radio value={AgendaType.WAITING_DATE}>{translate('RESERVE_AGENDA_PLUS_WAITING_DATE_LIST')}</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                  </React.Fragment>}

                  {agendaType === AgendaType.VISIT && <React.Fragment>
                    <Row gutter={[12, 12]} className='reserve-title'>
                      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                        <ReserveTitle>{translate('VISIT_SCHEDULES')}</ReserveTitle>
                        <ReserveDescription>
                          <Icon type="clock-circle" />
                          &nbsp;
                          <span>{translate('LISBON_TIME')}</span>
                        </ReserveDescription>
                      </Col>
                    </Row>



                    <Row gutter={[12, 12]}>
                      <Col xs={24} sm={12} md={12} lg={24} xl={24}>
                        <ReserveSubtitle>{local?.name}</ReserveSubtitle>
                        {visitsLoading && <Loading src={LoadingIcon} width={30} />}
                        {visitList?.length === 0 && <p>{translate('NO_VISITS')}</p>}
                        <VisitList>
                          {visitList
                            .map(m => ({ ...m, dateStr: moment.utc(m.date).format('YYYY-MM-DD'), dateTimeStr: `${moment.utc(m.date).format('YYYY-MM-DD')} ${m.startTime}` }))
                            .sort((a, b) => momentTimezone(a.dateTimeStr, dateTimeFormat).tz('Europe/Lisbon').isBefore(momentTimezone(b.dateTimeStr, dateTimeFormat).tz('Europe/Lisbon')) ? -1 : 1)
                            .map((schedule, indexR) => {
                              const date = moment.utc(schedule.startDate).startOf('day');

                              return <VisitItem key={indexR}>
                                <div className="info">
                                  <div className="info-clock">
                                    <span>
                                      <b>{translate('DATE')}:</b>&nbsp;
                                      {moment.utc(schedule.date).format('DD/MM/YYYY')}
                                    </span>
                                    <span className="clock">
                                      {momentTimezone.utc(schedule.startDate).tz("Europe/Lisbon").format('HH:mm')}
                                      <Icon type="swap-right" />
                                      {momentTimezone.utc(schedule.endDate).tz("Europe/Lisbon").format('HH:mm')}
                                    </span>
                                  </div>
                                  <span className="place">
                                    <b>{translate('VENUE')}:</b>&nbsp;
                                    {schedule.weddingPlace === 3
                                      ? translate(VisitVenuesTypes.BOTH)
                                      : schedule.weddingPlace === 2
                                        ? translate(VisitVenuesTypes.LAGO)
                                        : translate(VisitVenuesTypes.SOLAR)
                                    }
                                  </span>
                                </div>
                                {/* Delete */}
                                {!schedule?.presence && moment.utc().isBefore(date, 'day')
                                  && <Popconfirm
                                    placement="topRight"
                                    title={this.getRemoveMessage()}
                                    okText={translate('DELETE')}
                                    cancelText={translate('CANCEL')}
                                    onConfirm={async () => await this.removeDate(schedule)}>
                                    <Icon type="delete" />
                                  </Popconfirm>}
                              </VisitItem>
                            })}
                        </VisitList>
                      </Col>
                    </Row>

                    {/* <Row gutter={[12, 12]}>
                      {(loading || loadingAgenda)
                        ? <Loading src={LoadingIcon} width={30} />
                        : <React.Fragment>
                          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Checkbox
                              checked={acceptedPolicy}
                              disabled={wedding?.acceptedSchedulePolicy}
                              onChange={(e) => this.setState({ acceptedPolicy: e?.target?.checked })}>
                              <LinkAcceptPolicy onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showPolicyModal: true })
                              }}>{translate('ACCEPT_INTEREST_POLICY')}</LinkAcceptPolicy>
                            </Checkbox>
                          </Col>
                        </React.Fragment>}
                    </Row>
                    <Row gutter={[12, 12]} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                      <SaveButton
                        htmlType="submit"
                        icon='save'
                        text={translate("SAVE")}
                        textMobile={translate("SAVE")}
                        onClick={this.changeWeddingInfo}
                        loading={loading || loadingAgenda || savingPolicy ? true : false}
                      />
                    </Row> */}
                  </React.Fragment>}
                </ReserveContent>
              </Col>}
            </Row>

            {/* {showPolicyModal
              && <WaitingInterestPolicyModal
                openModal={showPolicyModal}
                closeModal={() => this.setState({ showPolicyModal: false })}
                translate={translate}
                agendaType={agendaType}
                activeLanguage={activeLanguage}
              />} */}

            {showNoResourcesModal
              && <NoResourcesModal
                openModal={showNoResourcesModal}
                loading={sendingNotificationToAdmin}
                closeModal={() => this.setState({ showNoResourcesModal: false, valueNoResourcesModal: null })}
                onSubmit={() => this.notifyAdminsOfNoResources()}
                translate={translate}
                activeLanguage={activeLanguage}
                initialValues={valueNoResourcesModal}
              />}

            {showServiceModal &&
              <ServiceSchedulesModal
                openModal={showServiceModal}
                loading={savingService}
                closeModal={() => this.setState({ showServiceModal: false, valueServiceModal: [] })}
                onSubmit={(values) => this.saveMeeting(values)}
                translate={translate}
                activeLanguage={activeLanguage}
                initialValues={valueServiceModal}
              />}
          </Container>
        </Content>
      </React.Fragment >
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  menuOpen: state.menu
});

export default withLocalize(withRouter(connect(mapStateToProps)(VisitAgendaPage)));