import React from 'react'
import moment from 'moment'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { Badge, Button, Col, Dropdown, Icon, Menu, Row, Tooltip } from 'antd'
import CurrencyComponent from '../currency/CurrencyComponent'
import Bookmark from "../../assets/icons/bookmark.svg";
import SelectedBookmark from "../../assets/icons/selected_bookmark.svg";
import Star from "../../assets/icons/star.svg";
import SelectedStar from "../../assets/icons/selected_star.svg";
import { WaitingIcon } from '../inputs/InputStyles'
import { AgendaType, WaitingActionType } from '../../pages/agenda/AgendaPage'
import { WeddingStatus } from '../../pages/reserve/ReservePage'
import { PrimaryColour } from '../../styles/Colours'

function createCalendar(currentDate) {
    if (!currentDate) {
        currentDate = moment()
    } else {
        currentDate = moment(currentDate)
    }

    const first = currentDate.clone().startOf('month')
    const last = currentDate.clone().endOf('month')
    const weeksCount = Math.ceil((first.day() + last.date()) / 7)
    const calendar = Object.assign([], { currentDate, first, last })

    for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
        const week = []
        calendar.push(week)
        calendar.year = currentDate.year()
        calendar.month = currentDate.month()

        for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
            const date = currentDate.clone().set('date', day + 1 - first.day())
            date.calendar = calendar
            week.push(date)
        }
    }

    return calendar
}

function getHolidayName(holiday, activeLanguage) {
    // console.warn('activeLanguage', activeLanguage);
    return activeLanguage.code === 'pt' ? holiday.localName : holiday.name;
}

function CalendarDate(props) {
    // console.warn('Props Date', props);
    const { activeLanguage, translate } = props;
    const { dateToRender, dateOfMonth, events, waitingDates, interestDates, selecting, wedding } = props;
    const { reserveView, agendaType } = props;
    const date = moment.utc(dateToRender.toISOString());
    const tmpId = moment.utc(date).format('DDDYYYY');
    const event = events.find(f => f.id === tmpId);
    const waitingDate = agendaType === AgendaType.WAITING_DATE
        ? waitingDates?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === tmpId)
        : null;
    const interestDate = agendaType === AgendaType.INTEREST
        ? interestDates?.find(f => f?.date && moment.utc(f?.date).format('DDDYYYY') === tmpId)
        : null;
    const weddingReserve = event && event.resource && event.resource.weddingReserve ? event.resource.weddingReserve : null;

    const today = dateToRender.format('YYYY-MM-DD') === moment.utc().format('YYYY-MM-DD') ? 'today' : '';
    const occupied = event && event.resource.isDateOccupied ? 'occupied' : '';
    const selected = agendaType === AgendaType.AGENDA && event && event.resource.selected ? 'selected' : '';

    const style = event && event.resource.holiday || event && event.resource.localHoliday
        ? { color: PrimaryColour }
        : agendaType === AgendaType.AGENDA && event && event.resource.reserves.length > 0
            ? {
                backgroundColor: event ? event.resource.color : '',
                color: event ? event.resource.color === PrimaryColour ? 'white' : 'grey' : ''
            }
            : null;

    const borderBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0
        ? '1px solid rgb(255 255 255 / 90%)'
        : 'none';

    const marginBottom = event && !event.resource.isCompanyRestDay &&
        !event.resource.isDateOccupied && event.resource.price
        && event.resource.price.minPax > 0 && event.resource.price.minPrice > 0
        ? 5
        : 0;

    if (dateToRender.month() < dateOfMonth.month() || dateToRender.month() > dateOfMonth.month()) {
        return (<button disabled={true} className="date next-month prev-month"></button>)
    }

    // if (dateToRender.month() < dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date prev-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    // if (dateToRender.month() > dateOfMonth.month()) {
    //     return (
    //         <button disabled={true} className="date next-month">
    //             {dateToRender.date()}
    //         </button>
    //     )
    // }

    const tooltip = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday, activeLanguage)}
            </p>
        }

        {event?.resource?.reserves?.length > 0
            && !event?.resource?.isDateOccupied ?
            <React.Fragment>
                <p style={{ marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                    {translate('CAN_RESERVE')}
                </p>
                <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                    {translate('RESERVES_BY_DATE_PART1')} {event.resource.reserves.length} {translate('RESERVES_BY_DATE_PART2')}
                </p>
            </React.Fragment>
            : <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                {event ? translate(event.title) : ''}
            </p>}

        {event && !event.resource.isCompanyRestDay && !event.resource.isDateOccupied && event.resource.price
            && event.resource.price?.minPax && event.resource.price?.minPax > 0 && event.resource.price?.minPrice && event.resource.price?.minPrice > 0 ?
            <React.Fragment>
                <p style={{ marginBottom: 0 }}>
                    <span><CurrencyComponent value={event.resource.price.minPrice} /></span>
                    &nbsp; {translate('BY')}
                    &nbsp; {translate('PAX')}
                </p>
                <p style={{ marginBottom: 0 }}>
                    {translate('MINIMUM')}:
                    &nbsp; <span>{event.resource.price.minPax}</span>
                    &nbsp; {translate('PAX')}
                </p>
                <p style={{ marginBottom: 0 }}>
                    {translate('MINIMUM_GLOBAL_PRICE')}: &nbsp;
                    <span><CurrencyComponent value={parseFloat((event.resource.price.minPax * event.resource.price.minPrice).toFixed(2))} /></span>
                </p>
                {reserveView && agendaType === AgendaType.AGENDA &&
                    <div className='mobile' style={{ textAlign: 'center', marginTop: '5px' }}>
                        <Button
                            type="primary"
                            loading={selecting}
                            onClick={(e) => reserveView && agendaType === AgendaType.AGENDA
                                ? props.onClick({ date, event })
                                : e?.preventDefault()}>
                            {translate('SELECT')}
                        </Button>
                    </div>
                }
            </React.Fragment>
            : null
        }
    </React.Fragment>

    const tooltipWaiting = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday, activeLanguage)}
            </p>
        }

        <p className='desktop' style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {waitingDate ? translate('REMOVE_WAITING_DATE') : translate('ADD_WAITING_DATE')}
        </p>

        <p className='mobile' style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {translate('WAITING_DATE')}
        </p>

        {!reserveView && agendaType === AgendaType.WAITING_DATE
            && <div className='mobile' style={{ textAlign: 'center', marginTop: '5px' }}>
                <Button
                    type="primary"
                    loading={selecting}
                    onClick={(e) => !reserveView && agendaType === AgendaType.WAITING_DATE
                        ? props.onWaitingClick({ date, selected: waitingDate ? true : false })
                        : e?.preventDefault()}>
                    {waitingDate ? translate('REMOVE_WAITING_DATE_MOBILE') : translate('ADD_WAITING_DATE_MOBILE')}
                </Button>
            </div>}
    </React.Fragment>

    const tooltipInterest = <React.Fragment>
        {(event && event.resource.holiday || event && event.resource.localHoliday) &&
            <p style={{ marginBottom: 0, textAlign: 'center', fontSize: 13, color: '#dbdbdb' }}>
                {getHolidayName(event && event.resource.localHoliday ? event.resource.localHoliday : event.resource.holiday, activeLanguage)}
            </p>
        }

        <p style={{ borderBottom, marginBottom, textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
            {event?.resource?.interestDates > 0
                ? `${translate('INTEREST_DATE_PART1')} ${event?.resource?.interestDates} ${translate('INTEREST_DATE_PART2')}`
                : translate('INTEREST_DATE')}
        </p>

        {event && event.resource.price && event.resource.price.minPax && event.resource.price.minPax > 0
            && event.resource.price?.minPrice && event.resource.price?.minPrice > 0 &&
            <React.Fragment>
                <p style={{ marginBottom: 0 }}>
                    <span><CurrencyComponent value={event.resource.price?.minPrice} /></span>
                    &nbsp; {translate('BY')}
                    &nbsp; {translate('PAX')}
                </p>
                <p style={{ marginBottom: 0 }}>
                    {translate('MINIMUM')}:
                    &nbsp; <span>{event.resource.price.minPax}</span>
                    &nbsp; {translate('PAX')}
                </p>
                <p style={{ marginBottom: 0 }}>
                    {translate('MINIMUM_GLOBAL_PRICE')}: &nbsp;
                    <span><CurrencyComponent value={parseFloat((event.resource.price.minPax * event.resource.price.minPrice).toFixed(2))} /></span>
                </p>
            </React.Fragment>
        }

        {!reserveView && agendaType === AgendaType.INTEREST
            && <div className='mobile' style={{ textAlign: 'center', marginTop: '5px' }}>
                <Button
                    type="primary"
                    loading={selecting}
                    onClick={(e) => !reserveView && agendaType === AgendaType.INTEREST
                        ? props.onInterestClick({ date, event, selected: interestDate ? true : false })
                        : e?.preventDefault()}>
                    {interestDate ? translate('REMOVE_INTEREST_DATE_MOBILE') : translate('ADD_INTEREST_DATE_MOBILE')}
                </Button>
            </div>
        }
    </React.Fragment>

    const waitingDateTxt = waitingDate && !reserveView && agendaType === AgendaType.WAITING_DATE
        ? event && event.resource.localHoliday
            ? translate('LOCAL_HOLIDAY_SHORT')
            : event && event.resource.holiday
                ? translate('HOLIDAY_SHORT')
                : dateToRender.date()
        : <b>{dateToRender.date()}</b>;
    const interestDateTxt = interestDate && !reserveView && agendaType === AgendaType.INTEREST
        ? event && event.resource.localHoliday
            ? translate('LOCAL_HOLIDAY_SHORT')
            : event && event.resource.holiday
                ? translate('HOLIDAY_SHORT')
                : dateToRender.date()
        : <b>{dateToRender.date()}</b>;

    return event && event?.resource?.isCompanyRestDay
        ? <React.Fragment>
            <Tooltip title={tooltip} trigger='click' className='mobile'>
                <button
                    className={`date in-month ${occupied} ${today} ${selected}`}
                    style={style}>
                    {event && event.resource.isCompanyRestDay
                        ? translate('UNAVAILABLE_DATE_SHORT')
                        : dateToRender.date()
                    }
                </button>
            </Tooltip>
            <Tooltip title={tooltip} trigger='hover' className='desktop'>
                <button
                    className={`date in-month ${occupied} ${today} ${selected}`}
                    style={style}>
                    {event && event.resource.isCompanyRestDay
                        ? translate('UNAVAILABLE_DATE_SHORT')
                        : dateToRender.date()
                    }
                </button>
            </Tooltip>
        </React.Fragment>
        // : event && (!event.resource.price || (event?.resource?.price && (event?.resource?.price?.minPax <= 0 || event?.resource?.price?.minPrice <= 0)))
        //     ? <React.Fragment>
        //         <Tooltip
        //             title={
        //                 !reserveView && agendaType === AgendaType.INTEREST
        //                     ? tooltipInterest
        //                     : tooltip
        //             }
        //             trigger='click'
        //             className='mobile'>
        //             <button
        //                 className={`date in-month ${occupied} ${today} ${selected}`}
        //                 style={style}>
        //                 {interestDate && !reserveView && agendaType === AgendaType.INTEREST
        //                     ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
        //                         {dateToRender.date()}
        //                     </Badge>
        //                     : event && event.resource.localHoliday
        //                         ? translate('LOCAL_HOLIDAY_SHORT')
        //                         : event && event.resource.holiday
        //                             ? translate('HOLIDAY_SHORT')
        //                             : dateToRender.date()
        //                 }
        //             </button>
        //         </Tooltip>
        //         <Tooltip
        //             title={!reserveView && agendaType === AgendaType.INTEREST
        //                 ? interestDate
        //                     ? translate('REMOVE_INTEREST_DATE')
        //                     : tooltipInterest
        //                 : tooltip
        //             }
        //             trigger='hover'
        //             className='desktop'>
        //             <button
        //                 className={`date in-month ${occupied} ${today} ${selected}`}
        //                 style={style}
        //                 onClick={(e) => !reserveView && agendaType === AgendaType.INTEREST
        //                     ? props.onInterestClick({ date, event, selected: interestDate ? true : false })
        //                     : e.preventDefault()
        //                 }>
        //                 {interestDate && !reserveView && agendaType === AgendaType.INTEREST
        //                     ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
        //                         {dateToRender.date()}
        //                     </Badge>
        //                     : event && event.resource.localHoliday
        //                         ? translate('LOCAL_HOLIDAY_SHORT')
        //                         : event && event.resource.holiday
        //                             ? translate('HOLIDAY_SHORT')
        //                             : dateToRender.date()
        //                 }
        //             </button>
        //         </Tooltip>
        //     </React.Fragment>
        : event && event?.resource?.isDateOccupied
            ? <React.Fragment>
                <Tooltip
                    title={!reserveView && agendaType === AgendaType.WAITING_DATE
                        ? tooltipWaiting
                        : tooltip
                    }
                    trigger='click'
                    className='mobile'>
                    <button
                        className={`date in-month ${occupied} ${today} ${selected}`}
                        style={style}>
                        {waitingDate && !reserveView && agendaType === AgendaType.WAITING_DATE
                            ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>
                                {waitingDateTxt}
                            </Badge>
                            : dateToRender.date()}
                    </button>
                </Tooltip>
                <Tooltip
                    title={!reserveView && agendaType === AgendaType.WAITING_DATE
                        ? tooltipWaiting
                        : tooltip
                    }
                    trigger='hover'
                    className='desktop'>
                    <button
                        className={`date in-month ${occupied} ${today} ${selected}`}
                        style={style}
                        onClick={e => !reserveView && agendaType === AgendaType.WAITING_DATE
                            ? props.onWaitingClick({ date, selected: waitingDate ? true : false })
                            : e.preventDefault()}>
                        {waitingDate && !reserveView && agendaType === AgendaType.WAITING_DATE
                            ? <Badge className='waiting-date' count={<WaitingIcon src={SelectedBookmark} />}>
                                {waitingDateTxt}
                            </Badge>
                            : event && event.resource.localHoliday
                                ? translate('LOCAL_HOLIDAY_SHORT')
                                : event && event.resource.holiday
                                    ? translate('HOLIDAY_SHORT')
                                    : (<b>{dateToRender.date()}</b>)}
                    </button>
                </Tooltip>
            </React.Fragment>
            : event && event.resource.price && event.resource.price.minPax && event.resource.price.minPax > 0
                && event.resource.price?.minPrice && event.resource.price?.minPrice > 0
                ? <React.Fragment>
                    <Tooltip
                        title={!reserveView && agendaType === AgendaType.INTEREST ? tooltipInterest : tooltip}
                        trigger='click'
                        className='mobile'>
                        <button
                            className={`date in-month ${occupied} ${today} ${selected}`}
                            style={style}
                        >
                            {interestDate && !reserveView && agendaType === AgendaType.INTEREST
                                ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
                                    {interestDateTxt}
                                </Badge>
                                : event && event.resource.localHoliday
                                    ? translate('LOCAL_HOLIDAY_SHORT')
                                    : event && event.resource.holiday
                                        ? translate('HOLIDAY_SHORT')
                                        : (<b>{dateToRender.date()}</b>)}
                        </button>
                    </Tooltip>
                    <Tooltip
                        title={!reserveView && agendaType === AgendaType.INTEREST
                            ? interestDate
                                ? translate('REMOVE_INTEREST_DATE')
                                : tooltipInterest
                            : tooltip
                        }
                        trigger='hover'
                        className='desktop'>
                        <button
                            className={`date in-month ${occupied} ${today} ${selected}`}
                            style={style}
                            onClick={(e) => reserveView
                                ? props.onClick({ date, event })
                                : !reserveView && agendaType === AgendaType.INTEREST
                                    ? props.onInterestClick({ date, event, selected: interestDate ? true : false })
                                    : e.preventDefault()
                            }>
                            {interestDate && !reserveView && agendaType === AgendaType.INTEREST
                                ? <Badge className='interest-date' count={<WaitingIcon src={SelectedStar} />}>
                                    {interestDateTxt}
                                </Badge>
                                : event && event.resource.localHoliday
                                    ? translate('LOCAL_HOLIDAY_SHORT')
                                    : event && event.resource.holiday
                                        ? translate('HOLIDAY_SHORT')
                                        : (<b>{dateToRender.date()}</b>)}
                        </button>
                    </Tooltip>
                </React.Fragment>
                : <React.Fragment>
                    <Tooltip
                        title={tooltip}
                        trigger='click'
                        className='mobile'>
                        <button
                            className={`date in-month ${occupied} ${today} ${selected}`}
                            style={style}>
                            {event && event.resource.localHoliday
                                ? translate('LOCAL_HOLIDAY_SHORT')
                                : event && event.resource.holiday
                                    ? translate('HOLIDAY_SHORT')
                                    : dateToRender.date()
                            }
                        </button>
                    </Tooltip>
                    <Tooltip
                        title={tooltip}
                        trigger='hover'
                        className='desktop'>
                        <button
                            className={`date in-month ${occupied} ${today} ${selected}`}
                            style={style}
                            onClick={(e) => e.preventDefault()}>
                            {event && event.resource.localHoliday
                                ? translate('LOCAL_HOLIDAY_SHORT')
                                : event && event.resource.holiday
                                    ? translate('HOLIDAY_SHORT')
                                    : dateToRender.date()
                            }
                        </button>
                    </Tooltip>
                </React.Fragment>;

}
class YearView extends React.Component {
    state = {
        date: moment(),
        events: [],
        waitingDates: [],
        interestDates: [],
        wedding: null,
        reserveView: true,
        agendaType: AgendaType.AGENDA,
        menuOpen: false,
        months: [],
        activeLanguage: {},
        translate: [],
    }

    componentDidMount() {
        this.setState({
            date: this.props.date,
            events: this.props.events || [],
            waitingDates: this.props.waitingDates || [],
            interestDates: this.props.interestDates || [],
            wedding: this.props.wedding,
            reserveView: this.props.reserveView,
            agendaType: this.props.agendaType,
            menuOpen: this.props.menuOpen,
            activeLanguage: this.props.activeLanguage,
            translate: this.props.translate
        }, () => {
            this.setMonthsCalendar();
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.date !== prevProps.date) {
            this.setState({ date: this.props.date })
        }

        if (this.props.loading !== prevProps.loading
            || this.props.menuOpen !== prevProps.menuOpen) {
            this.setState({
                events: this.props.events || [],
                waitingDates: this.props.waitingDates || [],
                interestDates: this.props.interestDates || [],
                wedding: this.props.wedding,
                reserveView: this.props.reserveView,
                agendaType: this.props.agendaType,
                menuOpen: this.props.menuOpen
            }, () => this.setMonthsCalendar())
        }

        if (this.props.activeLanguage?.code !== prevProps.activeLanguage?.code) {
            this.setState({
                activeLanguage: this.props.activeLanguage,
                translate: this.props.translate
            }, () => this.setMonthsCalendar());
        }
    }

    setMonthsCalendar = () => {
        const { date, events, waitingDates, interestDates, activeLanguage } = this.state;
        const months = [];
        moment().locale(activeLanguage ? activeLanguage?.code : 'pt')

        for (let i = 0; i < 12; i++) {
            const startDate = moment.utc(date).set('month', i).startOf('month');
            const monthInfo = {
                month: i,
                calendar: createCalendar(startDate),
                events: events.filter(f => moment.utc(f.start).month() === i),
                waitingDates: Array.isArray(waitingDates) && waitingDates.length > 0
                    ? waitingDates.filter(f => moment.utc(f.date).month() === i)
                    : [],
                interestDates: Array.isArray(interestDates) && interestDates.length > 0
                    ? interestDates.filter(f => moment.utc(f.date).month() === i)
                    : [],
            };
            months.push(monthInfo);
        }

        this.setState({ months });
    }

    getMonthName = (date) => {
        const { activeLanguage } = this.state;
        return moment.utc(date).locale(activeLanguage ? activeLanguage.code : 'pt').format('MMMM').toUpperCase();
    }

    getWeekdays = () => {
        const { activeLanguage } = this.state;
        moment.locale(activeLanguage ? activeLanguage?.code : 'pt');
        // return [this.props.translate('WEEK_SHORT'), ...moment.weekdaysShort()];
        return moment.weekdaysShort();
    }

    getWeekInterval = (datesOfWeek, month, waitingDates) => {
        let startDate = null;
        let endDate = null;
        const waitingDatesOfWeek = [];

        if (Array.isArray(datesOfWeek) && datesOfWeek.length > 0) {
            const datesFromMonth = datesOfWeek.filter(f => moment(f).month() === month);

            if (Array.isArray(datesFromMonth) && datesFromMonth.length > 0) {
                startDate = datesFromMonth[0];
                endDate = datesFromMonth[datesFromMonth.length - 1];

                for (let index = 0; index < datesFromMonth.length; index++) {
                    const date = datesFromMonth[index];
                    const waitingDate = waitingDates.find(f => moment.utc(f?.date).format('DDDYYYY') === moment.utc(date).format('DDDYYYY'))
                    if (waitingDate) waitingDatesOfWeek.push(waitingDate);
                }
            }
        }

        return {
            date: startDate ? moment.utc(startDate) : null,
            endDate: endDate ? moment.utc(endDate) : null,
            waitingDates: waitingDatesOfWeek,
            selected: waitingDatesOfWeek?.length > 0
                ? true
                : false
        };
    }

    getWaitingDatesByWeekDay = (weekday, month, waitingDates) => {
        let selected = false;

        if (waitingDates && Array.isArray(waitingDates) && waitingDates.length > 0) {
            const waitingDatesByWeekNumber = waitingDates.filter(f => f?.date && f?.type === WaitingActionType.WEEKDAY && moment.utc(f?.date).month() === month && moment.utc(f?.date).day() === weekday);
            selected = waitingDatesByWeekNumber?.length > 0 ? true : false;
        }

        return selected;
    }

    render() {
        let { months, translate, activeLanguage, wedding, reserveView, agendaType, menuOpen } = this.state;
        const { selecting } = this.props;
        const reservePhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) ? true : false;
        const reserveSinalizationPhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE_SINALIZATION)) ? true : false;

        return <Row gutter={[12, 12]} className="year">
            {months.map((month, monthIndex) => {
                // const data = {
                //     month: month.month,
                //     startDate: moment.utc().set('month', monthIndex).startOf('month') || null,
                //     endDate: moment.utc().set('month', monthIndex).endOf('month') || null,
                //     waitingDate: month?.waitingDate || null,
                //     selected: month?.waitingDate !== null && month?.waitingDate !== undefined ? true : false
                // };
                // const translation = weekInterval?.selected
                //     ? 'REMOVE_WAITING_DATE'
                //     : 'ADD_WAITING_DATE';

                return <Col key={monthIndex} xs={24} sm={12} md={8} lg={8} xl={menuOpen && !reservePhase && !reserveSinalizationPhase ? 10 : 8} xxl={8} className="month">
                    <div className="month-name">
                        {this.getMonthName(month)}
                        {/* <Tooltip title={translate(translation)}>
                            <button className='waitingDate' onClick={(e) => this.props.onWaitingClick(data)}>
                                <WaitingIcon src={weekInterval?.selected ? SelectedBookmark : Bookmark} />
                            </button>
                        </Tooltip> */}
                    </div>
                    <div className='weekdays'>
                        {this.getWeekdays().map((day, index) => {
                            const selectedByWeekday = this.getWaitingDatesByWeekDay(index, monthIndex, month.waitingDates);
                            const translation = selectedByWeekday
                                ? 'REMOVE_WAITING_DATE'
                                : 'ADD_WAITING_DATE';


                            return wedding !== null && wedding !== undefined && !reserveView && agendaType === AgendaType.WAITING_DATE
                                ? <span key={index} className="weekday">
                                    <Tooltip title={translate(translation)}>
                                        <button
                                            className='waiting-weekday'
                                            onClick={(e) => this.props.onMultipleWaitingClick({ type: WaitingActionType.WEEKDAY, weekday: index, month: monthIndex, selected: selectedByWeekday })}>
                                            <WaitingIcon src={selectedByWeekday ? SelectedBookmark : Bookmark} />
                                            <span>{day}</span>
                                        </button>
                                    </Tooltip>
                                </span>
                                : <span key={index} className="weekday">
                                    {day}
                                </span>
                        })}
                    </div>
                    {month.calendar.map((week, index) => {
                        // const weekInterval = this.getWeekInterval(week[index].calendar[index], month.month, month.waitingDates);
                        // const data = {
                        //     month: month.month,
                        //     startDate: weekInterval?.startDate || null,
                        //     endDate: weekInterval?.endDate || null,
                        //     waitingDate: weekInterval?.waitingDate || null,
                        //     selected: weekInterval?.selected || false
                        // };
                        // const translation = weekInterval?.selected
                        //     ? 'REMOVE_WAITING_DATE'
                        //     : 'ADD_WAITING_DATE';

                        return <div key={index} className='month-days'>
                            {/* <Tooltip title={translate(translation)}>
                                <button className='waitingDate' onClick={(e) => this.props.onWaitingClick(data)}>
                                    <WaitingIcon src={weekInterval?.selected ? SelectedBookmark : Bookmark} />
                                </button>
                            </Tooltip> */}

                            {week.map(date => {
                                // console.warn('Date', date)
                                return <CalendarDate
                                    key={date.date()}
                                    selecting={selecting}
                                    dateToRender={date}
                                    events={month.events}
                                    // waitingDates={month.waitingDates.filter(f => f?.type && f?.type === WaitingActionType.DAY)}
                                    waitingDates={month.waitingDates}
                                    interestDates={month.interestDates}
                                    dateOfMonth={month.calendar.currentDate}
                                    wedding={wedding}
                                    reserveView={reserveView}
                                    agendaType={agendaType}
                                    translate={translate}
                                    activeLanguage={activeLanguage}
                                    onClick={date => this.props.onDayClick(date)}
                                    onWaitingClick={data => this.props.onWaitingClick({ type: WaitingActionType.DAY, ...data })}
                                    onInterestClick={data => this.props.onInterestClick(data)}
                                />
                            })}
                        </div>
                    })}
                </Col>
            })}
        </Row>
    }
}

// Day.propTypes = {
//     date: PropTypes.instanceOf(Date).isRequired,
// }

YearView.range = date => {
    //     return [dates.startOf(date, 'year')]
}

YearView.navigate = (date, action) => {
    switch (action) {
        case navigate.PREVIOUS:
            return moment(date).add(1, 'year') //dates.add(date, -1, 'year')
        case navigate.NEXT:
            return moment(date).subtract(1, 'year')
        default:
            return date
    }
}

YearView.title = (date, { localizer }) => localizer.format(date, 'yearHeaderFormat')

export default YearView
