import React, { useState } from "react";
import { withLocalize } from "react-localize-redux";
import PropTypes from "prop-types";
import {
  MaterialInputSection,
  MaterialLabel,
  MaterialInput,
  MaterialErrorLabel,
  MaterialIcon,
  MaterialLabelFixed
} from "./MaterialStyles";

const TextInput = ({
  input,
  meta: { invalid, submitFailed, error },
  type,
  label,
  disabled,
  suffix,
  placeholder,
  tag,
  translate,
  onClick,
  readOnly,
  requiredError,
  labelFixed = false,
  small = false,
}) => {
  const [filled, setFilled] = useState(input && input.value !== "");
  const showError = invalid && submitFailed;

  let errorMsg = '';
  if (error) {
    if (Array.isArray(error) && error.length === 3) {
      errorMsg = translate(error[0]).replace(error[1], error[2]);
    }
    else {
      errorMsg = translate(error);
    }
  }

  if (requiredError) {
    errorMsg = translate('INPUT_ERROR_REQUIRED');
  }

  return (
    <MaterialInputSection noLabel={!label}>
      {
        label && (labelFixed ?
          <MaterialLabelFixed tag={tag} filled={filled} showError={showError || requiredError}>
            {label}
          </MaterialLabelFixed>
          :
          <MaterialLabel tag={tag} filled={filled} showError={showError || requiredError}>
            {label}
          </MaterialLabel>)
      }
      <MaterialInput
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        type={type}
        value={input.value}
        onChange={input.onChange}
        showError={showError || requiredError}
        onBlur={() => setFilled(input && input.value !== "")}
        onFocus={() => setFilled(true)}
        tag={tag}
        onClick={onClick}
        labelFixed={labelFixed}
        noLabel={!label}
        small={small}
      />
      {
        suffix &&
        <MaterialIcon>{suffix}</MaterialIcon>
      }
      {
        ((invalid && submitFailed) || requiredError) &&
        <MaterialErrorLabel visible={(invalid && submitFailed) || requiredError}>
          {errorMsg.toUpperCase()}
        </MaterialErrorLabel>
      }
    </MaterialInputSection>
  );
};

TextInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  tag: PropTypes.string,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  requiredError: PropTypes.bool,
  labelFixed: PropTypes.bool
};

export default withLocalize(TextInput);