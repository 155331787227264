import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

const menuTransition = "0.5s";

export const Menu = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100vh - 108px);
  position: fixed;
  left: 0;
  z-index: 999;
  width: ${({ open }) => (open ? "300px" : "105px")};
  background-color: #FFFFFF;
  border-right: 1px solid ${p => p.theme.primaryBorderColor};
  transition: width ${menuTransition} linear;

  @media ${device.desktopL} {
    height: calc(100vh - 89px);
  }

  @media ${device.desktop} {
    height: calc(100vh - 80px);
  }

  @media ${device.laptopL} {
    height: calc(100vh - 66px);
    width: ${({ open }) => (open ? "250px" : "105px")};
  }

  @media ${device.laptop} {
    width: ${({ open }) => (open ? "300px" : "105px")};
  }

  @media ${device.tablet} {
    width: ${({ open }) => (open ? "300px" : "50px")};
  }

  @media ${device.mobileL} {
    width: ${({ open }) => (open ? "220px" : "50px")};
    height: calc(100vh - 60px);
  }

  @media ${device.mobileS} {
    width: ${({ open }) => (open ? "200px" : "50px")};
  }
`;

export const ToggleMenu = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  padding-right: ${p => p.open ? '30px' : '0px'};
  text-align: ${p => p.open ? 'right' : 'center'};

  @media ${device.laptop} {
    padding-top: 25px;
    padding-bottom: 25px;
    text-align: ${p => p.open ? 'right' : 'center'};
  }

  @media ${device.tablet} {
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;

export const ArrowImg = styled.div`
  cursor: pointer;
  display: inline-block;
  
  & svg {
    width: 12px;
    height: 21px;
    transition: transform ${menuTransition} linear;
    transform: ${p => p.open ? 'none' : 'rotateY(180deg)'};

    @media ${device.tablet} {
      width: 8px;
      height: 12px;
    }

    & #right, & #left {
      fill: ${p => p.theme.primaryCTA};
    }

    &:hover #right, &:hover #left {
      fill: ${p => p.theme.secondaryCTA};
    }
  }

  @media ${device.laptop} {
    & svg {
      transform: ${p => p.open ? 'none' : 'rotateY(180deg)'};
    }
  }
`;

export const MenuItem = styled.div`
  margin-left: 45px;
  padding-top: ${p => p.first ? '0px' : '85px'};
  border-left: 1px dashed #000000;
  margin-bottom: ${p => p.last ? '50px' : '0px'};

  @media ${device.desktop} {
    padding-top: ${p => p.first ? '0px' : '70px'};
  }

  @media ${device.laptop} {
    margin-left: 50px;
    padding-top: ${p => p.first ? '0px' : '55px'};
  }

  @media ${device.tablet} {
    margin-left: 25px;
    padding-top: ${p => p.first ? '0px' : '45px'};
  }
`;

export const MenuContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-left: -7px;
  position: relative;
  cursor: pointer;
  
  &:hover {
    & div {
      transition: background-color 0.5s linear, border 0.5s linear;
      background-color: ${p => p.theme.secondaryBorderColor};
      border: 1px solid ${p => p.theme.secondaryBorderColor};
    }

    & h5 {
      transition: color 0.5s linear;
      color: ${p => p.theme.primaryColor};
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: ${p => p.first ? '-6px' : p.last ? '20px' : '0px'};
    left: 0;
    background-color: ${p => p.first || p.last ? '#ffffff' : 'transparent'};
    width: 13px;
    height: 50%;
  }

  @media ${device.laptop} {
    &:before { 
      top: ${p => p.first ? '-6px' : p.last ? '14px' : '0px'};
    }
  }

  @media ${device.tablet} {
    margin-left: ${p => p.active ? '-7px' : '-4px'};

    &:before { 
      top: ${p => p.first ? '-3px' : p.last ? '14px' : '0px'};
    }
  }
`;

export const MenuBullet = styled.div`
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  background-color: ${p => p.active ? p.theme.secondaryBorderColor : '#ffffff'};
  border: 1px solid ${p => p.active ? p.theme.secondaryBorderColor : '#000000'};
  border-radius: 50%;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${p => p.active ? p.theme.secondaryBorderColor : 'transparent'};
    opacity: 0.4;
    width: 26px;
    height: 26px;
    border-radius: 50%;
  }

  @media ${device.tablet} {
    width: ${p => p.active ? '13px' : '7px'};
    height: ${p => p.active ? '13px' : '7px'};

    &:before {
      width: ${p => p.active ? '26px' : '14px'};
      height: ${p => p.active ? '26px' : '14px'};
    }
  }
`;

export const MenuText = styled(titleH5)`
  line-height: 29px;
  flex-shrink: 0;
  padding-left: 50px;
  width: 220px;
  opacity: ${p => p.open ? '1' : '0'};
  transition: opacity ${menuTransition} linear;

  @media ${device.desktop} {
    padding-left: 30px;
    font-size: 20px;
  }

  @media ${device.laptopL} {
    width: 180px;
    padding-left: 20px;
    font-size: 18px;
  }

  @media ${device.laptop} {
    width: 220px;
    opacity: ${p => p.open ? '1' : '0'};
    font-size: 16px;
  }

  @media ${device.mobileL} {
    opacity: ${p => p.open ? '1' : '0'};
    font-size: 14px;
  }

  @media ${device.mobileS} {
    opacity: ${p => p.open ? '1' : '0'};
    font-size: 13px;
  }
`;