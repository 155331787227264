import React from "react";
import { withLocalize } from 'react-localize-redux';
import {
  TableContainer,
  TableLine,
  TableColumn
} from "./BudgetTablePaymentsStyles";
import { numberFormat } from '../../infra/services/utils/FormatNumber';

const BudgetTablePayments = ({ payments, translate }) => {
  return (
    <TableContainer>
      {
        payments.length > 0 &&
        payments.map((detail, index) =>
          <TableLine key={index}>
            <TableColumn>{translate(detail.name)}</TableColumn>
            <TableColumn>{detail.value > 0 ? `${numberFormat(detail.value, 2, ',', '.')}€` : ''}</TableColumn>
          </TableLine>
        )
      }
    </TableContainer>
  );
}

export default withLocalize(BudgetTablePayments);