import moment from "moment";

export const checkPreviousPhasesCompleted = (currentPhase, paymentPhases) => {
    const currentOrder = currentPhase?.order;
    const previousPhases = paymentPhases.filter(p => p?.order < currentOrder);
    const previousPhasesCompleted = previousPhases.filter(p => p?.phaseComplete);
    return previousPhases?.length == previousPhasesCompleted?.length
        ? true
        : false;
}

export const getPhaseDate = (currentPhase, translate) => {
    return currentPhase?.date ?
        `${translate('UNTIL_BIG')} ` + (currentPhase?.order == 4
            ? moment.utc(currentPhase?.date).format('DD/MM/YYYY HH:00')
            : moment.utc(currentPhase?.date).format('DD/MM/YYYY')
        ) + (currentPhase?.specificDate
            ? ` (${translate('INDICATED_DATE')})`
            : '')
        : '';
}

export const getMissingValue = (currentPhase) => {
    const missing = ((currentPhase?.toPay || 0) - (currentPhase?.payed || 0));
    return missing >= 0
        ? missing
        : 0;
}