import React from "react";
import { withLocalize } from "react-localize-redux";
import {
  CardContainer,
  CardContent,
  DetailContainer,
  Question,
  Answer
} from "./ChoicesCardStyles";

const ObservationsCard = ({ notes, translate }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{translate('ORGANIZER_NOTES')}</Question>
        <DetailContainer isObservation={true}>
          <Answer hasImage={false} dangerouslySetInnerHTML={{__html: notes}}/>
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default withLocalize(ObservationsCard);