import React from "react";
import {withLocalize} from "react-localize-redux";
import {Field} from 'redux-form';
import {
  BillingContainer,
  BillingContent,
  RemoveContainer,
  RemoveImg
} from "./BillingStyles";
import TextInput from "../inputs/TextInput";
import Close from "../../assets/icons/close.svg";

const Billing = ({ type, removeElement, index, field, isProcessClosed, translate }) => {
  return (
    <BillingContainer type={type}>
      <BillingContent>
        {
          type === 'filled' && !isProcessClosed && 
          <RemoveContainer>
            <RemoveImg src={Close} onClick={() => removeElement(index)}/>
          </RemoveContainer>
        }
        <Field
          component={TextInput}
          name={`${field}.nif`}
          type='number'
          label={translate('VAT_NUMBER')}
          disabled={isProcessClosed}
        />
        <Field
          component={TextInput}
          name={`${field}.name`}
          type='text'
          label={translate('NAME_WD')}
          disabled={isProcessClosed}
        />
        <Field
          component={TextInput}
          name={`${field}.address`}
          type='text'
          label={translate('ADDRESS')}
          disabled={isProcessClosed}
        />
        <Field
          component={TextInput}
          name={`${field}.observations`}
          type='text'
          label={translate('OBSERVATIONS_WD')}
          disabled={isProcessClosed}
        />
      </BillingContent>
    </BillingContainer>
  );
}

export default withLocalize(Billing);