import React from "react";
import {
  CardContainer,
  CardContent,
  BgContainer,
  Image,
  DetailContainer,
  Question,
  TextOverImage
} from "./ChoicesCardStyles";

const ChoicesCard = ({ question, image }) => {
  return (
    <CardContainer>
      <CardContent>
        <Question>{question}</Question>
        <DetailContainer>
          <BgContainer>
            <Image src={image} />
            <TextOverImage>{question}</TextOverImage>
          </BgContainer>
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default ChoicesCard;