import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Prompt } from "react-router-dom";

const LeavePageBlocker = ({ when, message, checkedChanges, onAttemptedRouteChange }) => {
  useEffect(() => {
    if (!when) return () => { };
    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };
    window.addEventListener("beforeunload", beforeUnloadCallback);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadCallback);
    };
  }, [when, message]);

  return <Prompt
    when={when}
    message={(location, action) => {
      // Notify parent component about the route change attempt
      if (when && !checkedChanges && onAttemptedRouteChange) {
        const result = onAttemptedRouteChange(location, action);
        if (result == false) {
          return true;
        }
      }

      return message;
    }} />;
};

LeavePageBlocker.propTypes = {
  when: PropTypes.bool.isRequired,
  checkedChanges: PropTypes.bool,
  onAttemptedRouteChange: PropTypes.func
};

export default LeavePageBlocker;
