import React from "react";
import { Field } from "redux-form";
import { withLocalize } from "react-localize-redux";
import TextInput from "../../components/inputs/TextInput";
import {
  QuestionTitle,
  FieldContainer,
  QuestionDescription
} from '../decoration/PageDetailStyles';
import StaffListQuestion from '../../components/staffListQuestion/StaffListQuestion';
import StaffOptionQuestion from '../../components/staffOptionQuestion/StaffOptionQuestion';

const StaffInternalForm = ({ fields, questions, weddingInfo, isProcessClosed, activeLanguage }) => {
  const renderQuestion = (field, question) => {
    return (
      <FieldContainer key={question._id}>
        <QuestionTitle>{question.title[activeLanguage?.code]}</QuestionTitle>
        {
          question.description &&
          <QuestionDescription dangerouslySetInnerHTML={{ __html: question.description[activeLanguage?.code] }} />
        }
        {
          //Organizer
          question._id === '5e3184829621e5d6a3d89742' ?
            <TextInput
              meta={{}}
              input={{
                value: weddingInfo?.organizer?.length > 0 
                ? weddingInfo?.organizer?.map(m => m?.name).join(', ') 
                : weddingInfo?.organizer?.name || '',
                onChange: () => null
              }}
              type="text"
              disabled={true}
              tag="GUESTS"
            />
            :
            //Coordinator
            question._id === '5e3184939621e5d6a3d89743' ?
              <TextInput
                meta={{}}
                input={{ value: weddingInfo?.coordinator, onChange: () => null }}
                type="text"
                disabled={true}
                tag="GUESTS"
              />
              :
              //Animadora Infantil
              question._id === '5e3184b59621e5d6a3d89744' ?
                <Field
                  component={StaffListQuestion}
                  name={`${field}.answer.response`}
                  question={question}
                  isProcessClosed={isProcessClosed}
                />
                :
                //Funcionários Extra
                question._id === '5e3185a69621e5d6a3d89749' ?
                  <Field
                    component={StaffOptionQuestion}
                    name={`${field}.answer.response`}
                    question={question}
                    isProcessClosed={isProcessClosed}
                  />
                  :
                  //Utilização do Cofre
                  question._id === '5e6a0a0629196b814faa7ce9' ?
                    <Field
                      component={StaffListQuestion}
                      name={`${field}.answer.response`}
                      question={question}
                      isProcessClosed={isProcessClosed}
                    />
                    :
                    //Animação musical
                    question._id === '60e2dbe612cef67e129c0d33' ?
                      <Field
                        component={StaffListQuestion}
                        name={`${field}.answer.response`}
                        question={question}
                        isProcessClosed={isProcessClosed}
                      />
                      :
                      null
        }
      </FieldContainer>
    );
  };

  return (
    <React.Fragment>
      {
        fields.length > 0 && fields.map((question, index) => renderQuestion(question, questions[index]))
      }
    </React.Fragment>
  );
};

export default withLocalize(StaffInternalForm);