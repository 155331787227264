import { SAVE_UPGRADE } from "../ActionsType";

export const SaveUpgrade = upgrade => {
  return {
    type: SAVE_UPGRADE,
    upgrade
  };
};

export const DeleteUpgrade = () => {
  return {
    type: SAVE_UPGRADE,
    upgrade: ''
  };
};