import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const CardContainer = styled.div`
  flex-basis: 33.333%;
  height: 360px;
  position: relative;
  padding: 0 15px;
  margin-bottom: 40px;

  @media ${device.laptopS} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const CardContent = styled.div`
  background-color: ${p => p.theme.thirdColor};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.5s linear;
  
  &:hover {
    background-color: ${p => p.theme.secondaryColor};
  }
`;

export const Title = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: ${p => p?.color ? p?.color : p?.theme?.primaryColor};
  font-size: 30px;
  font-weight: 700;
  line-height: 31px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Arrow = styled.img`
  position: absolute;
  bottom: 12px;
  right: 22px;
  width: 24px;
  transform: rotateZ(180deg);
`;

export const Image = styled.img`
  max-width: 100%;
`;