import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, FieldArray, initialize } from "redux-form";
import { connect } from "react-redux";
import { DetailContainer, FormContainer } from "../decoration/PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import {
  findPageByTag,
  findPageByID,
} from "../../infra/services/utils/PageInfo";
import {
  findNextChapter,
  findPrevQuestion,
} from "../../infra/services/utils/PageNavigation";
import StaffInternalForm from "./StaffInternalForm";
import { AnswerQuestion } from "../../infra/requests/MyWeddingRequests";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { SpinLoading } from "../../styles/BasicStyles";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";
import { correctResponseValues } from "../../components/optionCard/OptionUtils";

const sectionTag = "STAFF";
const chapterId = "5e3181e69621e5d6a3d89740";
const returnURL = "/staff";

const FormValidations = (values) => {
  //console.log('FORM', values);
  return {};
};

class StaffInternal extends Component {
  state = {
    chapter: undefined,
    nextChapter: undefined,
    prevQuestion: undefined,
    isProcessClosed: undefined,
    ready: false,
    loading: false,
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    const { weddingInfo, decorationInfo, dispatch } = this.props;

    const { chapters } = findPageByTag(decorationInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterId);
    const nextChapter = findNextChapter(
      decorationInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    const prevQuestion = findPrevQuestion(decorationInfo, chapters, chapter, 0);
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    dispatch(initialize("section_form", {}));

    if (chapter.questions) {
      dispatch(
        initialize("section_form", {
          questions: chapter.questions,
        })
      );
    }

    this.setState({
      chapter,
      nextChapter,
      prevQuestion,
      isProcessClosed,
      ready: true,
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history } = this.props;
      const { chapter, nextChapter } = this.state;

      //Find question "Animadora Infantil" (5e3184b59621e5d6a3d89744)
      const question1 = values.questions.filter((question) =>
        question._id === "5e3184b59621e5d6a3d89744" ? question : null
      )[0];
      if(question1?.answer) question1.answer = correctResponseValues(question1.answer);
      const payload1 = TransformToFormData({
        question: question1._id,
        response: question1.answer?.response,
      });
      const result1 = await AnswerQuestion(payload1);

      //Find question "Funcionários Extra" (5e3185a69621e5d6a3d89749)
      const question2 = values.questions.filter((question) =>
        question._id === "5e3185a69621e5d6a3d89749" ? question : null
      )[0];
      if(question2?.answer) question2.answer = correctResponseValues(question2.answer);
      const payload2 = TransformToFormData({
        question: question2._id,
        response: question2.answer?.response,
      });
      const result2 = await AnswerQuestion(payload2);

      //Find question "Utilização do Cofre" (5e6a0a0629196b814faa7ce9)
      const question3 = values.questions.filter((question) =>
        question._id === "5e6a0a0629196b814faa7ce9" ? question : null
      )[0];
      if(question3?.answer) question3.answer = correctResponseValues(question3.answer);
      const payload3 = TransformToFormData({
        question: question3._id,
        response: question3.answer?.response,
      });
      const result3 = await AnswerQuestion(payload3);

      //Find question "Animação musical" (60e2dbe612cef67e129c0d33)
      const question4 = values.questions.filter((question) =>
        question._id === "60e2dbe612cef67e129c0d33" ? question : null
      )[0];
      if(question4?.answer) question4.answer = correctResponseValues(question4.answer);
      const payload4 = TransformToFormData({
        question: question4._id,
        response: question4.answer?.response,
      });
      const result4 = await AnswerQuestion(payload4);

      if (
        result1.success &&
        result2.success &&
        result3.success &&
        result4.success
      ) {
        //Update answers in the current Chapter
        for (let i = 0; i < chapter.questions.length; i++) {
          if (chapter.questions[i]._id === question1._id) {
            chapter.questions[i] = question1;
          } else if (chapter.questions[i]._id === question2._id) {
            chapter.questions[i] = question2;
          } else if (chapter.questions[i]._id === question3._id) {
            chapter.questions[i] = question3;
          } else if (chapter.questions[i]._id === question4._id) {
            chapter.questions[i] = question4;
          }
        }

        this.setState({
          chapter,
        });

        history.push(`${returnURL}/${nextChapter?._id}`);
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      weddingInfo,
      handleSubmit,
      translate,
      activeLanguage,
      history,
      dirty,
      submitting,
    } = this.props;
    const {
      chapter,
      nextChapter,
      prevQuestion,
      isProcessClosed,
      ready,
      loading,
    } = this.state;

    if (!ready) return <SpinLoading />;

    let num_answers = 0;
    for (let i = 0; i < chapter.questions.length; i++) {
      if (
        (chapter.questions[i]._id === "5e3184b59621e5d6a3d89744" ||
          chapter.questions[i]._id === "5e3185a69621e5d6a3d89749" ||
          chapter.questions[i]._id === "5e6a0a0629196b814faa7ce9" ||
          chapter.questions[i]._id === "60e2dbe612cef67e129c0d33") &&
        chapter.questions[i].answer?.response?.length > 0
      ) {
        num_answers++;
      }
    }

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirty && !submitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <ProgressBar
          type="section"
          num_questions={4}
          num_answers={num_answers}
        />
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${chapterId}`}
          nextQuestion={nextChapter?.name[activeLanguage?.code]}
          nextQuestionUrl={`${returnURL}/${nextChapter?._id}`}
          prevQuestion={
            prevQuestion?.tag
              ? translate(prevQuestion?.tag)
              : prevQuestion?.name[activeLanguage?.code]
          }
          prevQuestionUrl={prevQuestion?.url}
        />
        <DetailContainer>
          <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
            <FieldArray
              name="questions"
              component={StaffInternalForm}
              questions={chapter.questions}
              weddingInfo={weddingInfo}
              isProcessClosed={isProcessClosed}
            />
            {!isProcessClosed && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
          </FormContainer>
          {isProcessClosed && (
            <SaveButton
              htmlType="button"
              text={translate("CONTINUE")}
              textMobile={translate("CONTINUE")}
              withBackground={true}
              onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
            />
          )}
        </DetailContainer>
      </React.Fragment>
    );
  }
}

StaffInternal = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(StaffInternal);

const mapStateToProps = (state) => ({
  decorationInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding,
});

export default connect(mapStateToProps)(
  withLocalize(withRouter(StaffInternal))
);
