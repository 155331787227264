import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from "react-router-dom";
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  DetailContainer,
  Description,
  FormContainer
} from './OurStyleStyles';
import ProgressBar from '../../components/progressBar/ProgressBar';
import DecorationNavigation from '../../components/decorationNavigation/DecorationNavigation';
import {
  findPageByTag,
  findPageByID
} from '../../infra/services/utils/PageInfo';
import {
  findPrevChapter
} from '../../infra/services/utils/PageNavigation';
import YourSuggestionsForm from './YourSuggestionsForm';
import { GetCurrentInspirations } from '../../infra/requests/OurStyleRequests';
import SaveButton from '../../components/buttons/SaveButton';
import { SpinLoading } from "../../styles/BasicStyles";
import {
  processClosed
} from '../../infra/services/utils/BlockAnswers';

const limitSuggestions = 10;
const sectionTag = 'STYLE';
const chapterId = '5e42e53429196b814f617d30';
const returnURL = '/our-style';
const nextUrl = '/our-day';
const nextName = 'OUR_DAY';

class YourSuggestions extends Component {
  state = {
    chapter: undefined,
    prevChapter: undefined,
    currentSuggestions: undefined,
    isProcessClosed: undefined,
    ready: false
  };

  componentDidMount() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    document.body.style.overflowY = 'auto';

    this.updateSection();
  }

  componentDidUpdate(prevProps) {
    if(this.props.id !== prevProps?.id) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      document.body.style.overflowY = 'auto';
      
      this.updateSection();
    }
  }

  updateSection = async () => {
    const { weddingInfo, ourStyleInfo } = this.props;

    const { chapters } = findPageByTag(ourStyleInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterId);
    const prevChapter = findPrevChapter(ourStyleInfo, chapter.section, chapters, chapter._id);
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    const resultInspirations = await GetCurrentInspirations();
    let currentSuggestions = [];

    if(resultInspirations.success) {
      currentSuggestions = resultInspirations.data.images;
    }

    this.setState({
      chapter,
      prevChapter,
      currentSuggestions,
      isProcessClosed,
      ready: true
    });
  };

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { chapter, prevChapter, currentSuggestions, isProcessClosed, ready } = this.state;
 
    if(!ready) return <SpinLoading/>;

    return (
      <React.Fragment>
        <ProgressBar type='total' num_questions={1} num_answers={currentSuggestions.length > 0 ? 1 : 0}/>
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={translate('OUR_STYLE')}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${chapterId}`}
          nextQuestion={translate(nextName)}
          nextQuestionUrl={nextUrl}
          prevQuestion={prevChapter?.name[activeLanguage?.code]}
          prevQuestionUrl={`${returnURL}/${prevChapter?._id}`}
        />
        <DetailContainer>
          <Description dangerouslySetInnerHTML={{__html: chapter.description && chapter.description[activeLanguage?.code]}}/>
          <FormContainer>
            <Field
              name='suggestions'
              component={YourSuggestionsForm}
              currentSuggestions={currentSuggestions}
              limitSuggestions={limitSuggestions}
              isProcessClosed={isProcessClosed}
            />
          </FormContainer>
          <SaveButton
            htmlType='button'
            text={translate('CONTINUE')}
            textMobile={translate('CONTINUE')}
            withBackground={true}
            onClick={() => history.push(nextUrl)}
          />
        </DetailContainer>
      </React.Fragment>
    );
  }
}

YourSuggestions = reduxForm({
  form: 'section_form'
})(YourSuggestions);

const mapStateToProps = state => ({
  ourStyleInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding
});

export default connect(mapStateToProps)(withLocalize(withRouter(YourSuggestions)));