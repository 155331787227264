import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { Icon } from "antd";

export const DocumentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${p => p.theme.primaryBorderColor};
  border-radius: 6px;
  width: 100%;
  padding: 20px 30px;
  margin-top: 40px;
  overflow: hidden;

  @media ${device.tablet} {
    padding: 15px 15px;
  }

  @media ${device.mobileM} {
    padding: 10px 5px;
  }
`;

export const DocumentColumn1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DocumentColumn2 = styled.div`
  
`;

export const DocIcon = styled(Icon)`
  padding: 0px 20px;

  & svg {
    width: auto;
    height: 40px;
  }

  @media ${device.tablet} {
    padding: 0px 10px;

    & svg {
      height: 35px;
    }
  }

  @media ${device.mobileL} {
    padding: 0px 0px;

    & svg {
      height: 25px;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 60px;
  padding-right: 20px;

  @media ${device.laptop} {
    padding-left: 30px;
  }

  @media ${device.tablet} {
    padding-left: 10px;
  }
`;

export const Name = styled.div`
  text-align: left;
  color: #000000;
  word-break: break-all;
`;

export const Size = styled.div`
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  font-family: 'Titillium Web', sans-serif;
  color: ${p => p.theme.primaryCTA};
`;