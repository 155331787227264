import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { 
  SelectDiv, 
  StyledSelectInput, 
  SelectArrow, 
  ArrowImg,
  OptionContainer,
  OptionContentOurDay,
  BoxOurDay,
  OptionTextOurDay,
  SelectErrorLabel,
  CustomSelectLabel
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const CustomSelectInput = ({
  input,
  data,
  placeholder,
  disabled,
  fetchData,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  translate,
  createNew,
  selectOption,
  showNew,
  label
}) => {
  const { invalid, submitFailed, error } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = value => {
    input.onChange(value || '');
  };

  let errorMsg = '';
  if(error) {
    if(Array.isArray(error) && error.length === 3) {
      errorMsg = translate(error[0]).replace(error[1], error[2]);
    }
    else {
      errorMsg = translate(error);
    }
  }
  
  return (
    <React.Fragment>
      <SelectDiv>
        {
          label &&
          <CustomSelectLabel>{label}</CustomSelectLabel>
        } 
        <StyledSelectInput
          disabled={disabled}
          placeholder={placeholder}
          filterOption={filterOption}
          allowClear={allowClear}
          onSearch={fetchData}
          onChange={changeSelect}
          value={formatValue(input?.value)}
          showArrow={false}
          showSearch={false}
          error={showError ? 1 : 0}
          className='customSelect'
        > 
          {
            data.map((elem, index) => (
              <OptionContainer
                key={elem.name[activeLanguage.code]}
                onClick={() => selectOption()}
              >
                <OptionContentOurDay 
                  selected={!showNew && elem.name[activeLanguage.code] === input.value}
                >
                  <BoxOurDay 
                    className='customSelectBox' 
                    selected={!showNew && elem.name[activeLanguage.code] === input.value}
                  />
                  <OptionTextOurDay 
                    selected={!showNew && elem.name[activeLanguage.code] === input.value}
                  >
                    {elem.important ? `${elem.name[activeLanguage.code]} *`: elem.name[activeLanguage.code]}
                  </OptionTextOurDay>
                </OptionContentOurDay>
              </OptionContainer>
            ))
          }
          <OptionContainer
            key={translate('CREATE_MOMENT')}
            onClick={() => createNew()}
          >
            <OptionContentOurDay 
              selected={showNew}
              hasBorder={true}
            >
              <BoxOurDay 
                className='customSelectBox' 
                selected={showNew}
              />
              <OptionTextOurDay 
                selected={showNew}
              >
                {translate('CREATE_MOMENT')}
              </OptionTextOurDay>
            </OptionContentOurDay>
          </OptionContainer>
        </StyledSelectInput>
        <SelectArrow 
          className='customSelectArrow'
        >
          <ArrowImg src={ArrowDown}/>
        </SelectArrow>
      </SelectDiv>
      {invalid && submitFailed && (
        <SelectErrorLabel visible={invalid && submitFailed}>
          {errorMsg.toUpperCase()}
        </SelectErrorLabel>
      )}
    </React.Fragment>
  );
};

CustomSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  createNew: PropTypes.func,
  selectOption: PropTypes.func,
  showNew: PropTypes.bool,
  label: PropTypes.string
};

CustomSelectInput.defaultProps = {
  data: [],
  allowClear: false,
};

export default withLocalize(CustomSelectInput);