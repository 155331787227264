import styled from 'styled-components';

export const ListContainer = styled.div`
  max-width: 970px;
  margin: auto;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    width: auto;

    &:first-child {
      margin-top: 0px;
    }
  }
`;