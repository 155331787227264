import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import {
  SettingsMenu,
  SettingsMenuGroup,
  SettingsImg,
  SettingsText,
  LanguageImg,
  LanguageSpace
} from "./HeaderStyles";
import Check from "../../assets/icons/check.svg";
import Logout from "../../assets/icons/logout.svg";
import AboutUs from "../../assets/icons/about_us.svg";
import { settingsMenu } from "./HeaderOptions";
import { DeleteUser } from "../../redux/User/user.actions";
import { DeleteWedding } from "../../redux/Wedding/wedding.actions";
import Alert from "../../components/alert/Alert";
import { WeddingStatus } from "../../pages/reserve/ReservePage";
import { accessReserve } from "../../infra/services/utils/Helpers";

const SettingsMenuComponent = ({
  languages,
  history,
  location,
  translate,
  open,
  activeLanguage,
  setActiveLanguage,
  user,
  wedding
}) => {
  const changeLanguage = code => {
    setActiveLanguage(code);
    moment.locale(code);
    localStorage.setItem("LANG_COOKIE", code);
  };

  const handleClickMenu = url => {
    if (url === "DISABLED") {
      Alert.new({
        type: "success",
        title: translate("AVAILABLE_SOON_ALERT")
      });
    } else {
      history.push(url);
    }
  };

  const logout = () => {
    localStorage.clear();
    DeleteUser();
    DeleteWedding();
    history.push("/login");
  };

  const goToProfile = () => {
    history.push("/profile");
  };

  const toggleLanguage = (e, code) => {
    e.stopPropagation();

    changeLanguage(code);
  };

  return (
    <SettingsMenu open={open}>
      {settingsMenu.map(item => (
        <SettingsMenuGroup
          key={item.name}
          onClick={() => handleClickMenu(item.url)}
        >
          <SettingsText>{translate(item.name)}</SettingsText>
        </SettingsMenuGroup>
      ))}
      {languages.map((item, index) => (
        <SettingsMenuGroup
          key={index}
          border={index === 0 ? true : false}
          onClick={e => toggleLanguage(e, item.code)}
        >
          {activeLanguage && activeLanguage.code === item.code ? (
            <LanguageImg src={Check} />
          ) : (
            <LanguageSpace />
          )}
          <SettingsText>
            {translate(`LANG_${item.code.toUpperCase()}`)}
          </SettingsText>
        </SettingsMenuGroup>
      ))}
      {accessReserve(user, wedding) &&
        <SettingsMenuGroup border={true} onClick={() => goToProfile()}>
          <SettingsImg src={AboutUs} />
          <SettingsText>{translate("ABOUT_US")}</SettingsText>
        </SettingsMenuGroup>}
      <SettingsMenuGroup border={true} onClick={() => logout()}>
        <SettingsImg src={Logout} />
        <SettingsText>{translate("LOGOUT")}</SettingsText>
      </SettingsMenuGroup>
    </SettingsMenu>
  );
};

export default withLocalize(withRouter(SettingsMenuComponent));
