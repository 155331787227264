/*
* Returns true if the time between is greater than 1 hour
* False otherwise
*/
export const compareHours = (currentHour, lastHour) => {
  let res = false;

  //Split both hours by ":"
  const currentHourParts = currentHour.split(':');
  const lastHourParts = lastHour.split(':');

  //Build Date objects with each time
  const currentDate = new Date();
  currentDate.setHours(currentHourParts[0], currentHourParts[1], 0);

  const lastDate = new Date();
  lastDate.setHours(lastHourParts[0], lastHourParts[1], 0);

  //Get difference between the 2 dates in hours
  const diff = Math.abs(currentDate.getTime() - lastDate.getTime()) / 3600000;

  //If the difference is greater or equal to 1 hour, a blank space must be added in the timeline
  if(diff >= 1) {
    res = true;
  }

  return res;
}