import styled from 'styled-components';
import { device } from '../../styles/Responsive';

export const BarContainer = styled.div`
  width: 100%;
  height: ${p => p.type === 'dashboard' ? '12px' : '14px'};
  background-color: ${p => p.type === 'card' ? p.theme.thirdColor : (p.type === 'total' ? p.theme.secondaryColor : '#ffffff')};
  position: relative;

  @media ${device.tablet} {
    height: ${p => p.type === 'dashboard' ? '12px' : '8px'};
  }
`;

export const Bar = styled.div`
  width: ${p => p.percentage ? p.percentage+'%' : '0%' };
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${p => p.type === 'dashboard' || p.type === 'card' ? p.theme.secondaryColor : p.theme.primaryCTA};
  border-top-right-radius: ${p => p.type === 'dashboard' || p.type === 'card' || p.percentage >= 100 ? '0px' : '7px'};
  border-bottom-right-radius: ${p => p.type === 'dashboard' || p.type === 'card' || p.percentage >= 100 ? '0px' : '7px'};
`;

export const DotsContainer = styled.div`
  width: ${p => p.percentage ? p.percentage+'%' : '0%' };
  max-width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: ${p => p.num_answers === 1 ? 'flex-end' : 'space-between'};
  align-items: center;
  padding: 0px 5px;
`;

export const Dot = styled.div`
  background-color: ${p => p.theme.secondaryColor};
  border-radius: 100%;
  width: 8px;
  height: 8px;

  @media ${device.tablet} {
    width: 6px;
    height: 6px;
  }
`;