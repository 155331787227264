import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  DetailContent,
  DetailContainer,
  Title,
  Description,
  Arrow,
  ReturnContent,
  MainCategory,
  DocumentsSection,
  ImageContainer,
} from "./NewsDetailStyles";
import LongArrowLeft from "../../assets/icons/ic_arrow_left.svg";
import Document from "../../components/document/Document";
import { SpinLoading } from "../../styles/BasicStyles";
import Slider from "../../components/slider/Slider";
import ImageComponent from "../../components/images/ImageComponent";
import { GetNewsDetail } from "../../infra/requests/MyWeddingRequests";

class NewsDetail extends Component {
  state = {
    newsDetail: undefined,
    ready: false,
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.getNewsDetail();
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps?.match?.params?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.getNewsDetail();
    }
  }

  getNewsDetail = async () => {
    const { history } = this.props;
    let newsDetail = undefined;

    const { data, success } = await GetNewsDetail(this.props.match.params.id);

    if (success) {
      newsDetail = data;
    }

    if (!newsDetail) {
      history.push("/404");
      return false;
    }

    this.setState({
      newsDetail,
      ready: true,
    });
  };

  render() {
    const { translate, activeLanguage, history } = this.props;
    const { newsDetail, ready } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <DetailContent>
        <DetailContainer>
          <ReturnContent>
            <Arrow src={LongArrowLeft} onClick={() => history.goBack()} />
            <MainCategory>{translate("NEWS")}</MainCategory>
          </ReturnContent>
          <Title>{newsDetail.title[activeLanguage?.code]}</Title>
          <Description
            dangerouslySetInnerHTML={{
              __html:
                newsDetail.description &&
                newsDetail.description[activeLanguage?.code],
            }}
          />
        </DetailContainer>
        {newsDetail.gallery && newsDetail.gallery.length > 0 ? (
          newsDetail.gallery.length === 1 ? (
            <ImageContainer>
              <ImageComponent
                ratio={0.6}
                url={newsDetail.gallery[0].url}
                color={newsDetail.gallery[0].color}
              />
            </ImageContainer>
          ) : (
            <Slider gallery={newsDetail.gallery} />
          )
        ) : null}
        <DetailContainer>
          {newsDetail.attachments && newsDetail.attachments.length > 0 && (
            <DocumentsSection>
              {newsDetail.attachments.map((file) => (
                <Document key={file._id} document={file} />
              ))}
            </DocumentsSection>
          )}
        </DetailContainer>
      </DetailContent>
    );
  }
}

export default withLocalize(withRouter(NewsDetail));
