import client from "../config/AxiosConfig";

export const GetInfo = async () => client.get("/info/weddingday");

export const GetTranslations = async () =>
  client.get("/translations/platform/BO");

export const GetTranslationsRoomPlan = async () =>
  client.get("/translations/roomPlan");

export const GetDynamicPage = async id => client.get(`/dynamic_pages/${id}`);
