export const getDocumentType = (type) => {
  if(type === 'application/pdf') {
    return 'file-pdf';
  }
  else if(type === 'image/jpeg') {
    return 'file-jpg';
  }
  else if(type.indexOf('image/') !== -1) {
    return 'file-image';
  }
  else if(type === 'application/x-rar-compressed' || type === 'application/zip') {
    return 'file-zip';
  }
  else if(type === 'application/vnd.ms-excel' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    return 'file-excel';
  }
  else if(type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    return 'file-word';
  }
  else if(type === 'application/vnd.ms-powerpoint' || type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') {
    return 'file-ppt';
  }
  else if(type === 'text/plain') {
    return 'file-text';
  }
  else {
    return 'file';
  }
}