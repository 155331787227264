import React, { Component } from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { SpinLoading } from "../../styles/BasicStyles";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import {
  Content,
  Container,
  Title,
  Legend,
  LegendItem,
  ReserveContent,
  ReserveTitle,
  ReserveSubtitle,
  ReserveList,
  ReserveItem,
  Loading
} from "./AgendaStyles";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import BaseButton from "../../components/buttons/BaseButton";
import moment from "moment";
import Alerts from "../../components/alert/Alert";
import { Button, Checkbox, Col, Dropdown, Icon, Menu, Modal, Popconfirm, Radio, Row, Tooltip } from "antd";
import { CalendarBox, CalendarPicker, LinkAcceptPolicy, RadioPlace, RadioYear } from "../../components/YearView/CalendarStyles";
import YearView from "../../components/YearView/YearView";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import YearInput from "../../components/inputs/YearInput";
import XpertGoYearInput from "../../components/inputs/XpertGoYearInput";
import LoadingIcon from "../../assets/icons/loading.svg";
import SaveButton from "../../components/buttons/SaveButton";
import ReserveInfoModal from "./ReserveInfoModal";
import { GetWedding, GetWeddingInfo, ReservesBlock, UpdateInterestInfo, UpdateReserveRelatedInfo, UpdateWaitingDatePeriodNotification, UpdateWaitingInfo } from "../../infra/requests/MyWeddingRequests";
import DateWarningModal from "./DateWarningModal";
import { ReserveStatus, WeddingStatus } from "../reserve/ReservePage";
import XpertGoLeavePageBlocker from "../../components/pageBlocker/XpertGoLeavePageBlocker";
import { CheckReservesActions } from "../../infra/requests/ReserveRequests";
import { MngMultipleReserves, GetReserveAgenda, GetWeddingReserves, GetReserve, GetReservesWithOrder, CheckDatesAvailable } from "../../infra/requests/ReserveRequests";
import { CreateMultipleWaitingDate, CreateWaitingDate, GetWeddingWaitingDates, SoftDeleteMultipleWaitingDate, SoftDeleteWaitingDate, softDeleteWaitingDateAgenda } from "../../infra/requests/WaintingDateRequests";
import { WaitingIcon } from "../../components/inputs/InputStyles";
import Bookmark from "../../assets/icons/bookmark.svg";
import ReservesIcon from "../../assets/icons/reserves_white.svg";
import InterestListIcon from "../../assets/icons/interest_list_white.svg";
import VisitsIcon from "../../assets/icons/visit_list_white.svg";
import WaitingListIcon from "../../assets/icons/waiting_list_white.svg";
import AgendaIcon from "../../assets/icons/calendar.svg";
import Star from "../../assets/icons/star.svg";
import { CreateInterestDate, DeleteInterestDateAgenda, GetWeddingInterestDates } from "../../infra/requests/InterestDateRequests";
import WaitingInterestPolicyModal from "./WaitingDatesPolicyModal";
import UpButton from "../../components/buttons/UpButton";
import { IconAgenda, IconImg } from "../../components/header/HeaderStyles";
import { StyledButton } from "../../components/buttons/ButtonsStyles";
import styledComponents from "styled-components";
import { VisitsBlockedType } from "./VisitAgendaPage";
import { inAgendaTest } from "../../infra/services/utils/Helpers";
import InterestPolicyModal from "./InterestPolicyModal";
import WaitingDatesPolicyModal from "./WaitingDatesPolicyModal";
import { device } from "../../styles/Responsive";

const RowButtons = styledComponents.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: auto;

  .ant-btn {
    margin-inline-start: 10px;
    margin-block-start: 10px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
  }

  @media ${device.tabletS} {
    justify-content: flex-start;
  }
`;

class AgendaButtons extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { location, translate, history } = this.props;
    const { wedding } = this.props;

  }

  componentDidUpdate(prevProps, props) {
    const oldReserveId = new URLSearchParams(prevProps.location.search).get('reserve') !== undefined
      ? new URLSearchParams(prevProps.location.search).get('reserve')
      : null;
    const newReserveId = new URLSearchParams(prevProps.history.location.search).get('reserve') !== undefined
      ? new URLSearchParams(prevProps.history.location.search).get('reserve')
      : null;
  }

  render() {
    const { translate, history, activeLanguage, location, menuOpen } = this.props;
    const { wedding, agendaPage } = this.props;

    const reservePhase = (!wedding || (wedding?.status && wedding?.status === WeddingStatus.RESERVE)) ? true : false;

    return (
      <React.Fragment>
        <RowButtons>
          {reservePhase && <StyledButton
            style={{}}
            type="primary"
            bgcolor="primaryColor"
            onClick={() => agendaPage ? this.props.presentAgenda() : this.props.history.push('/agenda')}
            align={'right'}>
            <span>{translate('RESERVES')}</span>
            <IconAgenda src={ReservesIcon} />
          </StyledButton>}
          {inAgendaTest() && wedding?.status == WeddingStatus.CONFIRMED && wedding?.visitsBlocked == VisitsBlockedType.COMPLETED
            ? <StyledButton
              type="primary"
              bgcolor="primaryColor"
              onClick={() => this.props.history.push('/meeting-agenda')}
              align={'right'}>
              <span>{translate('MEETINGS_SHORT')}</span>
              <IconAgenda src={VisitsIcon} />
            </StyledButton>
            : inAgendaTest() && wedding?.visitsBlocked && wedding?.visitsBlocked == VisitsBlockedType.NONE
              ? <StyledButton
                type="primary"
                bgcolor="primaryColor"
                onClick={() => this.props.history.push('/visit-agenda')}
                align={'right'}>
                <span>{translate('VISITS')}</span>
                <IconAgenda src={VisitsIcon} />
              </StyledButton>
              : null}
          {inAgendaTest() && reservePhase && <StyledButton
            type="primary"
            bgcolor="primaryColor"
            onClick={() => this.props.history.push('/interest-agenda')}
            align={'right'}>
            <span>{translate('INTEREST_DATE')}</span>
            <IconAgenda src={InterestListIcon} />
          </StyledButton>}
          <StyledButton
            type="primary"
            bgcolor="primaryColor"
            onClick={() => this.props.history.push('/waiting-agenda')}
            align={'right'}>
            <span>{translate('WAITING_DATE')}</span>
            <IconAgenda src={WaitingListIcon} />
          </StyledButton>
        </RowButtons>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  wedding: state.wedding.wedding,
  menuOpen: state.menu
});

export default withLocalize(withRouter(connect(mapStateToProps)(AgendaButtons)));