import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { bindActionCreators } from 'redux';
import {
  Layout,
  Dashboard,
  MainContainer
} from "./LayoutStyles";
import HeaderComponent from "../header/HeaderComponent";
import MenuComponent from "../menu/MenuComponent";
import { CloseMenu } from "../../redux/Menu/menu.actions";
import OutsideAlerter from '../outsideAlerter/OutsideAlerter';
import { WeddingStatus } from "../../pages/reserve/ReservePage";
import { accessReserve } from "../../infra/services/utils/Helpers";

class ResponsiveSider extends Component {
  render() {
    const { menuOpen, CloseMenu, user, children, wedding } = this.props;

    return (
      <Layout>
        <HeaderComponent />
        <MainContainer reserve={accessReserve(user, wedding)} open={menuOpen}>
          {!accessReserve(user, wedding) &&
            <OutsideAlerter close={CloseMenu}>
              <MenuComponent />
            </OutsideAlerter>
          }
          <Dashboard open={menuOpen}>{children}</Dashboard>
        </MainContainer>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  menuOpen: state.menu,
  wedding: state.wedding.wedding,
});

const mapActionToProps = dispatch => bindActionCreators({ CloseMenu }, dispatch);

export default connect(mapStateToProps, mapActionToProps)(withRouter(ResponsiveSider));