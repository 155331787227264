import React from "react";
import {withLocalize} from "react-localize-redux";
import CustomSelectInputOurDay from "../inputs/CustomSelectInputOurDay";

const TimelineCategory = ({ input, meta, categories, editName, showNew, setShowNew, translate }) => {
  //When editing a Moment, change the select to the current value
  if(editName && !input.value) {
    input.onChange(editName);
  }

  const selectOption = () => {
    setShowNew(false);
  }

  const createNew = () => {
    setShowNew(true);
  }
  
  return (
    <CustomSelectInputOurDay
      data={categories}
      selectOption={selectOption}
      createNew={createNew}
      showNew={showNew}
      placeholder={translate('CHOOSE')}
      label={translate('TITLE')}
      meta={meta}
      input={input}
    />
  );
}

export default withLocalize(TimelineCategory);