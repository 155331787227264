import React from "react";
import { Row, Col } from "antd";
import { ContentContainer } from "../../styles/BasicStyles";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import GetInformationIcon from "../../infra/services/utils/InformationTypes";
import ArrowRight from "../../assets/icons/ic_arrow_right.svg";
import {
  Title,
  InformationSection,
  InformationTitle,
  InformationIcon,
  InformationArrow
} from "./InformationStyles";
import { accessReserve } from "../../infra/services/utils/Helpers";

const InformationPage = ({ translate, categories, history, user, wedding }) => {
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  document.body.style.overflowY = 'auto';

  return (
    <ContentContainer>
      <Title>{translate("USEFUL_INFORMATION")}</Title>
      <Row gutter={[24, 24]}>
        {categories
          .filter(f => accessReserve(user, wedding) ? f.platform !== 'C' : f.platform !== 'R')
          .map(category => (
            <Col key={category._id} xs={24} sm={12} md={12} lg={8}>
              <InformationSection
                onClick={() => history.push(`/information/${category._id}`)}
              >
                <InformationTitle>{translate(category.tag)}</InformationTitle>
                {/* <InformationIcon src={GetInformationIcon(category._id)} /> */}
                <InformationIcon src={category.iconCouple} />
                <InformationArrow src={ArrowRight} />
              </InformationSection>
            </Col>
          ))}
      </Row>
    </ContentContainer>
  );
};

const mapStateToProps = state => ({
  categories: state.info.content_categories || [],
  wedding: state.wedding.wedding,
  user: state.user,
});

export default withLocalize(connect(mapStateToProps)(InformationPage));
