import client from '../config/AxiosConfig';

export const CreateSchedule = async (data) =>
  client.put(`/my-wedding/createSchedule`, data);

export const CreateMeetingSchedule = async (data) =>
  client.put(`/my-wedding/createMeetingSchedule`, data);

// export const UpdateSchedule = async (data) =>
//   client.post(`/my-wedding/updateSchedule`, data);

export const FinishSchedule = async (id) =>
  client.post(`/my-wedding/finishSchedule/${id}`);

export const NotifySchdeduleNoResources = async (data) =>
  client.post(`/my-wedding/notifyScheduleNoResources`, data);

export const SoftDeleteSchedule = async (id, deleted) =>
  client.delete(`/my-wedding/softDeleteSchedule/${id}/${deleted}`);

export const GetScheduleAgenda = async (filters) =>
  client.post(`/my-wedding/getScheduleAgenda`, filters);

export const GetSchedules = async (filters) =>
  client.get(`/my-wedding/getSchedules?filter=${filters}`);

export const GetWeddingSchedules = async (filters) =>
  client.get(`/my-wedding/getWeddingSchedules?filter=${filters}`);

export const GetSchedule = async (id) =>
  client.get(`/my-wedding/getSchedule/${id}`);

export const GetSchedulesPaginated = async (currentPage, limit, filters) =>
  client.get(`/my-wedding/getSchedules/${currentPage}/${limit}?filter=${filters}`);

export const GetVenueColors = async () =>
  client.get(`/my-wedding/getVenueColors`);

export const GetServiceSchedules = async (filters) =>
  client.get(`/my-wedding/getServiceSchedules?filter=${JSON.stringify(filters)}`);

export const CheckSchedulesAccess = async () =>
  client.get(`/my-wedding/checkSchedulesAccess`);
