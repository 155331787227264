import { Icon } from "antd";
import React from "react";
import { withLocalize } from 'react-localize-redux';
import styledComponents from "styled-components";
import {
  ModalContainer,
  ModalContent,
  ModalButton
} from "./ConfirmModalStyles";

const Content = styledComponents.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;

  .anticon {
    font-size: 25px;
    color: #ffc53d;
  }
`;

const TextContent = styledComponents.div`
  margin-left: 10px;  
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
`;

const ConfirmModal = ({ loading, title, text, upgrade, handleConfirm, handleCancel, translate }) => {
  return (
    <ModalContainer>
      <ModalContent
        title={title}
        visible={true}
        onOk={handleConfirm}
        onCancel={handleCancel}
        footer={[
          <ModalButton key="back" onClick={handleCancel}>
            {translate('CONFIRM_NO')}
          </ModalButton>,
          <ModalButton key="submit" type="primary" loading={loading} onClick={handleConfirm}>
            {translate('CONFIRM_YES')}
          </ModalButton>
        ]}
      >
        <Content>
          <Icon type='question-circle' />
          <TextContent>
            <span>{text}</span>
            {upgrade?.hasPremiumOptions ? <span style={{ fontWeight: 600 }}>{translate('MODAL_TEXT_ATENTION_UPGRADE')}</span> : null}
          </TextContent>
        </Content>
      </ModalContent>
    </ModalContainer>
  );
}

export default withLocalize(ConfirmModal);