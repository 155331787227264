import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, FormSection } from "redux-form";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  MngGuestAccess,
  NotifyGuest,
  UpdateAboutUs,
  UpdateBillings,
} from "../../infra/requests/MyWeddingRequests";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { findPageByTag } from "../../infra/services/utils/PageInfo";
import { formatSpace } from "../../infra/services/utils/SpaceName";
import ImageInput from "../../components/inputs/ImageInput";
import SaveButton from "../../components/buttons/SaveButton";
import {
  brideFields,
  groomFields,
  contactFields,
  weddingFields,
  guestFields,
} from "./FormFields";
import {
  AboutUsContainer,
  Title,
  Subtitle,
  FormContainer,
  SectionTitle,
  SectionSubtitle,
  PaymentInfoContainer,
} from "./AboutUsStyles";
import Document from "../../components/document/Document";
import moment from "moment";
import { bindActionCreators } from "redux";
import { updateAboutUsAction } from "../../redux/Wedding/wedding.actions";
import BillingForm from "./BillingForm";
import QuestionsForm from "./QuestionsForm";
import FormValidator from "../../infra/services/validations/FormValidator";
import { SpinLoading } from "../../styles/BasicStyles";
import { AnswerQuestion } from "../../infra/requests/MyWeddingRequests";
import TextAreaInput from "../../components/inputs/TextAreaInput";
import DraftInput from "../../components/inputs/DraftInput";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";
import { isValidPhoneNumber } from 'react-phone-number-input';
import CheckboxInput from "../../components/inputs/CheckboxInput";
import { Input, Modal, Popconfirm, Tooltip, message } from "antd";
import Alerts from "../../components/alert/Alert";
import { CodeInput, CodeP } from "../profileReserve/ProfileReserve";
import BaseButton from "../../components/buttons/BaseButton";
import { InputLabel, MaterialInputSection, MaterialLabel, MaterialLabelFixed } from "../../components/inputs/MaterialStyles";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";
import SelectInput from "../../components/inputs/SelectInput";
import { weddingContractTypes } from "../reserve/UtilWeddingContract";
import { InputDiv, InputLabelDiv } from "../../components/inputs/InputStyles";
const confirm = Modal.confirm;

const FormValidations = (values) => {
  let errors: any = {};

  errors = FormValidator.make({
    language: 'required',
    weddingDayLanguage: 'required',
  })(values);

  // errors['billings'] = [];
  // if (values.billings && values.billings.length) {
  //   values.billings.forEach((value) => {
  //     const error = FormValidator.make({
  //       nif: "required",
  //       name: "required",
  //     })(value);

  //     errors.billings.push(error);
  //   });
  // }

  errors.bride = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.bride);

  errors.groom = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.groom);

  errors.alternative = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.alternative);

  errors.alternative2 = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.alternative2);

  const guestValidations = {};
  // If exists guest contact or email info, then name is required
  if ((values?.alternativeGuest?.email && values?.alternativeGuest?.email !== '')
    || (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact !== '')) {
    guestValidations['name'] = 'required';
    guestValidations['relationToCouple'] = 'required';
  }
  errors.alternativeGuest = FormValidator.make(guestValidations)(values?.alternativeGuest);

  // Check for the emails between couple, alternative contacts and guest are not the same
  if (values?.alternative?.email && values?.alternative?.email !== '' && (
    (values?.bride?.email && values?.bride?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.groom?.email && values?.groom?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.alternative2?.email && values?.alternative2?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()) ||
    (values?.alternativeGuest?.email && values?.alternativeGuest?.email.toLowerCase().trim() === values?.alternative?.email.toLowerCase().trim()))
  ) {
    errors.alternative.email = 'ALTERNATIVE_ERROR_SAME_EMAIL';
  }

  if (values?.alternative2?.email && values?.alternative2?.email !== '' && (
    (values?.bride?.email && values?.bride?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.groom?.email && values?.groom?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.alternative?.email && values?.alternative?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()) ||
    (values?.alternativeGuest?.email && values?.alternativeGuest?.email.toLowerCase().trim() === values?.alternative2?.email.toLowerCase().trim()))
  ) {
    errors.alternative2.email = 'ALTERNATIVE_ERROR_SAME_EMAIL';
  }

  // Check contact number is valid
  if (values.bride && values.bride.contact && !isValidPhoneNumber(values.bride.contact)) {
    errors.bride.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values.groom && values.groom.contact && !isValidPhoneNumber(values.groom.contact)) {
    errors.groom.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values?.bride?.contact && values?.groom?.contact && values?.bride?.contact.trim() === values?.groom?.contact.trim()) {
    errors.bride.contact = 'REGISTER_ERROR_SAME_CONTACT';
    errors.groom.contact = 'REGISTER_ERROR_SAME_CONTACT';
  }

  if (values.alternative && values.alternative.contact && !isValidPhoneNumber(values.alternative.contact)) {
    errors.alternative.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values.alternative2 && values.alternative2.contact && !isValidPhoneNumber(values.alternative2.contact)) {
    errors.alternative2.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values.alternativeGuest && values.alternativeGuest.contact && !isValidPhoneNumber(values.alternativeGuest.contact)) {
    errors.alternativeGuest.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  // Check that the contact between couple, guest and alternative contacts are not the same
  if (values?.alternative?.contact && (
    (values?.bride?.contact && values?.bride?.contact.trim() === values?.alternative?.contact.trim()) ||
    (values?.groom?.contact && values?.groom?.contact.trim() === values?.alternative?.contact.trim()) ||
    (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact.trim() === values?.alternative?.contact.trim()) ||
    (values?.alternative2?.contact && values?.alternative2?.contact.trim() === values?.alternative?.contact.trim()))
  ) {
    errors.alternative.contact = 'ALTERNATIVE_ERROR_SAME_CONTACT';
  }

  if (values?.alternative2?.contact && (
    (values?.bride?.contact && values?.bride?.contact.trim() === values?.alternative2?.contact.trim()) ||
    (values?.groom?.contact && values?.groom?.contact.trim() === values?.alternative2?.contact.trim()) ||
    (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact.trim() === values?.alternative2?.contact.trim()) ||
    (values?.alternative?.contact && values?.alternative?.contact.trim() === values?.alternative2?.contact.trim()))
  ) {
    errors.alternative2.contact = 'ALTERNATIVE_ERROR_SAME_CONTACT';
  }

  if (values?.alternativeGuest?.contact && (
    (values?.bride?.contact && values?.bride?.contact.trim() === values?.alternativeGuest?.contact.trim()) ||
    (values?.groom?.contact && values?.groom?.contact.trim() === values?.alternativeGuest?.contact.trim()) ||
    (values?.alternative?.contact && values?.alternative?.contact.trim() === values?.alternativeGuest?.contact.trim()) ||
    (values?.alternative2?.contact && values?.alternative2?.contact.trim() === values?.alternativeGuest?.contact.trim()))
  ) {
    errors.alternativeGuest.contact = 'ALTERNATIVE_GUEST_ERROR_SAME_CONTACT';
  }

  return errors;
};

class AboutUs extends Component {
  state = {
    description: null,
    chapters: [],
    addNewBilling: true,
    goToNextPage: false,
    isProcessClosed: undefined,
    ready: false,
    loading: false,
    mngAccess: false,
    blockGuestAccess: false,
    sendingEmail: false,
    weddingAccessCode: '',
  };

  componentDidMount = () => {
    document.body.style.overflowY = "auto";
    this.getInformation(1);
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history, dispatch, updateAboutUsAction } = this.props;
      const { goToNextPage, chapters } = this.state;

      // Set all billings as NOT new
      // if (values.billings.length > 0) {
      //   for (let i = 0; i < values.billings.length; i++) {
      //     values.billings[i].new = false;
      //   }
      // }

      const payload = TransformToFormData({
        ...values,
        billings: undefined,
        questions: undefined,
        documents: undefined,
        organizer: undefined,
        upgrade: undefined,
      });

      if (values?.accessToConfirmedWedding === false) {
        payload.append('accessToConfirmedWedding', 'false');
      }

      let { data, success } = await UpdateAboutUs(payload);

      if (success) {
        // Save the Billing data
        // ({ data, success } = await UpdateBillings({
        //   billings: values.billings,
        // }));

        if (success) {
          //Update data in reducer
          dispatch(updateAboutUsAction(data));

          //Find question "Métodos de pagamento: até 15 dias antes da data da cerimónia" (5e3184b59621e5d6a3d89744)
          const question1 = values.questions.find((question) =>
            question._id === "5e7360f129196b814fb9885b" ? question : null
          );
          let result1 = null;
          if (question1) {
            const payload1 = TransformToFormData({
              question: question1._id,
              response: question1?.answer?.response,
            });
            result1 = await AnswerQuestion(payload1);
          }

          //Find question "Métodos de pagamento: após a cerimónia" (5e73610729196b814fb9887f)
          const question2 = values.questions.find((question) =>
            question._id === "5e73610729196b814fb9887f" ? question : null
          );

          let result2 = null;
          if (question2) {
            let response = question2?.answer?.response;
            if (Array.isArray(question2?.answer?.response) && question2?.answer?.response?.[0]) {
              response = [{
                qtd: question2.answer.response[0].qtd,
                option: question2.options?.find(f => f.couplePaymentDate && f._id === question2.answer?.response[0].id),
                id: question2.answer.response[0].id,
                confirmed: question2.answer.response[0].confirmed ? question2.answer.response[0].confirmed : 'false',
              }];
            }
            const payload2 = TransformToFormData({
              question: question2._id,
              response,
            });
            result2 = await AnswerQuestion(payload2);
          }

          if (result1 && result1?.success && result2 && result2?.success) {
            //Update answers in the current Chapter
            for (let i = 0; i < chapters[0].questions.length; i++) {
              if (chapters[0].questions[i]._id === question1._id) {
                chapters[0].questions[i] = question1;
              } else if (chapters[0].questions[i]._id === question2._id) {
                chapters[0].questions[i] = question2;
              }
            }

            this.setState({
              chapters,
            });
          }

          //Clicked in the "Save" button on adding a new Billing
          //If "false" means we clicked in the "Add more" button
          if (!goToNextPage) {
            this.toggleNewBilling(true);

            this.getInformation();
          }
          //Clicked in the "Save and continue" button
          else {
            history.push("/our-style/5e42e52b29196b814f617d18");
          }
        }
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  formatWeddingInfoValue = (value, name) => {
    if (name === "date") {
      return moment(value).utc().format("DD/MM/YYYY");
    } else if (name === "wedding_place") {
      return formatSpace(value);
    } else {
      return value;
    }
  };

  getInformation = (scrollTop = 0) => {
    const { initialValues, aboutUsInfo } = this.props;

    const { description, chapters } = findPageByTag(aboutUsInfo, "ABOUT_US");
    // const isProcessClosed = processClosed(initialValues.date);
    const isProcessClosed = processClosed(initialValues.blockDate);

    if (scrollTop === 1) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    }

    this.setState({
      description,
      chapters,
      isProcessClosed,
      blockGuestAccess: initialValues.blockGuestAccess || false,
      weddingAccessCode: initialValues.code || '',
      ready: true,
    });
  };

  //Controls the number of "Add New Billing" (limited to 1)
  toggleNewBilling = (state) => {
    this.setState({
      addNewBilling: state,
    });
  };

  // confirmMngGuestAccess = () => {
  //   const { blockGuestAccess } = this.state;
  //   const { translate } = this.props;
  //   if (blockGuestAccess) {
  //     confirm({
  //       title: translate('ALTERNATIVE_GUEST_NOTIFY_QUESTION'),
  //       content: translate('ALTERNATIVE_GUEST_ACCESS_TEXT'),
  //       okText: translate('UNLOCK_SEND'),
  //       cancelText: translate('UNLOCK'),
  //       onOk: () => {
  //         this.mngGuestAccess(true);
  //       },
  //       onCancel: () => {
  //         this.mngGuestAccess();
  //       },
  //     });
  //   } else this.mngGuestAccess();

  // }

  // mngGuestAccess = async (sendEmail: boolean = false) => {
  //   const { translate } = this.props;

  //   this.setState({ mngAccess: true });
  //   let { data, success } = await MngGuestAccess({ sendEmail });

  //   if (success && data) {
  //     Alerts.new({
  //       type: 'success',
  //       title: translate('SUCCESS'),
  //       text: data?.blockGuestAccess ? translate('ALTERNATIVE_GUEST_BLOCKED') : translate('ALTERNATIVE_GUEST_UNBLOCKED')
  //     });
  //     this.setState({
  //       mngAccess: false,
  //       blockGuestAccess: data?.blockGuestAccess || false,
  //       weddingAccessCode: data?.code || ''
  //     });
  //   } else {
  //     Alerts.new({
  //       type: 'error',
  //       title: translate('ERROR'),
  //       text: translate('ALTERNATIVE_GUEST_ERROR_MNG_ACCESS')
  //     });
  //     this.setState({ mngAccess: false });
  //   }
  // }

  // copyCode = () => {
  //   const { translate } = this.props;
  //   const { weddingAccessCode } = this.state;
  //   // Navigator clipboard api needs a secure context (https)
  //   if (navigator.clipboard && window.isSecureContext) {
  //     navigator.clipboard.writeText(weddingAccessCode);
  //   } else {
  //     // Use the 'out of viewport hidden text area' trick
  //     const textArea = document.createElement('textarea');
  //     textArea.value = weddingAccessCode;

  //     // Move textarea out of the viewport so it's not visible
  //     textArea.style.position = 'absolute';
  //     textArea.style.left = '-999999px';

  //     document.body.prepend(textArea);
  //     textArea.select();

  //     document.execCommand('copy');
  //   }
  //   message.success(translate('TEXT_COPIED'));
  // }

  // notifyGuestCode = async () => {
  //   const { translate } = this.props;

  //   this.setState({ sendingEmail: true });
  //   let { data, success } = await NotifyGuest();

  //   if (success && data) {
  //     Alerts.new({
  //       type: 'success',
  //       title: translate('SUCCESS'),
  //       text: translate('ALTERNATIVE_GUEST_NOTIFIED')
  //     });
  //     this.setState({ sendingEmail: false });
  //   } else {
  //     Alerts.new({
  //       type: 'error',
  //       title: translate('ERROR'),
  //       text: translate('GENERIC_ERROR')
  //     });
  //     this.setState({ sendingEmail: false });
  //   }
  // }

  render() {
    const { initialValues, info, user } = this.props;
    const { translate, activeLanguage, history } = this.props;
    const { handleSubmit, submitFailed, invalid, dirty, submitting } = this.props;
    const { description, chapters, addNewBilling } = this.state;
    const { isProcessClosed, ready, loading, mngAccess, blockGuestAccess, weddingAccessCode, sendingEmail } = this.state;

    const BlockAccess = (
      <div>
        <div>{translate('ALTERNATIVE_GUEST_BLOCK_ACCESS_QUESTION')}</div>
        <div>{translate('ALTERNATIVE_GUEST_BLOCK_ACCESS_INFO')}</div>
      </div>
    );

    const UnblockAccess = (
      <div>
        <div>{translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS_QUESTION')}</div>
        <div>{translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS_INFO')}</div>
      </div>
    );

    const notifyGuest = (
      <div>
        <div>{translate('NOTIFY_GUEST_QUESTION')}</div>
        <div>{translate('NOTIFY_GUEST_INFO')}</div>
      </div>
    );

    if (!ready) return <SpinLoading />;

    if (
      !initialValues.questions &&
      chapters[0].questions &&
      chapters[0].questions.length > 0
    ) {
      initialValues.questions = chapters[0].questions;
    }

    if (Array.isArray(initialValues?.organizer) && initialValues?.organizer?.length > 0) {
      initialValues.organizer = initialValues?.organizer?.map(m => m?.name).join(', ');
    }

    return (
      <AboutUsContainer>
        <LeavePageBlocker
          when={dirty && !submitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <Title>{translate("ABOUT_US")}</Title>
        <Subtitle
          dangerouslySetInnerHTML={{
            __html: description && description[activeLanguage?.code],
          }}
        />
        <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
          <FormSection name="">
            <SectionTitle>{translate("COUPLE_PICTURE")}</SectionTitle>
            <Field
              component={ImageInput}
              accept="image/jpeg, image/png"
              name={"photo"}
              label={translate("UPLOAD_YOUR_FILE")}
              disabled={isProcessClosed}
              hasCrop={true}
              ratio={1.0}
            />
          </FormSection>
          <FormSection name="">
            <SectionTitle>{translate("WEDDING_INFO")}</SectionTitle>
            {weddingFields.map((field) => (
              <Field
                key={field.name}
                component={field.component}
                name={field.name}
                type={field.type}
                disabled={isProcessClosed || field.disabled}
                format={(value, name) =>
                  this.formatWeddingInfoValue(value, name)
                }
                label={translate(field.label)}
              />
            ))}
            <Field
              key={'organizerLanguage'}
              component={SelectLanguageInput}
              name={'organizerLanguage'}
              type={'select'}
              disabled={isProcessClosed}
              format={(value, name) =>
                this.formatWeddingInfoValue(value, name)
              }
              label={translate('ORGANIZER_COMMUNICATION_LANGUAGE')}
              languages={[
                { code: 'pt', name: translate('LANG_PT') },
                { code: 'en', name: translate('LANG_EN') },
                { code: 'pt_en', name: translate('LANG_PT_EN') },
              ]}
            />
            <Field
              key={'weddingDayLanguage'}
              component={SelectLanguageInput}
              name={'weddingDayLanguage'}
              type={'select'}
              disabled={isProcessClosed}
              format={(value, name) =>
                this.formatWeddingInfoValue(value, name)
              }
              label={`${translate('LANGUAGE_ON_WEDDING_DAY')} *`}
              languages={[
                { code: 'pt', name: translate('LANG_PT') },
                { code: 'en', name: translate('LANG_EN') },
                { code: 'pt_en', name: translate('LANG_PT_EN') },
              ]}
            />
          </FormSection>
          {initialValues && initialValues.meetings && (
            <FormSection name="">
              <SectionTitle>{translate("MEETINGS")}</SectionTitle>
              <Field
                name="meetings"
                component={DraftInput}
                readonly={true}
              />
            </FormSection>
          )}
          {initialValues && initialValues.visits && (
            <FormSection name="">
              <SectionTitle>{translate("VISITS")}</SectionTitle>
              <Field
                name="visits"
                component={DraftInput}
                readonly={true}
              />
            </FormSection>
          )}
          {initialValues && initialValues.documents.length > 0 && (
            <FormSection name="wedding_info">
              <SectionTitle>{translate("YOUR_DOCUMENTS")}</SectionTitle>
              {initialValues.documents.map((document) => (
                <Document key={document._id} document={document} />
              ))}
            </FormSection>
          )}
          <FormSection name="">
            <SectionTitle>{translate("WEDDING_CONTRACT_CONTRACT")}</SectionTitle>
            <Field
              component={SelectLanguageInput}
              name={'contractLanguage'}
              type={'text'}
              label={translate('WEDDING_LANGUAGE_CONTRACT')}
              disabled={true}
            />
            <Field
              component={SelectInput}
              name={'contractType'}
              type={'text'}
              label={translate('CONTRACT_TYPE_CONTRACT')}
              data={weddingContractTypes}
              dataKey={'_id'}
              costumeLabel={this.translateContractTypes}
              disabled={true}
            />
            <Field
              component={CheckboxInput}
              name={'trueData'}
              label={translate('VALIDATE_TRUE_DATA_CONTACT')}
              disabled={true}
            />
            {/* Couple ID Files */}
            {initialValues.coupleIdFiles?.length > 0
              && <InputDiv>
                <InputLabelDiv>{translate('WEDDING_FILES_CONTRACT')}</InputLabelDiv>
                {initialValues.coupleIdFiles.map((idFile) => (
                  <Document key={idFile._id} document={idFile} />
                ))}
              </InputDiv>
            }
            {/* Contract */}
            {initialValues.contractFile?.length > 0
              && <InputDiv>
                <InputLabelDiv>{translate('WEDDING_CONTRACT_CONTRACT')}</InputLabelDiv>
                  <Document key={initialValues.contractFile._id} document={initialValues.contractFile} />
              </InputDiv>
            }
          </FormSection>
          <FormSection name="bride">
            <SectionTitle>{translate("BRIDE_INFO")}</SectionTitle>
            {brideFields.map((field) => (
              <React.Fragment>
                {field?.contract && <Subtitle>{translate('COUPLE_INFO_CONTRACT')}</Subtitle>}
                <Field
                  key={field.name}
                  component={field.component}
                  name={field.name}
                  type={field.type}
                  disabled={isProcessClosed || field.disabled}
                  label={
                    field.asterisc
                      ? `${translate(field.label)} *`
                      : translate(field.label)
                  }
                />
              </React.Fragment>
            ))}
          </FormSection>
          <FormSection name="groom">
            <SectionTitle>{translate("GROOM_INFO")}</SectionTitle>
            {groomFields.map((field) => (
              <React.Fragment>
                {field?.contract && <Subtitle>{translate('COUPLE_INFO_CONTRACT')}</Subtitle>}
                <Field
                  key={field.name}
                  component={field.component}
                  name={field.name}
                  type={field.type}
                  disabled={isProcessClosed || field.disabled}
                  label={
                    field.asterisc
                      ? `${translate(field.label)} *`
                      : translate(field.label)
                  }
                />
              </React.Fragment>
            ))}
          </FormSection>
          <FormSection name="alternative">
            <SectionTitle>{translate("ALTERNATIVE_CONTACT")}</SectionTitle>
            <SectionSubtitle>
              {translate("ALTERNATIVE_CONTACT_SUBTITLE")}
            </SectionSubtitle>
            {contactFields.map((field) => (
              <Field
                key={field.name}
                component={field.component}
                name={field.name}
                type={field.type}
                label={
                  field.asterisc
                    ? `${translate(field.label)} *`
                    : translate(field.label)
                }
                disabled={isProcessClosed}
              />
            ))}
          </FormSection>
          <FormSection name="alternative2">
            <SectionTitle>{translate("ALTERNATIVE_CONTACT")}</SectionTitle>
            <SectionSubtitle>
              {translate("ALTERNATIVE_CONTACT_SUBTITLE")}
            </SectionSubtitle>
            {contactFields.map((field) => (
              <Field
                key={field.name}
                component={field.component}
                name={field.name}
                type={field.type}
                label={
                  field.asterisc
                    ? `${translate(field.label)} *`
                    : translate(field.label)
                }
                disabled={isProcessClosed}
              />
            ))}
          </FormSection>

          {initialValues?.alternativeGuest?.email && initialValues?.alternativeGuest?.email !== '' && initialValues?.accessToConfirmedWedding && <React.Fragment>
            <FormSection name="alternativeGuest">
              <SectionTitle>{translate("ALTERNATIVE_GUEST_INFO")}</SectionTitle>
              {guestFields.map((field) => (
                <Field
                  key={field.name}
                  component={field.component}
                  name={field.name}
                  type={field.type}
                  label={
                    field.asterisc
                      ? `${translate(field.label)} *`
                      : translate(field.label)
                  }
                  disabled={isProcessClosed || field.disabled}
                />
              ))}
              <Field
                component={SelectInput}
                name={'relationToCouple'}
                type={'select'}
                disabled={false}
                label={translate('ALTERNATIVE_GUEST_RELATION_COUPLE')}
                dataKey={'value'}
                dataLabel={'name'}
                data={[
                  { value: 'TU', name: translate('THIRD_USER_OPTION') },
                  { value: 'FF', name: translate('FRIEND_FAMILY_OPTION') },
                  { value: 'WP', name: translate('WEDDING_PLANNER_OPTION') },
                ]}
              />
            </FormSection>
            {/* {!user?.alternativeUser && <FormSection name='' style={{ textAlign: 'initial' }}>
              <!-- <Field
                component={CheckboxInput}
                name={'accessToConfirmedWedding'}
                label={translate('ALTERNATIVE_GUEST_ACCESS_WEDDING')}
              /> -->

              <SectionTitle>{translate("ALTERNATIVE_GUEST_ACCESS_INFO")}</SectionTitle>
              <SectionSubtitle>{translate("ALTERNATIVE_GUEST_ACCESS_SUBTITLE")}</SectionSubtitle>
              <Popconfirm
                title={blockGuestAccess ? UnblockAccess : BlockAccess}
                onConfirm={() => this.confirmMngGuestAccess()}
                okType={blockGuestAccess ? 'primary' : 'danger'}
                okText={blockGuestAccess ? 'Desbloquear' : 'Bloquear'}
                cancelText="Cancelar">
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="button"
                  type={blockGuestAccess ? 'primaryMedium' : 'dangerMedium'}
                  loading={mngAccess}
                  style={{ marginTop: 15 }}
                  text={blockGuestAccess ? translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS') : translate('ALTERNATIVE_GUEST_BLOCK_ACCESS')}
                />
              </Popconfirm>

              {!blockGuestAccess && <MaterialInputSection>
                <InputLabel>{translate('WEDDING_CODE')}</InputLabel>
                <CodeInput>
                  <Input value={weddingAccessCode} disabled={true} />
                  <Tooltip title={translate('COPY_CODE')}>
                    <BaseButton
                      type='default'
                      icon='copy'
                      style={{ borderRadius: 0 }}
                      onClick={this.copyCode}
                    />
                  </Tooltip>
                  <Popconfirm
                    title={notifyGuest}
                    onConfirm={this.notifyGuestCode}
                    okText={translate('NOTIFY')}
                    cancelText={translate('CANCEL')}>
                    <BaseButton
                      type='default'
                      icon={sendingEmail ? 'loading' : 'mail'}
                      style={{ borderRadius: '0 4px 4px 0' }}
                    />
                  </Popconfirm>
                </CodeInput>
              </MaterialInputSection>}
            </FormSection>} */}
          </React.Fragment>}

          {/* Billing Section Form */}
          <FormSection name="">
            {/* <SectionTitle>
              {chapters[0].name && chapters[0].name[activeLanguage?.code]} *
            </SectionTitle>
            <SectionSubtitle
              dangerouslySetInnerHTML={{
                __html:
                  chapters[0].description &&
                  chapters[0].description[activeLanguage?.code],
              }}
            />
            <FieldArray
              component={BillingForm}
              name="billings"
              addNewBilling={addNewBilling}
              toggleNewBilling={this.toggleNewBilling}
              isProcessClosed={isProcessClosed}
            /> */}

            {info?.configs?.iban && info?.configs?.swift && (
              <PaymentInfoContainer>
                <SectionTitle>{translate("PAYMENT_INFO")}</SectionTitle>
                <SectionSubtitle>
                  Nome: {info?.configs?.payment_company}
                </SectionSubtitle>
                <SectionSubtitle>IBAN: {info?.configs?.iban}</SectionSubtitle>
                <SectionSubtitle>
                  BIC/SWIFT: {info?.configs?.swift}
                </SectionSubtitle>
              </PaymentInfoContainer>
            )}
            {initialValues?.questions && (
              <FieldArray
                name="questions"
                component={QuestionsForm}
                questions={chapters[0].questions}
                isProcessClosed={isProcessClosed}
              />
            )}
          </FormSection>
          {!isProcessClosed && (
            <SaveButton
              htmlType="submit"
              error={submitFailed && invalid}
              text={translate("SAVE_CONTINUE")}
              textMobile={translate("SAVE")}
              onClick={() => this.setState({ goToNextPage: true })}
              loading={loading}
            />
          )}
        </FormContainer>
        {isProcessClosed && (
          <SaveButton
            htmlType="button"
            text={translate("CONTINUE")}
            textMobile={translate("CONTINUE")}
            withBackground
            onClick={() => history.push("/our-style/5e42e52b29196b814f617d18")}
          />
        )}
      </AboutUsContainer>
    );
  }
}

AboutUs = reduxForm({
  form: "about_us_form",
  validate: FormValidations,
})(AboutUs);

const mapStateToProps = (state) => ({
  initialValues: state.wedding.wedding,
  // initialValues: {
  //   ...state.wedding.wedding,
  //   organizers: state.wedding.wedding?.organizer?.length > 0
  //     ? state.wedding.wedding?.organizer?.map(m => m?.name)
  //     : ''
  // },
  aboutUsInfo: state.wedding.structure,
  info: state.info,
  user: state.user,
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ updateAboutUsAction }, dispatch);

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(AboutUs))
);
