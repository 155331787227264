import client from "../config/AxiosConfig";

export const GetOurDay = async () =>
  await client.get('/my-wedding/our-day');

export const AddMoment = async data =>
  await client.post('/my-wedding/our-day', data);

export const EditMoment = async (id, data) =>
  client.put(`/my-wedding/our-day/${id}`, data);

export const DeleteMoment = async id =>
  client.delete(`/my-wedding/our-day/${id}`);