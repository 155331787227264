import React, {Component} from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import StyleCard from "../../components/optionCard/StyleCard";
import StyleInspirations from "../../components/optionCard/StyleInspirations";
import SelectInput from "../../components/inputs/SelectInput";
import Pagination from "../../components/pagination/Pagination";
import { 
  OurStyleContainer,
  CardsContainer,
  CardsTitle,
  CardsRow, 
  EmptyRow,
  FiltersRow, 
  Filter, 
  NoResults, 
  Subtitle
} from "./OurStyleStyles";
import NoImage from "../../components/images/NoImage";
import Alert from '../../components/alert/Alert';

class OurSuggestionsForm extends Component {
  state = {
    categories: '',
    tag: '',
    inspirationsList: []
  };

  componentDidMount() {
    const { currentInspirations, input } = this.props;
    const inspirationsList = [];

    if(currentInspirations?.length > 0) {
      for(let i = 0; i < currentInspirations.length; i++) {
        inspirationsList.push(currentInspirations[i]);
      }
    }

    input.onChange(inspirationsList);
  }

  onInputChange = (field, value, timeout = 0) => {
    this.setState({[field]: value}, () => {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(this.constructQuery, timeout);
    });
  };

  constructQuery = () => {
    const {queryChange} = this.props;
    const query = {};

    Object.keys(this.state).map(key => {
      if(key !== 'inspirationsList' && this.state[key] && this.state[key] !== '') {
        query[key] = this.state[key].replace('#', '');
      }

      return key;
    });

    queryChange(JSON.stringify(query));
  };

  onPressTag = (e, tag) => {
    e.stopPropagation();

    this.onInputChange('tag', tag);
  };

  removeFilter = (tag) => {
    this.onInputChange(tag, '');
  }

  toggleOption = elem => {
    const { input, limitInspirations, isProcessClosed, translate } = this.props;
    const inspirationsList = [...input.value];
    const index = inspirationsList.findIndex(x => x._id === elem._id);

    if(isProcessClosed) {
      Alert.new({
        type: "success",
        title: translate('BLOCKED_ANSWER_TITLE'),
        text: translate('PROCESS_CLOSED_TEXT')
      });
    }
    else if(index >= 0) {
      inspirationsList.splice(index, 1);
    }
    else {
      //Limit of Inspirations: defined in "OurSuggestions.js"
      if(inspirationsList.length < limitInspirations) {
        inspirationsList.push(elem);
      }
      else {
        Alert.new({
          type: "error",
          title: translate("ALERT_ERROR_TITLE"),
          text: translate("ALERT_MAX_INSPIRATIONS")
        });
      }
    }

    input.onChange(inspirationsList);
  };

  render() {
    const { input, meta, filterCategories, filterTags, inspirations, changePage, containerRef, translate } = this.props;
    const { categories, tag } = this.state;

    return(
      <OurStyleContainer>
        <Subtitle>{translate('OUR_SUGGESTIONS')}</Subtitle>
        <CardsContainer>
          <CardsTitle>{translate('INSPIRATIONS_TITLE')}</CardsTitle>
          { 
            input?.value && input.value.length > 0 ?
            <CardsRow>
              {
                input.value.map((elem, index) =>
                  <StyleInspirations
                    key={index}
                    record={elem}
                    input={input}
                    toggleOption={this.toggleOption}
                  />
                )
              }
            </CardsRow>
            :
            <EmptyRow>
              <NoImage tag='NO_INSPIRATIONS'/>
            </EmptyRow>
          }
        </CardsContainer>
        <FiltersRow ref={containerRef}>
          <Filter>
            <SelectInput 
              input={{
                value: categories,
                onChange: value => this.onInputChange('categories', value)
              }}
              meta={meta}
              data={filterCategories}
              translatable={false}
              dataKey="_id"
              dataLabel="name"
              placeholder={translate('CATEGORIES')}
              removeSelection={this.removeFilter}
              tag="categories"
            />
          </Filter>
          <Filter>
            <SelectInput
              input={{
                value: tag,
                onChange: value => this.onInputChange('tag', value)
              }}
              meta={meta}
              data={filterTags}
              dataKey="name"
              dataLabel="name"
              placeholder={translate('TAGS')}
              onInputChange={this.onInputChange}
              removeSelection={this.removeFilter}
              tag="tag"
            />
          </Filter>
        </FiltersRow>
        <CardsRow>
          { 
            inspirations?.items?.length > 0 ?
            inspirations?.items?.map((elem, index) =>
              <StyleCard
                key={index}
                record={elem}
                selected={input?.value && input.value.find(x => x._id === elem._id)}
                input={input}
                onPressTag={this.onPressTag}
                toggleOption={this.toggleOption}
              />
            )
            :
            <NoResults>{translate('NO_RESULTS')}</NoResults>
          }
        </CardsRow>
        <Pagination
          changePage={changePage}
          totalItems={parseInt(inspirations?.total)} 
          currentPage={parseInt(inspirations?.page)} 
          perPage={parseInt(inspirations?.limit)}
        />
      </OurStyleContainer>
    );
  }
};

OurSuggestionsForm.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired
};

export default withLocalize(OurSuggestionsForm);