import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import {
  StyledSaveButton,
  TextContainer,
  IconContainer,
  IconText,
  Icon,
  LoadingImage,
} from "./ButtonsStyles";
import LoadingIcon from "../../assets/icons/loading_save.svg";
import LongArrowUp from "../svg/LongArrowUp";

export const UpButton = ({
  text,
  textMobile,
  withBackground,
  loading,
  error,
}) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    showTopBtn
      ? <StyledSaveButton
        type="button"
        onClick={goToTop}
        withBackground={withBackground}
        loading={loading}
        error={error}
      >
        {text ? <TextContainer>{text}</TextContainer> : null}
        <IconContainer error={error}>
          {textMobile ? <IconText>{textMobile}</IconText> : null}{" "}
          <Icon>{loading ? <LoadingImage src={LoadingIcon} /> : <LongArrowUp />}</Icon>
        </IconContainer>
      </StyledSaveButton>
      : null
  );
};

UpButton.propTypes = {
  text: PropTypes.string,
  textMobile: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  withBackground: PropTypes.bool,
  loading: PropTypes.bool,
};

UpButton.defaultProps = {
  htmlType: "button",
};

export default UpButton;
