import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import {
  QuestionTitle,
  FieldContainer,
  QuestionDescription,
} from "../decoration/PageDetailStyles";
import Document from "../../components/document/Document";

const GuestsRoomPlanForm = ({
  input,
  meta,
  questions,
  roomPlans,
  weddingInfo,
  isProcessClosed,
  translate,
  activeLanguage,
}) => {
  const getDocument = () => {
    return roomPlans.filter((plan) =>
      plan.local === weddingInfo.wedding_place ? plan : null
    )[0].room_plan;
  };

  const renderQuestion = (question) => {
    return (
      <FieldContainer key={question._id}>
        {question._id === "5e2ae09f47f38787fd9e842a" && (
          <QuestionTitle>{question.title[activeLanguage?.code]}</QuestionTitle>
        )}
        {question.description && (
          <QuestionDescription
            dangerouslySetInnerHTML={{
              __html: question.description[activeLanguage?.code],
            }}
          />
        )}
        {
          //Download Room Plan
          question._id === "5e2ae09f47f38787fd9e842a" && roomPlans ? (
            <Document document={getDocument()} />
          ) : /*:
          //Add Room Plan
          question._id === '5e2ae0b247f38787fd9e842b' ?
          <Field
            component={ImageInput}
            name='room_plan'
            label={translate("UPLOAD_YOUR_FILE")}
            disabled={isProcessClosed}
          />*/
          null
        }
      </FieldContainer>
    );
  };

  return (
    <React.Fragment>
      {questions.length > 0 &&
        questions.map((question) => renderQuestion(question))}
    </React.Fragment>
  );
};

GuestsRoomPlanForm.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
};

export default withLocalize(GuestsRoomPlanForm);
