import styled from 'styled-components';
import { device, vertical } from '../../styles/Responsive';
import { Form } from "antd";
import { titleH3, titleH4 } from '../../styles/TextSizes';

export const Description = styled.div`
  width: 100%;
  max-width: 970px; 
  text-align: left;
  padding: 0px 15px;
  margin: auto;
  margin-bottom: 30px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`;

export const Information = styled.div`
  width: 100%;
  max-width: 970px; 
  text-align: left;
  padding: 0px 15px;
  margin: auto;
  margin-bottom: 30px;
  white-space: pre-line;
  color: ${p => p?.theme?.primaryColor};
  font-size: 20px;
  font-weight: 700;
  line-height: 31px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 24px;
  }

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`;

export const FormContainer = styled(Form)`
  position: fixed;
  right: ${p => p.open ? '0px' : '-460px'};
  top: 300px;
  width: 460px;
  min-height: 400px;
  border: 1px solid ${p => p.theme.primaryCTA};
  border-radius: 12px 0px 0px 12px;
  background-color: #ffffff;
  z-index: 1000;
  transition: right 0.3s linear;
  padding: 50px 25px !important;

  @media ${device.laptop} {
    right: ${p => p.open ? '0px' : '-400px'};
    top: 250px;
    width: 400px;
    min-height: 380px;
    padding: 35px 15px !important;
  }

  @media ${device.tablet} {
    right: ${p => p.open ? '0px' : '-350px'};
    top: 220px;
    width: 350px;
    min-height: 340px;
    padding: 30px 10px !important;

    & button.ant-btn-primaryLarge {
      padding: 10px 80px;
    }
  }

  @media ${device.mobileL} {
    right: ${p => p.open ? '0px' : '-290px'};
    width: 290px;
    min-height: 330px;

    & button.ant-btn-primaryLarge {
      padding: 8px 60px;
    }
  }

  @media ${vertical.medium} {
    top: 250px;
  }

  @media ${vertical.mediumS} {
    top: 200px;
  }

  @media ${vertical.smallL} {
    top: 120px;
  }

  @media ${vertical.small} {
    top: 20px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & button:first-child {
    margin-right: 5px;
  }

  & button:last-child {
    margin-left: 5px;
  }
`;

export const CloseButton = styled.img`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 0.6;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 30px;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const NewOptionContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  display: ${p => p.showNew ? 'block' : 'none'};
  position: relative;

  & div {
    margin-top: 0px;
    height: 40px;
  }

  & input {
    margin-top: 0px;
    height: 40px;
    padding-right: 30px;
  }

  @media ${device.tablet} {
    margin-left: 0px;
    margin-top: 15px;
    max-width: 100%;
  }
`;

export const ImportantMomentsTitle = styled(titleH3)`
  width: 100%;
  max-width: 970px; 
  text-align: left;
  padding: 0px 15px;
  margin: auto;
  margin-bottom: 20px;

  @media ${device.mobileL} {
    padding: 0px 10px;
  }
`;

export const ImportantMomentsContainer = styled.div`
  max-width: 970px;
  margin: auto;
  margin-bottom: 30px;
`;

export const ImportantMomentsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ImportantMoment = styled.div`
  flex-basis: 33.333%;
  padding: 10px 15px;

  @media ${device.laptop} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
    padding: 5px 15px;
  }

  @media ${device.mobileL} {
    flex-basis: 100%;
    padding: 5px 10px;
  }
`;

export const ImportantMomentText = styled(titleH4)`
  background-color: ${p => p.theme.primaryCTA};
  border-radius: 6px;
  padding: 12px 20px;
  font-size: 18px;
  line-height: 29px;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s linear;

  &:hover {
    background-color: ${p => p.theme.secondaryCTA};
  }

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 20px;
  }
`;