import React from "react";
import {withLocalize} from 'react-localize-redux';
import {
  Container,
  Content,
  FirstColumn,
  SecondColumn,
  BgContainer,
  Title,
  Text,
  FirstRow,
  SecondRow,
  FirstTextColumn,
  SecondTextColumn
} from "./TextImageStyles";
import ImageComponent from "../images/ImageComponent";

/*
* Type: 1 - Text/Image || 2 - Text/Text
* Direction: 1 - Text/Image || 2 - Image/Text (for Type 1 only)
*/

const TextImage = ({ type, direction, title, text1, text2, image, imgColor, color, bgColor }) => {
  return (
    <Container bgColor={bgColor}>
      <Content type={type}>
        {
          type === 1 ?
          <React.Fragment>
            <FirstColumn>
              {
                direction === 2 ?
                image && <BgContainer >
                  <ImageComponent url={image} color={imgColor}/>
                </BgContainer>
                :
                <React.Fragment>
                  {
                    title && <Title color={color}>{title}</Title>
                  }
                  <Text color={color} dangerouslySetInnerHTML={{__html: text1}}/>
                </React.Fragment>
              }
            </FirstColumn>
            <SecondColumn>
              {
                direction === 1 ?
                image && <BgContainer >
                  <ImageComponent url={image} color={imgColor}/>
                </BgContainer>
                :
                <React.Fragment>
                  {
                    title && <Title color={color}>{title}</Title>
                  }
                  <Text color={color} dangerouslySetInnerHTML={{__html: text1}}/>
                </React.Fragment>
              }
            </SecondColumn>
          </React.Fragment>
          :
          <React.Fragment>
            {
              title && 
              <FirstRow>
                <Title color={color}>{title}</Title>
              </FirstRow>
            }
            <SecondRow>
              <FirstTextColumn hasSecondText={text2 !== ''}>
                <Text color={color} dangerouslySetInnerHTML={{__html: text1}}/>
              </FirstTextColumn>
              {
                text2 && 
                <SecondTextColumn>
                  <Text color={color} dangerouslySetInnerHTML={{__html: text2}}/>
                </SecondTextColumn>
              }
            </SecondRow>
          </React.Fragment>
        }
        
      </Content>
    </Container>
  );
}

export default withLocalize(TextImage);