import client from "../config/AxiosConfig";

export const GetTags = async (filter = {}) =>
  await client.get(`/inspirations/tags/?filter=${JSON.stringify(filter)}`);

export const GetInspirations = async (page, limit, filter = "") =>
  await client.get(`/inspirations/${page}/${limit}?filter=${filter}`);

export const GetCurrentInspirations = async () =>
  await client.get(`/my-wedding/our-style`);

export const EditInspirations = async data =>
  client.put("/my-wedding/our-style/inspirations", data);

export const AddSuggestion = async data =>
  client.put("/my-wedding/our-style/images", data);

export const DeleteSuggestion = async id =>
  client.delete(`/my-wedding/our-style/images/${id}`);