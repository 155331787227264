import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH5 } from '../../styles/TextSizes';

export const CardContainer = styled.div`
  flex-basis: 33.333%;
  position: relative;
  padding: 0 15px;
  margin-bottom: 50px;

  @media ${device.laptopS} {
    flex-basis: 50%;
  }

  @media ${device.tabletS} {
    flex-basis: 100%;
  }
`;

export const CardContent = styled.div`
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Question = styled(titleH5)`
  color: #757575;
  line-height: 29px;
  width: 100%;
  text-align: left;
  height: 60px;
  margin-bottom: 20px;
  overflow: hidden;

  @media ${device.desktop} {
    margin-bottom: 10px;
  }
`;

export const DetailContainer = styled.div`
  padding: 30px;
  width: 100%;
  height: 372px;
  background-color: ${p => p.isObservation ? p.theme.secondaryColor : p.theme.thirdColor};

  @media ${device.tablet} {
    height: 340px;
  }
`;

export const BgContainer = styled.div`
  width: 100%;
  max-width: 300px;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
`;

export const BgColorContainer = styled.div`
  width: 100%;
  max-width: 300px;
  height: 200px;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
`;

export const BgColor = styled.div`
  background-color: ${p => p.color};
  width: 100%;
  height: 100%;
`;

export const BgImage = styled.img`
  height: 100%;
  max-width: 100%;
`;

export const Answer = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  width: 100%;
  text-align: left;
  height: ${p => p.hasImage ? '95px' : '310px'};
  overflow-y: auto;
  white-space: pre-line;

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const TextOverImage = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: #B39A71;
  font-size: 30px;
  font-weight: 700;
  line-height: 31px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FileContaner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 0.6;
  }
`;

export const ImageIcon = styled.img`
  width: 63px;
`;

export const FileText = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  padding-top: 30px;
`;