import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
  DetailContainer,
  Description,
  NumGuestsContainer
} from '../decoration/PageDetailStyles';
import ProgressBar from '../../components/progressBar/ProgressBar';
import DecorationNavigation from '../../components/decorationNavigation/DecorationNavigation';
import {
  findPageByTag,
  findPageByID
} from '../../infra/services/utils/PageInfo';
import {
  findNextQuestion,
  findPrevChapter
} from '../../infra/services/utils/PageNavigation';
import TextInput from "../../components/inputs/TextInput";
import { GetGuests } from '../../infra/requests/GuestsRequests';
import SaveButton from '../../components/buttons/SaveButton';
import { SpinLoading } from "../../styles/BasicStyles";

const sectionTag = 'GUESTS';
const sectionId = '5e2adad647f38787fd9e8425';
const returnURL = '/guests';

class GuestsDefinitiveList extends Component {
  state = {
    chapter: undefined,
    nextQuestion: undefined,
    prevChapter: undefined,
    numberGuests: undefined,
    numberChildrens: undefined,
    numberStaff: undefined,
    numberBabies: undefined,
    numberPax: undefined,
    numberSeats: undefined,
    numberTables: undefined,
    completed: undefined,
    ready: false
  };

  componentDidMount() {
    window.scroll({top: 0, left: 0, behavior: 'smooth'});
    document.body.style.overflowY = 'auto';

    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if(this.props.id !== prevProps?.id) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
      document.body.style.overflowY = 'auto';
      
      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    const { decorationInfo } = this.props;

    const { chapters } = findPageByTag(decorationInfo, sectionTag);
    const chapter = findPageByID(chapters, sectionId);
    const nextQuestion = findNextQuestion(decorationInfo, chapters, chapter, 0);
    const prevChapter = findPrevChapter(decorationInfo, chapter.section, chapters, chapter._id);

    const { data, success } = await GetGuests();
    let numberGuests = 0,
    numberChildrens = 0,
    numberStaff = 0,
    numberBabies = 0,
    numberPax = 0, 
    numberSeats = 0, 
    numberTables = 0, 
    completed = false;

    if(success) {
      numberGuests = data.guests;
      numberChildrens = data.children;
      numberStaff = data.staff;
      numberBabies = data.babies;
      completed = data.completed;

      //Formula: Guests with +8 years + (Children / 2) + (Staff / 2)
      numberPax = numberGuests + (numberChildrens / 2) + (numberStaff / 2);

      //Check if Pax is integer or ends with ".5". In that case add "1/2" to the result
      if(numberPax > 0 && Math.floor(numberPax) !== numberPax) {
        numberPax = `${Math.floor(numberPax)} 1/2`;
      }

      //Formula: Guests with +8 years + Children + Staff + Babies
      numberSeats = numberGuests + numberChildrens + numberStaff + numberBabies;

      //Formula: (Guests with +8 years + Children) / 10
      numberTables = Math.ceil((numberGuests + numberChildrens) / 10);
    }

    this.setState({
      chapter,
      nextQuestion,
      prevChapter,
      numberGuests,
      numberChildrens,
      numberStaff,
      numberBabies,
      numberPax,
      numberSeats,
      numberTables,
      completed,
      ready: true
    });
  };

  renderQuestion = (question) => {
    const { activeLanguage, translate } = this.props;
    const { numberGuests, numberChildrens, numberStaff, numberBabies, numberPax, numberSeats, numberTables, completed } = this.state;
    let value = '';
    
    if(completed) {
      if(question._id === '5e2ae0f647f38787fd9e842c' && numberGuests) {
        value = numberGuests;
      }
      else if(question._id === '5e2ae11147f38787fd9e842d' && numberChildrens) {
        value = numberChildrens;
      }
      else if(question._id === '5e2ae12647f38787fd9e842e' && numberStaff) {
        value = numberStaff;
      }
      else if(question._id === '5e2ae14347f38787fd9e842f' && numberBabies) {
        value = numberBabies;
      }
      else if(question._id === '5e2ae16b47f38787fd9e8430' && numberPax) {
        value = `${numberPax} Pax`;
      }
      else if(question._id === '5e2ae17c47f38787fd9e8431' && numberSeats) {
        value = `${numberSeats} ${translate('MOUNTED_PLACES')}`;
      }
      else if(question._id === '5e2ae18d47f38787fd9e8432' && numberTables) {
        value = `${numberTables} ${translate('TABLES')}`;
      }
    }

    return (
      <TextInput
        key={question._id}
        meta={{}}
        input={{value: value, onChange: () => null}} 
        type="text" 
        label={question.title[activeLanguage?.code]} 
        disabled={true}
        tag={value !== '' ? "GUESTS" : ""}
        labelFixed={true}
      />
    );
  }

  render() {
    const { history, translate, activeLanguage } = this.props;
    const { chapter, nextQuestion, prevChapter, ready } = this.state;

    if(!ready) return <SpinLoading/>;

    return (
      <React.Fragment>
        <ProgressBar type='section' num_questions={0} num_answers={0}/>
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${sectionId}`}
          nextQuestion={nextQuestion?.tag ? translate(nextQuestion?.tag) : nextQuestion?.name[activeLanguage?.code]}
          nextQuestionUrl={nextQuestion?.url}
          prevQuestion={prevChapter?.name[activeLanguage?.code]}
          prevQuestionUrl={`${returnURL}/${prevChapter?._id}`}
        />
        <DetailContainer>
          {
            chapter.description && <Description dangerouslySetInnerHTML={{__html: chapter.description && chapter.description[activeLanguage?.code]}}/>
          }
          <NumGuestsContainer>
            {
              chapter.questions.length > 0 && chapter.questions.map(question => this.renderQuestion(question))
            }
          </NumGuestsContainer>
          <SaveButton
            htmlType='button'
            text={translate('CONTINUE')}
            textMobile={translate('CONTINUE')}
            withBackground={true}
            onClick={() => history.push(nextQuestion?.url)}
          />
        </DetailContainer>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  decorationInfo: state.wedding.structure
});

export default connect(mapStateToProps)(withLocalize(withRouter(GuestsDefinitiveList)));