import React from "react";
import {
  CardContainer,
  CardContent,
  BgContainer,
  DetailContainer,
  Title,
  SelectContainer,
  TextAvailable,
  CheckboxContainer,
  TagsContainer,
  Tag
} from "./OptionCardStyles";
import CheckboxInput from "../inputs/CheckboxInput";
import ImageComponent from "../images/ImageComponent";

const StyleCard = ({record, selected, toggleOption, onPressTag, input}) => {
  return (
    <CardContainer type='styleCard' onClick={() => toggleOption(record)}>
      <CardContent selected={selected}>
        <SelectContainer>
          <TextAvailable/>
          <CheckboxContainer>
            <CheckboxInput
              checkboxType="round"
              meta={{}}
              input={{ value: selected, onChange: () => null }}
            />
          </CheckboxContainer>
        </SelectContainer>
        <BgContainer border={false}>
          <ImageComponent ratio={0.6} url={record.image?.url} color={record.image?.color}/>
        </BgContainer>
        <DetailContainer>
          <Title>
            { 
              record.text
            }
          </Title>
          {
            record.tags?.length > 0 &&
            <TagsContainer>
              {
                record.tags.map((elem, index) => <Tag key={index} onClick={(e) => onPressTag(e, elem)}>#{elem}</Tag>)
              }
            </TagsContainer>
          }
        </DetailContainer>
      </CardContent>
    </CardContainer>
  );
};

export default StyleCard;