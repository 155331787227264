import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Checkbox, Form, Popconfirm, Icon, Row, Col } from 'antd';
import { Spin, Form as AntForm } from 'antd';
import { Field, FormSection, formValueSelector, reduxForm } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { MaterialInput, MaterialInputSection } from '../../components/inputs/MaterialStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import styled from 'styled-components';
import TextInput from '../../components/inputs/TextInput';
import { titleH2, titleH3 } from '../../styles/TextSizes';
import { device } from '../../styles/Responsive';
import { weddingContractBrideFields, weddingContractGroomFields } from '../aboutUs/FormFields';
import CustomSelectInput from '../../components/inputs/CustomSelectInput';
import SelectLanguageInput from '../../components/inputs/SelectLanguageInput';
import SelectInput from '../../components/inputs/SelectInput';
import { weddingContractTypes } from './UtilWeddingContract';
import FilesInput from "../../components/inputs/FilesInput";
import CheckboxInput from '../../components/inputs/CheckboxInput';
import FileInput from '../../components/inputs/FileInput';
import { AddCoupleIdFiles, RemoveCoupleIdFiles, SaveWeddingContract } from '../../infra/requests/MyWeddingRequests';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Alerts from '../../components/alert/Alert';
import { SpinLoading } from '../../styles/BasicStyles';
import { ProfileContainer } from '../profileReserve/ProfileStyles';
import { ReserveInfo, ReserveText, Title } from './ReserveStyles';
import { FormContainer } from './ReserveModal';
import SaveButton from '../../components/buttons/SaveButton';
import { bindActionCreators } from 'redux';
import { updateAboutUsAction } from "../../redux/Wedding/wedding.actions";
import { ConfirmReserve, GetReserve } from '../../infra/requests/ReserveRequests';
import moment from 'moment';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import { ReserveStatus } from './ReservePage';

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const SectionTitle = styled(titleH3)`
  text-align: left;
  padding-top: 105px;

  @media ${device.desktopL} {
    padding-top: 70px;
  }

  @media ${device.desktop} {
    padding-top: 50px;
  }
`;

export const SectionSubtitle = styled.div`
  text-align: left;
  padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-top: 40px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const validations = (values) => {
    let errors = {};

    errors.bride = FormValidator.make({
        brideFullName: "required",
        brideNationality: "required",
        brideStreetDoorNumber: "required",
        brideZipCode: "required",
        brideStreet: "required",
        brideCountry: "required",
        bridePersonalEmail: "required|email",
        bridePersonalPhone: "required"
    })(values?.bride);

    errors.groom = FormValidator.make({
        groomFullName: "required",
        groomNationality: "required",
        groomStreetDoorNumber: "required",
        groomZipCode: "required",
        groomStreet: "required",
        groomCountry: "required",
        groomPersonalEmail: "required|email",
        groomPersonalPhone: "required"
    })(values?.groom);

    if (values?.bride?.bridePersonalPhone && !isValidPhoneNumber(values?.bride?.bridePersonalPhone)) {
        errors.bride.bridePersonalPhone = 'INVALID_PHONE_NUMBER_CONTRACT';
    }

    if (values?.groom?.groomPersonalPhone && !isValidPhoneNumber(values?.groom?.groomPersonalPhone)) {
        errors.groom.groomPersonalPhone = 'INVALID_PHONE_NUMBER_CONTRACT';
    }

    const brideIdentification = String(values?.bride?.brideIdentification)?.toLowerCase()?.trim();
    const groomIdentification = String(values?.groom?.groomIdentification)?.toLowerCase()?.trim();
    if (values?.bride?.brideIdentification && values?.groom?.groomIdentification && (brideIdentification === groomIdentification)) {
        errors.bride.brideIdentification = 'SAME_IDENTIFICATION_CONTRACT';
        errors.groom.groomIdentification = 'SAME_IDENTIFICATION_CONTRACT';
    }

    const brideIdentificationNumber = String(values?.bride?.brideIdentificationNumber)?.toLowerCase()?.trim();
    const groomIdentificationNumber = String(values?.groom?.groomIdentificationNumber)?.toLowerCase()?.trim();
    if (values?.bride?.brideIdentificationNumber && values?.groom?.groomIdentificationNumber && (brideIdentificationNumber === groomIdentificationNumber)) {
        errors.bride.brideIdentificationNumber = 'SAME_IDENTIFICATION_NUMBER_CONTRACT';
        errors.groom.groomIdentificationNumber = 'SAME_IDENTIFICATION_NUMBER_CONTRACT';
    }

    const bridePersonalEmail = String(values?.bride?.bridePersonalEmail)?.toLowerCase()?.trim();
    const groomPersonalEmail = String(values?.groom?.groomPersonalEmail)?.toLowerCase()?.trim();
    if (values?.bride?.bridePersonalEmail && values?.groom?.groomPersonalEmail && (bridePersonalEmail === groomPersonalEmail)) {
        errors.bride.bridePersonalEmail = 'SAME_EMAIL_CONTRACT';
        errors.groom.groomPersonalEmail = 'SAME_EMAIL_CONTRACT';
    }

    const bridePersonalPhone = String(values?.bride?.bridePersonalPhone)?.toLowerCase()?.trim();
    const groomPersonalPhone = String(values?.groom?.groomPersonalPhone)?.toLowerCase()?.trim();
    if (values?.bride?.bridePersonalPhone && values?.groom?.groomPersonalPhone && (bridePersonalPhone === groomPersonalPhone)) {
        errors.bride.bridePersonalPhone = 'SAME_PHONE_NUMBER_CONTRACT';
        errors.groom.groomPersonalPhone = 'SAME_PHONE_NUMBER_CONTRACT';
    }

    const errorsGeneral = FormValidator.make({
        contractLanguage: "required",
        contractType: "required",
        trueData: "required",
        coupleIdFiles: "required|min:1|max:2"
    })(values);

    if (values?.coupleIdFiles?.length === 0) {
        errorsGeneral['coupleIdFiles'] = 'INPUT_ERROR_REQUIRED';
    }

    errors = { ...errors, ...errorsGeneral };

    return errors;
};

class WeddingContractPage extends React.Component {
    state = {
        wedding: {},
        ready: false,
        loading: false,
    }

    componentDidMount() {
        const { initialValues, history, match: { params } } = this.props;

        if (!params?.id) {
            return history.goBack();
        }

        document.body.style.overflowY = "auto";
        window.scroll({ top: 0, left: 0, behavior: "smooth" });

        this.getReserve();
    }


    getReserve = async () => {
        const { match: { params }, history } = this.props;

        const result = await GetReserve(params.id);

        if (result?.success) {
            const now = moment.utc();
            const reserveIsActive = now.isSameOrBefore(moment.utc(result?.data.endActivationDate));

            if (reserveIsActive
                && result?.data?.status == ReserveStatus.RSC) {
                this.setState({
                    reserve: result?.data,
                    ready: true
                });
            } else {
                return history.goBack();
            }

        } else {
            return history.goBack();
        }
    }

    translateContractTypes = (contractType) => {
        const { translate } = this.props;
        return translate(contractType.name);
    }

    handleFileAdd = async file => {
        const payload = new FormData();
        payload.append("image", file);
        return await AddCoupleIdFiles(payload);
    };

    handleFileDelete = async id => {
        return await RemoveCoupleIdFiles(id);
    };

    onSubmit = async (values) => {
        const { translate, history, dispatch } = this.props;
        const { reserve } = this.state;
        this.setState({ loading: true });

        const result = await ConfirmReserve(reserve._id, values);
        console.log('result', result);

        if (result?.success) {
            Alerts.new({
                type: "success",
                title: translate("SUCCESS"),
                text: translate('WEDDING_SUBMITTED_CONTRACT'),
            });
            //  window.location.reload(true);
            dispatch(updateAboutUsAction(result?.data?.wedding));
            this.setState({ loading: false }, () => {
                return history.push('/confirmations')
            });
        } else {
            if (
                result?.data === "ERROR_DATE_OCCUPIED" ||
                result?.data === "ERROR_CANT_CONFIRM" ||
                result?.data === "ERROR_DATE_UNAVAILABLE" ||
                result?.data === "NOT_AUTHORIZED"
            ) {
                Alerts.new({
                    type: "error",
                    title: translate("ERROR"),
                    text: translate(result?.data),
                });
            } else {
                Alerts.new({
                    type: "error",
                    title: translate("ERROR"),
                    text: result?.data,
                });
            }
            return history.goBack();
        }

    };

    getPax = () => {
        const { reserve } = this.state;
        const now = moment.utc();
        const reserveIsActive = now.isSameOrBefore(
            moment.utc(reserve.endActivationDate)
        );
        return reserveIsActive
            ? reserve.priceObj.minPax
            : reserve.price.minPax;
    }

    getPrice = () => {
        const { reserve } = this.state;
        const now = moment.utc();
        const reserveIsActive = now.isSameOrBefore(
            moment.utc(reserve.endActivationDate)
        );
        return reserveIsActive
            ? reserve.priceObj.minPrice
            : reserve.price.minPrice;
    }

    getTotal = () => {
        const { reserve } = this.state;
        const now = moment.utc();
        const reserveIsActive = now.isSameOrBefore(
            moment.utc(reserve.endActivationDate)
        );
        const price = reserveIsActive
            ? reserve.priceObj?.minPrice
            : reserve.price?.minPrice;
        const pax = reserveIsActive
            ? reserve.priceObj?.minPax
            : reserve.price?.minPax;

        return Number((price * pax).toFixed(2))
    }

    render() {
        const { translate, activeLanguage } = this.props;
        const { handleSubmit, submitFailed, invalid, dirty, submitting } = this.props;
        const { loading, ready } = this.state;
        const { wedding, trueData, initialValues } = this.props;
        const { reserve } = this.state;

        if (!ready) return <SpinLoading />;

        return (
            <ProfileContainer>
                <Title style={{ marginBottom: 10 }}>{translate("RESERVE_INFO_CONTRACT")}</Title>
                <ReserveInfo>
                    <ReserveText>
                        <b>{translate('DATE')}:</b>
                        {moment.utc(reserve?.date).format('DD/MM/YYYY')}
                    </ReserveText>
                    <ReserveText>
                        <b>{translate('WEDDING_PLACE')}:</b>
                        {reserve?.weddingPlace == 2 ? 'Quinta Lago dos Cisnes' : 'Solar da Levada'}
                    </ReserveText>
                    <ReserveText>
                        <b>{translate('PAX')}:</b>
                        {this.getPax()}
                    </ReserveText>
                    <ReserveText>
                        <b>{translate('PRICE')}:</b>
                        <CurrencyComponent value={this.getPrice()} />
                    </ReserveText>
                    <ReserveText>
                        <b>{translate('MINIMUM_GLOBAL_PRICE')}:</b>
                        <CurrencyComponent value={this.getTotal()} />
                    </ReserveText>
                </ReserveInfo>


                <Title style={{ marginTop: 30 }}>{translate("COUPLE_INFO_CONTRACT")}</Title>
                <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                        component={SelectLanguageInput}
                        name={'contractLanguage'}
                        type={'select'}
                        label={`${translate('WEDDING_LANGUAGE_CONTRACT')} *`}
                    />

                    <Field
                        component={SelectInput}
                        name={'contractType'}
                        data={weddingContractTypes}
                        type={'select'}
                        costumeLabel={this.translateContractTypes}
                        label={`${translate('CONTRACT_TYPE_CONTRACT')} *`}
                    />

                    <Row gutter={[12, 12]}>
                        <FormSection name="bride">
                            <SectionSubtitle>{translate('GROOM_BRIDE_WEDDING_CONTRACT')}</SectionSubtitle>
                            {weddingContractBrideFields.map((field, key) => (
                                <Col key={key} xs={field?.xs || 24} sm={field?.sm || 24} md={field?.md || 24} lg={field?.lg || 24} xl={field?.xl || 24}>
                                    <Field
                                        key={field.name}
                                        component={field.component}
                                        name={field.name}
                                        type={field.type}
                                        data={field?.dataOptions}
                                        dataKey={field?.dataKey}
                                        costumeLabel={(country) => field?.costumeLabel(activeLanguage, country)}
                                        label={
                                            field.asterisc
                                                ? `${translate(field.label)} *`
                                                : translate(field.label)
                                        }
                                    />
                                </Col>
                            ))}
                        </FormSection>
                    </Row>

                    <Row gutter={[12, 12]}>
                        <FormSection name="groom">
                            <SectionSubtitle>{translate('GROOM_BRIDE_WEDDING_CONTRACT')}</SectionSubtitle>
                            {weddingContractGroomFields.map((field, key) => (
                                <Col key={key} xs={field?.xs || 24} sm={field?.sm || 24} md={field?.md || 24} lg={field?.lg || 24} xl={field?.xl || 24}>
                                    <Field
                                        key={field.name}
                                        component={field.component}
                                        name={field.name}
                                        type={field.type}
                                        data={field?.dataOptions}
                                        dataKey={field?.dataKey}
                                        costumeLabel={(country) => field?.costumeLabel(activeLanguage, country)}
                                        label={
                                            field.asterisc
                                                ? `${translate(field.label)} *`
                                                : translate(field.label)
                                        }
                                    />
                                </Col>
                            ))}
                        </FormSection>
                    </Row>

                    <Field
                        component={FilesInput}
                        name={"coupleIdFiles"}
                        label={`${translate('WEDDING_FILES_CONTRACT')} *`}
                        handleDelete={this.handleFileDelete}
                        handleAdd={this.handleFileAdd}
                        maxFiles={2}
                        accept= {['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                    />

                    {wedding?.alternativeGuest && wedding?.alternativeGuest?.email?.trim() !== '' && <Field
                        component={CheckboxInput}
                        name={'validateThirdEmail'}
                        label={translate('VALIDATE_THIRD_EMAIL_CONTACT')}
                    />}

                    <Field
                        component={CheckboxInput}
                        name={'trueData'}
                        label={translate('VALIDATE_TRUE_DATA_CONTACT')}
                    />
                    {trueData && <SaveButton
                        htmlType="submit"
                        error={submitFailed && invalid}
                        text={translate("SAVE")}
                        textMobile={translate("SAVE")}
                        // onClick={() => this.setState({ goToNextPage: true })}
                        loading={loading}
                    />}
                </FormContainer>
            </ProfileContainer>
        )
    }
};

const selector = formValueSelector('wedding_contract_form');

WeddingContractPage = reduxForm({
    form: 'wedding_contract_form',
    validate: validations
})(WeddingContractPage);

const mapStateToProps = state => ({
    wedding: state.wedding.wedding,
    initialValues: {
        ...state.wedding.wedding,
        bride: {
            brideFullName: state?.wedding?.wedding?.bride?.fullName,
            brideNationality: state?.wedding?.wedding?.bride?.nationality,
            brideIdentification: state?.wedding?.wedding?.bride?.identification,
            brideIdentificationNumber: state?.wedding?.wedding?.bride?.identificationNumber,
            brideStreetDoorNumber: state?.wedding?.wedding?.bride?.streetDoorNumber,
            brideZipCode: state?.wedding?.wedding?.bride?.zipCode,
            brideStreet: state?.wedding?.wedding?.bride?.street,
            brideCountry: state?.wedding?.wedding?.bride?.country,
            bridePersonalEmail: state?.wedding?.wedding?.bride?.personalEmail,
            bridePersonalPhone: state?.wedding?.wedding?.bride?.personalPhone,
        },
        groom: {
            groomFullName: state?.wedding?.wedding?.groom?.fullName,
            groomNationality: state?.wedding?.wedding?.groom?.nationality,
            groomIdentification: state?.wedding?.wedding?.groom?.identification,
            groomIdentificationNumber: state?.wedding?.wedding?.groom?.identificationNumber,
            groomStreetDoorNumber: state?.wedding?.wedding?.groom?.streetDoorNumber,
            groomZipCode: state?.wedding?.wedding?.groom?.zipCode,
            groomStreet: state?.wedding?.wedding?.groom?.street,
            groomCountry: state?.wedding?.wedding?.groom?.country,
            groomPersonalEmail: state?.wedding?.wedding?.groom?.personalEmail,
            groomPersonalPhone: state?.wedding?.wedding?.groom?.personalPhone,
        },
        validateThirdEmail: false,
        trueData: false
    },
    coupleIdFiles: state.wedding.coupleIdFiles,
    brideFullName: selector(state, 'brideFullName'),
    brideNationality: selector(state, 'brideNationality'),
    brideIdentification: selector(state, 'brideIdentification'),
    brideIdentificationNumber: selector(state, 'brideIdentificationNumber'),
    brideStreetDoorNumber: selector(state, 'brideStreetDoorNumber'),
    brideZipCode: selector(state, 'brideZipCode'),
    brideStreet: selector(state, 'brideStreet'),
    brideCountry: selector(state, 'brideCountry'),
    bridePersonalEmail: selector(state, 'bridePersonalEmail'),
    bridePersonalPhone: selector(state, 'bridePersonalPhone'),
    groomFullName: selector(state, 'groomFullName'),
    groomNationality: selector(state, 'groomNationality'),
    groomIdentification: selector(state, 'groomIdentification'),
    groomIdentificationNumber: selector(state, 'groomIdentificationNumber'),
    groomStreetDoorNumber: selector(state, 'groomStreetDoorNumber'),
    groomZipCode: selector(state, 'groomZipCode'),
    groomStreet: selector(state, 'groomStreet'),
    groomCountry: selector(state, 'groomCountry'),
    groomPersonalEmail: selector(state, 'groomPersonalEmail'),
    groomPersonalPhone: selector(state, 'groomPersonalPhone'),
    trueData: selector(state, 'trueData'),
});

const mapActionToProps = (dispatch) =>
    bindActionCreators({ updateAboutUsAction }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(WeddingContractPage))