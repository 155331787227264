import TextInput from "../../components/inputs/TextInput";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";
import MaterialNumberInput from "../../components/inputs/MaterialNumberInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import SelectInput from "../../components/inputs/SelectInput";
import { visitedLocals } from "../agenda/ReserveInfoModal";
import { getCountryOptions, getCountryTranslation } from "../../infra/services/utils/Countries";

export const brideFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    contract: true,
    name: "fullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "nationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "identification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "zipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "street",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "country",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "personalEmail",
    type: "text",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "personalPhone",
    type: "text",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const groomFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    contract: true,
    name: "fullName",
    type: "text",
    label: "FULLNAME_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "nationality",
    type: "text",
    label: "NATIONALITY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "identification",
    type: "text",
    label: "IDENTIFICATION_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "identificationNumber",
    type: "text",
    label: "IDENTIFICATION_NUMBER_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "streetDoorNumber",
    type: "text",
    label: "STREET_DOOR_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "zipCode",
    type: "text",
    label: "ZIP_CODE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "street",
    type: "text",
    label: "STREET_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "country",
    type: "text",
    label: "COUNTRY_CONTRACT",
    component: SelectInput,
    disabled: true,
    asterisc: false,
    dataOptions: getCountryOptions(),
    dataKey: 'value',
    costumeLabel: getCountryTranslation
  },
  {
    name: "personalEmail",
    type: "text",
    label: "PERSONAL_EMAIL_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "personalPhone",
    type: "text",
    label: "PERSONAL_PHONE_CONTRACT",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const guestFields = [
  {
    name: "name",
    type: "text",
    label: "ALTERNATIVE_GUEST_NAME",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "contact",
    type: "tel",
    label: "ALTERNATIVE_GUEST_CELLPHONE",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "email",
    type: "email",
    label: "ALTERNATIVE_GUEST_EMAIL",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const weddingFields = [
  {
    name: "language",
    type: "select",
    label: "COMMUNICATION_LANGUAGE",
    disabled: false,
    component: SelectLanguageInput
  }
];
