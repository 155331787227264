import client from "../config/AxiosConfig";

export const GetWedding = async () => client.get("/my-wedding");

export const GetWeddingInfo = async () => client.get("/my-wedding/wedding");

export const MyBudget = async () => client.get("/my-wedding/budget");

export const CreateBudget = async data => client.post('/my-wedding/budget', data);

export const RemoveBudget = async id => client.delete(`/my-wedding/budget/${id}`);

export const GetByTag = async tag => client.get(`/sections/${tag}`);

export const UpdateAboutUs = async data =>
  client.put("/my-wedding/about", data);

export const UpdateProfile = async data =>
  client.put("/my-wedding/profile", data);

export const MngGuestAccess = async (data) =>
  client.post("/my-wedding/guestAccess", data);

export const NotifyGuest = async () =>
  client.post("/my-wedding/notifyGuest");

export const UpdateReserveRelatedInfo = async data =>
  client.put("/my-wedding/relatedReserve", data);

export const UpdateWaitingInfo = async data =>
  client.put("/my-wedding/updateWaitingInfo", data);

export const UpdateInterestInfo = async data =>
  client.put("/my-wedding/updateInterestInfo", data);

export const UpdateBillings = async data =>
  client.put("/my-wedding/about/billing", data);

export const AnswerQuestion = async data =>
  client.put("/my-wedding/answer", data);

export const ReservesBlock = async () =>
  client.post("/my-wedding/reserveBlock");

export const GetSidedishes = async data =>
  client.get("my-wedding/food/sidedishes", data);

export const GetNewsPaginated = async (page, limit) =>
  client.get(`/my-wedding/activeNews/${page}/${limit}`);

export const GetNewsDetail = async (id) =>
  client.get(`/my-wedding/activeNew/${id}`);

export const SaveWeddingContract = async data =>
  client.post('/my-wedding/weddingContract', data);

export const AddCoupleIdFiles = async data =>
  client.post('/my-wedding/addCoupleIdFiles', data);

export const RemoveCoupleIdFiles = async (id) =>
  client.delete(`/my-wedding/removeCoupleIdFiles/${id}`);
