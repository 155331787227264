import React, { useEffect, useState } from "react";
import { withLocalize } from "react-localize-redux";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import { 
  PageContainer, 
  Title
} from "./PageStyles";

const PageContent = ({ id, tag, translate }) => {
  const [content, setContent] = useState({});

  useEffect(() => {
    async function getContent() {
      const { data } = await GetDynamicPage(id);
      setContent(data);
    }
    getContent();
    document.body.style.overflowY = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <Title>{translate(tag)}</Title>
      <DraftJSContent content={content?.description} />
    </PageContainer>
  );
};

export default withLocalize(PageContent);