import React from "react";
import { PageContainer, Page, ArrowImg } from "./PaginationStyles";
import ArrowRight from "../../assets/icons/arrow_right.svg";
import ArrowLeft from "../../assets/icons/arrow_left.svg";

const Pagination = ({ totalItems, currentPage, perPage, changePage }) => {
  const totalPages = Math.ceil(totalItems / perPage);

  const renderPages = () => {
    let pages = [];
    let inferior = currentPage - 1;
    let superior = currentPage + 1;

    if(currentPage === 1) {
      superior = currentPage + 2;
    }
    else if(currentPage === totalPages) {
      inferior = currentPage - 2;
    }

    if((currentPage - 2) > 0 && totalPages > 3) {
      pages.push(<Page key='prev' onClick={() => changePage(currentPage - 1)}><ArrowImg src={ArrowLeft}/></Page>);
    }

    for(let i = inferior; i <= superior && i <= totalPages; i++) {
      if(i > 0) {
        pages.push(<Page key={i} active={i === currentPage} onClick={() => changePage(i)}>{i}</Page>);
      }
    }

   if((currentPage + 2) <= totalPages && totalPages > 3) {
      pages.push(<Page key='next' onClick={() => changePage(currentPage + 1)}><ArrowImg src={ArrowRight}/></Page>);
    }

    return pages;
  }

  return(
    <React.Fragment>
      {
        totalItems > perPage &&
        <PageContainer>
          {renderPages()}
        </PageContainer>
      }
    </React.Fragment>
  );
};

export default Pagination;