/*eslint no-useless-escape: "off"*/

import * as moment from "moment";
import { Languages } from "../translations/AvailableTranslations";

class FormRules {
  static required(label, rule, value) {
    if (!value || value === "") {
      return { _error: 'INPUT_ERROR_REQUIRED' };
    }
    return undefined;
  }

  static array(label, rule, value) {
    if (!Array.isArray(value) || (Array.isArray(value) && value.length < 1)) {
      return { _error: `${label} EMPTY`.toUpperCase() };
    }
    return undefined;
  }

  static repeat_password(label, rule, value, params, values) {
    if (value !== values["password"]) {
      return { _error: 'INPUT_ERROR_PASS_MATCH' };
    }
    return undefined;
  }

  static isInteger(label, rule, value) {
    // if (!Number.isInteger(parseInt(value))) {
    if (value && !Number.isInteger(Number(value))) {
      return { _error: 'INPUT_ERROR_INTEGER_NUMBER' };
    }
    return undefined;
  }

  static minNumber(label, rule, value, params) {
    if (params.length > 0) {
      const minValue = params[0];
      if (value && value < minValue) {
        return { _error: ['INPUT_ERROR_MIN', '#min#', minValue] };
      }
    }
    return undefined;
  }

  static maxNumber(label, rule, value = '', params) {
    if (params.length > 0) {
      const maxValue = params[0];
      if (value && value > maxValue) {
        return { _error: ['INPUT_ERROR_MAX', '#max#', maxValue] };
      }
    }
    return undefined;
  }

  static min(label, rule, value, params) {
    if (params.length > 0) {
      const minLength = params[0];
      if (!value || value.length < minLength) {
        return { _error: ['INPUT_ERROR_MIN', '#min#', minLength] };
      }
    }
    return undefined;
  }
  static max(label, rule, value = "", params) {
    if (params.length > 0) {
      const maxLength = params[0];
      if (value) {
        if (value.length > maxLength) {
          return { _error: ['INPUT_ERROR_MAX', '#max#', maxLength] };
        }
      }
    }
    return undefined;
  }

  static email(label, rule, value) {
    const expression = /^(([^<>()\\[\]\\.,;:\s@\\"]+(\.[^<>()\\[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: 'INPUT_ERROR_INVALID_EMAIL' };
    }
    return undefined;
  }

  static date(label, rule, value) {
    const isInValid = moment(value).isValid() === false;
    if (isInValid) {
      return { _error: 'INPUT_ERROR_INVALID_DATE' };
    }
    return undefined;
  }

  static languages(label, rule, value) {
    let error = {};
    Languages.map(lang => {
      if (value && value[lang.code] && value[lang.code] !== "") {
      } else {
        error[lang.code] = {
          _error: `${label} REQUIRED ${lang.code}`.toUpperCase()
        };
      }
      return lang;
    });
    return error;
  }

  static noSpaces(label, rule, value) {
    if (!value) return { _error: `${label}_${rule}`.toUpperCase() };
    if (value && value.indexOf(" ") >= 0) {
      return { _error: `${label}_${rule}`.toUpperCase() };
    }
    return undefined;
  }

  static domain(label, rule, value) {
    const expression = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    const isInvalid = expression.test(value) === false;
    if (isInvalid) {
      return { _error: `${label}_${rule}_INVALID`.toUpperCase() };
    }
    return undefined;
  }

  static emptyLanguage(label, rule, value) {
    if (!value || value === "")
      return { _error: { en: `${label}_${rule}_INVALID`.toUpperCase() } };
    if (Object.keys(value).length === 0)
      return { _error: { en: `${label}_${rule}_EMPTY`.toUpperCase() } };

    if (!value?.en)
      return { _error: { en: `${label}_${rule}_PRIMARY`.toUpperCase() } };

    const errors = [];
    Object.keys(value).map(key => {
      if (!value[key] || value[key] === "")
        errors.push({ key, _error: `${label}_${rule}_EMPTY`.toUpperCase() });
      return key;
    });

    if (errors.length) {
      const result = { _error: {} };
      errors.map(error => {
        result._error[error.key] = `${label}_${rule}_EMPTY`.toUpperCase();
        return error;
      });
      return result;
    }

    return undefined;
  }
}

export default FormRules;
