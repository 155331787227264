import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, Field, initialize } from "redux-form";
import { connect } from "react-redux";
import { DetailContainer, FormContainer } from "../decoration/PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import {
  findPageByTag,
  findPageByID,
} from "../../infra/services/utils/PageInfo";
import {
  findNextChapter,
  findPrevQuestion,
} from "../../infra/services/utils/PageNavigation";
import GuestsProvisionalListForm from "./GuestsProvisionalListForm";
import {
  GetGuests,
  EditProvisoryInfo,
} from "../../infra/requests/GuestsRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";

const sectionTag = "GUESTS";
const sectionId = "5e2ada5d47f38787fd9e8423";
const returnURL = "/guests";

const FormValidations = (values) => {
  //console.log('FORM', values);
  return {};
};

class GuestsProvisionalList extends Component {
  state = {
    chapter: undefined,
    nextChapter: undefined,
    prevQuestion: undefined,
    provisoryGuests: undefined,
    guestsLocation: undefined,
    isProcessClosed: undefined,
    ready: false,
    loading: false,
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    const { weddingInfo, decorationInfo, dispatch } = this.props;

    const { chapters } = findPageByTag(decorationInfo, sectionTag);
    const chapter = findPageByID(chapters, sectionId);
    const nextChapter = findNextChapter(
      decorationInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    const prevQuestion = findPrevQuestion(decorationInfo, chapters, chapter, 0);
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    dispatch(initialize("section_form", {}));

    const resultGuests = await GetGuests();
    let provisoryGuests = 0,
      guestsLocation = "";

    if (resultGuests.success) {
      provisoryGuests = resultGuests.data.provisory_guests;
      guestsLocation = resultGuests.data.guests_location;

      dispatch(
        initialize("section_form", {
          provisory_guests: resultGuests.data.provisory_guests,
          guests_location: resultGuests.data.guests_location,
        })
      );
    }

    this.setState({
      chapter,
      nextChapter,
      prevQuestion,
      provisoryGuests,
      guestsLocation,
      isProcessClosed,
      ready: true,
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { history } = this.props;
      const { nextChapter } = this.state;

      const { success } = await EditProvisoryInfo(values);

      if (success) {
        return history.push(`${returnURL}/${nextChapter?._id}`);
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      weddingInfo,
      handleSubmit,
      translate,
      activeLanguage,
      history,
      dirty,
      submitting,
    } = this.props;
    const {
      chapter,
      nextChapter,
      prevQuestion,
      provisoryGuests,
      guestsLocation,
      isProcessClosed,
      ready,
      loading,
    } = this.state;

    if (!ready) return <SpinLoading />;

    let num_answers = 0;
    if (provisoryGuests > 0) {
      num_answers++;
    }
    if (guestsLocation) {
      num_answers++;
    }

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirty && !submitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <ProgressBar
          type="section"
          num_questions={2}
          num_answers={num_answers}
        />
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${sectionId}`}
          nextQuestion={nextChapter?.name[activeLanguage?.code]}
          nextQuestionUrl={`${returnURL}/${nextChapter?._id}`}
          prevQuestion={
            prevQuestion?.tag
              ? translate(prevQuestion?.tag)
              : prevQuestion?.name[activeLanguage?.code]
          }
          prevQuestionUrl={prevQuestion?.url}
        />
        <DetailContainer>
          <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
            <Field
              name="response"
              component={GuestsProvisionalListForm}
              questions={chapter.questions}
              weddingInfo={weddingInfo}
              isProcessClosed={isProcessClosed}
            />
            {!isProcessClosed && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                loading={loading}
              />
            )}
          </FormContainer>
          {isProcessClosed && (
            <SaveButton
              htmlType="button"
              text={translate("CONTINUE")}
              textMobile={translate("CONTINUE")}
              withBackground={true}
              onClick={() => history.push(`${returnURL}/${nextChapter?._id}`)}
            />
          )}
        </DetailContainer>
      </React.Fragment>
    );
  }
}

GuestsProvisionalList = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(GuestsProvisionalList);

const mapStateToProps = (state) => ({
  decorationInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding,
});

export default connect(mapStateToProps)(
  withLocalize(withRouter(GuestsProvisionalList))
);
