import React from "react";
import {withLocalize} from "react-localize-redux";
import { 
  Container,
  Text,
  TextContainer
} from "./NoImageStyles";

const NoImage = ({ translate, tag }) => {
  return (
    <Container>
      <TextContainer>
        <Text>{translate(tag ? tag : 'NO_IMAGE')}</Text>
      </TextContainer>
    </Container>
  );
};

export default withLocalize(NoImage);