import React from "react";
import { Switch } from "react-router-dom";

import AuthenticatedRoute from "./types/AuthenticatedRoute";
import UnauthenticatedRoute from "./types/UnauthenticatedRoute";
import CombinedRoutes from "./CombinedRoutes";

import NotFoundComponent from "../pages/notFound/NotFoundPage";

const ApplicationRoutes = ({ childProps }) => {
  return (
    <Switch>
      {CombinedRoutes.map((route, key) =>
        route.isAuthenticated ? (
          <AuthenticatedRoute
            key={key}
            path={route.path}
            exact
            component={route.component}
            props={childProps}
            route={route}
          />
        ) : (
          <UnauthenticatedRoute
            path={route.path}
            key={key}
            exact
            component={route.component}
            props={childProps}
            route={route}
          />
        )
      )}
      <AuthenticatedRoute component={NotFoundComponent} props={childProps} />
    </Switch>
  );
};

export default ApplicationRoutes;
