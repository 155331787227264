import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH4 } from '../../styles/TextSizes';

export const StaffContainer = styled.div`
  width: 100%;
  padding: 20px ${p => p.type === 'filled' ? '10px' : '0px'};
  background-color: ${p => p.type === 'filled' ? p.theme.thirdColor : '#ffffff'};
  margin-top: ${p => p.type === 'filled' ? '80px' : '0px'};

  @media ${device.desktopL} {
    margin-top: ${p => p.type === 'filled' ? '60px' : '0px'};
  }

  @media ${device.laptop} {
    margin-top: ${p => p.type === 'filled' ? '40px' : '0px'};
  }
`;

export const StaffContent = styled.div`
  
`;

export const Category = styled(titleH4)`
  width: 100%;
  text-align: left;
  line-height: 29px;
`;

export const TextAreaContainer = styled.div`
  margin-top: 60px;
`;

export const RemoveContainer = styled.div`
  width: 100%;
  text-align: right;
  margin-bottom: 20px;
`;

export const RemoveImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const SelectContainer = styled.div`
  width: 100%;
  max-width: 293px;
  position: relative;

  @media ${device.tablet} {
    max-width: 100%;
  }
`;

export const NewOptionContainer = styled.div`
  width: 100%;
  margin-left: 30px;
  max-width: 293px;
  display: ${p => p.showNew ? 'block' : 'none'};
  position: relative;

  & div {
    margin-top: 0px;
    height: 40px;
  }

  & input {
    margin-top: 0px;
    height: 40px;
    padding-right: 30px;
  }

  @media ${device.tablet} {
    margin-left: 0px;
    margin-top: 15px;
    max-width: 100%;
  }
`;