export const sideMenuItems = [
  {
    name: "START",
    url: "/"
  },
  {
    name: "ABOUT_US",
    url: "/about-us"
  },
  {
    name: "OUR_STYLE",
    url: "/our-style"
  },
  {
    name: "OUR_DAY",
    url: "/our-day"
  },
  {
    name: "CEREMONY",
    url: "/ceremony"
  },
  {
    name: "STAFF",
    url: "/staff"
  },
  {
    name: "GUESTS",
    url: "/guests"
  },
  {
    name: "FOOD_SELECTION",
    url: "/food-selection"
  },
  {
    name: "DRINK_SELECTION",
    url: "/drink-selection"
  },
  {
    name: "DECORATION",
    url: "/decoration"
  },
  {
    name: "PARTY_SELECTION",
    url: "/party-selection"
  }
]