export const findNextUpgrade = (upgrades, current) => {
  let res, foundCurrent = 0;

  //When calling the function by recursion, "current === 0" because we want the first upgrade
  if(current === 0) {
    foundCurrent = 1;

    if(upgrades.length > 0) {
      res = upgrades[0];
    }
  } 
  else {
    for(let i = 0; i < upgrades.length; i++) {
      //Found the "Next Upgrade"
      if(foundCurrent === 1) {
        res = upgrades[i];
        break;
      }

      //If we found the current upgrade, in the next loop will be the upgrade we want
      if(upgrades[i]._id === current) {
        foundCurrent = 1;
      }
    }
  }

  //If we found the current upgrade, but don't have any result, then the current upgrade was the last. Must return the first upgrade
  if(foundCurrent === 1 && !res) {
    res = findNextUpgrade(upgrades, 0);
  }

  return res;
};

export const findPrevUpgrade = (upgrades, current) => {
  let res, foundCurrent = 0;

  //When calling the function by recursion, "current === 0" because we want the last upgrade
  if(current === 0) {
    foundCurrent = 1;

    if(upgrades.length > 0) {
      res = upgrades[upgrades.length - 1];
    }
  } 
  else {
    for(let i = 0; i < upgrades.length; i++) {
      //If we found the current upgrade, in the previous loop was the upgrade we want. So we break the cycle and maintain the value of the last upgrade
      if(upgrades[i]._id === current) {
        foundCurrent = 1;
        break;
      }

      res = upgrades[i];
    }
  }

  //If we found the current upgrade, but don't have any result, then the current upgrade was the first. Must return the last upgrade
  if(foundCurrent === 1 && !res) {
    res = findPrevUpgrade(upgrades, 0);
  }

  return res;
};