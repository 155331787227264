import { SAVE_UPGRADE } from "../ActionsType";

const upgrade = '';

export default (state = upgrade, action) => {
  switch (action.type) {
    case SAVE_UPGRADE:
      return action.upgrade;
    default:
      return state;
  }
};
