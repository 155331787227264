import styled from 'styled-components';

export const CardsContainer = styled.div`
  max-width: 970px;
  margin: auto;
  ${p => p?.inModal && `
    overflow: auto;
    max-height: calc(100vh - 350px);
  `}
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;

export const ListContainer = styled.div`
  max-width: 970px;
  margin: auto;
  margin-bottom: 30px;
  padding: 0px 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > div {
    width: auto;

    &:first-child {
      margin-top: 0px;
    }
  }
`;