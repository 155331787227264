import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector, change, initialize, FormSection } from 'redux-form';
import { Modal, Button } from 'antd';
import { BaseForm } from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import TextAreaInput from '../../components/inputs/TextAreaInput';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileInput from '../../components/inputs/FileInput';
import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import SelectInput from '../../components/inputs/SelectInput';
import { BillingUsersEnum } from './UtilBillingUsers';
import TextInput from '../../components/inputs/TextInput';
import { getCountryOptions, getCountryTranslation } from '../../infra/services/utils/Countries';
import { isValidPhoneNumber } from 'react-phone-number-input';

export const SectionSubtitle = styled.div`
  text-align: left;
  //padding-top: 15px;
  white-space: pre-line;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  //margin-top: 40px;

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
  }
`;

const validations = values => {
  let error = {};

  error.billing = FormValidator.make({
    user: 'required',
    fullName: 'required',
    // nationality: 'required',
    // identification: 'required',
    identificationNumber: 'required',
    streetDoorNumber: 'required',
    zipCode: 'required',
    street: 'required',
    country: 'required',
    document: 'required'
    // personalEmail: 'required',
    // personalPhone: 'required'
  })(values?.billing);

  // if (values?.billing?.personalPhone && !isValidPhoneNumber(values?.billing?.personalPhone)) {
  //   error.billing.personalPhone = 'INVALID_PHONE_NUMBER_CONTRACT';
  // }

  const billingErrors = FormValidator.make({
    receipt: 'required',
    description: 'required',
    document: 'required'
  })(values);

  error = { ...error, ...billingErrors };

  return error;
};

let PaymentProofModal = ({
  open,
  edit,
  loading,
  handleSubmit,
  onSubmit,
  closeModal,
  type,
  isProcessClosed,
  translate,
  wedding,
  activeLanguage,
  dispatch,
  change,
  budgetForm,
  onlyView,
  initialValues
}) => {

  const onBillingUserSelect = (billingUser) => {
    const data = { ...budgetForm };
    data['billing'] = {
      user: billingUser,
      fullName: (billingUser === 'GROOM') ? wedding?.groom?.fullName : (billingUser === 'BRIDE') ? wedding?.bride?.fullName : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.fullName : '',
      identificationNumber: (billingUser === 'GROOM') ? wedding?.groom?.identificationNumber : (billingUser === 'BRIDE') ? wedding?.bride?.identificationNumber : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.identificationNumber : '',
      streetDoorNumber: (billingUser === 'GROOM') ? wedding?.groom?.streetDoorNumber : (billingUser === 'BRIDE') ? wedding?.bride?.streetDoorNumber : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.streetDoorNumber : '',
      zipCode: (billingUser === 'GROOM') ? wedding?.groom?.zipCode : (billingUser === 'BRIDE') ? wedding?.bride?.zipCode : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.zipCode : '',
      street: (billingUser === 'GROOM') ? wedding?.groom?.street : (billingUser === 'BRIDE') ? wedding?.bride?.street : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.street : '',
      country: (billingUser === 'GROOM') ? wedding?.groom?.country : (billingUser === 'BRIDE') ? wedding?.bride?.country : (billingUser === 'LAST_OTHER') ? wedding?.otherUserBilling?.country : '',
    };

    dispatch(initialize('add_payment_proof_form', data));
    change('billing', data?.billing);
  }

  const getBillingUsers = () => {
    const billingUsers = [
      { _id: BillingUsersEnum.GROOM, name: wedding?.groom?.fullName || wedding?.groom?.name },
      { _id: BillingUsersEnum.BRIDE, name: wedding?.bride?.fullName || wedding?.bride?.name },
    ];

    if (wedding?.otherUserBilling && wedding?.otherUserBilling?.fullName?.trim() !== '') {
      billingUsers.push({ _id: BillingUsersEnum.LAST_OTHER, name: wedding?.otherUserBilling?.fullName});
    }

    billingUsers.push({ _id: BillingUsersEnum.OTHER, name: 'OTHER' });
    return billingUsers;
  }

  return (
    <Modal
      visible={open}
      title={onlyView ? translate('VIEW_PAYMENT_PROOF') : translate('ADD_PAYMENT_PROOF')}
      maskClosable={false}
      onCancel={closeModal}
      footer={!onlyView ? [
        <Button key="back" onClick={closeModal}>
          {translate('CANCEL')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          loading={loading}
          onClick={handleSubmit(onSubmit)}>
          {translate('SAVE')}
        </Button>
      ] : [<Button key="back" onClick={closeModal}>
        {translate('CANCEL')}
      </Button>]}>
      {open && (
        <BaseForm onSubmit={handleSubmit(onSubmit)}>
          <SectionSubtitle>{translate('PAYMENT_PROOF')}</SectionSubtitle>
          <Field
            disabled={onlyView}
            component={FileInput}
            name={'document'}
            label={`${translate('UPLOAD_PAYMENT_PROOF_FILE')} *`}
            translate={translate}
            filePreview={onlyView ? true : false}
            addFile={onlyView ? false : true}
            accept= {['image/png', 'image/jpeg', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
          />

          {onlyView && initialValues?.description.trim() && <Field
            disabled={onlyView}
            component={TextAreaInput}
            name={'description'}
            label={translate('DESCRIPTION')}
            placeholder={translate('DESCRIPTION_PAYMENT_PROOF')}
            inForm={true}
            readOnly={false}
          />}

          {!onlyView && <Field
            disabled={onlyView}
            component={TextAreaInput}
            name={'description'}
            label={translate('DESCRIPTION')}
            placeholder={translate('DESCRIPTION_PAYMENT_PROOF')}
            inForm={true}
            readOnly={false}
          />}

          <SectionSubtitle style={{ marginTop: '20px' }}>{translate('BILLING_DATA')}</SectionSubtitle>
          <FormSection name='billing'>
            <Field
              disabled={onlyView}
              component={SelectInput}
              name={'user'}
              data={getBillingUsers()}
              costumeLabel={(userBilling) => (userBilling.name === 'OTHER') ? translate(userBilling.name) : userBilling.name }
              label={`${translate('BILLING_USER')} *`}
              onChange={(billingUser) => onBillingUserSelect(billingUser)}
            />

            {budgetForm?.billing?.user && <React.Fragment>
              <Field
                disabled={onlyView}
                component={TextInput}
                name={'fullName'}
                type={'text'}
                label={`${translate('FULLNAME_CONTRACT')} *`}
              />


              {/* <Field
                disabled={onlyView}
                component={SelectInput}
                name={'nationality'}
                data={getCountryOptions()}
                dataKey={'value'}
                costumeLabel={(country) => getCountryTranslation(activeLanguage, country)}
                label={`${translate('NATIONALITY_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'identification'}
                type={'text'}
                label={`${translate('IDENTIFICATION_CONTRACT')} *`}
              /> */}

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'identificationNumber'}
                type={'text'}
                label={`${translate('IDENTIFICATION_NUMBER_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'streetDoorNumber'}
                type={'text'}
                label={`${translate('STREET_DOOR_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'zipCode'}
                type={'text'}
                label={`${translate('ZIP_CODE_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'street'}
                type={'text'}
                label={`${translate('STREET_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={SelectInput}
                name={'country'}
                data={getCountryOptions()}
                dataKey={'value'}
                costumeLabel={(country) => getCountryTranslation(activeLanguage, country)}
                label={`${translate('COUNTRY_CONTRACT')} *`}
              />

              {/* <Field
                disabled={onlyView}
                component={TextInput}
                name={'personalEmail'}
                type={'email'}
                label={`${translate('PERSONAL_EMAIL_CONTRACT')} *`}
              />

              <Field
                disabled={onlyView}
                component={TextInput}
                name={'personalPhone'}
                type={'tel'}
                label={`${translate('PERSONAL_PHONE_CONTRACT')} *`}
              /> */}
            </React.Fragment>}
          </FormSection>
        </BaseForm>
      )}
    </Modal>
  );
};


PaymentProofModal = reduxForm({
  form: 'add_payment_proof_form',
  validate: validations
})(PaymentProofModal);

const selector = formValueSelector('add_payment_proof_form');

const mapStateToProps = state => ({
  type: selector(state, 'type'),
  budgetForm: {
    document: selector(state, 'document'),
    description: selector(state, 'description'),
    billing: {
      user: selector(state, 'billing.user')
    }
  }
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ change }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentProofModal);
