export const sliderItems = [
  {
    name: "ABOUT_US",
    url: "/about-us",
    questions: 0,
    answers: 0
  },
  {
    name: "OUR_STYLE",
    url: "/our-style",
    questions: 0,
    answers: 0
  },
  {
    name: "OUR_DAY",
    url: "/our-day",
    questions: 0,
    answers: 0
  },
  {
    name: "CEREMONY",
    url: "/ceremony",
    questions: 0,
    answers: 0
  },
  {
    name: "STAFF",
    url: "/staff",
    questions: 0,
    answers: 0
  },
  {
    name: "GUESTS",
    url: "/guests",
    questions: 0,
    answers: 0
  },
  {
    name: "FOOD_SELECTION",
    url: "/food-selection",
    questions: 0,
    answers: 0
  },
  {
    name: "DRINK_SELECTION",
    url: "/drink-selection",
    questions: 0,
    answers: 0
  },
  {
    name: "DECORATION",
    url: "/decoration",
    questions: 0,
    answers: 0
  },
  {
    name: "PARTY_SELECTION",
    url: "/party-selection",
    questions: 0,
    answers: 0
  }
]