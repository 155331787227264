import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2, titleH3 } from '../../styles/TextSizes';

export const ChoicesContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  margin: auto;
  margin-bottom: 40px;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 0px;
  }

  @media ${device.mobileL} {
    padding: 30px 0px;
  }
`;

export const SectionContainer = styled.div`
  width: 100%;
  margin-bottom: 75px;

  @media ${device.desktopL} {
    margin-bottom: 60px;
  }

  @media ${device.desktop} {
    margin-bottom: 40px;
  }
`;

export const Title = styled(titleH2)`
  width: 100%;
  text-align: left;
  margin-bottom: 70px;
  padding: 0px 15px;

  @media ${device.desktopL} {
    margin-bottom: 50px;
  }

  @media ${device.desktop} {
    margin-bottom: 30px;
  }
`;

export const TitleSection = styled(titleH3)`
  width: 100%;
  text-align: left;
  margin-bottom: 30px;
  padding: 0px 15px;

  @media ${device.desktop} {
    margin-bottom: 15px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
`;

export const CardsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
`;