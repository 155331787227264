import React from "react";
import {
  SuggestionsContainer,
  SuggestionsImage,
  ButtonContainer,
  ButtonImg
} from "./OptionCardStyles";
import Close from "../../assets/icons/close.svg";

const StyleSuggestions = ({record, toggleOption}) => {
  return (
    <SuggestionsContainer>
      <SuggestionsImage src={record.image?.url ? record.image?.url : record.url}/>
      <ButtonContainer type='suggestions' onClick={() => toggleOption(record)}>
        <ButtonImg src={Close}/>
      </ButtonContainer>
    </SuggestionsContainer>
  );
};

export default StyleSuggestions;