import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { reduxForm, FieldArray, initialize } from "redux-form";
import { connect } from "react-redux";
import {
  DetailContainer,
  FormContainer,
  Description,
} from "../decoration/PageDetailStyles";
import ProgressBar from "../../components/progressBar/ProgressBar";
import DecorationNavigation from "../../components/decorationNavigation/DecorationNavigation";
import SaveButton from "../../components/buttons/SaveButton";
import {
  findPageByTag,
  findPageByID,
} from "../../infra/services/utils/PageInfo";
import {
  findNextQuestion,
  findPrevChapter,
} from "../../infra/services/utils/PageNavigation";
import StaffExternalForm from "./StaffExternalForm";
import { GetStaff, EditStaffList } from "../../infra/requests/StaffRequests";
import FormValidator from "../../infra/services/validations/FormValidator";
import { SpinLoading } from "../../styles/BasicStyles";
import { processClosed } from "../../infra/services/utils/BlockAnswers";
import LeavePageBlocker from "../../components/pageBlocker/LeavePageBlocker";

const sectionTag = "STAFF";
const chapterID = "5e3182279621e5d6a3d89741";
const returnURL = "/staff";

const FormValidations = (values) => {
  const errors = { list: [] };

  if (values.list && values.list.length) {
    values.list.forEach((value) => {
      const error = FormValidator.make({
        category: "required",
        name: "required",
      })(value);

      errors.list.push(error);
    });
  }

  return errors;
};

class StaffExternal extends Component {
  state = {
    chapter: undefined,
    nextQuestion: undefined,
    prevChapter: undefined,
    addNewStaff: true,
    goToNextPage: false,
    requiredError: false,
    showNewCategory: [],
    isProcessClosed: undefined,
    ready: false,
    loading: false,
  };

  //Add's a new element when clicking "Add More" (controls the visibility of the "Create Category" field)
  addShowNew = () => {
    const { showNewCategory } = this.state;

    let array = [...showNewCategory];
    array.unshift(false);

    this.setState({
      showNewCategory: array,
    });
  };

  //Removes the first element when cancelling the "Add New Staff" (controls the visibility of the "Create Category" field)
  removeShowNew = () => {
    const { showNewCategory } = this.state;

    let array = [...showNewCategory];
    array.shift();

    this.setState({
      showNewCategory: array,
    });
  };

  //Toggles the "Create Category" field for each Staff
  toggleShowNew = (index, state) => {
    const { showNewCategory } = this.state;

    let array = [...showNewCategory];
    array[index] = state;

    this.setState({
      showNewCategory: array,
    });
  };

  //Controls the number of "Add New Staff" (limited to 1)
  toggleNewStaff = (state) => {
    this.setState({
      addNewStaff: state,
    });
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    document.body.style.overflowY = "auto";

    this.updateQuestion();
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps?.id) {
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
      document.body.style.overflowY = "auto";

      this.updateQuestion();
    }
  }

  updateQuestion = async () => {
    const { weddingInfo, decorationInfo, dispatch } = this.props;

    const { chapters } = findPageByTag(decorationInfo, sectionTag);
    const chapter = findPageByID(chapters, chapterID);
    const nextQuestion = findNextQuestion(decorationInfo, chapters, chapter, 0);
    const prevChapter = findPrevChapter(
      decorationInfo,
      chapter.section,
      chapters,
      chapter._id
    );
    // const isProcessClosed = processClosed(weddingInfo.date);
    const isProcessClosed = processClosed(weddingInfo.blockDate);

    dispatch(initialize("section_form", { list: [] }));

    const { data, success } = await GetStaff();
    let showNewCategory = [];

    if (success) {
      dispatch(
        initialize("section_form", {
          list: [].concat(data.list),
        })
      );

      //Every Staff starts with the "Create Category" field hidden
      showNewCategory = Array(data.list.length).fill(false);
    }

    this.setState({
      chapter,
      nextQuestion,
      prevChapter,
      showNewCategory,
      isProcessClosed,
      ready: true,
    });
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });

      const { dispatch, history, translate } = this.props;
      const { nextQuestion, goToNextPage } = this.state;
      let showNewCategory = [];

      if (values.list.length > 0) {
        for (let i = 0; i < values.list.length; i++) {
          //If we're creating a New Type of Staff
          if (values.list[i].category === translate("CREATE")) {
            //It's required
            if (!values.list[i].newCategory) {
              this.setState({
                requiredError: true,
              });

              return false;
            }

            values.list[i].category = values.list[i].newCategory;
            values.list[i].newCategory = "";
          }
        }

        //Every Staff starts with the "Create Category" field hidden
        showNewCategory = Array(values.list.length).fill(false);
      }

      this.setState({
        requiredError: false,
        showNewCategory,
      });

      let { data, success } = await EditStaffList(values);

      if (success) {
        //Clicked in the "Save" button on adding a new Staff
        //If "false" means we clicked in the "Add more" button
        if (!goToNextPage) {
          this.toggleNewStaff(true);

          //Get updated list of Staff
          ({ data, success } = await GetStaff());

          if (success) {
            dispatch(
              initialize("section_form", {
                list: [].concat(data.list),
              })
            );
          }
        }
        //Clicked in the "Save and continue" button
        else {
          return history.push(nextQuestion?.url);
        }
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      handleSubmit,
      translate,
      activeLanguage,
      history,
      dirty,
      submitting,
    } = this.props;
    const {
      chapter,
      nextQuestion,
      prevChapter,
      addNewStaff,
      requiredError,
      showNewCategory,
      isProcessClosed,
      ready,
      loading,
    } = this.state;

    if (!ready) return <SpinLoading />;

    return (
      <React.Fragment>
        <LeavePageBlocker
          when={dirty && !submitting}
          message={translate("UNSAVED_OPTION_CHANGES")}
        />
        <ProgressBar type="section" num_questions={0} num_answers={0} />
        <DecorationNavigation
          returnUrl={returnURL}
          mainCategory={chapter.name[activeLanguage?.code]}
          currentQuestion={chapter.name[activeLanguage?.code]}
          currentQuestionUrl={`${returnURL}/${chapterID}`}
          nextQuestion={
            nextQuestion?.tag
              ? translate(nextQuestion?.tag)
              : nextQuestion?.name[activeLanguage?.code]
          }
          nextQuestionUrl={nextQuestion?.url}
          prevQuestion={prevChapter?.name[activeLanguage?.code]}
          prevQuestionUrl={`${returnURL}/${prevChapter?._id}`}
        />
        <DetailContainer>
          <Description
            dangerouslySetInnerHTML={{
              __html:
                chapter.description &&
                chapter.description[activeLanguage?.code],
            }}
          />
          <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
            <FieldArray
              component={StaffExternalForm}
              name="list"
              addNewStaff={addNewStaff}
              toggleNewStaff={this.toggleNewStaff}
              toggleShowNew={this.toggleShowNew}
              showNewCategory={showNewCategory}
              addShowNew={this.addShowNew}
              removeShowNew={this.removeShowNew}
              requiredError={requiredError}
              isProcessClosed={isProcessClosed}
            />
            {!isProcessClosed && (
              <SaveButton
                htmlType="submit"
                text={translate("SAVE_CONTINUE")}
                textMobile={translate("SAVE")}
                withBackground={true}
                onClick={() => this.setState({ goToNextPage: true })}
                loading={loading}
              />
            )}
          </FormContainer>
          {isProcessClosed && (
            <SaveButton
              htmlType="button"
              text={translate("CONTINUE")}
              textMobile={translate("CONTINUE")}
              withBackground={true}
              onClick={() => history.push(nextQuestion?.url)}
            />
          )}
        </DetailContainer>
      </React.Fragment>
    );
  }
}

StaffExternal = reduxForm({
  form: "section_form",
  validate: FormValidations,
})(StaffExternal);

const mapStateToProps = (state) => ({
  decorationInfo: state.wedding.structure,
  weddingInfo: state.wedding.wedding,
});

export default connect(mapStateToProps)(
  withLocalize(withRouter(StaffExternal))
);
