import React from 'react'

const ArrowLeft = ({ fill }) => {
  return (
    <svg width="12px" height="18px" viewBox="0 0 12 18">
      <g id="Componentes-e-Styles" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
        <g id="Assets" transform="translate(-117.000000, -1891.000000)">
          <g id="01-Icons" transform="translate(78.000000, 101.000000)">
            <g id="Outros-icons" transform="translate(33.000000, 1715.000000)">
              <g id="seta_esquerda" transform="translate(0.000000, 72.000000)">
                <rect id="Rectangle-36-Copy-9" x="0" y="0" width="24" height="24"></rect>
                <g id="left" transform="translate(5.976562, 3.000000)" fill={ fill } fillRule="nonzero">
                  <path d="M10.978125,17.9929688 C10.753125,17.9929688 10.5257812,17.9179688 10.3359375,17.7679688 L0.403125,9.778125 C0.16171875,9.58359375 0.02109375,9.290625 0.02109375,8.97890625 C0.02109375,8.66953125 0.16171875,8.37421875 0.403125,8.18203125 L10.29375,0.234375 C10.734375,-0.11953125 11.3789062,-0.04921875 11.7328125,0.39140625 C12.0867187,0.83203125 12.0164062,1.4765625 11.5757812,1.83046875 L2.67890625,8.97890625 L11.6203125,16.171875 C12.0609375,16.5257813 12.13125,17.1703125 11.7773437,17.6109375 C11.5734375,17.8617188 11.278125,17.9929688 10.978125,17.9929688 Z" id="Shape"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArrowLeft