import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import {
  NewsContainer,
  NewsTitleContainer,
  NewsTitle,
  SeeAll,
  CardsContainer,
  CardsRow,
} from "./HomeStyles";
import NewsItem from '../../components/newsItem/NewsItem';
import Slider from '../../components/sliderDashboard/Slider';
import { SpinLoading } from "../../styles/BasicStyles";
import { GetContentByCategory } from "../../infra/requests/InformationRequests";
import DraftJSContent from "../../components/draftjs/DraftJSContent";
import {
  DetailContent,
  DetailContainer,
  Title,
  BackButton,
  DocumentsSection,
  NextWeddingsTitle,
  Backdrop,
  DocumentPreview
} from "../information/InformationStyles";
import Document from "../../components/document/Document";
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import BaseButton from "../../components/buttons/BaseButton";
import { GetNewsPaginated } from "../../infra/requests/MyWeddingRequests";

class HomePage extends Component {
  state = {
    loading: true,
    content: {},
    newsList: [],
  };

  componentDidMount() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'auto';

    this.getContent();
  }

  getContent = async () => {
    const { history, match: { params }, activeLanguage } = this.props;

    const { data } = await GetDynamicPage('63bec51458f9a5503e21081f');

    if (!data) {
      history.push('/404');
      return false;
    }

    //NEWS
    const resultNews = await GetNewsPaginated(1, 8);

    this.setState({
      content: data,
      loading: false,
      newsList: resultNews.success && resultNews.data && resultNews.data.items ? resultNews.data.items : []
    });
  };

  presentAgenda = () => {
    this.setState({ showAgenda: true }, () => {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
      document.body.style.overflowY = 'auto';
      this.props.history.push('/agenda');
    });
  }

  render() {
    const { translate, history, activeLanguage } = this.props;
    const { content, loading, newsList } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <DetailContent>
        <DetailContainer>
          <DraftJSContent content={content?.description} />

          <div style={{ textAlign: 'center', margin: '0 auto 40px auto' }}>
            <BaseButton type="primary" text={translate('AGENDA')} onClick={this.presentAgenda} />
          </div>

          { newsList.length > 0 &&
            <NewsContainer>
              <NewsTitleContainer>
                <NewsTitle>{translate('NEWS')}</NewsTitle>
                <SeeAll onClick={() => history.push('/news')}>{translate('SEE_ALL')}</SeeAll>
              </NewsTitleContainer>
              <CardsContainer>
                <CardsRow>
                  {
                    newsList.map((news, index) =>
                      <NewsItem
                        key={index}
                        id={news._id}
                        title={news.title}
                        date={news.date}
                        group={news.category}
                        image={news.image}
                      />
                    )
                  }
                </CardsRow>
              </CardsContainer>
              <NewsTitleContainer>
                <NewsTitle>&nbsp;</NewsTitle>
                <SeeAll onClick={() => history.push('/news')}>{translate('SEE_ALL')}</SeeAll>
              </NewsTitleContainer>
            </NewsContainer>
          }
        </DetailContainer>
      </DetailContent>
    );
  }
}

const mapStateToProps = state => ({
  sectionsInfo: state.wedding.structure
});

export default withLocalize(withRouter(connect(mapStateToProps)(HomePage)));