import { findPageByTag } from './PageInfo';

export const groupPublicNotes = (notesInfo) => {
  const notes = {};

  if (notesInfo.length > 0) {
    for (let i = 0; i < notesInfo.length; i++) {
      notes[notesInfo[i].section] = notesInfo[i].notes;
    }
  }

  return notes;
}

const findAnswers = (section, weddingPlace) => {
  let res = [];

  if (section && section.chapters?.length > 0) {
    for (let i = 0; i < section.chapters.length; i++) {
      let chapter = section.chapters[i];

      if (chapter && chapter.questions.length > 0) {
        for (let j = 0; j < chapter.questions.length; j++) {
          let question = chapter.questions[j];
          let response = undefined;

          //Save the response
          if (question.answer?.response) {
            //If type = 4 || 5 || 6, we need to check if the "local" is available in this wedding place
            if (question.type === 4 || question.type === 5 || question.type === 6) {
              //FIX: check every answer and return the first valid one. There might be invalid questions over the time
              for (let i = 0; i < question.answer.response.length; i++) {
                if (question.answer.response[i].id) {
                  response = findResponseByID(question, question.answer.response[i].id, weddingPlace);
                }
                else {
                  response = findResponseByID(question, question.answer.response[i], weddingPlace);
                }

                if (response) {
                  break;
                }
              }

              if (response) {
                res.push(question.answer);
              }
            }
            else {
              res.push(question.answer);
            }
          }

          //Specific TAG to show Observations as a new card (ONLY if question still has observations = true)
          if (question.observations && question.answer?.observations) {
            res.push({ ...question.answer, showObs: true });
          }
        }
      }
    }
  }

  return res;
}

export const groupAnswers = (sections, weddingPlace) => {
  let ceremony = [], food = [], drinks = [], decoration = [], party = [];
  let section;

  section = findPageByTag(sections, 'CEREMONY');
  ceremony = findAnswers(section, weddingPlace);

  section = findPageByTag(sections, 'FOOD');
  food = findAnswers(section, weddingPlace);

  section = findPageByTag(sections, 'DRINKS');
  drinks = findAnswers(section, weddingPlace);

  section = findPageByTag(sections, 'DECORATION');
  decoration = findAnswers(section, weddingPlace);

  section = findPageByTag(sections, 'PARTY');
  party = findAnswers(section, weddingPlace);

  return { ceremony, food, drinks, decoration, party };
};

export const findQuestionByID = (sections, id) => {
  let res;

  for (let h = 0; h < sections.length; h++) {
    if (sections[h].chapters.length > 0) {
      for (let i = 0; i < sections[h].chapters.length; i++) {
        if (sections[h].chapters[i].questions.length > 0) {
          for (let j = 0; j < sections[h].chapters[i].questions.length; j++) {
            if (sections[h].chapters[i].questions[j]._id === id) {
              res = sections[h].chapters[i].questions[j];
              break;
            }
          }
        }
      }
    }
  }

  return res;
};

export const findChapterByID = (sections, id) => {
  let res;

  for (let h = 0; h < sections.length; h++) {
    if (sections[h].chapters.length > 0) {
      for (let i = 0; i < sections[h].chapters.length; i++) {
        if (sections[h].chapters[i]._id === id) {
          res = sections[h].chapters[i];
          break;
        }
      }
    }
  }

  return res;
};

export const findResponseByID = (question, response, weddingPlace) => {
  let res;

  if (question.options.length > 0) {
    for (let i = 0; i < question.options.length; i++) {
      if (response && (response.id ? (response.id === question.options[i]._id) : (question.options[i]._id === response)) && (!question.options[i].local || question.options[i].local === weddingPlace)) {
        res = question.options[i];
        break;
      }
    }
  }

  return res;
};

export const findColorByID = (colors, response) => {
  let res;

  if (colors.length > 0) {
    for (let i = 0; i < colors.length; i++) {
      if (colors[i]._id === response) {
        res = colors[i];
        break;
      }
    }
  }

  return res;
};

export const findFoodByID = (food, response, sidedishes) => {
  let res;

  if (food.length > 0) {
    for (let i = 0; i < food.length; i++) {
      if (response && response.id && food[i]._id === response.id) {
        res = {
          food: food[i],
          sidedishes: response && response.sidedishes && response.sidedishes.length > 0 && sidedishes && sidedishes.length > 0 && response.sidedishes.map(m => sidedishes.find(f => f._id === m))
        };
        break;
      } else if (response && !response.id && food[i]._id === response) {
        res = food[i];
        break;
      }
    }
  }

  return res;
};