import React, { useEffect, useState } from "react";
import { Modal, Button, Icon } from 'antd';
import styled from 'styled-components';
import { GetDynamicPage } from "../../infra/requests/BaseRequests";
import { SpinLoading } from "../../styles/BasicStyles";
import { TranslateFromObject } from "../../infra/services/translations/AvailableTranslations";

export const StyledDraftJS = styled.div`
  display: inline-block;
  width: 100%;
  height: calc(100vh - 250px);
  padding: 24px;
  overflow: auto;
  text-align: left;
  white-space: pre-line;
`;

let GuestPolicyModal = ({ openModal, closeModal, translate, activeLanguage }) => {

  const [content, setContent] = useState({});
  const [loading, toogleLoading] = useState(true);

  useEffect(() => {
    async function getContent() {
      toogleLoading(true);
      const { data } = await GetDynamicPage('65b3820fb42ba97e4a0db4cb');
      setContent(data);
      toogleLoading(false);
    }
    getContent();
    document.body.style.overflowY = 'auto';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      visible={openModal}
      title={translate('TERMS_CONSIDERATIONS_WD')}
      maskClosable
      onCancel={closeModal}
      className="policy-modal"
      footer={[,
        <Button
          key='submit'
          loading={loading}
          type='primary'
          onClick={() => closeModal()}>
          {translate('CLOSE')}
        </Button>
      ]}
    >
      {loading
        ? <SpinLoading />
        : <StyledDraftJS
          dangerouslySetInnerHTML={{
            __html: TranslateFromObject(content?.description || '', activeLanguage.code)
          }}
        />
      }
    </Modal>
  );
};

export default GuestPolicyModal;